import { Image, Modal, Text, TouchableOpacity, useWindowDimensions, View } from 'react-native'
import React from 'react'
import tw from 'twrnc';
import { AntDesign } from '@expo/vector-icons';
import { Colores } from '../../constants/colores';

const ModalCorreoEnviado = ({modalVisible, setModalVisible, ocultarModalRecuperarPassword, email , setEmail}) => {
    const {width} = useWindowDimensions();

  return (
    <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            Alert.alert('Modal has been closed.');
            setModalVisible(!modalVisible);
          }}>
              <View style={[tw`bg-white flex-1`, width > 758 && { alignItems: 'center' } ]}>
                <View style={tw`items-center`}>
                    <Image source={require('../../assets/Login/IconoEmailEnviado.png')} style={[tw`w-full h-40 mt-10`,{resizeMode:'contain'}]} />
                    <Text style={tw`font-semibold text-lg mt-7`}>Hemos enviado un email a</Text>
                    <View style={[tw`mt-5 border py-2 px-3`,{borderColor:Colores.primary,borderRadius:15}]}><Text style={[tw`font-semibold text-lg`,{color:Colores.primary}]}>{email}</Text></View>
                </View>
                <View style={tw`mt-5`}>
                    <View style={tw`items-center`}>
                        <Text style={tw`text-lg`}>Por favor, asegúrate de:</Text>
                    </View>

                    <View style={tw`flex-row mt-5`}>
                        <View style={tw`justify-center flex-1 items-end`}>
                            <AntDesign name="checkcircleo" size={20} color={Colores.primary} style={tw`mr-2`} />
                        </View>
                        <View style={tw`justify-center flex-6`}>
                            <Text>Haber escrito correctamente</Text>
                        </View>
                    </View>

                    <View style={tw`flex-row mt-5`}>
                        <View style={tw`justify-center flex-1 items-end`}>
                            <AntDesign name="checkcircleo" size={20} color={Colores.primary} style={tw`mr-2`} />
                        </View>
                        <View style={tw`justify-center flex-6`}>
                            <Text>El email debe estar vinculado a tu perfil</Text>
                        </View>
                    </View>

                    <View style={tw`flex-row mt-5`}>
                        <View style={tw`justify-center flex-1 items-end`}>
                            <AntDesign name="checkcircleo" size={20} color={Colores.primary} style={tw`mr-2`} />
                        </View>
                        <View style={tw`justify-center flex-6`}>
                            <Text>Revisa las carpetas de spam o notificaciones en tu bandeja de entrada</Text>
                        </View>
                    </View>

                    <View style={tw`mt-20 items-center`}>
                        <TouchableOpacity onPress={()=>{setModalVisible(false), ocultarModalRecuperarPassword(false), setEmail('')}}>
                            <Text style={[tw`underline text-lg`,{color:Colores.primary}]}>Volver al inicio de sesión</Text>
                        </TouchableOpacity>
                    </View>
 
                </View>
              </View>
        </Modal>
  )
}

export default ModalCorreoEnviado

