import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import React from "react";
import {
  Pressable,
  Text,
  View,
} from "react-native";
import { useMutation, useQueryClient } from "react-query";
import tw from "twrnc";

import { elegirMenu } from "../../services/apiClient";
import { awesomeAlertAtom } from "../../store/store";
import ResultadoModal from "../ResultadoModal";
import { Colores } from "../../constants/colores";
import DetallePlato from "./DetallePlato";

function MenuDelDia({ menu, setMostrarModalSeleccionar }) {
  const [, setawsomeAlert] = useAtom(awesomeAlertAtom);
  const reset = useResetAtom(awesomeAlertAtom);
  const queryClient = useQueryClient();
  const elegirMenuMutate = useMutation(elegirMenu, {
    onSuccess: async (response) => {
      if (!response.data.ok) {
        setawsomeAlert({
          show: true,
          showProgress: false,
          onDismiss: reset,
          customView: (
            <ResultadoModal success={false} message={response.data.message} />
          ),
        });
        console.error(response.data.message);
      } else {
        console.log("menu seleccionado", response.data);
        queryClient.invalidateQueries("menuSemanalSeleccionado");
      }
    },
    onError: async (error) => {
      console.log("error en consulta", error);
    },
  });

  const submit = (menuId) => {
    console.log("Se selecciono el menu", menuId);
    elegirMenuMutate.mutate(menuId);
    setMostrarModalSeleccionar(false);
  };

  return (
    <Pressable key={`detalle-menu-${menu.id}`} onPress={() => submit(menu.id)}>
      <View
        style={tw`flex flex-row items-center justify-between flex-wrap border rounded-lg border-gray-300 my-1 mx-2 shadow-lg rounded-md`}
      >
        <View style={tw`flex  flex-1`}>
          <View
            style={[
              tw`px-3 py-1 absolute right-1 top-1 z-40 rounded-full`,
              { backgroundColor: Colores.primaryMedium },
            ]}
          >
            <Text style={tw`capitalize text-white`}>
              {menu.tipo.tipo}
            </Text>
          </View>
          <DetallePlato plato={menu.comida} tipo="Plato Principal" />
          <DetallePlato plato={menu.guarnicion} tipo="Guarnición" />
          <DetallePlato plato={menu.postre} tipo="Postre" />
        </View>
      </View>
    </Pressable>
  );
}

export default MenuDelDia;
