import React from "react";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import moment from "moment";
import tw from 'twrnc';

import { Colores } from "../../constants/colores";
import MenuDelDiaDesktop from "./MenuDelDiaDesktop";

function MenuPorDiaDesktop({ menuSemanal, setMostrarModalSeleccionar, setMenu }) {
  //se agrupan los menu por dia
  const porDia = menuSemanal.reduce((grupo, menu) => {
    const dia = moment(menu["fecha"]).locale("es").format("dddd DD/MM/YYYY");
    (grupo[dia] = grupo[dia] || []).push(menu);
    return grupo;
  }, {});

  const dias = Object.entries(porDia).map((dia) => {
    const menu = dia[1].map((menu) => (
      <MenuDelDiaDesktop key={`menuDelDia-${menu.id}`} menu={menu} setMostrarModalSeleccionar={setMostrarModalSeleccionar} />
    ));

    return (
        <View style={tw`bg-white`}>
            <View style={[tw`px-3 py-2 mt-1`,{backgroundColor:Colores.primaryMedium}]}>
              <View style={tw`justify-center flex-5`}>
                <Text style={tw`text-white capitalize`}>{dia[0]}</Text>
              </View>
            </View>
            <View>
                <ScrollView horizontal={true}>
                {menu}
                </ScrollView>
            </View>
      </View>
    );
  });

  return <View>{dias}</View>;
}

export default MenuPorDiaDesktop;
