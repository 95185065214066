import AsyncStorage from "@react-native-async-storage/async-storage";

export async function setItem(key, value) {
  try {
    await AsyncStorage.setItem(key, value);
  } catch (error) {
    console.error("Error al intentar guardar en async storage", error);
  }
}

export async function getItem(key) {
  try {
    const result = await AsyncStorage.getItem(key);
    if (result) {
      return result;
    }
    return null;
  } catch (error) {
    console.error("Error al intentar leer en async storage", error);
  }
}

export async function deleteItem(key) {
  try {
    const result = await AsyncStorage.removeItem(key);
    console.log("eliminado", result);
    if (result) {
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error al intentar eliminar en async storate", error);
  }
}
