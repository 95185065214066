import React from "react";
import { Pressable, Text, View } from "react-native";
import tw from "twrnc";

import * as WebBrowser from "expo-web-browser";
import { MaterialCommunityIcons } from "@expo/vector-icons";

function BotonCertificadoLicencia({ url }) {
  return (
    <View style={{ backgroundColor: "white" }}>
      <Pressable
        onPress={async () => {
          await WebBrowser.openBrowserAsync(url);
          //TODO: si falla en android checkear si no es web usar https://docs.expo.dev/versions/latest/sdk/filesystem/
        }}
        foreground={false}
      >
        <View syle={tw`flex flex-row p-1 items-center justify-center`}>
          <MaterialCommunityIcons
            name="certificate-outline"
            size={24}
            color="black"
          />
          <Text>Ver certificado</Text>
        </View>
      </Pressable>
    </View>
  );
}



export default BotonCertificadoLicencia;
