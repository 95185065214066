import React from "react";
import { Text, View } from "react-native";
import tw from 'twrnc';
import { Colores } from "../../constants/colores";

function ColaboradorDatosDeContacto({
  emailCorporativo = "-",
  emailPersonal = "-",
  telCorporativo = "-",
  telPersonal = "-",
  datosPersonalesDisabled
}) {
  return (
    <View
      style={[tw`bg-white px-5 pb-7 pt-5 flex border border-gray-300 shadow-lg`,{borderRadius:20}]}
    >
      <View style={[tw`mb-3`,{borderBottomWidth:1, borderBottomColor:Colores.primaryLight}]}>
        <Text style={[tw`text-2xl mb-2`,{color:Colores.primary}]}>Datos de Contacto</Text>
      </View>
      {!datosPersonalesDisabled && (
        <>
          <View style={tw`flex flex-row items-center mt-2`}>
            <Text style={[tw` font-semibold`,{color:Colores.primary}]}>
              Email Personal:
            </Text>
            <Text style={tw` ml-2`}>{emailPersonal ?? "-"}</Text>
          </View>
          <View style={tw`flex flex-row items-center mt-2`}>
            <Text style={[tw` font-semibold`,{color:Colores.primary}]}>
              Tel Personal:
            </Text>
            <Text style={tw` ml-2`}>{telPersonal ?? "-"}</Text>
          </View>
          
          <View style={tw`px-10`}>
          <View style={[tw`my-5`,{borderBottomWidth:1, borderBottomColor:Colores.primaryLight}]} />
          </View>
        </>
      )}

      <View style={tw`flex flex-row items-center`}>
        <Text style={[tw` font-semibold`,{color:Colores.primary}]}>
          Tel Corporativo:
        </Text>
        <Text style={tw` ml-2`}>{telCorporativo ?? "-"}</Text>
      </View>
      <View style={tw`flex flex-row items-center mt-2`}>
        <Text style={[tw` font-semibold`,{color:Colores.primary}]}>
          Email Corporativo:
        </Text>
        <Text style={tw` ml-2`}>{emailCorporativo ?? "-"}</Text>
      </View>

    </View>
  );
}

export default ColaboradorDatosDeContacto;
