import React from "react";
import { Text, View } from "react-native";
import { filtrarPermisos } from "../utils/permisos";
import PermisosBadge from "./PermisosBadge";
import tw from 'twrnc';
import { Colores } from "../constants/colores";

function DatosPermisos({ permisos }) {
  const administrar = filtrarPermisos(permisos, "administrar", true);
  const editar = filtrarPermisos(permisos, "editar", true);
  const ver = filtrarPermisos(permisos, "ver", true);
  const crear = filtrarPermisos(permisos, "crear", true);
  const eliminar = filtrarPermisos(permisos, "eliminar", true);

  return (
    <View style={tw`bg-white p-1 flex  gap-8`}>
      <View style={tw`flex  flex-wrap`}>
        <Text style={[tw` font-semibold`,{color:Colores.primary}]}>
          Administrar
        </Text>
        <View style={tw`flex-1 flex flex-row flex-wrap gap-8`}>
          <PermisosBadge permisos={administrar} />
        </View>
      </View>

      <View style={tw`flex  items-start flex-wrap m-1`}>
        <Text style={[tw` font-semibold mt-2`,{color:Colores.primary}]}>Editar</Text>
        <View style={tw`flex-1 flex flex-row flex-wrap gap-4`}>
          <PermisosBadge permisos={editar} />
        </View>
      </View>

      <View style={tw`flex  items-start flex-wrap m-1`}>
        <Text style={[tw` font-semibold mt-2`,{color:Colores.primary}]}>Crear</Text>
        <View style={tw`flex-1 flex flex-row flex-wrap gap-4`}>
          <PermisosBadge permisos={crear} />
        </View>
      </View>

      <View style={tw`flex  items-start flex-wrap m-1`}>
        <Text style={[tw` font-semibold mt-2`,{color:Colores.primary}]}>Ver</Text>
        <View style={tw`flex-1 flex flex-row flex-wrap gap-4`}>
          <PermisosBadge permisos={ver} />
        </View>
      </View>

      <View style={tw`flex  items-start flex-wrap m-1`}>
        <Text style={[tw` font-semibold mt-2`,{color:Colores.primary}]}>Eliminar</Text>
        <View style={tw`flex-1 flex flex-row flex-wrap gap-4`}>
          <PermisosBadge permisos={eliminar} />
        </View>
      </View>
    </View>
  );
}

export default DatosPermisos;
