import React from "react";
import {
  ScrollView,
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { FontAwesome5, FontAwesome, Feather } from "@expo/vector-icons";
import CantidadEntidad from "../../components/Empresa/CantidadEntidad";
import { PageContainer } from "../../components/PageContainer";
import tw from 'twrnc';
import { Colores } from "../../constants/colores";

export default function AdministrarEmpresaScreen(props) {
  const { empresa } = props.route.params;
  const {width} = useWindowDimensions();

  return (
    <PageContainer paddingMd>
      <ScrollView>
        <View style={[tw`bg-white p-5`, width > 768 && {borderRadius: 20}]}>
        <View style={[styles.container, tw`rounded-b-lg border-b-2 border-gray-200 bg-white`]}>
            <Text style={tw`font-semibold text-lg ml-2`}>
              <FontAwesome name="building" size={24} color="black" />{" "}
              {empresa.nombre}
            </Text>
            <TouchableOpacity
              style={[tw`absolute right-2 top-2 px-6 py-2.5 rounded shadow-md`,{backgroundColor:Colores.primary}]}
              onPress={() =>
                props.navigation.navigate("Crear Empresa", {
                  navigation: props.navigation,
                  empresa: empresa,
                })
              }
            >
              <Text style={tw`text-center text-white`}>Editar Empresa</Text>
            </TouchableOpacity>
            
            <View style={tw`flex flex-row`}>
                <View style={tw`ml-3`}>
                  <CantidadEntidad entidad="departamentos" cantidad={empresa.departamentos_count} />
                </View>
                <View style={tw`ml-3`}>
                  <CantidadEntidad entidad="colaboradores" cantidad={empresa.colaboradores_count} />
                </View>
            </View>
            
            <TouchableOpacity
              style={[tw`px-6 py-2.5 rounded shadow-md`,{backgroundColor:Colores.primary}]}
              onPress={() =>
                props.navigation.navigate("Crear Departamento", {
                  navigation: props.navigation,
                  empresaId: empresa.id,
                })
              }
            >
              <Text style={tw`text-center text-white`}>Crear Departamento</Text>
            </TouchableOpacity>
        </View>

        <View style={[styles.container,tw`bg-gray-100`]}>
          <View>
            {empresa?.departamentos.map((departamento) => (
              <View
                key={`departamento-${departamento.id}`}
                style={[styles.departamento, tw`rounded-lg border border-gray-200 shadow-md`]}
              >
                <View style={styles.puestosContainer}>
                  <Text style={styles.departamentoNombre}>
                    <FontAwesome5 name="building" size={20} color="#333333" />{" "}
                    {departamento.nombre}
                  </Text>
                  <CantidadEntidad
                    entidad="puestos"
                    cantidad={departamento?.puestos.length}
                  />
                  <CantidadEntidad
                    entidad="colaboradores"
                    cantidad={departamento?.colaboradores.length}
                  />
                </View>

                <View style={styles.buttonGroup}>
                  <TouchableOpacity
                    style={[tw`p-2 rounded-lg`,{backgroundColor:Colores.primary}]}
                    onPress={() => {
                      props.navigation.navigate("Administrar Departamento", {
                        departamento,
                        empresa: empresa,
                      });
                    }}
                  >
                    <Feather name="settings" size={20} color="white" />
                  </TouchableOpacity>
                  {/* <TouchableOpacity style={[tw`p-2 rounded-lg`,{backgroundColor:Colores.primary}]} onPress={() => {}}>
                    <Feather name="edit" size={20} color="white" />
                  </TouchableOpacity>
                  <TouchableOpacity style={tw`p-2 bg-red-500 rounded-lg`} onPress={() => {}}>
                    <FontAwesome5 name="trash-alt" size={20} color="white" />
                  </TouchableOpacity> */}
                </View>
              </View>
            ))}
          </View>
        </View>
        </View>
      </ScrollView>
    </PageContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    gap: 16,
  },
  empresaNombre: {
    fontWeight: "bold",
  },
  departamento: {
    backgroundColor: "white",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 16,
    marginBottom: 16,
  },
  departamentoNombre: {
    fontWeight: "bold",
    color: "#333333",
  },
  puestosContainer: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "8px",
  },
  puestosCount: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
  },
  buttonGroup: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
  },
  button: {
    alignItems: "center",
    backgroundColor: "#DDDDDD",
    padding: 10,
  },
});
