import React, { useState } from 'react';
import { Dimensions, Image, StyleSheet,View } from 'react-native';
import SwiperFlatList from 'react-native-swiper-flatlist';
import tw from 'twrnc';


const { width, height } = Dimensions.get('window');


export default ({archivos}) => {

  return (
      <SwiperFlatList
        autoplay
        autoplayDelay={5}
        autoplayLoop
        data={archivos}
        renderItem={({ item }) => <Image style={styles.image} source={item.foto} /> }
        showPagination
        paginationActiveColor='#5753dd'
        paginationStyleItem={{width: 10, height: 10}}
      />
  );
};

const styles = StyleSheet.create({
  image: {
    width,
    height: height * 0.7,
    resizeMode: 'contain',
  },
});
