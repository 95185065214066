import React, { useEffect, useState } from "react";
import {
  FlatList,
  Image,
  ScrollView,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";

import { useAtom } from "jotai";
import AvisoModal from "../components/Avisos/AvisoModal";
import {
  avisoAtom,
  esVisibleAvisoModalAtom,
  esVisibleHistoriaModalAtom,
  historiaAtom,
  notificacionesNoLeidasAtom,
  userAtom,
} from "../store/store";
import HistoriaModal from "../components/Historias/HistoriaModal";
import Historias from "../components/Historias/Historias";
import Avisos from "../components/Avisos/Avisos";
import { PageContainer } from "../components/PageContainer";
import AwesomeAlert from "react-native-awesome-alerts";
import {
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  eliminarAviso,
  getNotificaciones,
  marcarTodasNotificacionesLeidas,
  marcarNotificacionComoLeida,
} from "../services/apiClient";
import tw from "twrnc";
import { FloatingAction } from "react-native-floating-action";
import { Colores } from "../constants/colores";
import CrearAvisoModalDesktop from "../components/Avisos/CrearAvisoModalDesktop";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from "react-native-popup-menu";
import { Feather } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import CrearHistoriaModalDesktop from "../components/Avisos/CrearHistoriaModalDesktop";
import { FontAwesome } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import ModalCambiarPassword from "../components/Avisos/Homescreen/ModalCambiarPassword";
import moment from "moment";
import { momentLocale } from "../utils/momentSettings";

export default function HomeScreen(props) {
  const {navigation} = props;
  const [aviso] = useAtom(avisoAtom);
  const [historia] = useAtom(historiaAtom);
  const [historiaModalVisible] = useAtom(esVisibleHistoriaModalAtom);
  const [notificacionesNoLeidas, setNotificacionesNoLeidas] = useAtom(
    notificacionesNoLeidasAtom
  );
  const [avisoModalVisible] = useAtom(esVisibleAvisoModalAtom);
  const [refreshing, setRefreshing] = useState(false);
  const [avisoId, setavisoId] = useState();
  const [mostrarAlerta, setmostrarAlerta] = useState(false);
  const queryClient = useQueryClient();
  const { width } = useWindowDimensions();
  const [abrirModalCrearAviso, setAbrirModalCrearAviso] = useState(false);
  const [abrirModalCrearHistoria, setAbrirModalCrearHistoria] = useState(false);
  const [user, setUser] = useAtom(userAtom);
  const [mostrarNotificaciones, setMostrarNotificaciones] = useState(false);
  const [badgeNotificaciones, setBadgeNotificaciones] = useState();
  const [mostrarModalCambiarPassword, setMostrarModalCambiarPassword] = useState(user.password_change_at ? false : true)
  const notificaciones = useQuery("notificaciones", getNotificaciones);

  momentLocale();
  const tiempoTranscurrido = fecha => {
    const tiempo = moment(fecha).fromNow().charAt(0).toUpperCase() + moment(fecha).fromNow().slice(1);
    return tiempo;
  };

  const onRefresh = () => {
    setRefreshing(true);
  };

  const confirmar = (avisoId) => {
    setavisoId(avisoId);
    setmostrarAlerta(true);
  };

  const eliminarMutate = useMutation(eliminarAviso, {
    onSuccess: async (response) => {
      console.log("aviso eliminado", response.data);
      queryClient.invalidateQueries("avisos");
    },
    onError: async (error) => {
      console.log("hubo un error al intentar eliminar");
    },
    onSettled: (data) => {
      if (data.data.ok) {
      }
    },
  });

  const marcarNotificacion = useMutation(marcarNotificacionComoLeida, {
    onSuccess: async (response) => {
      queryClient.invalidateQueries("notificaciones");
    },
    onError: async (error) => { },
    onSettled: (data) => { },
  });

  const marcarTodasLeidas = useMutation(marcarTodasNotificacionesLeidas, {
    onSuccess: async (response) => {
      console.log("Todas leidas", response.data);
      queryClient.invalidateQueries("notificaciones");
    },
    onError: async (error) => {
      console.log("hubo un error al intentar");
    },
    onSettled: (data) => {
      if (data.data.ok) {
      }
    },
  });

  const badgeNotificacionesNoLeidas = () => {
    if (notificaciones?.data?.data?.notificaciones) {
      const resultado = notificaciones?.data?.data?.notificaciones.filter(
        (item) => item.read_at === undefined || item.read_at === null
      );
      setNotificacionesNoLeidas(resultado?.length);
    }
  };

  useEffect(() => {
    badgeNotificacionesNoLeidas();
  }, [notificaciones?.data?.data?.notificaciones, notificacionesNoLeidas]);

  return (
    <>
      {/* HEADER INICIO DESKTOP */}
      {width >= 768 && (
        <View style={tw`bg-white h-15 flex-row z-50`}>
          <View style={tw`flex-3`}>
            <Image
              style={[
                tw`ml-20`,
                {
                  width: 120,
                  height: 40,
                  padding: 30,
                  alignItems: "center",
                  resizeMode: "contain",
                },
              ]}
              source={require("../assets/workie_logo_morado.png")}
            />
          </View>

          <View style={tw`flex-3 flex-row justify-around`}>
            <View style={tw`justify-center`}>
              <TouchableOpacity onPress={() => navigation.navigate("Avisos")}>
                <Feather name="home" size={24} color={Colores.primaryMedium} />
              </TouchableOpacity>
            </View>
            <View style={tw`justify-center`}>
              <TouchableOpacity
                onPress={() => navigation.navigate("Habla con nosotros")}
              >
                <MaterialIcons
                  name="move-to-inbox"
                  size={24}
                  color={Colores.primaryMedium}
                />
              </TouchableOpacity>
            </View>
            <View style={tw`justify-center`}>
              <TouchableOpacity
                onPress={() => navigation.navigate("Beneficios")}
              >
                <MaterialCommunityIcons
                  name="ticket-percent"
                  size={24}
                  color={Colores.primaryMedium}
                />
              </TouchableOpacity>
            </View>
            <View style={tw`justify-center`}>
              <TouchableOpacity
                onPress={() => navigation.navigate("Menu Semanal")}
              >
                <FontAwesome
                  name="cutlery"
                  size={24}
                  style={tw`mr-3`}
                  color={Colores.primaryMedium}
                />
              </TouchableOpacity>
            </View>
            <View style={tw`justify-center`}>
              <TouchableOpacity
                onPress={() => navigation.navigate("Mi Perfil")}
              >
                <FontAwesome
                  name="user-circle"
                  size={24}
                  color={Colores.primaryMedium}
                />
              </TouchableOpacity>
            </View>
          </View>

          <View style={tw`flex-3 items-center justify-center`}>
            <View style={tw`flex-row h-full`}>
              <View nativeID="Publicar" style={tw`justify-center`}>
                <Menu renderer={renderers.NotAnimatedContextMenu} style={tw`rounded-lg`}>
                  <MenuTrigger>
                    <Feather name="edit" style={tw`p-4`} size={24} color={Colores.primary} />
                  </MenuTrigger>
                  <MenuOptions
                    style={tw`p-1`}
                    optionsContainerStyle={{ borderRadius: 10, marginTop: 60, marginLeft: -70 }}
                  >
                    <MenuOption
                      onSelect={() => setAbrirModalCrearAviso(true)}
                      style={[tw`flex flex-row justify-center px-2 py-2`]}
                    >
                      <Text style={tw`mr-10`}> Publicar </Text>
                      <FontAwesome5 name="th" size={20} style={tw`ml-10`}/>
                    </MenuOption>

                    <MenuOption 
                    onSelect={() => setAbrirModalCrearHistoria(true)}
                    style={tw`flex flex-row justify-center px-2 py-2`}
                    >
                      <Text style={tw`mr-10`}> Historia </Text>
                      <FontAwesome5
                        name="history"
                        size={20}
                        style={tw`ml-10`}
                      />
                    </MenuOption>
                  </MenuOptions>
                </Menu>
              </View>

              <View nativeID="Notificaciones" style={tw`justify-center z-50`}>
                <Menu renderer={renderers.NotAnimatedContextMenu} style={tw``}>
                  <MenuTrigger>
                  <View style={tw`p-4`}>
                        <FontAwesome name="bell" size={24} color={Colores.primary} />

                        {notificacionesNoLeidas > 0 && (
                          <View style={tw`w-4 h-4 ml-3 top-2 bg-red-500 rounded-full absolute items-center justify-center`}>
                            <Text style={tw`text-xs top-0 text-white text-center`} >
                              {notificacionesNoLeidas}
                            </Text>
                          </View>
                        )}
                      </View>
                    </MenuTrigger>

                  <MenuOptions
                    style={tw`shadow-xl p-1 h-80 absolute right-5 mt-2 bg-white rounded-xl px-2`}
                    optionsContainerStyle={{
                      borderRadius: 10,
                      marginTop: 52,
                      width: 200,
                    }}
                  >
                    <ScrollView>
                      <View style={tw`flex-row`}>
                        <View style={tw`flex-1 justify-center`}>
                          <Text style={tw`my-2`}>Notificaciones</Text>
                        </View>
                        <View style={tw`flex-1 justify-center items-end`}>
                          <TouchableOpacity
                            onPress={() => marcarTodasLeidas.mutate()}
                          >
                            <Text
                              style={[
                                tw`my-2 mr-2`,
                                { color: Colores.primary },
                              ]}
                            >
                              Marcar todas leidas
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>

                      <FlatList
                        data={notificaciones?.data?.data?.notificaciones}
                        keyExtractor={(item) => item.id}
                        renderItem={({ item, index }) => (
                          <MenuOption
                            style={[tw`flex-row px-2 py-2 mt-2 mr-2 w-100`, { backgroundColor: item.read_at ? '#f1f1f1' : '#d9f4ff', borderRadius: 8 }]}
                            onSelect={() => marcarNotificacion.mutate(item.id)}
                          >
                            <View style={tw`justify-center`}>
                              <Image
                                style={tw`h-7 w-7 rounded-full`}
                                source={{ uri: user?.foto }}
                              />
                            </View>

                            <View style={tw`justify-around ml-3 w-11/12`}>
                              <Text style={[tw`font-semibold`, { color: item.read_at ? '#4c4b4b' : '#47416e' }]}>{item.data.titulo}</Text>
                              <Text style={[tw`text-xs`, { color: item.read_at ? '#625d5d' : '#645d8b' }]}>{item.data.texto}</Text>
                              <Text style={tw`text-xs text-right text-slate-400 mr-3`}>{tiempoTranscurrido(item.created_at)}</Text>
                            </View>
                          </MenuOption>
                        )}
                      />
                    </ScrollView>
                  </MenuOptions>
                </Menu>
              </View>

              <View nativeID="usuario" style={tw`justify-center`}>
                <Menu renderer={renderers.NotAnimatedContextMenu} style={tw`rounded-lg`}>
                  <MenuTrigger>
                    <Image
                      source={{ uri: user?.foto }}
                      style={tw`h-8 w-8 mt-1 mx-3 rounded-full`}
                    />
                  </MenuTrigger>
                  <MenuOptions
                    style={tw`p-1`}
                    optionsContainerStyle={{ borderRadius: 10, marginTop: 50, marginLeft: 70 }}
                  >
                    <MenuOption
                      onSelect={() => setUser(null)}
                      style={tw`flex flex-row pl-1`}
                    >
                      <MaterialCommunityIcons
                        name="logout-variant"
                        size={20}
                        style={tw`mx-3`}
                        color={Colores.danger}
                      />

                      <Text style={{ color: Colores.danger }}> Salir </Text>
                    </MenuOption>
                  </MenuOptions>
                </Menu>
              </View>
            </View>
          </View>
        </View>
      )}

      {/* INICIO */}

      <PageContainer bgGray paddingXl {...props}>
        <View style={{ height: "100%" }}>
          <Historias navigation={props.navigation} />
          <Avisos navigation={props.navigation} confirmar={confirmar} />
        </View>

        <AwesomeAlert
            show={mostrarAlerta}
            showProgress={eliminarMutate.isLoading}
            title="Atención"
            message="Esta seguro que desea eliminar?"
            closeOnTouchOutside={true}
            closeOnHardwareBackPress={false}
            showCancelButton={true}
            showConfirmButton={true}
            cancelText="Cancelar"
            confirmText="Eliminar"
            confirmButtonColor="#DD6B55"
            onCancelPressed={() => {
              setmostrarAlerta(false);
            }}
            onConfirmPressed={() => {
              setmostrarAlerta(false);
              eliminarMutate.mutate(avisoId);
            }}
          />
        <AvisoModal visible={avisoModalVisible} aviso={aviso} />
        <HistoriaModal
          visible={historiaModalVisible}
          historia={historia}
          navigation={props.navigation}
        />
      </PageContainer>

      <CrearAvisoModalDesktop
        modalVisibleAvisoDesktop={abrirModalCrearAviso}
        setModalVisibleAvisoDesktop={setAbrirModalCrearAviso}
        navigation={navigation}
      />
      <CrearHistoriaModalDesktop
        modalVisibleHistoriaDesktop={abrirModalCrearHistoria}
        setModalVisibleHistoriaDesktop={setAbrirModalCrearHistoria}
        navigation={navigation}
      />
       <ModalCambiarPassword modalVisible={mostrarModalCambiarPassword} setModalVisible={setMostrarModalCambiarPassword  } />
    </>
  );
}
