import React from 'react'
import { View } from 'react-native'
import tw from "twrnc"
import { Colores } from '../../constants/colores'

const LineaStep = ({current}) => {
  return (
    <View
        style={[
          tw`w-32 h-2 bg-transparent border-2 border-transparent`,
          current
            ? tw`border-b-[${Colores.primary}]`
            : tw`border-b-gray-200`,
        ]}
      />
  )
}

export default LineaStep