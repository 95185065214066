import React, { useState } from "react";
import { Alert, Modal, StyleSheet, Text, CheckBox, Pressable, View, TouchableOpacity, useWindowDimensions } from "react-native";
import tw from 'twrnc';
import { Colores } from "../../constants/colores";

const ModalFiltrarComida = ({modalVisible, setModalVisible, filtrarComida}) => {

    const {width} = useWindowDimensions()
    const [comida, setComida] = useState(false)
    const [guarnicion, setGuarnicion] = useState(false)
    const [postre, setPostre] = useState(false)
    const [activo, setActivo] = useState(false)
    const [inactivo, setInactivo] = useState(false)

    const limpiarFiltros = () => {
        setComida(false)
        setGuarnicion(false)
        setPostre(false)
        setActivo(false)
        setInactivo(false)
    }

  return (
    <View style={tw`flex-1 justify-center items-center`}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          Alert.alert("Modal has been closed.");
          setModalVisible(!modalVisible);
        }}
      >
        <View style={[width >=768 ? tw`flex-1 justify-center items-center p-5` : tw`flex-1 justify-center p-5` ,{backgroundColor:'#7D7D7D50'}]}>
          <View style={[tw`bg-white p-5`,{borderRadius:10}]}>
            <View style={tw`flex-row`}>
                <View style={tw`flex-1 justify-center`}>
                    <Text style={tw`text-lg`}>Filtrar por:</Text>
                </View>
                <View style={tw`flex-1 items-end justify-center`}>
                    <TouchableOpacity onPress={()=>limpiarFiltros()}>
                        <Text style={[tw``,{color:Colores.primary}]}>Limpiar filtros</Text>
                    </TouchableOpacity>
                </View>
            </View>
            <View>
                <Text style={tw`text-lg`}>Categoria</Text>

                <View style={tw`px-5 flex-row`}>
                    <View style={tw`flex-1 justify-center`}>
                        <Text style={[tw`mt-2`,{color:'#7D7D7D'}]}>Comida</Text>
                    </View>
                    <View style={tw`flex-1 items-end justify-center`}>
                        <CheckBox
                        value={comida}
                        onValueChange={setComida}
                        />
                    </View>
                </View>

                <View style={tw`px-5 flex-row`}>
                    <View style={tw`flex-1 justify-center`}>
                        <Text style={[tw`mt-2`,{color:'#7D7D7D'}]}>Guarnicion</Text>
                    </View>
                    <View style={tw`flex-1 items-end justify-center`}>
                        <CheckBox
                        value={guarnicion}
                        onValueChange={setGuarnicion}
                        />
                    </View>
                </View>
                <View style={tw`px-5 flex-row`}>
                    <View style={tw`flex-1 justify-center`}>
                        <Text style={[tw`mt-2`,{color:'#7D7D7D'}]}>Postre</Text>
                    </View>
                    <View style={tw`flex-1 items-end justify-center`}>
                        <CheckBox
                        value={postre}
                        onValueChange={setPostre}
                        />
                    </View>
                </View>

            </View>

            <View style={[tw`my-5`,{borderBottomWidth:1, borderBottomColor:Colores.gray}]} />
            
            <View>
                <Text style={tw`text-lg`}>Estado</Text>

                <View style={tw`px-5 flex-row`}>
                    <View style={tw`flex-1 justify-center`}>
                        <Text style={[tw`mt-2`,{color:'#7D7D7D'}]}>Activos</Text>
                    </View>
                    <View style={tw`flex-1 items-end justify-center`}>
                        <CheckBox
                        value={activo}
                        onValueChange={setActivo}
                        disabled={inactivo}
                        />
                    </View>
                </View>
                <View style={tw`px-5 flex-row`}>
                    <View style={tw`flex-1 justify-center`}>
                        <Text style={[tw`mt-2`,{color:'#7D7D7D'}]}>Inactivos</Text>
                    </View>
                    <View style={tw`flex-1 items-end justify-center`}>
                        <CheckBox
                        value={inactivo}
                        onValueChange={setInactivo}
                        disabled={activo}
                        />
                    </View>
                </View>
            </View>

            <View style={tw`flex-row items-center justify-center mt-5`}>

                <TouchableOpacity style={[tw`py-2 px-10 mr-1`,{backgroundColor:Colores.primaryMedium, borderRadius:16}]} onPress={()=>setModalVisible(!modalVisible)}>
                    <Text style={tw`text-white`}>Salir</Text>
                </TouchableOpacity>

                <TouchableOpacity style={[tw`py-2 px-10 ml-1`,{backgroundColor:Colores.primary, borderRadius:16}]} onPress={()=>filtrarComida(comida, guarnicion, postre, activo, inactivo)}>
                    <Text style={tw`text-white`}>Aplicar</Text>
                </TouchableOpacity>
            </View>

          </View>
        </View>
      </Modal>
    </View>
  );
};

export default ModalFiltrarComida;