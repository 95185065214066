import React from "react";
import { StyleSheet, Text, View } from "react-native";
import tw from 'twrnc';
import { Colores } from "../../constants/colores";
import { AntDesign, Ionicons, MaterialCommunityIcons,Feather } from '@expo/vector-icons';

function Advertencia({ licenciaTipo = {}, visible = false }) {
  const {
    tipo,
    horaLimiteParaJustificar,
    horasLimiteParaSolicitud,
    limiteAnual,
    limiteMensual,
  } = licenciaTipo;

  if (visible) {
    return (
      <View>
        {tipo === "VACAIONES" && (
          <View style={{ display: "flex", flexDirection: "column" }}>
            <View style={[tw`p-3 my-3`,{backgroundColor:Colores.primaryLight, borderRadius:20}]}>
              <Text style={[tw`text-lg`,{color:Colores.primary}]}>
                Seguí a continuación los pasos para informarnos tús vacaciones.
              </Text>
            </View>

            <View style={[tw`py-3 pr-3 border border-gray-200 `,{borderRadius:20}]}>
              <View style={tw`flex-row`}>
                <View style={tw`flex-1 items-center justify-center`}>
                  <AntDesign name="calendar" size={20} color={Colores.primary} />
                </View>
                <Text style={tw`flex-6  mt-2`}>
                  - Ingresa los días de descanso 45 días antes de la fecha de inicio
                  para ser autorizadas por tu líder.
                </Text>
              </View>

              <View style={tw`flex-row`}>
                <View style={tw`flex-1 items-center justify-center`}>
                  <AntDesign name="closecircleo" size={20} color={Colores.primary} />
                </View>
                <Text style={tw`flex-6  mt-2`}>
                  - Recuerda que los días de licencia no son acumulables, son
                  válidos desde el 1 de octubre del año en curso hasta el 30 de
                  abril del año siguiente.{" "}
                </Text>
              </View>

              <View style={tw`flex-row`}>
                <View style={tw`flex-1 items-center justify-center`}>
                  <Ionicons name="chatbubbles-outline" size={20} color={Colores.primary} />
                </View>
                <Text style={tw`flex-6  mt-2`}>
                  - Podés solicitar extender tu licencia después del 30 de abril con
                  justificación a tu líder quien notificará a capital humano para
                  autorizarlas.{" "}
                </Text>
              </View>


              <View style={tw`flex-row`}>
                <View style={tw`flex-1 items-center justify-center`}>
                  <MaterialCommunityIcons name="note-text-outline" size={20} color={Colores.primary} />
                </View>
                  <Text style={tw`flex-6  mt-2`}>
                    - Cuando informes tus días de descanso, recuerda que deben
                    comenzar un lunes o el primer día hábil de la semana. Los días de
                    descanso correspondientes a tu antigüedad en la empresa son días
                    corridos independiente de feriados y fines de semanas.{" "}
                  </Text>
              </View>

              <View style={tw`flex-row`}>
                <View style={tw`flex-1 items-center justify-center`}>
                  <Feather name="inbox" size={20} color={Colores.primary} />
                </View>
                  <Text style={tw`flex-6  mt-2`}>
                    - Verás reflejada la liquidación de tus días de descanso en el
                    recibo de sueldo del mes en que tomaste tu licencia.
                  </Text>
              </View>
            </View>

          </View>
        )}

        {(tipo === "PROGRAMADA" || tipo === "NO PROGRAMADA") && (
          <View>
            <View style={[tw`p-3 my-3`,{backgroundColor:Colores.primaryLight, borderRadius:20}]}>
              <Text style={[tw`text-lg`,{color:Colores.primary}]}>
                Seguí a continuación los pasos para informarnos una licencia.
              </Text>
            </View>

            <View style={[tw`py-3 pr-3 border border-gray-200 shadow-md`,{borderRadius:20}]}>
              <View style={tw`flex-row`}>
                <View style={tw`flex-1 items-center justify-center`}>
                  <Feather name="clock" size={20} color={Colores.primary} />
                </View>
                <Text style={tw`flex-6  mt-2`}>
                  - Ingresa 72 horas antes tu solicitud de licencia o permiso para
                  ser autorizadas por tu líder.
                </Text>
              </View>

              <View style={tw`flex-row`}>
                <View style={tw`flex-1 items-center justify-center`}>
                  <AntDesign name="closecircleo" size={20} color={Colores.primary} />
                </View>
                <Text style={tw`flex-6  mt-2`}>
                  - Durante una licencia no programada, recuerda comunicar a tu
                  líder y a capital humano, antes de comenzar la jornada laboral o
                  inmediato al hecho ocurrido.
                </Text>
              </View>

              <View style={tw`flex-row`}>
                <View style={tw`flex-1 items-center justify-center`}>
                  <AntDesign name="calendar" size={20} color={Colores.primary} />
                </View>
                <Text style={tw`flex-6  mt-2`}>
                  - Para justificar la inasistencia deberás enviarnos la
                  documentación de respaldo, según lo establecido en la Política de
                  licencias y hasta 24 horas después del aviso de ausencia.
                </Text>
              </View>
              <View style={tw`flex-row`}>
                  <View style={tw`flex-1 items-center justify-center`}>
                    <Ionicons name="checkmark-done-circle-outline" size={20} color={Colores.primary} />
                  </View>
                  <Text style={tw`flex-6  mt-2`}>
                    - La falta de registro del trámite durante el día de ausencia se
                    verá reflejado en tu liquidación mensual como ausencia
                    injustificada.
                  </Text>
                  </View>
              </View>
            </View>
        )}

        {tipo === "PERMISO LABORAL" && (
          <View>
            <View style={[tw`p-3 my-3`,{backgroundColor:Colores.primaryLight, borderRadius:20}]}>
              <Text style={[tw`text-lg`,{color:Colores.primary}]}>
              Seguí a continuación los pasos para informarnos un permiso.
              </Text>
            </View>

            <View style={[tw`py-3 pr-3 border border-gray-200 shadow-md`,{borderRadius:20}]}>
              <View style={tw`flex-row`}>
                <View style={tw`flex-1 items-center justify-center`}>
                  <Feather name="clock" size={20} color={Colores.primary} />
                </View>
                <Text style={tw`flex-6  mt-2`}>
                  {" "}
                  - Ingresa 72 horas antes tu solicitud de permiso para ser
                  autorizadas por tu líder.
                </Text>
              </View>

              <View style={tw`flex-row`}>
                <View style={tw`flex-1 items-center justify-center`}>
                  <AntDesign name="closecircleo" size={20} color={Colores.primary} />
                </View>
                <Text style={tw`flex-6  mt-2`}>
                  - La falta de registro del trámite durante el día de ausencia se
                  verá reflejado en tu liquidación mensual como ausencia
                  injustificada.
                </Text>
              </View>

              <View style={tw`flex-row`}>
                <View style={tw`flex-1 items-center justify-center`}>
                  <AntDesign name="calendar" size={20} color={Colores.primary} />
                </View>
                <Text style={tw`flex-6  mt-2`}>
                  - Podrás solicitar un total de {limiteAnual} permisos anuales para trámites
                  personales, con máximo de {limiteMensual} permiso mensual.
                </Text>
              </View>

              <View style={tw`flex-row`}>
                  <View style={tw`flex-1 items-center justify-center`}>
                    <Ionicons name="checkmark-done-circle-outline" size={20} color={Colores.primary} />
                  </View>
                  <Text style={tw`flex-6  mt-2`}>
                    - La autorización del permiso será brindada por tu líder, teniendo
                    presente desempeño, permisos disponibles del equipo y las tareas
                    programadas durante la semana.
                  </Text>
              </View>
            </View>
          </View>
        )}
      </View>
    );
  } else {
    return null;
  }
}



export default Advertencia;
