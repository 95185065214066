import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  ActivityIndicator,
  TouchableOpacity,
  Text,
  TextInput,
  View,
} from "react-native";
import { useMutation, useQueryClient } from "react-query";
import tw from 'twrnc';
import { FontAwesome5 } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";

import MensajeError from "../components/MensajeError";
import RadioButton from "../components/RadioButton";
import ResultadoModal from "../components/ResultadoModal";
import { autorizarLicencia } from "../services/apiClient";
import { awesomeAlertAtom } from "../store/store";
import { Colores } from "../constants/colores";

function LicenciaAutorizarForm(props) {
  const navigator = useNavigation()
  const { licencia, cambiarEstado } = props;
  const [_, setAwsomeAlert] = useAtom(awesomeAlertAtom);
  const resetAlert = useResetAtom(awesomeAlertAtom);
  const [estado, setestado] = useState(licencia.estado_id)
  const queryClient = useQueryClient();

  useEffect(() => {
    setestado(licencia.estado_id)
  },[licencia])

  useEffect(() => {
    cambiarEstado(estado)
  }, [estado])

 
  const autorizarLicenciaMutate = useMutation(autorizarLicencia, {
    onSuccess: async (response) => {
      queryClient.invalidateQueries("licencias");
      resetForm();
      setAwsomeAlert({
        show: true,
        showConfirmButton: true,
        onConfirmPressed: () => {
          resetAlert();
          props.navigation.navigate("Panel de control");
        },
        customView: (
          <ResultadoModal success={true} message="La Licencia fue modificada" />
        ),
      });
    },
    onError: async (error) => {
      console.log(error.response.data);
      const mensaje = error.response.data?.errors["estado_id"];
      setAwsomeAlert({
        show: true,
        customView: (
          <ResultadoModal
            success={false}
            message={mensaje ?? "Hubo un error"}
          />
        ),
      });
    },
  });

  const valoresInicialesForm = {
    observaciones: "",
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: valoresInicialesForm
  });

  const resetForm = () => {
    reset(valoresInicialesForm);
    setestado(null);
  };


  const formData = new FormData();
  const onSubmit = (data) => {
    formData.append("estado_id", estado);
    //se cargan el resto de campos del formulario en formData
    for (const key in data) {
      if (key === "field") {
        if(data[key][1])
        formData.append(key, data[key][1]);
      } else {
        if(data[key])
        formData.append(key, data[key]);
      }
    }
    autorizarLicenciaMutate.mutate({ licenciaId: licencia.id, formData });
  };

  const radioButtonData = [
    { label: "PENDIENTE", value: 1 },
    { label: "AUTORIZADO", value: 2 },
    { label: "NO AUTORIZADO", value: 3 },
  ];

  return (
    <View>
      <Text style={tw`text-lg ml-2 mt-2`}>Autorizar</Text>
      <View style={tw`flex`}>
        <View>
          <RadioButton
            data={radioButtonData}
            defaultSelected={estado}
            onSelect={(value) => setestado(value)}
          />
        </View>
        <View>
          <Text style={tw`mt-4 ml-2 text-lg`}>Observaciones</Text>
          <Controller
            control={control}
            rules={{
              minLength: {
                value: 10,
                message: "Indique al menos 10 caracteres",
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                style={[tw` w-full h-50 px-3 mt-2 py-1.5 text-base font-normal text-gray-700 rounded-lg`,{backgroundColor:'#edf3f8'}]}
                onBlur={onBlur}
                onChangeText={onChange}
                multiline={true}
                placeholder="Observaciones"
                value={value}
              />
            )}
            name="observaciones"
          />
          {errors?.observaciones && (
            <MensajeError message={errors.observaciones?.message} />
          )}
        </View>

        <View style={tw`mt-4`}>
          {autorizarLicenciaMutate.isLoading ? (
            <ActivityIndicator size="small" color={Colores.primary} />
          ) : (
            <View style={tw`flex flex-row items-center justify-center`}>

              <TouchableOpacity style={[tw`mt-2 mr-2 mb-5 px-10 py-2.5 bg-gray-500 shadow-md flex flex-row`,{backgroundColor:Colores.primaryMedium, borderRadius:16}]} onPress={()=>navigator.navigate("Panel de control")}>
                <FontAwesome5 name="angle-left" size={15} color="#fff" style={tw`text-center mr-1 mr-2`}/>
                <Text style={tw` text-white text-center`}>
                  Volver
                </Text>
              </TouchableOpacity>

              <TouchableOpacity style={[tw`mt-2 ml-2 mb-5 px-10 py-2.5 shadow-md ml-1`,{backgroundColor:Colores.primary, borderRadius:16}]} onPress={handleSubmit(onSubmit)}>
                <Text style={tw` text-white text-center`}>Guardar</Text>
              </TouchableOpacity>

            </View>
          )}
        </View>
      </View>
    </View>
  );
}

export default LicenciaAutorizarForm;
