import React, { useEffect, useState } from "react";
import {
  Modal,
  ScrollView,
  StyleSheet,
  Text,
  View,
  Button,
  CheckBox,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { useQuery, useMutation } from "react-query";
import tw from 'twrnc';

import { Loader } from "../../components/Loader";
import { getEstimativo, exportarEstimativo } from "../../services/apiClient";
import { groupBy, orderBy } from "../../utils/generales";
import { ListaEstimativoSemanal } from "../../components/Menu/ListaEstimativoSemanal";
import { PageContainer } from "../../components/PageContainer";
import { dia, formatoFechaES } from "../../utils/fechas";
import { Colores } from "../../constants/colores";
import { esWeb } from "../../utils/plataforma";
import { Ionicons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';

function EstimativoScreen(props) {
  const { data, isLoading, isError } = useQuery("estimativo", getEstimativo);
  const exportarEstimativoMutate = useMutation(exportarEstimativo, {
    onSuccess: (response) => {
      if(esWeb()){
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'estimativo.xlsx');
        document.body.appendChild(link);
        link.click();
        setModalVisible(!modalVisible);
      }
      //TODO: agregar un metodo para android e ios
      
    },
  });
  const [pedidosPorFecha, setpedidosPorFecha] = useState([]);
  const [dias, setdias] = useState([]);
  const [diasAExportar, setdiasAExportar] = useState("");
  const {width} = useWindowDimensions()


  const handleChekChange = (id) => {
    let temp = dias.map((dia) => {
      if (id === dia.id) {
        return { ...dia, isChecked: !dia.isChecked };
      }
      return dia;
    });

    setdias(temp);
    const fechasSeleccionadas = temp
      .filter((dia) => dia.isChecked)
      .map((dia) => dia.fecha)
      .join(",");
    setdiasAExportar(fechasSeleccionadas);
  };

  useEffect(() => {
    if (data) {
      const estimativo = groupBy(data.data.estimativo, "fecha_menu");
      ordenadoPorFecha(estimativo);
      //se recorre estimativos por fecha
      obtenerPedidosPorFecha(estimativo);
    }
  }, [data]);

  useEffect(() => {
    let ped = pedidosPorFecha.map(({ fecha }, id) => ({
      id,
      fecha,
      isChecked: false,
    }));
    setdias(ped);
  }, [pedidosPorFecha]);

  const ordenadoPorFecha = (estimativoEmpresas) => {
    Object.entries(estimativoEmpresas).forEach(([key, value]) => {
      estimativoEmpresas[key] = orderBy(value, "fecha_menu");
      estimativoEmpresas[key] = groupBy(value, "tipo");
    });
  };

  //TODO: remplazar for por reducers
  const obtenerPedidosPorFecha = (estimativo) => {
    const datosPorFecha = [];
    Object.entries(estimativo).forEach(([fecha, valor]) => {
      let empresas = [];
      const menuPorDia = estimativo[fecha];
      const tipos = [...Object.keys(menuPorDia)];
      //setcabecerasTabla(tipos);

      //se extraen las empresas que solicitaron menu ese dia
      const pedidoEmpresas = [];
      Object.entries(menuPorDia).forEach(([tipoMenu, valor]) => {
        valor.forEach(({ empresa, cantidad }) => {
          pedidoEmpresas.push([empresa, tipoMenu, cantidad]);
          empresas = new Set([empresa, ...empresas]);
        });
      });

      //settitulosTabla([...empresas]);
      const rows = [];
      empresas.forEach((empresa) => {
        //inicializo la fila con el nombre de la empresa
        const row = [];
        //recorro los tipos de menu
        tipos.forEach((tipo, indexTipo) => {
          //valido si la empresa tiene algun pedido con ese tipo de menu
          const tieneTipoDeMenu = pedidoEmpresas.filter((pedido) => {
            const [empresaPedido, tipoPedido] = pedido;
            if (empresaPedido === empresa && tipoPedido === tipo) {
              return true;
            }
            return false;
          });

          if (tieneTipoDeMenu.length) {
            //si tiene se coloca la cantidad solicitada
            const [emp, tipoMenu, cantidad] = tieneTipoDeMenu[0];
            row.push(cantidad);
          } else {
            //si no tiene se coloca 0 cantidad solicitada para ese tipo de menu
            row.push(0);
          }
        });

        rows.push(row);
      });
      //setfilasTabla(rows);
      datosPorFecha.push({
        fecha,
        cabecerasTabla: tipos,
        titulosTabla: [...empresas],
        data: rows,
      });
    });

    setpedidosPorFecha(datosPorFecha);
  };

  const [modalVisible, setModalVisible] = useState(false);

  return (
    <PageContainer paddingMd>
      <View style={[tw`bg-white`,{borderRadius:20}]}>
      <ScrollView horizontal={false}>
        <View style={tw`flex px-3 mt-3`}>
          <View style={tw`items-center justify-center`}>
            <Text style={tw`text-lg text-gray-900`}>Almuerzos seleccionados</Text>
          </View>
          <View style={[tw`p-3 mt-3`,{backgroundColor:Colores.primaryLight, borderRadius:10}]}>
            <Text style={[tw`font-semibold`,{color:Colores.primary}]}>Esta lista <Text style={tw`font-bold`}>puede variar hasta las 8:00</Text> AM de cada día. Siempre deberá tomarse como referencia los valores fijados <Text style={tw`font-bold`}>después de ese horario.</Text></Text>
          </View>
          {isLoading ? (
            <Loader text="Cargando estimativo" />
          ) : (
            isError && <Text>Hubo un error</Text>
          )}
          
            <View style={tw`items-center justify-center`}>
              {pedidosPorFecha.length > 0 && (
                <TouchableOpacity onPress={() => setModalVisible(!modalVisible)} style={[tw`mt-5 p-3 items-center justify-center`,{backgroundColor:Colores.primaryLight, borderRadius:15}]}>
                  <Text style={[tw`font-bold`,{color:Colores.primary}]}><Ionicons name="document-attach-outline" size={20} style={tw`ml-1`} /> Descargar listado de almuerzos</Text>
                </TouchableOpacity>
              )}
            </View>
        </View>
        <View style={tw`items-center px-3 mt-5`}>
          <ListaEstimativoSemanal pedidosPorFecha={pedidosPorFecha} />
        </View>
      </ScrollView>
      <Modal
            animationType="fade"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
              setModalVisible(!modalVisible);
            }}
          >

            <View style={[ width >=768 ? tw`h-full w-full p-10 items-center justify-center` : tw`h-full w-full p-10 justify-center` ,{backgroundColor:'#7D7D7D50'}]}>
              <View style={width >=768 ? tw`bg-white rounded-lg w-100` : tw`bg-white rounded-lg` }>
                <TouchableOpacity onPress={() => setModalVisible(!modalVisible)} style={tw`absolute right-2 top-2`}>
                  <AntDesign name="closecircle" size={24} style={tw`text-red-500`} />
                </TouchableOpacity>
                <View style={tw`mt-10 mx-10`}>
                {dias?.map((pedido, idx) => (
                  <View
                    style={tw`flex-1 flex-row mt-3`}
                    key={`'pedido'-${idx}`}
                  >
                    <View style={tw`flex-1`}>
                      <CheckBox
                        value={pedido.isChecked}
                        onChange={() => handleChekChange(pedido.id)}
                        style={tw`mt-1`}
                      />
                    </View>

                    <View style={tw`flex-row flex-6`}>
                      <View style={tw`justify-center flex-1`}>
                        <Text style={tw`capitalize ml-1 text-lg`}>
                          {dia(pedido.fecha)}
                        </Text>
                      </View>
                      <View style={tw`items-center flex-1 ml-5`}>
                        <Text style={tw`text-lg text-center`}>
                          {formatoFechaES(pedido.fecha)}
                        </Text>
                      </View>
                    </View>
                  </View>
                ))}
                </View>
                {dias.length && (
                  <View style={tw`items-center justify-center`}>
                  <TouchableOpacity onPress={() => exportarEstimativoMutate.mutate(diasAExportar)} style={[tw`px-5 py-2 mt-10 mb-5 items-center justify-center`,{backgroundColor:Colores.primaryLight, borderRadius:15}]}>
                    <Text style={{color:Colores.primary}}><Feather name="download" size={20} style={tw`mr-2`} />Descargar</Text>
                  </TouchableOpacity>
                  </View>
                )}
              </View>
            </View>
      </Modal>
      </View>
    </PageContainer>
  );
}

export default EstimativoScreen;
