import React from "react";
import { ActivityIndicator, Text, View } from "react-native";
import { Colores } from "../constants/colores";

export const Loader = ({ text, flex = 1 }) => {
  return (
    <View
      style={{
        flex: flex,
        marginBottom: 16,
        marginTop: 16,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ActivityIndicator size="small" color={Colores.primary} />
      <Text style={{ marginLeft: 16 }}>{text}</Text>
    </View>
  );
};
