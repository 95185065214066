import React, { useEffect, useState } from "react";
import {
  SafeAreaView,
  View,
  Text,
  FlatList,
  StyleSheet,
  Button,
  ScrollView,
  Modal,
  TouchableOpacity,
  Image,
  useWindowDimensions,
} from "react-native";
import tw from 'twrnc';
import { PageContainer } from "../../components/PageContainer";
import { LinearGradient } from "expo-linear-gradient";
import moment from "moment";
import CardPartido from "../../components/Prode/CardPartido";
import { useMutation, useQueryClient } from "react-query";
import { pronosticar, pronosticar as pronosticarPartido } from "../../services/apiClient";
import { useNavigation } from "@react-navigation/native";
import { FloatingAction } from "react-native-floating-action";
import { Ionicons } from '@expo/vector-icons';

export default function DetalleProdeScreen(props) {
    const {informacion} = props.route.params;
    const { width } = useWindowDimensions();
    const queryClient = useQueryClient();
    const navigator = useNavigation()


    const pronosticarProde = useMutation(pronosticar, {
      onSuccess: async (response) => {
        console.log(response)
        queryClient.invalidateQueries("misPronosticos");
      },
      onError: async (error) => {
        const { errors } = error.response.data;
      },
    });
  
    const pronosticarPartido = (fixtureId, resultado) => {
      pronosticarProde.mutate({fixtureId,formData:{
          "pronostico" : resultado
      }});
  } 

  const nombreDeFase = (round) =>{
    switch (round) {
      case "R16":
        return "OCTAVOS"
        break;

      case "QF":
        return "CUARTOS"
        break;

      case "SF":
        return "SEMIFINALES"
        break;
      case "3PPO":
        return "3RO Y 4TO PUESTO"
        break;
      case "F":
        return "FINAL"
        break;
    
      default:
        break;
    }
  }
  
  return (
    <>
    <PageContainer paddingMd>
      <ScrollView>
        {width >= 758 && (
          <LinearGradient style={[tw`flex-row flex-1 mb-5`,{borderRadius:10}]} colors={['#af032e','#9d022b']}>
          <View style={tw`flex-4 justify-center`}>
            <Text style={tw`text-white text-2xl font-semibold text-center`}>¡Jugá al PRODE 2022!</Text>
          </View>
          <View style={tw`flex-4 justify-center`}>
            <Image source={require('../../assets/Prode/img.png')} style={[tw`h-30 w-full`,{resizeMode:'cover', borderRadius:10}]} />
          </View>
      </LinearGradient>
        )}
        <View style={[tw`bg-white`, width >= 758 && {borderRadius: 20}]}>
          {width <= 758 && (
            <View style={tw`bg-red-500`}>
              <Image source={require('../../assets/Prode/img.png')} style={[tw`h-48 w-full`,{resizeMode:'cover'}]} />
            </View>
          )}
          <View style={ width >=768 ? tw`mt-5` : tw`mt-3`}>
            <Text style={[tw`text-lg font-semibold`,{textAlign: width >= 768 ? 'left' : 'center', marginLeft: width >=768 && 20 }]}>Seleccioná al Ganador de la fecha</Text>
            <Text style={[tw`text-lg text-center font-semibold`,{textAlign: width >= 768 ? 'left' : 'center', marginLeft: width >=768 && 20 }]}> o si será un Empate </Text>
            <Text style={tw`text-lg mt-2 text-center font-semibold`}>¡Cada acierto sumas 3 puntos!</Text>
          </View>

          <LinearGradient style={tw`px-3 mt-3`} end={{ x: 0.5, y: 0.1 }} colors={['#80082c','#FFF']}>
            <Text style={tw`text-lg text-white`}>{informacion[0].grupo === "0" ? nombreDeFase(informacion[0].round) : `GRUPO ${informacion[0].grupo}`}</Text>
          </LinearGradient>

          <View style={tw`px-5 mb-5`}>
            
            {informacion[0].grupo === "A" && (
            <>
              <CardPartido info={informacion[0]} color={['#9d022b','#034ea2']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[1]} color={['#00853f','#21468b']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[2]} color={['#9d022b','#00853f']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[3]} color={['#21468b','#034ea2']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[4]} color={['#21468b','#9d022b']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[5]} color={['#034ea2','#00853f']} pronosticarPartido={pronosticarPartido} />
            </>
            )}
            {informacion[0].grupo === "B" && (
            <>
              <CardPartido info={informacion[0]} color={['#cd202c','#239f40']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[1]} color={['#b22234','#00ab39']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[2]} color={['#00ab39','#239f40']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[3]} color={['#cd202c','#b22234']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[4]} color={['#239f40','#b22234']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[5]} color={['#00ab39','#cd202c']} pronosticarPartido={pronosticarPartido} />
            </>
            )}
            {informacion[0].grupo === "C" && (
            <>
              <CardPartido info={informacion[0]} color={['#74acdf','#006c35']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[1]} color={['#006847','#dc143c']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[2]} color={['#dc143c','#006c35']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[3]} color={['#74acdf','#006847']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[4]} color={['#dc143c','#74acdf']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[5]} color={['#006c35','#006847']} pronosticarPartido={pronosticarPartido} />
            </>
            )}
            {informacion[0].grupo === "D" && (
            <>
              <CardPartido info={informacion[0]} color={['#c60c30','#e70013']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[1]} color={['#002395','#012169']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[2]} color={['#e70013','#012169']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[3]} color={['#002395','#c60c30']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[4]} color={['#e70013','#002395']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[5]} color={['#012169','#c60c30']} pronosticarPartido={pronosticarPartido} />
            </>
            )}
            {informacion[0].grupo === "E" && (
            <>
              <CardPartido info={informacion[0]} color={['#000000','#bc002d']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[1]} color={['#ffc400','#002b7f']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[2]} color={['#bc002d','#002b7f']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[3]} color={['#ffc400','#000000']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[4]} color={['#bc002d','#ffc400']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[5]} color={['#002b7f','#000000']} pronosticarPartido={pronosticarPartido} />
            </>
            )}
            {informacion[0].grupo === "F" && (
            <>
              <CardPartido info={informacion[0]} color={['#006233','#191795']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[1]} color={['#ffc400','#ff0000']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[2]} color={['#ffc400','#006233']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[3]} color={['#191795','#ff0000']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[4]} color={['#191795','#ffc400']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[5]} color={['#ff0000','#006233']} pronosticarPartido={pronosticarPartido} />
            </>
            )}
            {informacion[0].grupo === "G" && (
            <>
              <CardPartido info={informacion[0]} color={['#ff0000','#007a5e']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[1]} color={['#009b3a','#0c4076']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[2]} color={['#007a5e','#0c4076']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[3]} color={['#009b3a','#ff0000']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[4]} color={['#007a5e','#009b3a']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[5]} color={['#0c4076','#ff0000']} pronosticarPartido={pronosticarPartido} />
            </>
            )}
            {informacion[0].grupo === "H" && (
            <>
              <CardPartido info={informacion[0]} color={['#0038a8','#cd2e3a']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[1]} color={['#006600','#fcd116']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[2]} color={['#cd2e3a','#fcd116']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[3]} color={['#006600','#ff0000']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[4]} color={['#cd2e3a','#0038a8']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[5]} color={['#fcd116','#0038a8']} pronosticarPartido={pronosticarPartido} />
            </>
            )}
            {informacion[0].round === "R16" && (
            <>
              <CardPartido info={informacion[0]} color={['#0038a8','#cd2e3a']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[2]} color={['#74acdf','#0038a8']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[3]} color={['#0038a8','#ff0000']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[1]} color={['#ff0000','#fcd116']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[4]} color={['#ff0000','#0038a8']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[5]} color={['#0038a8','#ff0000']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[6]} color={['#ff0000','#c60b1e']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[7]} color={['#c1272d','#ff0000']} pronosticarPartido={pronosticarPartido} />
            </>
            )}
            {informacion[0].round === "QF" && (
            <>
              <CardPartido info={informacion[4]} color={['#ff0000','#0038a8']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[1]} color={['#74acdf','#0038a8']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[5]} color={['#0038a8','#006600']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[2]} color={['#0038a8','#74acdf']} pronosticarPartido={pronosticarPartido} />
            </>
            )}
            {informacion[0].round === "SF" && (
            <>
              <CardPartido info={informacion[1]} color={['#74acdf','#ff0000']} pronosticarPartido={pronosticarPartido} />
              <CardPartido info={informacion[2]} color={['#0038a8','#c1272d']} pronosticarPartido={pronosticarPartido} />
            </>
            )}
            {informacion[0].round === "3PPO" && (
            <>
              <CardPartido info={informacion[0]} color={['#0038a8','#ff0000']} pronosticarPartido={pronosticarPartido} />
            </>
            )}
            {informacion[0].round === "F" && (
            <>
              <CardPartido info={informacion[0]} color={['#74acdf','#0038a8']} pronosticarPartido={pronosticarPartido} />
            </>
            )}
          </View>
        </View>

      </ScrollView>

    </PageContainer>
    <FloatingAction
      color="#FFF"
      floatingIcon={<Ionicons name="trophy-outline" size={24} color="#FF9900" />}
      onPressMain={()=>navigator.navigate('Ranking')}
      showBackground={false}
    />
    </>
  );
}

