import { View, Text } from "react-native";
import React from "react";
import tw from "twrnc";
import ColaboradorItem from "./ColaboradorItem";

const Observacion = ({
  texto,
  colaborador = null,
  alternado = false,
  fecha,
}) => {
  const estilosCajaTexto = alternado
    ? "rounded-l-lg rounded-br-lg"
    : "rounded-r-lg rounded-bl-lg";
  return (
    <View style={tw`pl-4 flex flex-col justify-center gap-4 my-4 shadow-lg p-2 rounded-lg`}>
      <View style={tw`${alternado ? "self-end" : ""}`}>
        <ColaboradorItem colaborador={colaborador} reversed={alternado} />
      </View>
      <View
        style={tw`bg-gray-200 flex flex-col justify-center ${estilosCajaTexto} p-2`}
      >
        <Text style={tw`text-gray-700`}>{texto}</Text>
        <Text style={tw`self-end text-gray-500 text-sm`}>
          {new Date(fecha).toLocaleDateString("es-AR", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </Text>
      </View>
    </View>
  );
};

export default function ObservacionesItem({ observaciones }) {
  //Si es un array, son observaciones viejas,si es una sola, es la que se crea al momento de asignar el item
  if (Array.isArray(observaciones)) {
    return observaciones.map(({ observacion, autor, created_at }, index) => (
      <Observacion
        texto={observacion}
        colaborador={autor}
        alternado={index % 2 === 0}
        fecha={created_at}
      />
    ));
  }
  return (
    <View style={tw`flex flex-col justify-center gap-6`}>
      <Text style={tw`text-xl font-semibold`}>Observaciones</Text>
      {observaciones ? (
        <View style={tw`pl-4 flex flex-col justify-center gap-2`}>
          <View>
            <ColaboradorItem />
          </View>
          <View
            style={tw`bg-gray-200 flex flex-col justify-center rounded-r-lg rounded-bl-lg p-2`}
          >
            <Text style={tw`text-gray-700`}>{observaciones}</Text>
            <Text style={tw`self-end text-gray-500 text-sm`}>
              {new Date().toLocaleDateString("es-AR", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </Text>
          </View>
        </View>
      ) : null}
    </View>
  );
}
