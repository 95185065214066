import moment from "moment";

export const momentLocale = () => {
    moment.updateLocale('es', {
        relativeTime : {
          future: "En %s",
          past:   "hace %s",
          s  : 'hace unos segundos',
          ss : '%d seg',
          m:  "1 min",
          mm: "%d min",
          h:  "1 h",
          hh: "%d h",
          d:  "un día",
          dd: "%d d",
          w:  "una semana",
          ww: "%d sem",
          M:  "un mes",
          MM: "%d meses",
          y:  "un año",
          yy: "%d años"
      }
    });
}


