import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  Collapse,
  CollapseHeader,
  CollapseBody,
} from "accordion-collapse-react-native";
import { Text, TouchableOpacity, View, ScrollView } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import tw from "twrnc";

import {
  getPermisosModulos,
  asignarPermisoPuesto,
} from "../../../services/apiClient";
import AsignarPermisos from "./AsignarPermisos";
import { Colores } from "../../../constants/colores";
import { Loader } from "../../Loader";
import { ActivityIndicator } from "react-native-web";

const ModulosPermisos = ({ puesto }) => {
  const {
    data: modulosPermisos,
    isLoading,
    isError,
    isRefetching,
    refetch,
  } = useQuery("modulosPermisos", getPermisosModulos);
  const [permisoSeleccionado, setPermisoSeleccionado] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const asignarMutation = useMutation(asignarPermisoPuesto, {
    onSuccess: async (response) => {
      if (response.data.ok) {
        //console.log("permiso asignado", response);
      }
    },
  });

  useEffect(() => {
    setSelectedItems(puesto?.permisos);
  }, [puesto]);

  if (isLoading) {
    return <Loader text="Cargando permisos por Modulos" />;
  }

  if (isError && !isRefetching) {
    return (
      <View style={tw`flex items-center justify-center w-auto`}>
        <TouchableOpacity
          style={[
            tw`mt-5 px-20 py-2.5 shadow-md text-white text-center`,
            { backgroundColor: Colores.primary, borderRadius: 16 },
          ]}
          onPress={refetch}
        >
          <Text style={tw`text-center text-white`}>Recargar</Text>
        </TouchableOpacity>
      </View>
    );
  }

  const handleItemPress = (permiso) => {
    const data = {
      puesto_id: puesto.id,
      permiso_id: permiso.id,
      departamento_id: puesto.departamento_id,
    };

    const isSelected = selectedItems.some(
      (selectedItem) => selectedItem.id === permiso.id
    );

    if (isSelected) {
      //si es el modulo de licencias, enviar los departamentos seleccionados
      if (permiso.modulo_id === 5) {
        const dptosConPermiso = puesto?.permisos
          ?.filter((permisoDpto) => permisoDpto.id === permiso.id)
          .map((permisoDpto) => permisoDpto.pivot.departamento_id);

        if (dptosConPermiso.length === 0) {
          setSelectedItems(
            selectedItems.filter(
              (selectedItem) => selectedItem.id !== permiso.id
            )
          );
        }
      } else {
        asignarMutation.mutate({
          puestoId: puesto.id,
          data,
        });
        setSelectedItems(
          selectedItems.filter((selectedItem) => selectedItem.id !== permiso.id)
        );
      }
    } else {
      if (permiso.modulo_id !== 5) {
        asignarMutation.mutate({
          puestoId: puesto.id,
          data,
        });
      }
      setSelectedItems([...selectedItems, permiso]);
    }

    setPermisoSeleccionado(permiso);
  };

  const cantidadPermisosPorModulo = (moduloId) => {
    const cantidad =
      selectedItems?.filter((permiso) => permiso.modulo_id === moduloId)
        .length ?? 0;
    return cantidad;
  };

  return (
    <>
      <TouchableOpacity
        key={`administrar-todo-accion-1`}
        style={[
          tw`flex flex-row items-center justify-between bg-white px-4 py-2 rounded-md border border-gray-300 mb-2`,
          selectedItems?.some((selectedItem) => selectedItem?.id === 1)
            ? { backgroundColor: Colores.successBg }
            : null,
        ]}
        onPress={() => handleItemPress({ id: 1, modulo_id: null })}
      >
        <Text style={tw`text-base`}>Administrar Todo</Text>
        {asignarMutation.isLoading ? (
          <ActivityIndicator size="small" color={Colores.success} />
        ) : null}
      </TouchableOpacity>
      {modulosPermisos.data.map((modulo) => {
        const permisosPorModulo = cantidadPermisosPorModulo(modulo.id);
        return (
          <Collapse style={tw`mt-3`} key={`modulo-${modulo.nombre}`}>
            <CollapseHeader
              style={[
                tw`flex flex-row items-center border border-gray-400 rounded-lg px-2 py-3`,
                permisosPorModulo > 0
                  ? { backgroundColor: Colores.successBg }
                  : null,
              ]}
            >
              <Text style={[tw`flex-1`, { fontSize: 16, fontWeight: "bold" }]}>
                {modulo.nombre}
              </Text>
              <View style={tw`flex flex-row items-center justify-center gap-8`}>
                <Text>({permisosPorModulo})</Text>
                <MaterialIcons
                  name="keyboard-arrow-down"
                  size={24}
                  color="black"
                />
              </View>
            </CollapseHeader>
            <CollapseBody style={tw`border border-gray-300 rounded-lg`}>
              <View style={tw`flex flex-row`}>
                <ScrollView style={tw`w-1/2 p-4`}>
                  {modulo.permisos.map((permiso) => (
                    <TouchableOpacity
                      key={`${modulo.nombre}-accion-${permiso.id}`}
                      style={[
                        tw`flex flex-row items-center justify-between bg-white px-4 py-2 rounded-md border border-gray-300 mb-2`,
                        selectedItems?.some(
                          (selectedItem) =>
                            selectedItem?.accion_id === permiso?.accion_id &&
                            selectedItem.id === permiso.id
                        )
                          ? { backgroundColor: Colores.successBg }
                          : null,
                      ]}
                      onPress={() => handleItemPress(permiso)}
                    >
                      <Text style={tw`text-base`}>
                        {permiso?.accion.nombre}
                      </Text>
                      {asignarMutation.isLoading ? (
                        <ActivityIndicator
                          size="small"
                          color={Colores.success}
                        />
                      ) : null}
                    </TouchableOpacity>
                  ))}
                </ScrollView>
                {permisoSeleccionado && modulo.nombre === "Licencias" ? (
                  <View style={tw`w-1/2 mt-2`}>
                    <Text style={tw`text-lg font-bold mb-1`}>
                      Departamentos:
                    </Text>
                    <AsignarPermisos
                      puesto={puesto}
                      permiso={permisoSeleccionado}
                      fnAsignarPermiso={asignarMutation}
                    />
                  </View>
                ) : null}
              </View>
            </CollapseBody>
          </Collapse>
        );
      })}
    </>
  );
};

export default ModulosPermisos;
