import React, { useEffect, useState } from "react";
import { FlatList, Image, Pressable, ScrollView, Text, TextInput, TouchableOpacity, useWindowDimensions, View } from "react-native";
import tw from 'twrnc';
import { useAtom } from "jotai";
import { userAtom } from "../../store/store";
import { PageContainer } from "../../components/PageContainer";
import { useMutation, useQuery } from "react-query";
import { avisoComentarios, nuevoComentarioAviso } from "../../services/apiClient";
import { Colores } from "../../constants/colores";
import moment from "moment";
import ColaboradorModalDetalle from "../../components/Colaboradores/ColaboradorModalDetalle";


export default function ComentariosScreen(props) {
  const { width } = useWindowDimensions()
  const [comentario, setComentario] = useState()
  const [user, setUser] = useAtom(userAtom);
  const [verMas, setVerMas] = useState(false)
  const { avisoId, aviso } = props.route.params;
  const [colaboradorSeleccionado, setColaboradorSeleccionado] = useState()
  const [modalDetalleColaborador, setModalDetalleColaborador] = useState(false)

  const { data, isLoading, isError, refetch } = useQuery('comentarios', () => avisoComentarios(avisoId))

  const nuevoComentarioAvisoMutate = useMutation(nuevoComentarioAviso, {
    onSuccess: async (response) => {
      setComentario('')
      refetch();
    },
    onError: async (error) => {
    },
  });

  const nuevoComentario = ({
    aviso_id: avisoId,
    comentario: comentario
  });

  useEffect(() => {
    refetch()
    setComentario('')
  }, [avisoId])

  const enviarComentario = () => nuevoComentarioAvisoMutate.mutate(nuevoComentario);

  // console.log(aviso)

  return (
    <PageContainer style={tw`items-center justify-center`}>
      <ScrollView>
        <View style={tw`w-full h-full`}>


          <View style={tw`px-4 mt-5`}>
            <View style={tw`flex-3 flex-row mb-2`}>
              <View style={tw`justify-center`}>
                <Image source={{ uri: aviso.autor.foto }} style={tw`h-8 w-8 rounded-full`} />
              </View>
              <View style={tw`flex-col ml-3`}>
                <Text style={tw`font-semibold capitalize`}>{`${aviso.autor.nombre} ${aviso.autor.apellido}`}</Text>
                <Text style={tw`font-normal text-stone-500`}>{`${aviso.autor.puesto_actual[0].nombre}`}</Text>
              </View>
            </View>
            <Text style={tw`mt-2 ml-4 text-lg font-semibold`}>{data?.data.aviso[0].titulo}</Text>
            <Text style={tw`mt-2 ml-4`}>
              {data?.data.aviso[0].texto.length > 200 && !verMas && width <= 768 ? (
                <Text>
                  {data?.data.aviso[0].texto.slice(0, 100)}
                  <Pressable onPress={() => setVerMas(true)} style={tw`font-medium text-xs text-slate-400`}>
                    <Text>... más</Text>
                  </Pressable>
                </Text>
              ) : (
                <Text style={tw`mt-2`}>
                  {data?.data.aviso[0].texto}
                  {/* { data?.data.aviso[0].texto.length > 200 && (
                      <Pressable onPress={()=>setVerMas(false)} style={tw`text-gray-500`}>
                        <Text style={tw`ml-2`}> ... mostrar menos</Text>
                      </Pressable>
                    )} */}
                </Text>
              )}
              <Text style={tw`mt-2 text-xs text-stone-300`}> {moment(data?.data.aviso[0].created_at).fromNow().charAt(0).toUpperCase() + moment(data?.data.aviso[0].created_at).fromNow().slice(1)}</Text>
            </Text>


          </View>

          <View style={[tw`mt-4 border-gray-300`, { borderBottomWidth: 1 }]} />

          {data?.data.aviso[0].comentariosActivo === 0 && (
            <Text style={tw`text-center text-gray-600 mt-10`}>
              Los comentarios estan desactivados
            </Text>
          )}

          <View style={tw`px-4 pb-20`}>
            <FlatList
              data={data?.data.aviso[0].comentarios}
              keyExtractor={(item) => item.id}
              renderItem={({ item }) => (
                <View style={tw`mt-4`}>
                  <View style={tw`flex-3 flex-row`}>
                    <TouchableOpacity onPress={() => { setColaboradorSeleccionado(item.colaborador), setModalDetalleColaborador(true) }}>
                      <Image source={{ uri: item.colaborador.foto }} style={tw`h-6 w-6 rounded-full`} />
                    </TouchableOpacity>
                    <TouchableOpacity style={tw`justify-center w-11/12`} onPress={() => { setColaboradorSeleccionado(item.colaborador), setModalDetalleColaborador(true) }}>
                      <Text style={tw`ml-2 font-medium capitalize`}>{`${item.colaborador.nombre} ${item.colaborador.apellido}`}</Text>
                      <Text style={tw`ml-2`}>{item.comentario} <Text style={tw`text-xs font-normal text-stone-300`}> {moment(item.created_at).fromNow().charAt(0).toUpperCase() + moment(item.created_at).fromNow().slice(1)}</Text></Text>
                    </TouchableOpacity>
                  </View>
                </View>
              )
              }
            />
          </View>
        </View>

      </ScrollView>

      {data?.data.aviso[0].comentariosActivo === 1 && (
        <View style={tw`flex-3 absolute bottom-0 w-full bg-white`}>
          <View style={[tw`border-gray-300`, { borderBottomWidth: 1 }]} />

          <View style={tw`flex flex-row justify-center items-center px-3 py-3`}>
            <View style={tw`flex-none m-2`}>
              <Image source={{ uri: user?.foto }} style={tw`h-6 w-6 rounded-full`} />
            </View>
            <View style={tw`grow`}>
              <TextInput value={comentario} onChangeText={(e) => setComentario(e)} placeholder="Agregar un comentario" placeholderTextColor={Colores.placeHolder} style={{outlineStyle: "none" }}/>
            </View>
            <TouchableOpacity style={tw`flex-none m-2`} onPress={() => enviarComentario()}>
              <Text style={[tw`font-medium`, {color: Colores.primaryMedium}]}>Publicar</Text>
            </TouchableOpacity>
          </View>

        </View>
      )}

      <ColaboradorModalDetalle inicio modalVisible={modalDetalleColaborador} setModalVisible={setModalDetalleColaborador} colaboradorSeleccionado={colaboradorSeleccionado} />


    </PageContainer>
  );
}
