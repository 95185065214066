import React from "react";
import { View } from "react-native";

import { PageContainer } from "../../components/PageContainer";
import { BeneficioEditarForm } from "../../forms/BeneficioEditarForm";

function EditarBeneficioScreen(props) {
  return (
    <PageContainer paddingMd >
      <View style={{ padding: "10px" }}>
        <BeneficioEditarForm {...props} />
      </View>
    </PageContainer>
  );
}

export default EditarBeneficioScreen;
