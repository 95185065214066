import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  TextInput,
  ActivityIndicator,
  Switch,
  TouchableOpacity,
} from "react-native";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import tw from "twrnc";

import { editarAviso, eliminarImagenAviso } from "../services/apiClient";
import { MultiSelectEmpresas } from "../components/MultiSelect";
import { useAtom } from "jotai";

import { empresasSeleccionadasAtom, idsEmpresasAtom } from "../store/store";
import AwesomeAlert from "react-native-awesome-alerts";
import ResultadoModal from "../components/ResultadoModal";
import MensajeError from "../components/MensajeError";
import { validateURL } from "../utils/generales";
import { Colores } from "../constants/colores";
import AvisoEditarImagen from "../components/Avisos/AvisoEditarImagen";

export const AvisoEditarForm = ({ aviso, route, navigation }) => {
  const [, setEmpresasSeleccionadas] = useAtom(empresasSeleccionadasAtom);
  const [idsEmpresas, setidsEmpresas] = useAtom(idsEmpresasAtom);
  const [esTemporal, setesTemporal] = useState(0);
  const [images, setImages] = useState([]);
  const queryClient = useQueryClient();

  const alertInitialState = {
    show: false,
    title: "",
    message: "",
    showConfirmButton: false,
  };
  const [mostrarAlert, setmostrarAlert] = useState(alertInitialState);
  const [imgDelete, setImgDelete] = useState([]);
  const toggleEsTemporalSwitch = () =>
    setesTemporal((previousState) => !previousState);

  const valoresInicialesForm = {
    titulo: aviso.titulo,
    texto: aviso.texto,
    esTemporal: aviso?.esTemporal ?? 0,
    url: aviso?.url
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: valoresInicialesForm,
  });

  useEffect(() => {
    console.log(
      "soy el useffect del form y el aviso actual es ",
      aviso,
      aviso.empresas
    );
    reset(valoresInicialesForm);
  }, [route.params]);

  const resetForm = () => {
    reset(valoresInicialesForm);
    setesTemporal(0);
    setidsEmpresas(null);
    //resetear multiselect
    setEmpresasSeleccionadas([]);
  };

  const nuevoAvisoMutate = useMutation(editarAviso, {
    onSuccess: async (response) => {
      resetForm();
      queryClient.invalidateQueries("avisos");
      //mostral mostrar modal
      setmostrarAlert({
        show: true,
        showConfirmButton: true,
        customView: (
          <ResultadoModal success={true} message="El aviso fue editado" />
        ),
      });
    },
    onError: async (error) => {
      console.log(error.response.data);
      //TODO: mostrar errores en campos
      setmostrarAlert({
        show: true,
        customView: <ResultadoModal success={false} message="Hubo un error" />,
      });
    },
  });

  const eliminarImgAvisoMutate = useMutation(eliminarImagenAviso);

  const formData = new FormData();
  const onSubmit = async (data) => {
    formData.append("unica", 1);

    if (idsEmpresas) {
      formData.append("empresas", idsEmpresas);
    }
    //se cargan el resto de campos del formulario en formData
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        if (data[key] && data[key] !== "") {
          formData.append(key, data[key]);
        }
      }
    }
    if (imgDelete?.length > 0) {
      imgDelete.forEach(async (imgId) => {
        await Promise.resolve(eliminarImgAvisoMutate.mutate(imgId));
      });
    }
    if (images?.length > 0) {
      images?.forEach((imgx) => {
        if (imgx?.file) {
          formData.append("foto[]", imgx?.file);
        }
      });
    }
    nuevoAvisoMutate.mutate({ avisoId: aviso.id, formData });
  };

  useEffect(() => {
    setImgDelete([]);
    if (aviso?.fotos?.length > 0) {
      setImages(aviso.fotos);
    }
  }, [route]);
  return (
    <>
      <View style={tw`flex justify-center px-4`}>
        <View style={{ flexDirection: "row", flex: 1 }}>
          <AvisoEditarImagen
            formData={formData}
            aviso={aviso}
            setImgDelete={setImgDelete}
            images={images}
            setImages={setImages}
          />
        </View>
        {/* <Text style={tw` text-lg text-gray-900`}>Titulo</Text> */}
        <Controller
          control={control}
          rules={{
            required: "Ingrese un titulo",
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={[
                tw` mt-3 px-3 py-1.5 text-base font-normal text-gray-700 shadow-sm border border-gray-300`,
                { backgroundColor: "#edf3f8", borderRadius: 50 },
              ]}
              onBlur={onBlur}
              onChangeText={onChange}
              placeholder="Titulo"
              value={value}
            />
          )}
          name="titulo"
        />
        {errors?.titulo && <MensajeError message={errors.titulo?.message} />}

        {/* <Text style={tw` mt-5 text-lg text-gray-900`}>Mensaje</Text> */}
        <Controller
          control={control}
          rules={{
            required: "Ingrese el texto",
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={[
                tw` w-full h-50 px-3 mt-5 py-1.5 text-base font-normal text-gray-700 border border-gray-300 shadow-sm`,
                { backgroundColor: "#edf3f8", borderRadius: 10 },
              ]}
              onBlur={onBlur}
              onChangeText={onChange}
              multiline={true}
              placeholder="Texto"
              value={value}
            />
          )}
          name="texto"
        />
        {errors?.texto && <MensajeError message={errors.texto?.message} />}

        <View style={tw`px-3`}>
          <Controller
            control={control}
            rules={{ validate: validateURL }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                style={[
                  tw`w-full px-3 mt-4 py-2.5 text-base font-normal text-gray-700 shadow-sm border border-gray-300`,
                  { borderRadius: 50 },
                ]}
                onBlur={onBlur}
                onChangeText={onChange}
                placeholder="Video url"
                value={value}
              />
            )}
            name="url"
          />
          {errors?.url && <MensajeError message={errors.url?.message} />}
        </View>

        <View style={tw`mt-5`}>
          <MultiSelectEmpresas modelo={aviso} />
        </View>

        <Controller
          control={control}
          defaultValue={esTemporal}
          name="esTemporal"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={tw`flex-row flex mt-6 mb-5 hidden`}>
              <Switch
                style={tw`mt-1`}
                trackColor={{ false: "#767577", true: "#81b0ff" }}
                thumbColor={value ? "#f5dd4b" : "#f4f3f4"}
                ios_backgroundColor="#3e3e3e"
                onValueChange={(value) => {
                  toggleEsTemporalSwitch;
                  onChange(value ? 1 : 0);
                }}
                value={value}
              />
              <Text style={tw`ml-3 text-gray-700 text-lg`}>
                Subir como historia
              </Text>
            </View>
          )}
        />

        {nuevoAvisoMutate.isLoading ? (
          <ActivityIndicator size="small" color={Colores.primary} />
        ) : (
          <View style={tw`items-center justify-center mb-10 mt-7`}>
            <TouchableOpacity
              style={[
                tw`px-20 py-2.5 shadow-md`,
                { backgroundColor: Colores.primary, borderRadius: 15 },
              ]}
              onPress={handleSubmit(onSubmit)}
            >
              <Text style={tw`text-white text-center`}>Guardar</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>

      <AwesomeAlert
        show={mostrarAlert.show}
        showProgress={false}
        title={mostrarAlert.title}
        message={mostrarAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={mostrarAlert.showConfirmButton}
        confirmText="Aceptar"
        customView={mostrarAlert.customView}
        confirmButtonColor="green"
        onDismiss={() => {
          setmostrarAlert(alertInitialState);
        }}
        onCancelPressed={() => {
          setmostrarAlert(alertInitialState);
        }}
        onConfirmPressed={() => {
          setmostrarAlert(alertInitialState);
          navigation.navigate("Avisos");
        }}
      />
    </>
  );
};