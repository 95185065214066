import React from "react";
import { Text, TouchableOpacity } from "react-native";

export const IconButton = ({ text, icon, style, onPress }) => {
  return (
    <TouchableOpacity style={style} onPress={onPress}>
      <Text>{text}</Text>
      {icon}
    </TouchableOpacity>
  );
};
