import React from "react";
import { View } from "react-native";
import { useInfiniteQuery } from "react-query";
import InfiniteScroll from "react-infinite-scroll-component";
import tw from "twrnc";

import {
  eliminarAviso,
  getAvisosInfiniteScroll,
} from "../../services/apiClient";
import { Loader } from "../Loader";
import Aviso from "./Aviso";
import IScroll from "./IScroll";

export default function Avisos({ navigation, confirmar }) {

  const renderItem = (aviso) => (
    <Aviso
      key={`aviso-${aviso?.id}`}
      aviso={aviso}
      props={navigation}
      eliminar={confirmar}
    />
  );

  return (
    <View style={[tw`flex pb-3`, { flex: 1 }]}>


      <IScroll fetchData={getAvisosInfiniteScroll} renderItem={renderItem} />
      {/* {status === "success" && esWeb() && (
        <InfiniteScroll
          dataLength={data?.pages[0]?.avisos.total}
          pullDownToRefreshContent={
            <View styles={{ textAlign: "center", padding: 16 }}>
              <Loader />
            </View>
          }
          releaseToRefreshContent={
            <View styles={{ textAlign: "center", padding: 16 }}>
              <Loader />
            </View>
          }
          pullDownToRefresh={true}
          pullDownToRefreshThreshold={200}
          refreshFunction={refetch}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={
            <View style={{ textAlign: "center", padding: 16 }}>
              <Loader text="Cargando" />
            </View>
          }
          height={"100%"}
        >
          {data?.pages[0].avisos.data.length > 0 ? (
            <View style={{ flex: 1 }}>
              {data?.pages?.map((page, i) => (
                <View key={`page-${i}`} style={[tw`px-2`, { flex: 1 }]}>
                  {page.avisos.data.map((aviso) => (
                    <Aviso
                      key={`aviso-${aviso.id}`}
                      aviso={aviso}
                      props={navigation}
                      eliminar={confirmar}
                      actualizarDatos={refetch}
                    />
                  ))}
                </View>
              ))}
            </View>
          ) : (
            <View style={tw`items-center mt-3`}>
              <Image
                source={require("../../assets/NO_POST_.png")}
                style={[
                  width > 768 ? tw`w-full h-100 mt-10` : tw`w-80 h-100`,
                  { resizeMode: "contain" },
                ]}
              />
              <Text
                style={[
                  width > 768
                    ? tw`text-2xl mt-20 font-bold`
                    : tw`text-xl font-bold`,
                  { color: "#a2a9b8" },
                ]}
              >
                Aún no hay publicaciones
              </Text>
            </View>
          )}
        </InfiniteScroll>
      )} */}
    </View>
  );
}
