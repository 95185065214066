import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { useMutation, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import tw from 'twrnc';

import { editarEmpresa, nuevaEmpresa } from "../services/apiClient";
import ResultadoModal from "../components/ResultadoModal";
import MensajeError from "../components/MensajeError";
import { awesomeAlertAtom } from "../store/store";
import { parsearErrores } from "../utils/generales";
import { Colores } from "../constants/colores";

function EmpresaForm(props) {
  const queryClient = useQueryClient();
  const { navigation, empresa } = props;
  const [quiereEditar, setquiereEditar] = useState(false);
  let initialValues = {
    nombre: "",
    direccion: "",
    telefono: "",
    web: "",
  };

  useEffect(() => {
    if (empresa) {
      reset({
        nombre: empresa?.nombre ?? "",
        direccion: empresa?.direccion ?? "",
        telefono: empresa?.telefono ?? "",
        web: empresa?.web ?? "",
      });
    }
    setquiereEditar(Object.keys(empresa).length ? true : false);
  }, [props]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: initialValues,
  });

  const resetForm = () => {
    setquiereEditar(false);
  };

  const [awsomeAlert, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);

  let mutate = nuevaEmpresa;
  let mensaje = "Empresa creada";

  //SI hay datos de la empresa, entonces es editar y se debe cambiar el mutate
  if (quiereEditar) {
    mutate = editarEmpresa;
    mensaje = "Empresa editada";
  }

  const empresaMutate = useMutation(mutate, {
    onSuccess: async (response) => {
      queryClient.invalidateQueries("empresas");
      resetForm();
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Exito!",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: <ResultadoModal success={true} message={mensaje} />,
        onConfirmPressed: () => {
          resetAwesomeAlert();
          navigation.navigate("Administrar");
        },
        onDismiss: () => {
          resetAwesomeAlert();
          navigation.navigate("Administrar");
        },
      });
    },
    onError: async (error) => {
      console.log(error.response.data);
      const mensajesDeError = parsearErrores(error.response.data?.errors);
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Error",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal success={false} message={mensajesDeError} />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else if (data[key]) {
        formData.append(key, data[key]);
      }
    }

    if (quiereEditar) {
      formData.append("_method", "PUT");
      empresaMutate.mutate({ empresaId: empresa.id, formData });
    } else {
      empresaMutate.mutate(formData);
    }
  };

  return (
    <View styles={tw`flex flex-1`}>
      <View>
        <Text style={tw`mt-2`}>Nombre</Text>
        <Controller
          control={control}
          rules={{
            required: "Ingrese un nombre",
            minLength: {
              value: 3,
              message: "Indique al menos 3 caracteres",
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={tw` mt-3 w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded`}
              onChangeText={onChange}
              onBlur={onBlur}
              multiline={true}
              placeholder="Indique un nombre"
              value={value}
            />
          )}
          name="nombre"
        />
        {errors?.nombre && <MensajeError message={errors.nombre?.message} />}

        <Text style={tw`mt-4`}>Dirección (Opcional)</Text>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={tw` mt-3 w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded`}
              onChangeText={onChange}
              multiline={true}
              placeholder="Indique una dirección"
              value={value}
            />
          )}
          name="direccion"
        />
        {errors?.direccion && (
          <MensajeError message={errors.direccion?.message} />
        )}

        <Text style={tw`mt-4`}>Telefono (Opcional)</Text>
        <Controller
          control={control}
          rules=""
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={tw` mt-3 w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded`}
              onChangeText={onChange}
              multiline={true}
              placeholder="Indique un telefono"
              value={value}
            />
          )}
          name="telefono"
        />
        {errors?.telefono && (
          <MensajeError message={errors.telefono?.message} />
        )}

        <Text style={tw`mt-4`}>Sitio web (Opcional)</Text>
        <Controller
          control={control}
          rules={{
            pattern: {
              value: /^((http|https):\/\/)?www\.([A-z]+)\.([A-z]{2,})/,
              message:
                "Ingrese una dirección web valida (incluir http:// ó https://)",
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={tw` mt-4 w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded`}
              onChangeText={onChange}
              onBlur={onBlur}
              multiline={true}
              placeholder="Indique dirección web"
              value={value}
            />
          )}
          name="web"
        />
        {errors?.web && <MensajeError message={errors.web?.message} />}

        {empresaMutate.isLoading ? (
          <ActivityIndicator size="small" color={Colores.primary} />
        ) : (
          <View style={tw`justify-center items-center mt-5`}>
          <TouchableOpacity style={[tw`px-20 py-2.5 shadow-md hover:bg-blue-700 hover:shadow-lg`,{backgroundColor:Colores.primary, borderRadius:15}]} onPress={handleSubmit(onSubmit)}>
            <Text style={tw`text-white text-center`}>Guardar</Text>
          </TouchableOpacity>
          </View>
        )}
      </View>
    </View>
  );
}

export default EmpresaForm;
