import React, { useEffect, useState } from "react";
import { Pressable, View, StyleSheet, Text } from "react-native";
import { AntDesign } from "@expo/vector-icons";

export default function DataTableButtons(props) {
  const { data, setData, numberVisiblePages, ...rest } = props;
  const [buttons, setbuttons] = useState([]);
  const [activePage, setactivePage] = useState(1);
  let totalRecords = data.length;
  let recordsPerPage = 5;
  const noOfPages = Math.ceil(totalRecords / recordsPerPage);
  let visiblePages =
    noOfPages > 1 && noOfPages >= numberVisiblePages
      ? numberVisiblePages
      : noOfPages;
  //let visiblePagesEndRange = visiblePages;
  const [visiblePagesEndRange, setvisiblePagesEndRange] =
    useState(visiblePages);
  const [beginningVisiblePage, setbeginningVisiblePage] = useState(1);
  const [endingVisiblePage, setendingVisiblePage] = useState(visiblePages);

  useEffect(() => {
    //resetea los botones y los actualiza
    drawDatatableButtons();
    getActiveRecordsIndices();
  }, [activePage, endingVisiblePage]);

  const getActivePage = () => activePage;
  const goToFirstPage = () => {
    setactivePage(1);
    setvisiblePagesEndRange(visiblePages);
    getVisiblePagesRange();
  };

  const gotoPrevPage = () => {
    if (activePage > 1) {
      setactivePage((prevstate) => prevstate - 1);
      if (activePage % visiblePages === 0) {
        setvisiblePagesEndRange(activePage);
      }
    }
    getVisiblePagesRange();
  };

  const gotoPage = (page) => {
    //activePage = page;
    setactivePage(page);
  };

  const gotoNextPage = () => {
    if (activePage < noOfPages) {
      if (activePage >= visiblePagesEndRange) {
        setvisiblePagesEndRange(visiblePagesEndRange + visiblePages);
        //setvisiblePagesEndRange( Math.min(visiblePagesEndRange, noOfPages));
      }
      setactivePage((prevstate) => prevstate + 1);
    }
  };

  useEffect(() => {
    getVisiblePagesRange();
  }, [visiblePagesEndRange]);

  const gotoLastPage = () => {
    setactivePage(noOfPages);
    setvisiblePagesEndRange(noOfPages);
    getVisiblePagesRange();
  };

  const getVisiblePagesRange = () => {
    if (visiblePagesEndRange % visiblePages === 0) {
      setbeginningVisiblePage(visiblePagesEndRange - visiblePages + 1);
    } else {
      setbeginningVisiblePage(
        visiblePagesEndRange - (visiblePagesEndRange % visiblePages) + 1
      );
    }
    setendingVisiblePage(visiblePagesEndRange);
  };

  const getActiveRecordsIndices = () => {
    let beginningRecordIndex = (activePage - 1) * recordsPerPage;
    let endingRecordIndex = Math.min(
      beginningRecordIndex + recordsPerPage,
      totalRecords
    );
    setData(data.slice(beginningRecordIndex, endingRecordIndex));
  };

  //draw button at the begining and in the end
  const drawFirstButtons = () => {
    if (noOfPages > 1) {
      setbuttons([
        <Pressable key="first" onPress={goToFirstPage}>
          <Text
            style={[
              styles.datatableButton,
              getActivePage() === 1 && styles.datatableDisabledButton,
            ]}
          >
            Primera
          </Text>
        </Pressable>,
        <Pressable key="prev" onPress={gotoPrevPage}>
          <AntDesign name="left" size={24} color="black" />
        </Pressable>,
      ]);
    }
  };

  const drawLastButtons = () => {
    if (noOfPages > 1) {
      setbuttons((prevstate) => [
        ...prevstate,
        <Pressable key="next" onPress={gotoNextPage}>
          <AntDesign name="right" size={24} color="black" />
        </Pressable>,
        <Pressable key="last" onPress={gotoLastPage}>
          <Text
            style={[
              styles.datatableButton,
              getActivePage() === noOfPages && styles.datatableDisabledButton,
            ]}
          >
            Ultima
          </Text>
        </Pressable>,
      ]);
    }
  };

  const drawDatatableButtons = () => {
    drawFirstButtons();
    for (let page = beginningVisiblePage; page <= endingVisiblePage; page++) {
      //para no dibujar un solo boton en caso de tener una sola pagina
      if (noOfPages === 1) {
        continue;
      }
      setbuttons((prevstate) => [
        ...prevstate,
        <Pressable
          key={`bnt-${page}`}
          title={page}
          onPress={() => gotoPage(page)}
        >
          <Text
            style={[
              styles.datatableButton,
              getActivePage() === page && styles.datatableActiveButton,
            ]}
          >
            {page}
          </Text>
        </Pressable>,
      ]);
    }
    //se agregan los botones del final
    drawLastButtons();
  };

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: "2rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {buttons}
    </View>
  );
}

const styles = StyleSheet.create({
  datatableButton: {
    fontSize: "0.9rem",
    padding: "0.6rem",
  },
  datatableActiveButton: {
    backgroundColor: "#4c9ed5",
    color:'white',
    borderRadius:5
  },
  datatableDisabledButton: {
    display: "none",
  },
});
