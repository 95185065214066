import React from "react";
import { Text, View } from "react-native";
import ItemCard from "../../Items/ItemCard";
import tw from "twrnc";
import { MaterialCommunityIcons } from "@expo/vector-icons";

const ItemsPendientes = ({ items }) => {
  if (!items?.length) return null;
  return (
    <View style={tw`flex flex-col flex-wrap gap-4`}>
      <View style={tw`flex flex-row items-center gap-2`}>
        <MaterialCommunityIcons name="bell" size={24} color="gold" />
        <Text style={tw`text-lg font-semibold`}>¡ Tienes {items.length} activos pendientes !</Text>
      </View>
      <View style={tw`flex flex-row flex-wrap items-strech gap-4`}>
        {items.map((item) => (
          <ItemCard key={item.id} item={item}  pendiente={true} />
        ))}
      </View>
    </View>
  );
};

export default ItemsPendientes;
