import React from "react";
import { FlatList, View, Text, TouchableOpacity, Image } from "react-native";
import { useInfiniteQuery } from "react-query";
import tw from "twrnc";
import { Loader } from "../Loader";

const IScroll = ({ fetchData, renderItem }) => {
  const {
    data,
    status,
    refetch,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery("avisos", fetchData, {
    getNextPageParam: (lastPage) => {
      if (lastPage.avisos.current_page < lastPage.avisos.last_page) {
        const nextPage = lastPage.avisos.current_page + 1;
        return nextPage <= lastPage.avisos.last_page ? nextPage : undefined;
      }
    },
  });

  const renderFooter = () => {
    if (isFetchingNextPage) {
      return (
        <View style={{ paddingVertical: 20 }}>
          <Loader text="Cargando avisos" />
        </View>
      );
    } else if (data) {
      return (
        <Text style={tw`text-center py-4 text-lg font-semibold`}>
          No hay más avisos
        </Text>
      );
    }
  };


  return (
    <View style={tw`h-[90%] overflow-y-hidden`}>
      {isLoading ? (
        <View style={{ textAlign: "center", padding: 16 }}>
          <Loader text="Cargando avisos" />
        </View>
      ) : (
        isError && (
          <View style={{ textAlign: "center", padding: 16 }}>
            <Text>Hubo un error</Text>
            <TouchableOpacity onPress={() => refetch()}>Recargar</TouchableOpacity>
          </View>
        )
      )}
      {data?.pages[0]?.avisos?.data?.length < 1 ? (
        <View style={tw`items-center mt-3`}>
          <Image
            source={require("../../assets/NO_POST_.png")}
            style={[tw`w-80 h-100`, { resizeMode: "contain" }]}
          />
          <Text style={[tw`text-xl font-bold`, { color: "#a2a9b8" }]}>
            Aún no hay publicaciones
          </Text>
        </View>
      ) : null}
      <FlatList
      style={tw`flex-1`}
        data={data ? data.pages.flatMap((page) => page.avisos.data) : []}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => renderItem(item)}
        ListFooterComponent={renderFooter}
        showsVerticalScrollIndicator={false}
        onEndReached={() => {
            if (hasNextPage && !isFetchingNextPage) {
              fetchNextPage();
            }
        }}
        onEndReachedThreshold={0.1}
      />
    </View>
  );
};

export default IScroll;
