import React from 'react'
import { View } from 'react-native'
import tw from "twrnc"

const ContainerStep = ({step, currentStep, children}) => {
  return (
    <View style={tw`${step === currentStep ? 'visible' : 'hidden'} w-2/3`}>
        {children}
    </View>
  )
}

export default ContainerStep