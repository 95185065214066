import { Picker } from "@react-native-picker/picker";
import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { useQuery } from "react-query";
import { getEmpresasDptosPuestos } from "../services/apiClient";
import { Loader } from "./Loader";
import tw from "twrnc";

import RadioButton from "../components/RadioButton";
import LicenciaOpciones from "./Licencias/LicenciaOpciones";

function FiltrosLicencias({ filtros, setfiltros }) {
  const { data, isLoading, isError } = useQuery(
    "empresasDptosPuestos",
    getEmpresasDptosPuestos
  );
  const [itemsEmpresas, setitemsEmpresas] = useState([]);
  const [itemsDptos, setitemsDptos] = useState([]);
  const [itemsPuestos, setitemsPuestos] = useState([]);
  const [empresa, setempresa] = useState();
  const [empresaID, setempresaID] = useState();
  const [dptoID, setdptoID] = useState();
  const [puestoID, setpuestoID] = useState();
  const [estado, setestado] = useState(0);
  const [opcion, setopcion] = useState(0);

  const empresas = [];
  if (data && data?.data.empresas.length) {
    data.data.empresas.map((empresa) => {
      empresas.push(
        <Picker.Item
          key={`empresa-${empresa.id}`}
          label={empresa.nombre}
          value={empresa.id}
        />
      );
    });
  }

  useEffect(() => {
    if (empresas.length) {
      setitemsEmpresas(empresas);
    }
  }, [data]);

  const cargarDptos = (empresaId) => {
    data?.data.empresas.filter((empresa) => {
      if (empresa.id == empresaId) {
        setempresa(empresa);
        const dptos = [];
        empresa.departamentos.map((dpto) => {
          dptos.push(
            <Picker.Item
              key={`dpto-${dpto.id}`}
              label={dpto.nombre}
              value={dpto.id}
            />
          );
        });
        setitemsDptos(dptos);
      }
    });
  };

  const cargarPuestos = (dptoId) => {
    empresa.departamentos.filter((dpto) => {
      if (dpto.id == dptoId) {
        const puestos = [];
        dpto.puestos.map((puesto) => {
          puestos.push(
            <Picker.Item
              key={`puesto-${puesto.id}`}
              label={puesto.nombre}
              value={puesto.id}
            />
          );
        });
        setitemsPuestos(puestos);
      }
    });
  };

  useEffect(() => {
    if (empresas.length) {
      cargarDptos(empresaID);
    }
  }, [empresaID]);

  useEffect(() => {
    if (filtros == undefined) {
      setempresaID(0);
      setdptoID(0);
      setpuestoID(0);
    }
  }, [filtros]);

  useEffect(() => {
    if (itemsDptos.length) {
      cargarPuestos(dptoID);
    }
  }, [dptoID, empresa, itemsDptos]);

  const radioButtonData = [
    { label: "TODOS", value: 0 },
    { label: "PENDIENTE", value: 1 },
    { label: "AUTORIZADO", value: 2 },
    { label: "NO AUTORIZADO", value: 3 },
  ];

  return (
    <View>
      {isLoading ? (
        <Loader flex={0} text="Cargando empresas" />
      ) : isError ? (
        <Text>Error: no se pudieron cargar las empresas</Text>
      ) : (
        <View>
          <Text style={[tw`text-lg mt-3 mb-1`, { color: "#6c7581" }]}>
            Empresa
          </Text>
          <Picker
            style={[
              tw` mt-2 w-full pl-1 py-3 border border-gray-300 text-gray-700 rounded-lg`,
              { borderRadius: 50 },
            ]}
            selectedValue={empresaID}
            onValueChange={(itemValue, itemIndex) => {
              setempresaID(itemValue);
              setfiltros((prev) => ({ ...prev, empresa_id: itemValue }));
            }}
          >
            <Picker.Item label="seleccione una empresa" value="null" />
            {itemsEmpresas.map((empresa) => empresa)}
          </Picker>

          <Text style={[tw`text-lg mt-3 mb-1`, { color: "#6c7581" }]}>
            Departamento
          </Text>

          <Picker
            style={[
              tw` mt-2 w-full pl-1 py-3 border border-gray-300 text-gray-700 rounded-lg`,
              { borderRadius: 50 },
            ]}
            selectedValue={dptoID}
            onValueChange={(itemValue, itemIndex) => {
              setdptoID(itemValue);
              setfiltros((prev) => ({ ...prev, departamento_id: itemValue }));
            }}
          >
            <Picker.Item label="seleccione un departamento" value="null" />
            {itemsDptos.map((dpto) => dpto)}
          </Picker>

          <Text style={[tw`mt-3 mb-1 text-lg`, { color: "#6c7581" }]}>
            Puesto
          </Text>

          <Picker
            style={[
              tw` mt-2 w-full pl-1 py-3 border border-gray-300 text-gray-700 rounded-lg`,
              { borderRadius: 50 },
            ]}
            selectedValue={puestoID}
            onValueChange={(itemValue, itemIndex) => {
              setpuestoID(itemValue);
              setfiltros((prev) => ({ ...prev, puesto_id: itemValue }));
            }}
          >
            <Picker.Item label="seleccione un puesto" value="null" />
            {itemsPuestos.map((puesto) => puesto)}
          </Picker>
        </View>
      )}

      <Text style={[tw`mt-3 mb-1 text-lg`, { color: "#6c7581" }]}>Opcion (tipo de licencia)</Text>
      <LicenciaOpciones
        opcionfiltrosada={(opcion) => {
          if (opcion) {
            setfiltros((prev) => ({ ...prev, opcion_id: opcion.id }));
          }
        }}
      />

      <View style={tw`flex-col items-start justify-center mb-2`}>
        <Text style={[tw`text-lg mt-3 mb-1`, { color: "#6c7581" }]}>
          Estado
        </Text>
        <RadioButton
          data={radioButtonData}
          defaultSelected={estado}
          onSelect={(value) => {
            console.log(filtros);
            if(value === 0){
              setfiltros();
            }else{
              setfiltros((prev) => ({ ...prev, estado_id: value }));
            }
          }}
        />
      </View>
    </View>
  );
}

export default FiltrosLicencias;
