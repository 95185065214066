import React, { useState, useEffect } from "react";
import { ScrollView } from "react-native";

import { PageContainer } from "../../components/PageContainer";
import LicenciaForm from "../../forms/LicenciaForm";
import AwesomeAlert from "react-native-awesome-alerts";

function SolicitarLicenciaScreen(props) {
  const alertInitialState = {
    show: false,
    title: "",
    message: "",
    showConfirmButton: false,
  };
  const [mostrarAlert, setmostrarAlert] = useState(alertInitialState);
  const [colaborador, setcolaborador] = useState(null);

  useEffect(() => {
    if(props.route?.params?.colaborador){
      setcolaborador(props.route?.params?.colaborador);
    }
  }, [props])
  
  return (
    <>
      <PageContainer paddingMd>
        <ScrollView contentContainerStyle={{ flexGrow: 1, padding: 16 }}>
          <LicenciaForm
            mostrarAlert={mostrarAlert}
            setmostrarAlert={setmostrarAlert}
            colaboradorAsignado={colaborador}
          />
        </ScrollView>
      </PageContainer>

      <AwesomeAlert
        show={mostrarAlert.show}
        showProgress={false}
        title={mostrarAlert.title}
        message={mostrarAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={mostrarAlert.showConfirmButton}
        confirmText="Aceptar"
        customView={mostrarAlert.customView}
        confirmButtonColor="green"
        onDismiss={() => {
          setmostrarAlert(alertInitialState);
        }}
        onCancelPressed={() => {
          setmostrarAlert(alertInitialState);
        }}
        onConfirmPressed={() => {
          setmostrarAlert(alertInitialState);
          props.navigation.navigate("Mis Licencias");
        }}
      />
    </>
  );
}

export default SolicitarLicenciaScreen;
