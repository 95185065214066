import React from "react";
import { Text, View } from "react-native";
import tw from 'twrnc';
import { Colores } from "../../constants/colores";

function ColaboradorDatosEmpresariales({
  empresa = "-",
  departamento = "-",
  puesto = "-",
}) {
  return (

    <View
      style={[tw`bg-white px-5 pt-5 pb-7 flex  border border-gray-300 shadow-lg`,{borderRadius:20}]}
    >
      <View style={[tw`mb-3`,{borderBottomWidth:1, borderBottomColor:Colores.primaryLight}]}>
        <Text style={[tw`text-2xl mb-2`,{color:Colores.primary}]}>Datos Laborales</Text>
      </View>
    
      <View style={tw`flex flex-row items-center mt-2`}>
        <Text style={[tw` font-semibold`,{color:Colores.primary}]}>
          Empresa:
        </Text>
        <Text style={tw` ml-2`}>{empresa?.nombre || empresa}</Text>
      </View>

      <View style={tw`flex flex-row items-center mt-2`}>
        <Text style={[tw` font-semibold`,{color:Colores.primary}]}>
          Departamento:
        </Text>
        <Text style={tw` ml-2`}>{departamento?.nombre || departamento}</Text>
      </View>

      <View style={tw`flex flex-row items-center mt-2`}>
        <Text style={[tw` font-semibold`,{color:Colores.primary}]}>
          Puesto:
        </Text>
        <Text style={tw` ml-2`}>{puesto?.nombre || puesto}</Text>
      </View>
    </View>
  );
}

export default ColaboradorDatosEmpresariales;
