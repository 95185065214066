import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  Text,
  TouchableOpacity,
  View,
  Linking
} from "react-native";
import { PageContainer } from "../../components/PageContainer";
import tw from "twrnc";
import { Colores } from "../../constants/colores";
import { AntDesign } from "@expo/vector-icons";
import { FontAwesome } from "@expo/vector-icons";
import {
  firmarRecibo,
} from "../../services/apiClient";
import { useMutation, useQueryClient } from "react-query";

import { PdfViewer } from "../../components/pdfViewver";
import ModalRazonesDesacuerdo from "../../components/Recibos/ModalRazonesDesacuerdo";
import moment from "moment";
import AwesomeAlert from "react-native-awesome-alerts";

function DetalleReciboScreen(props) {
  const { recibo: prueba } = props.route.params;
  const [recibo, setRecibo] = useState(prueba);
  const [modalRazones, setModalRazones] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [mostrarAlertaDescargarRecibo, setMostrarAlertaDescargarRecibo] =
    useState(false);
  const queryClient = useQueryClient();
  const [permitirDescarga, setPermitirDescarga] = useState(false);

  const firmar = useMutation(firmarRecibo, {
    onSuccess: async (response) => {
      queryClient.invalidateQueries("misRecibos");
      setMostrarAlerta(true);
      setIsLoading(false);
      setPermitirDescarga(true);
    },
    onError: async (error) => {
      console.log(
        "hubo un error al intentar eliminar",
        error.response.data.message
      );
      setIsLoading(false);
    },
    onSettled: (data) => {},
  });


  const descargarRecibo = async (url) => {
    const supported = await Linking.canOpenURL(url);
    if (supported) {
      await Linking.openURL(url);
    } else {
      console.log(`No se pudo descargar el archivo: ${url}`);
    }
  }

  const firmarReciboColaborador = () => {
    setIsLoading(true);
    firmar.mutate({
      reciboId: recibo.id,
      data: {
        conforme: 1,
      },
    });
  };

  useEffect(() => {
    setRecibo(prueba);
  }, [prueba]);


  return (
    <PageContainer>
      <View style={tw`px-3 h-full w-full`}>
        <View style={tw`flex-row flex-1`}>
          <View style={tw`flex-1 justify-center`}>
            <Text style={tw`capitalize font-semibold`}>
              Periodo: {moment(recibo.periodo).locale("es").format("MMMM YYYY")}
            </Text>
          </View>
          <View style={tw`flex-1 items-end justify-center`}>
            {!recibo.firmado ? (
              <TouchableOpacity onPress={() => setModalRazones(true)}>
                <Text style={tw`text-red-400`}>Firmar no conforme</Text>
              </TouchableOpacity>
            ) : null}
            {/* <TouchableOpacity style={[tw`py-2 px-5`,{borderWidth:1,borderRadius:15, borderColor:Colores.primaryStrong}]}>
                        <Text style={[tw`font-semibold`,{color:Colores.primaryStrong}]}>Consultar a RRHH</Text>    
                    </TouchableOpacity>           */}
          </View>
        </View>

        <View style={tw`flex-8`}>
          <View style={tw`h-full w-full items-center`}>
            <PdfViewer pdfUrl={recibo.url} />
          </View>
        </View>

        <View style={tw`flex-2 flex-row`}>
          <View style={tw`flex-2 items-center justify-center`}>
            <TouchableOpacity
              style={[
                tw`py-3 px-5`,
                { borderRadius: 15, backgroundColor: Colores.primaryLight },
              ]}
              onPress={() => {
                recibo.firmado || permitirDescarga
                  ? descargarRecibo(recibo.url)
                  : setMostrarAlertaDescargarRecibo(true);
              }}
            >
              <AntDesign
                name="download"
                size={19}
                color={Colores.primaryStrong}
              />
            </TouchableOpacity>
          </View>
          <View style={tw`flex-4 items-center justify-center`}>
            {recibo.firmado ? (
              <TouchableOpacity
                style={[
                  tw`py-3 px-15 items-center justify-center`,
                  { borderRadius: 15, backgroundColor: Colores.primary },
                ]}
              >
                <Text style={[tw`font-semibold text-white`]}>
                  <FontAwesome
                    name="check"
                    size={20}
                    style={tw`text-white mr-2`}
                  />{" "}
                  Firmado
                </Text>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                style={[
                  tw`py-3 px-15 items-center justify-center`,
                  { borderRadius: 15, backgroundColor: Colores.primaryLight },
                ]}
                onPress={() => firmarReciboColaborador()}
              >
                {isLoading ? (
                  <ActivityIndicator color={Colores.primaryStrong} />
                ) : (
                  <Text
                    style={[
                      tw`font-semibold`,
                      { color: Colores.primaryStrong },
                    ]}
                  >
                    Firmar conforme
                  </Text>
                )}
              </TouchableOpacity>
            )}
          </View>
        </View>
      </View>

      <ModalRazonesDesacuerdo
        modalVisible={modalRazones}
        setModalVisible={setModalRazones}
        reciboId={recibo.id}
        setPermitirDescarga={setPermitirDescarga}
      />

      <AwesomeAlert
        show={mostrarAlerta}
        title="Listo"
        message="El recibo se firmo correctamente"
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={false}
        onDismiss={() => {
          setMostrarAlerta(false);
        }}
      />

      <AwesomeAlert
        show={mostrarAlertaDescargarRecibo}
        title="Aviso"
        message="Necesitas firmar el recibo para poder descargarlo"
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={false}
        onDismiss={() => {
          setMostrarAlertaDescargarRecibo(false);
        }}
      />
    </PageContainer>
  );
}

export default DetalleReciboScreen;
