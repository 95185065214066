import React, { useEffect, useState } from "react";
import {
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
  Switch,
  StyleSheet,
} from "react-native";
import tw from "twrnc";
import {
  GestureDetector,
  PanGestureHandler,
} from "react-native-gesture-handler";
import Animated, {
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
} from "react-native-reanimated";
import { FontAwesome5 } from "@expo/vector-icons";
import * as DocumentPicker from "expo-document-picker";

import {
  getConfiguracionFirmaRecibo,
  configurarFirmaRecibo,
} from "../services/apiClient";
import { useMutation, useQuery } from "react-query";

import { Colores } from "../constants/colores";

const FirmaReciboForm = ({ empresaId }) => {
  const [plantilla, setplantilla] = useState();
  const configFirma = useQuery("configFirma", () =>
    getConfiguracionFirmaRecibo(empresaId)
  );
  const configurarFirmaMutate = useMutation(configurarFirmaRecibo, {
    onSuccess: async (response) => {
      console.log(response.data);
    },
    onError: async (error) => {
      console.log(error.response.data);
    },
  });

  const horizontal = {
    orientation: "horizontal",
    width: 842,
    height: 595,
  };
  const vertical = {
    orientation: "vertical",
    width: 595,
    height: 842,
  };
  const [documentOrientation, setDocumentOrientation] = useState(vertical);
  const [switchOrientation, setSwitchOrientation] = useState(false);

  const toggleDocumentOrientation = (status) => {
    if (status) {
      setDocumentOrientation(vertical);
    } else {
      setDocumentOrientation(horizontal);
    }
  };

  useEffect(() => {
    if (!configFirma?.data?.configuracion && empresaId != 1) {
      console.log("nop hay firma configurada");
    }
  }, [configFirma]);

  useEffect(() => {
    toggleDocumentOrientation(switchOrientation);
    translateX.value = 0;
    translateY.value = 0;
  }, [switchOrientation]);

  const translateX = useSharedValue(0);
  const translateY = useSharedValue(0);

  useEffect(() => {
    if (configFirma.data) {
      console.log(empresaId);
      console.log(configFirma.data.data);
    }
    translateX.value = 711;
    translateY.value = 542;
  }, [configFirma]);

  const panGestureEvent = useAnimatedGestureHandler({
    onStart: (_, context) => {
      context.translateX = translateX.value;
      context.translateY = translateY.value;
    },
    onActive: (event, context) => {
      translateX.value = event.translationX + context.translateX;
      translateY.value = event.translationY + context.translateY;
    },
    onEnd: (event, context) => {
      console.log("pos x", event.x);
      console.log("pos y", event.y);
      if (documentOrientation.orientation === "vertical") {
        console.log("vertical");
        if (event.x < 0 || event.x > 595) {
          translateX.value = 0;
        }
        if (event.y < 0 || event.y > 842) {
          translateY.value = 0;
        }
      }

      if (documentOrientation.orientation === "horizontal") {
        console.log("horizontal");
        if (event.x < 0 || event.x > 842) {
          translateX.value = 0;
        }
        if (event.y < 0 || event.y > 595) {
          translateY.value = 0;
        }
      }
    },
  });

  const rStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: translateX.value,
        },
        {
          translateY: translateY.value,
        },
      ],
    };
  });

  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: ["application/plantilla"],
    });
    setplantilla(result);
  };

  const onSubmit = () => {
    const formData = new FormData();
    if (plantilla) {
      formData.append("plantilla", plantilla?.file);
    }
    configurarFirmaMutate.mutate({empresaId, formData});
  };

  return (
    <View style={tw`px-3 h-full w-full`}>
      <View style={tw`flex-row flex-1`}>
        <View style={tw`flex-1 items-center justify-center`}>
          <TouchableOpacity
            style={[
              tw`py-2 px-3 border`,
              { borderRadius: 15, borderColor: Colores.orange },
            ]}
            onPress={pickDocument}
          >
            <Text style={[tw``, { color: Colores.orange }]}>
              Cargar modelo de recibo plantilla
            </Text>
          </TouchableOpacity>
        </View>
        <View style={tw`flex-1 items-center justify-center`}>
          <View style={tw`flex-row flex my-5`}>
            <Text style={tw`mr-3 text-gray-700 text-lg`}>horizontal</Text>
            <Switch
              ios_backgroundColor="#3e3e3e"
              onValueChange={(status) => {
                setSwitchOrientation((prev) => !prev);
              }}
              style={tw`mt-1`}
              value={switchOrientation}
            />
            <Text style={tw`ml-3 text-gray-700 text-lg`}>vertical</Text>
          </View>
        </View>
      </View>
      <View style={tw`flex-8 items-center content-center`}>
        <GestureDetector>
          <PanGestureHandler onGestureEvent={panGestureEvent} maxPointers={1}>
            <ImageBackground
              source={require("../assets/imagenRecibo.png")}
              style={tw`w-[${documentOrientation.width}px] h-[${documentOrientation.height}px]`}
            >
              <Animated.View style={[styles.circulo, rStyle]}>
                <FontAwesome5 name="signature" size={24} color="white" />
              </Animated.View>
            </ImageBackground>
          </PanGestureHandler>
        </GestureDetector>
      </View>
      <TouchableOpacity
        style={[
          tw`py-2 px-3 border`,
          { borderRadius: 15, borderColor: Colores.green },
        ]}
        onPress={onSubmit}
      >
        <Text style={[tw``, { color: Colores.green }]}>Guardar</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  circulo: {
    width: 50,
    height: 50,
    backgroundColor: "rgba(0,0,256,0.5)",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default FirmaReciboForm;
