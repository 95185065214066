import React, { useEffect, useState } from "react";
import {
  SafeAreaView,
  ScrollView,
  View,
  Modal,
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import {
  FontAwesome5,
  FontAwesome,
  Feather,
  MaterialIcons,
} from "@expo/vector-icons";
import {
  Collapse,
  CollapseHeader,
  CollapseBody,
} from "accordion-collapse-react-native";
import { useQuery } from "react-query";
import tw from "twrnc";

import { getPuesto, getPuestoColaboradores } from "../../services/apiClient";
import { Loader } from "../../components/Loader";
import DatosPermisos from "../../components/DatosPermisos";
import ColaboradorEmpresa from "../../components/Colaboradores/ColaboradorEmpresa";
import CantidadEntidad from "../../components/Empresa/CantidadEntidad";
import { PageContainer } from "../../components/PageContainer";
import { Colores } from "../../constants/colores";

export default function AdministrarPuestoScreen(props) {
  const { departamento, empresa, puesto } = props.route.params;
  const { width } = useWindowDimensions();
  const [colaboradores, setcolaboradores] = useState([]);
  const [permisosPuesto, setpermisosPuesto] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [colaborador, setcolaborador] = useState(null);
  const [relacion, setrelacion] = useState({});
  const { data, isFetching, isError, refetch:refetchColaboradores } = useQuery("puestoColaboradores", () =>
    getPuestoColaboradores(puesto.id)
  );

  useEffect(() => { refetchColaboradores(); console.log("cargando colaboradores") }, [props])

  const {
    data: permisos,
    isLoading: isLoadingPermisos,
    isError: isErrorPermisos,
  } = useQuery("puesto", () => getPuesto(puesto.id));

  console.log("permisos", permisos);
  useEffect(() => {
    if (data?.data?.puestos) {
      setcolaboradores(data.data.puestos[0]?.colaboradores);
      setrelacion({
        empresaActual: empresa.id,
        dptoActual: departamento.id,
        puestoActual: puesto.id,
      });
    }
  }, [data]);

  useEffect(() => {
    if (permisos?.data?.puesto) {
      setpermisosPuesto(permisos?.data?.puesto?.permisos);
    }
  }, [permisos]);

  return (
    <PageContainer paddingMd>
      <ScrollView>
        <View
          style={[
            { backgroundColor: "#FFF" },
            width > 768 && { borderRadius: 20 },
          ]}
        >
          <View
            style={[
              styles.container,
              tw`bg-white border border-gray-300 shadow-md`,
            ]}
          >
            <Text style={styles.path}>
              <FontAwesome name="building" size={24} color="black" />{" "}
              {empresa.nombre} -{" "}
              <FontAwesome5 name="building" size={20} color="black" />{" "}
              {departamento.nombre}
            </Text>
            <Text style={styles.path}>
              <FontAwesome name="suitcase" size={20} color="black" />{" "}
              {puesto.nombre}
            </Text>
            <TouchableOpacity
              style={[
                tw`mt-2 px-6 py-2.5 rounded shadow-md`,
                { backgroundColor: Colores.primary },
              ]}
              onPress={() =>
                props.navigation.navigate("Crear Puesto", {
                  navigation: props.navigation,
                  puesto,
                })
              }
            >
              <Text style={tw`text-white text-center`}>Editar Puesto</Text>
            </TouchableOpacity>
            <View>
              <View>
                {isLoadingPermisos || !permisos ? (
                  <Loader text="Cargando permisos" />
                ) : (
                  <Collapse>
                    <CollapseHeader
                      style={tw`flex flex-row items-center border border-gray-300 rounded-lg py-3 px-2`}
                    >
                      <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                        Permisos
                      </Text>
                      <MaterialIcons
                        name="keyboard-arrow-down"
                        size={24}
                        color="black"
                      />
                    </CollapseHeader>
                    <CollapseBody
                      style={tw`p-2 border border-gray-300 rounded-lg`}
                    >
                      {" "}
                      <DatosPermisos permisos={permisosPuesto} />
                    </CollapseBody>
                  </Collapse>
                )}
              </View>
            </View>
          </View>

          <View style={styles.container}>
            <View style={tw`gap-[0.5rem]`}>
              {isFetching ? (
                <Loader text="Cargando colaboradores" />
              ) : isError || !data.data?.ok ? (
                <Text>Hubo un error</Text>
              ) : (
                <>
                  <Text style={tw`font-semibold text-lg`}>
                    Colaboradores ({colaboradores.length})
                  </Text>
                  <View>
                    {colaboradores.map((colaborador) => (
                      <View
                        key={`colaborador-${colaborador.id}`}
                        style={tw`flex-row items-center border border-gray-300 rounded-lg py-2 pr-2 pl-3 mt-2 shadow-md`}
                      >
                        <View style={tw`flex-1 gap-8`}>
                          <Image
                            style={tw`w-15 h-15 rounded-full`}
                            source={{
                              uri: colaborador.foto,
                            }}
                          />
                          <Text style={tw`font-semibold text-lg`}>
                            {colaborador.apellido}, {colaborador.nombre}
                          </Text>
                          <Text style={{ color: "#94a3b8" }}>
                            DNI {colaborador.dni}
                          </Text>
                        </View>

                        <View style={tw`flex-row items-center justify-center`}>
                          <TouchableOpacity
                            style={[
                              tw`p-2 rounded-lg`,
                              { backgroundColor: Colores.primary },
                            ]}
                            onPress={() => {
                              props.navigation.navigate("Detalle Colaborador", {
                                colaborador,
                              });
                            }}
                          >
                            <Feather name="eye" size={20} color="white" />
                          </TouchableOpacity>
                          <TouchableOpacity
                            style={[
                              tw`p-2 rounded-lg ml-1`,
                              { backgroundColor: Colores.primary },
                            ]}
                            onPress={() => {
                              setcolaborador(colaborador);
                              setModalVisible(true);
                            }}
                          >
                            <MaterialIcons
                              name="compare-arrows"
                              size={23}
                              color="white"
                            />
                          </TouchableOpacity>
                          <TouchableOpacity
                            style={tw`p-2 bg-red-500 rounded-lg ml-1`}
                            onPress={() => {}}
                          >
                            <FontAwesome5
                              name="trash-alt"
                              size={20}
                              color="white"
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                    ))}
                  </View>
                </>
              )}
            </View>
          </View>
        </View>
        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(!modalVisible);
          }}
        >
          <View
            style={tw`flex items-center justify-center p-5 h-full bg-white`}
          >
            <View>
              <Text style={tw`text-lg font-semibold capitalize`}>
                {colaborador?.apellido}, {colaborador?.nombre}
              </Text>
            </View>
            <ColaboradorEmpresa colaborador={colaborador} relacion={relacion} />
            <TouchableOpacity
              style={styles.buttonClose}
              onPress={() => {
                setModalVisible(false);
              }}
            >
              <Text style={{ color: "white", fontWeight: "bold" }}>
                Cancelar
              </Text>
            </TouchableOpacity>
          </View>
        </Modal>
      </ScrollView>
    </PageContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    gap: 16,
  },
  path: {
    fontWeight: "bold",
  },
  permisoNombre: {
    fontWeight: "bold",
  },
  colaboradoresCount: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
  },
  button: {
    alignItems: "center",
    backgroundColor: "#DDDDDD",
    padding: 10,
  },
  foto: {
    width: "50px",
    height: "50px",
  },
  buttonClose: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    marginTop: 16,
    backgroundColor: "#FF3333",
  },
});
