import React from "react";
import { Text, Image, View, FlatList, StyleSheet, TouchableOpacity } from "react-native";
import {
  Collapse,
  CollapseHeader,
  CollapseBody,
} from "accordion-collapse-react-native";
import { MaterialIcons, FontAwesome5, FontAwesome } from "@expo/vector-icons";
import { formatoFechaES } from "../../utils/fechas";
import tw from 'twrnc';
import { Colores } from "../../constants/colores";

const Item = ({ datos }) => {
  const { nombre, apellido, foto, departamento, empresa, tipo } = datos;

  return (
    <View style={tw`flex flex-row p-2 border-gray-200`}>
        <View style={tw`flex items-center justify-center`}>
            <Image
              style={tw`rounded-full w-15 h-15`}
              source={{
                uri: foto,
              }}
            />
        </View>

        <View style={tw`ml-5`}>
          <Text style={tw`text-lg font-bold capitalize`}>
            {apellido}, {nombre}
          </Text>
          <View style={tw`flex flex-row items-center`}>
            <FontAwesome name="building-o" size={12} color="black" />
            <Text style={tw` ml-2`}>{empresa}</Text>
          </View>
          <View style={tw`flex flex-row items-center`}>
            <FontAwesome5 name="building" size={12} color="black" />
            <Text style={tw` ml-2`}>{departamento}</Text>
          </View>
        </View>
      {/* {
        tipo && (<View style={{alignItems: "end"}}>
          <Text>{tipo}</Text>
        </View>)
      } */}
    </View>
  );
};

export default function SeleccionPorDia({ hoy, fecha, datos, index }) {
  const { seleccionaron, noSeleccionaron } = datos;
  const renderItem = ({ item }) => <Item datos={item} />;

  return (
    <View style={tw``}>
      <View
        key={`fechas-${fecha}`}
        style={tw`p-5 mt-3 rounded-lg shadow-lg border border-gray-300`}
      >
        <Collapse isExpanded={hoy === fecha || index === 0 ? true : false}>
          <CollapseHeader
            style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between",
            }}
          >
            <Text style={tw`font-bold`}>
              {formatoFechaES(fecha)}
            </Text>
            <MaterialIcons name="keyboard-arrow-down" size={24} color={Colores.primary} />
          </CollapseHeader>
          <CollapseBody style={{ marginTop: 16 }}>
            {/* <View style={tw`flex-row`}>
              <View style={tw`flex-1 items-center justify-center`}>
                <TouchableOpacity style={tw`px-3 py-1 border rounded-full`}>
                  <Text style={tw`text-gray-600`}>Sin seleccion</Text>
                </TouchableOpacity>
              </View>
              <View style={tw`flex-1 items-center justify-center`}>
                <TouchableOpacity style={tw`px-3 py-1 border rounded-full`}>
                  <Text style={tw`text-gray-600`}>Seleccionaron</Text>
                </TouchableOpacity>
              </View>
            </View> */}
            <View>
              <View style={[tw`flex flex-row`,{borderBottomWidth:1,borderColor:Colores.primaryMedium}]}>
                  <Text style={tw`font-semibold ml-1`}>
                    Seleccionaron
                    ({seleccionaron.length})
                  </Text>
              </View>

              <FlatList
                style={styles.lista}
                data={seleccionaron}
                renderItem={renderItem}
                keyExtractor={(item, index) => index}
              />
            </View>
            <View>
              <Text style={[tw`font-semibold ml-1`,{borderBottomWidth:1,borderColor:Colores.primaryMedium}]}>
                No Seleccionaron ({noSeleccionaron.length})
              </Text>
              <FlatList
                style={styles.lista}
                data={noSeleccionaron}
                renderItem={renderItem}
                keyExtractor={(item, index) => index}
              />
            </View>
          </CollapseBody>
        </Collapse>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
  },
  lista: {
    maxHeight: 300,
    marginTop: 16,
    marginBottom: 16,
  },
  tipoMenu: {},
});
