import { Image } from 'react-native'
import React from 'react'

const LogoTitle = () => {
  return (
    <Image
        style={{ width: 120, height: 40, padding:30, alignItems:'center', resizeMode:'contain'}}
        source={require('../assets/workie_logo.png')}
    />
  )
}

export default LogoTitle