import React, { useState } from "react";
import { ActivityIndicator, Switch, Text, TouchableOpacity, View } from "react-native";
import { PageContainer } from "../../components/PageContainer";
import tw from "twrnc";
import { Colores } from "../../constants/colores";
import {
  cargarRecibos,
  getEmpresas,
  getRecibos,
  eliminarRecibo,
} from "../../services/apiClient";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Picker } from "@react-native-picker/picker";
import * as DocumentPicker from "expo-document-picker";
import { useForm } from "react-hook-form";
import AwesomeAlert from "react-native-awesome-alerts";
import DateTimePicker from "@react-native-community/datetimepicker";
import moment from "moment";
import TablaRecibos from "../../components/Panel/Recibos/TablaRecibos";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import { SelectTiposRecibo } from "../../components/Recibos/SelectTiposRecibo";
import WebDatePicker from "../../components/WebDatePicker";
import { esWeb } from "../../utils/plataforma";
import { RadioButton } from 'react-native-paper';

function CargarRecibosScreen(props) {
  const empresas = useQuery("empresas", getEmpresas);
  const [empresa, setEmpresa] = useState();
  const [mostrarAlertaRecibos, setMostrarAlertaRecibos] = useState(false);
  const [mensaje, setMensaje] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [zip, setZip] = useState();
  const [periodo, setPeriodo] = useState(new Date());
  const [mostrarCargarRecibos, setMostrarCargarRecibos] = useState(false);
  const [tipoRecibo, setTipoRecibo] = useState();
  const [showDateTimePicker, setshowDateTimePicker] = useState(false);
  const [firmadoPreviamenteEmpleador, setFirmadoPreviamenteEmpleador] = useState(0);
  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  const [mostrarAlertaEliminarRecibo, setMostrarAlertaEliminarRecibo] =
    useState(false);
  const [reciboSeleccionadoId, setReciboSeleccionadoId] = useState(null);

  const alertaEliminarRecibo = (reciboId) => {
    setReciboSeleccionadoId(reciboId);
    setMostrarAlertaEliminarRecibo(true);
  };

  const deleteRecibo = useMutation(eliminarRecibo, {
    onSuccess: async (response) => {
      queryClient.invalidateQueries("recibos");
    },
    onError: async (error) => {
      console.log(error.response.data.message);
    },
    onSettled: (data) => {},
  });

  const recibos = useQuery("recibos", getRecibos);

  const { navigation } = props;

  const formData = new FormData();

  const cargarReciboMutate = useMutation(cargarRecibos, {
    onSuccess: async (response) => {
      setMensaje({
        titulo: "Listo",
        mensaje: response.data.message,
      });

      setMostrarAlertaRecibos(true);
      setIsLoading(false);
    },
    onError: async (error) => {
      console.log(error.response.data);
      setMensaje({
        titulo: "Error",
        mensaje: error.response.data.message,
      });
      setIsLoading(false);
    },
  });

  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: ["application/zip", "application/x-zip-compressed"],
    });

    setZip(result);
  };

  const toggleFirmadoPreviamenteEmpleador = () =>
    setFirmadoPreviamenteEmpleador((previousState) => !previousState);

  const onSubmit = (data) => {
    setIsLoading(true);
    if (zip) {
      formData.append("zip", zip?.file);
    }
    formData.append("empresa", empresa);
    formData.append("periodo", moment(periodo).format("MM-YYYY"));
    formData.append("tipo", tipoRecibo);
    formData.append("firmadoPreviamenteEmpleador", firmadoPreviamenteEmpleador ? 1 : 0);

    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    cargarReciboMutate.mutate(formData);
  };

  return (
    <>
      <PageContainer paddingMd>
        <View style={[tw`bg-white flex-1 h-full p-5`, { borderRadius: 20 }]}>
          {!mostrarCargarRecibos ? (
            <TablaRecibos
              recibos={recibos?.data?.data?.recibos}
              mostrarCargarRecibos={mostrarCargarRecibos}
              setMostrarCargarRecibos={setMostrarCargarRecibos}
              alertaEliminarRecibo={alertaEliminarRecibo}
            />
          ) : (
            <View style={tw`px-3 h-full w-full bg-white`}>
              <View style={tw`z-50 flex-row mt-5`}>
                <View style={tw`flex-1`}>
                  <View style={tw`items-start`}>
                    <TouchableOpacity
                      onPress={() =>
                        setMostrarCargarRecibos(!mostrarCargarRecibos)
                      }
                    >
                      <Ionicons
                        name="arrow-back"
                        size={24}
                        color={Colores.primary}
                      />
                    </TouchableOpacity>
                  </View>

                  <View style={tw`mt-3`}>
                    <Text style={tw`text-lg font-semibold text-gray-500`}>
                      Cargar Recibos
                    </Text>
                  </View>

                  <View style={tw`flex-row mt-7 items-center`}>
                    <View>
                      <Text style={tw`mr-5`}>Periodo</Text>
                    </View>
                    <View>
                      {esWeb() ? (
                        <WebDatePicker
                          key="fecha-menu"
                          type="month"
                          selected={periodo}
                          onChange={(date) => {
                            setPeriodo(date);
                          }}
                        />
                      ) : (
                        <View
                          style={tw`flex flex-row items-center justify-start`}
                        >
                          {showDateTimePicker ? (
                            <DateTimePicker
                              value={periodo}
                              mode="month"
                              onChange={(event, date) => {
                                if (event.type == "set") {
                                  setPeriodo(date);
                                  setshowDateTimePicker(false);
                                } else {
                                  //cancel button clicked
                                  setshowDateTimePicker(false);
                                }
                              }}
                            />
                          ) : null}
                          <TouchableOpacity
                            onPress={() =>
                              setshowDateTimePicker(!showDateTimePicker)
                            }
                            style={[
                              tw`flex flex-row items-center justify-center border-2 border-indigo-400 px-4 py-2`,
                              { backgroundColor: "white", borderRadius: 15 },
                            ]}
                          >
                            <AntDesign
                              name="calendar"
                              size={20}
                              color={Colores.primary}
                            />
                            <Text style={tw`ml-2 text-center`}>
                              {moment(periodo).format("MM/YYYY")}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      )}
                    </View>
                  </View>
                </View>
              </View>

              <View style={tw`items-center`}>
                <View style={tw`w-full mt-7 px-1 z-40`}>
                  <Picker
                    placeholder="Seleccionar Empresa"
                    style={{
                      paddingVertical: 10,
                      paddingHorizontal: 5,
                      borderRadius: 50,
                    }}
                    selectedValue={empresa}
                    onValueChange={(itemValue, itemIndex) =>
                      setEmpresa(itemValue)
                    }
                  >
                    <Picker.Item
                      label={"Seleccione una empresa"}
                      value={null}
                    />
                    {empresas.data?.data?.empresas.map((item) => (
                      <Picker.Item
                        label={item.nombre}
                        value={item.id}
                        key={item.id}
                      />
                    ))}
                  </Picker>
                </View>

                <View style={tw`w-full mt-7 px-1 z-40`}>
                  <Text style={tw`mb-2`}>Tipo de recibo</Text>
                  <SelectTiposRecibo
                    setTipoRecibo={setTipoRecibo}
                    tipoRecibo={tipoRecibo}
                  />
                </View>

                <View style={tw`flex flex-row items-center gap-2 my-8 border rounded-md p-2 text-center`}>
                  <Text style={tw`ml-3 text-gray-700 text-lg`}>Los recibos están previamente firmados por el empleador</Text>
                  <RadioButton.Item
                    label="SI"
                    labelStyle={tw`ml-3 text-gray-700 text-md`}
                    status={firmadoPreviamenteEmpleador ? 'checked' : 'unchecked'}
                    onPress={() => toggleFirmadoPreviamenteEmpleador(true)}
                  />
                  <RadioButton.Item
                    label="NO"
                    labelStyle={tw`ml-3 text-gray-700 text-md`}
                    status={firmadoPreviamenteEmpleador ? 'unchecked' : 'checked'}
                    onPress={() => toggleFirmadoPreviamenteEmpleador(false)}
                  />
                </View>

                <Text style={tw`mt-7 text-center`}>
                  Se enviara un mail con un informe del resultado de la carga de
                  recibos
                </Text>

                <TouchableOpacity
                  onPress={pickDocument}
                  style={[
                    tw`mt-7 py-4 px-20 border border-gray-300`,
                    {
                      borderRadius: 20,
                      backgroundColor: `${Colores.success}20`,
                    },
                  ]}
                >
                  <Text
                    style={[
                      tw`text-white font-bold`,
                      { color: Colores.primaryStrong },
                    ]}
                  >
                    {zip ? zip.name : "Cargar .zip"}
                  </Text>
                </TouchableOpacity>

                {zip ? (
                  <>
                    {isLoading ? (
                      <ActivityIndicator
                        color={Colores.primary}
                        style={tw`mt-7`}
                      />
                    ) : (
                      <TouchableOpacity
                        style={[
                          tw`mt-7 py-3 px-15 border border-gray-300`,
                          {
                            borderRadius: 20,
                            backgroundColor: Colores.primary,
                          },
                        ]}
                        onPress={handleSubmit(onSubmit)}
                      >
                        <Text style={tw`text-white`}>Enviar</Text>
                      </TouchableOpacity>
                    )}
                  </>
                ) : (
                  <TouchableOpacity
                    disabled
                    style={[
                      tw`mt-7 py-3 px-15 border border-gray-300`,
                      { borderRadius: 20, backgroundColor: "white" },
                    ]}
                  >
                    <Text style={tw`text-gray-400`}>Enviar</Text>
                  </TouchableOpacity>
                )}
              </View>
            </View>
          )}
        </View>
      </PageContainer>
      <AwesomeAlert
        show={mostrarAlertaRecibos}
        title={mensaje.titulo}
        message={mensaje.mensaje}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={false}
        onDismiss={() => {
          setMostrarAlertaRecibos(false);
          setMensaje({});
        }}
      />
      <AwesomeAlert
        show={mostrarAlertaEliminarRecibo}
        showProgress={false}
        title="Atención"
        message="Esta seguro que desea eliminar el recibo?"
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="Cancelar"
        confirmText="Eliminar"
        confirmButtonColor={Colores.danger}
        onCancelPressed={() => {
          setMostrarAlertaEliminarRecibo(false);
        }}
        onConfirmPressed={() => {
          deleteRecibo.mutate(reciboSeleccionadoId);
          setMostrarAlertaEliminarRecibo(false);
        }}
      />
    </>
  );
}

export default CargarRecibosScreen;
