import React from "react";
import { Modal, Text, Pressable, View, ScrollView, useWindowDimensions } from "react-native";
import tw from 'twrnc';
import { AntDesign } from '@expo/vector-icons';


const ModalSeleccionarComida = ({modalVisible,setModalVisible, menu}) => {
  const {width} = useWindowDimensions();
  if(!menu) return null;
  return (
    <View style={tw`flex-1`}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View style={[width >= 758 ? tw`px-100 py-30 flex-1` :  tw`p-5 flex-1` ,{backgroundColor:'#7D7D7D80'}]}>

          <View style={tw`flex-1 bg-white rounded-md`}>
           <ScrollView>
            <Text style={tw`text-center mt-5 mb-3 text-lg font-semibold`}>Selecciona un menu</Text>
            {[...menu]}
           </ScrollView>
            <Pressable
              style={tw`p-1 rounded-full absolute right-2 top-2`}
              onPress={() => setModalVisible(!modalVisible)}
              >
              <AntDesign name="closecircle" size={24} style={tw`text-red-500`} />
            </Pressable>
          </View>
          
        </View>
        
        
      </Modal>
    </View>
  );
};

export default ModalSeleccionarComida;