import React from "react";
import { View } from "react-native";
import tw from 'twrnc';

import { PageContainer } from "../../components/PageContainer";
import { BeneficioForm } from "../../forms/BeneficioForm";

function CrearBeneficioScreen(props) {
  return (
    <PageContainer paddingMd >
      <View style={tw`w-full h-full`}>
        <BeneficioForm {...props} />
      </View>
    </PageContainer>
  );
}

export default CrearBeneficioScreen;
