import React from "react";
import Badge from "../Badge";

function LicenciaEstado({ estado }) {
  let color = "gray";
  switch (estado) {
    case "AUTORIZADO":
      color = "green";
      break;
    case "NO AUTORIZADO":
      color = "red";
      break;
  }
  return <Badge color={color} text={estado} />;
}

export default LicenciaEstado;
