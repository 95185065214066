import { View, Text } from "react-native";
import React from "react";
import tw from "twrnc";

export default function Cantidad({cantidad}) {
  return (
    <View style={tw`flex flex-row items-center`}>
      <Text style={tw`flex-1`}>Cantidad: </Text>
      <Text style={tw`text-gray-500`}>{cantidad}</Text>
    </View>
  );
}
