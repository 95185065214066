import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { useQuery } from "react-query";
import tw from "twrnc";
import { getEmpresasDptos } from "../../../services/apiClient";
import { Colores } from "../../../constants/colores";

const AsignarPermisos = ({ puesto, permiso, fnAsignarPermiso }) => {
  console.log("puesto", puesto);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const {
    data: empresas,
    isLoading,
    isError,
    refetch: refetchDepartamentos,
  } = useQuery("empresasDptos", getEmpresasDptos);

  useEffect(() => {
    const dptosConPermiso = puesto?.permisos?.filter(
      (permisoDpto) =>
        permisoDpto.id === permiso.id
    ).map(permisoDpto => permisoDpto.pivot.departamento_id);
    const initialSelectedItems = empresas?.data?.empresas
      .flatMap((empresa) => empresa.departamentos)
      .filter((departamento) => dptosConPermiso.includes(departamento.id));
    setSelectedItems(initialSelectedItems);
  }, [empresas, permiso]);

  if (isLoading) {
    return <Text>Cargando departamentos</Text>;
  }

  if (isError && !isRefetching) {
    return (
      <View style={tw`flex items-center justify-center w-auto`}>
        <TouchableOpacity
          style={[
            tw`mt-5 px-20 py-2.5 shadow-md text-white text-center`,
            { backgroundColor: Colores.primary, borderRadius: 16 },
          ]}
          onPress={refetchDepartamentos}
        >
          <Text style={tw`text-center text-white`}>Recargar</Text>
        </TouchableOpacity>
      </View>
    );
  }

  const handleItemPress = (departamento) => {
    const data = {
      puesto_id: puesto.id,
      permiso_id: permiso.id,
      departamento_id: departamento.id,
    };
    const isSelected = selectedItems.some(
      (selectedItem) => selectedItem.id === departamento.id
    );
    if (isSelected) {
      setSelectedItems(
        selectedItems.filter(
          (selectedItem) => selectedItem.id !== departamento.id
        )
      );
    } else {
      setSelectedItems([...selectedItems, departamento]);
    }

    fnAsignarPermiso.mutate({
      puestoId: puesto.id,
      data,
    })
  };

  const filterItems = (departamentos) => {
    return departamentos.filter((departamento) =>
      departamento.nombre.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  return (
    <ScrollView style={tw`p-4`}>
      <TextInput
        style={tw`h-10 border border-gray-300 rounded-md px-4 mb-4`}
        placeholder="Buscar..."
        value={searchText}
        onChangeText={(text) => setSearchText(text)}
      />

      {empresas?.data?.empresas.map((empresa) => (
        <View key={empresa.nombre} style={tw`mb-4`}>
          <Text style={tw`text-lg font-bold mb-2`}>{empresa.nombre}</Text>
          {filterItems(empresa?.departamentos).map((departamento) => (
            <TouchableOpacity
              key={departamento.id}
              style={[
                tw`bg-white px-4 py-2 rounded-md border border-gray-300 mb-2`,
                selectedItems?.some(
                  (selectedItem) => selectedItem.id === departamento.id
                )
                  ? { backgroundColor: Colores.successBg }
                  : null,
              ]}
              onPress={() => handleItemPress(departamento)}
            >
              <Text style={tw`text-base`}>{departamento.nombre}</Text>
            </TouchableOpacity>
          ))}
        </View>
      ))}
    </ScrollView>
  );
};

export default AsignarPermisos;
