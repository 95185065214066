import React, { useState } from "react";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import { Controller, useForm } from "react-hook-form";
import {
  View,
  Text,
  TextInput,
  ActivityIndicator,
  TouchableOpacity,
  Image,
  StyleSheet,
  useWindowDimensions,
} from "react-native";
import Checkbox from 'expo-checkbox';
import AwesomeAlert from "react-native-awesome-alerts";
import { useMutation } from "react-query";
import tw from "twrnc";

import ResultadoModal from "../components/ResultadoModal";
import SugerenciaTipos from "../components/sugerencias/SugerenciaTipos";
import { awesomeAlertAtom } from "../store/store";
import MensajeError from "../components/MensajeError";
import { nuevaSugerencia } from "../services/apiClient";
import { Colores } from "../constants/colores";

function SugerenciaForm() {
  const { width } = useWindowDimensions();
  const [awesomeAlert, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);
  const [esAnonima, setEsAnonima] = useState(true);
  const [tipo, settipo] = useState();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  const resetForm = () => {
    settipo();
    reset({ sugerencia: "" });
  };

  const nuevaSugerenciaMutate = useMutation(nuevaSugerencia, {
    onSuccess: async (response) => {
      if (response.data.ok) {
        setawesomeAlert({
          show: true,
          showProgress: false,
          title: "Exito!",
          title: null,
          message: null,
          showCancelButton: false,
          showConfirmButton: true,
          confirmText: "Aceptar",
          confirmButtonColor: "green",
          customView: (
            <ResultadoModal success={true} message="Sugerencia enviada" />
          ),
          onConfirmPressed: () => {
            resetAwesomeAlert();
            reset({ sugerencia: "" });
          },
          onDismiss: () => {
            resetAwesomeAlert();
            reset({ sugerencia: "" });
          },
        });
      }
    },
    onError: async (error) => {
      let mensajeErrores = "";
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        mensajeErrores = mensajeErrores + " \n" + value;
      }
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Error",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: <ResultadoModal success={false} message={mensajeErrores} />,
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
    },
  });

  const formData = new FormData();
  const onSubmit = (data) => {
    formData.append("tipo_id", tipo);
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    formData.append("anonima", esAnonima);

    nuevaSugerenciaMutate.mutate(formData);
  };

  return (
    <View>
      {width >= 768 && (
        <View
          style={[
            tw`w-full h-20 justify-center mt-10 bg-white`,
            { borderTopLeftRadius: 20, borderTopRightRadius: 20 },
          ]}
        >
          <Text style={tw`ml-10 text-lg font-semibold text-gray-600`}>
            Habla con nosotros
          </Text>
        </View>
      )}
      <View style={tw`h-full bg-white`}>
        <View style={tw`h-50 w-full justify-end`}>
          <Image
            source={require("../assets/sugerencias_fondo.png")}
            style={[tw`h-full w-full absolute`, StyleSheet.absoluteFill]}
          />
          <Text style={tw`text-white text-xl z-50 font-bold px-5 mb-5`}>
            Te invitamos a que nos cuentes como te sentís trabajando con
            nosotros.
          </Text>
        </View>

        <View style={tw`px-5`}>
          <View
            style={[
              tw`pb-2`,
              { borderBottomWidth: 1, borderBottomColor: Colores.primaryLight },
            ]}
          >
            <Text style={tw`mt-5 text-lg font-semibold`}>
              Saber lo que te pasa, el problema que tenes o la sugerencia de
              mejora nos sirve para crecer y ayudarte.
            </Text>
          </View>

          <SugerenciaTipos onChange={(tipo) => settipo(tipo)} />

          <Text style={tw`text-lg mt-5 mb-3`}>Mensaje</Text>
          <Controller
            control={control}
            rules={{
              required: "Ingrese un mensaje",
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                style={[
                  tw`w-full p-4 text-base font-normal text-gray-700 h-40 border border-gray-300`,
                  { borderRadius: 10, textAlignVertical: "top" },
                ]}
                onBlur={onBlur}
                onChangeText={onChange}
                placeholder="Tu mensaje"
                value={value}
                multiline={true}
              />
            )}
            name="sugerencia"
          />
          {errors?.sugerencia && (
            <MensajeError message={errors.sugerencia?.message} />
          )}

          <View style={tw`flex-row mt-5 ml-2`}>
            <View style={tw`justify-center`}>
              <Checkbox value={esAnonima} onValueChange={setEsAnonima} />
            </View>
            <View style={tw`ml-2`}>
              <Text>Anónima (no se mostrara tu nombre)</Text>
            </View>
          </View>

          {nuevaSugerenciaMutate.isLoading ? (
            <ActivityIndicator
              style={tw`mt-4`}
              size="small"
              color={Colores.primary}
            />
          ) : (
            <View style={tw`justify-center items-center mt-5 mb-10`}>
              <TouchableOpacity
                style={[
                  tw`mt-4 px-20 py-2.5 shadow-md`,
                  { backgroundColor: Colores.primary, borderRadius: 16 },
                ]}
                onPress={handleSubmit(onSubmit)}
              >
                <Text style={tw`text-white text-center`}>Enviar</Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </View>
      <AwesomeAlert
        show={awesomeAlert.show}
        showProgress={awesomeAlert.showProgress}
        title={awesomeAlert.title}
        message={awesomeAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={awesomeAlert.showCancelButton}
        showConfirmButton={awesomeAlert.showConfirmButton}
        cancelText="Cancelar"
        confirmText={awesomeAlert.confirmText}
        confirmButtonColor={awesomeAlert.confirmButtonColor}
        customView={awesomeAlert.customView}
        onDismiss={awesomeAlert.onDismiss}
        onConfirmPressed={awesomeAlert.onConfirmPressed}
      />
    </View>
  );
}

export default SugerenciaForm;
