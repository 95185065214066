import React, { useState } from "react";
import {
  ScrollView,
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
} from "react-native";
import tw from "twrnc";

import { PageContainer } from "../../components/PageContainer";
import { Colores } from "../../constants/colores";
import { DataTable } from "react-native-paper";
import { useQuery } from "react-query";
import { getItems } from "../../services/apiClient";
import { Loader } from "../../components/Loader";
import { SimpleLineIcons } from "@expo/vector-icons";
import EstadoItem from "../../components/Items/EstadoItem";
import ColaboradorItem from "../../components/Items/ColaboradorItem";
import AccionesDatatableItem from "../../components/Items/AccionesDatatableItem";
import AwesomeAlert from "react-native-awesome-alerts";
import { useAtom } from "jotai";
import { awesomeAlertAtom } from "../../store/store";
import { useResetAtom } from "jotai/utils";

export default function AdministrarItemsScreen(props) {
  const [awesomeAlert, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);
  const [page, setPage] = useState(0);
  const { data, error, isLoading } = useQuery(
    ["items", page],
    () => getItems(page),
    {}
  );
  if (error) {
    return <Text>{error}</Text>;
  }

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const estilosTitulos = tw`min-w-[120px] justify-center`;
  const estilosCeldas = tw`min-w-[120px] justify-center w-full px-2`;

  return (
    <PageContainer>
      <ScrollView>
        <TouchableOpacity
          onPress={() => props.navigation.navigate("Asignar Items")}
          style={[
            tw`self-end bg-white max-w-[220px] flex flex-row items-center shadow-md rounded-lg mr-2 my-4`,
          ]}
        >
          <View
            style={[
              tw`w-1 h-5 rounded-r-md`,
              { backgroundColor: Colores.primary },
            ]}
          ></View>
          <View
            style={tw`flex-1 flex flex-row items-center justify-center px-2 py-4`}
          >
            <Text style={tw`text-gray-700 text-center font-semibold`}>
              Asignar nuevo Activo
            </Text>
            <View style={tw`min-w-16 items-end`}>
              <SimpleLineIcons
                name="arrow-right"
                size={16}
                color={Colores.primary}
              />
            </View>
          </View>
        </TouchableOpacity>
        <Text style={tw`text-lg font-semibold`}>Activos de colaboradores</Text>
        {isLoading ? (
          <Loader text="Cargando Activos" />
        ) : (
          <View style={tw`w-full`}>
            <DataTable>
              <ScrollView
                horizontal
                contentContainerStyle={{ flex: 1, flexDirection: "column" }}
              >
                <DataTable.Header style={tw`bg-gray-200 rounded-t-md`}>
                  <DataTable.Title style={estilosTitulos}>
                    Colaborador
                  </DataTable.Title>
                  <DataTable.Title style={estilosTitulos}>
                    Tipo de Activo
                  </DataTable.Title>
                  <DataTable.Title style={estilosTitulos}>
                    Estado
                  </DataTable.Title>
                  <DataTable.Title style={estilosTitulos}>
                    Fecha asignacion
                  </DataTable.Title>
                  <DataTable.Title style={estilosTitulos}>
                    Fecha caducidad
                  </DataTable.Title>
                  <DataTable.Title style={estilosTitulos}>
                    Asignado por:
                  </DataTable.Title>
                  <DataTable.Title style={estilosTitulos}>
                    Descripción
                  </DataTable.Title>
                  <DataTable.Title style={estilosTitulos}>
                    Fecha devolución
                  </DataTable.Title>
                  <DataTable.Title style={estilosTitulos}>
                    Acciones
                  </DataTable.Title>
                </DataTable.Header>
                {data?.data?.colaboradores?.data.map((item, index) => (
                  <DataTable.Row
                    key={item.id}
                    // onPress={() =>
                    //   props.navigation.navigate("Detalle Item", {
                    //     item,
                    //   })
                    // }
                    style={index % 2 !== 0 ? tw`bg-gray-100` : ""}
                    pointerEvents="none"
                  >
                    <DataTable.Cell style={tw`min-w-[120px] justify-center`}>
                      <ColaboradorItem colaborador={item?.asignado} />
                    </DataTable.Cell>
                    <DataTable.Cell style={estilosCeldas}>
                      {item?.tipo?.tipo}
                    </DataTable.Cell>
                    <DataTable.Cell style={estilosCeldas}>
                      <EstadoItem item={item} />
                    </DataTable.Cell>
                    <DataTable.Cell style={estilosCeldas}>
                      {new Date(item?.created_at).toLocaleDateString()}
                    </DataTable.Cell>
                    <DataTable.Cell style={estilosCeldas}>
                      {item?.caduca
                        ? new Date(item?.fechaCaduca).toLocaleDateString()
                        : "-"}
                    </DataTable.Cell>
                    <DataTable.Cell style={tw`min-w-[120px] justify-center`}>
                      <ColaboradorItem colaborador={item?.asigno} />
                    </DataTable.Cell>
                    <DataTable.Cell style={estilosCeldas}>
                      {item?.descripcion}
                    </DataTable.Cell>
                    <DataTable.Cell style={estilosCeldas}>-</DataTable.Cell>
                    <DataTable.Cell
                      style={tw`min-w-[80px] justify-center px-2`}
                    >
                      <AccionesDatatableItem item={item} />
                    </DataTable.Cell>
                  </DataTable.Row>
                ))}
              </ScrollView>
            </DataTable>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text>Página: {page + 1}</Text>
              <DataTable.Pagination
                page={page}
                numberOfPages={
                  data?.data?.colaboradores?.last_page
                    ? data?.data?.colaboradores?.last_page
                    : 1
                }
                onPageChange={(newPage) => handlePageChange(newPage)}
                label={`de ${
                  data?.data?.colaboradores?.last_page
                    ? data?.data?.colaboradores?.last_page
                    : 1
                }`}
              />
            </View>
          </View>
        )}
      </ScrollView>
      <AwesomeAlert
        show={awesomeAlert.show}
        showProgress={awesomeAlert.showProgress}
        title={awesomeAlert.title}
        message={awesomeAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={awesomeAlert.showCancelButton}
        showConfirmButton={awesomeAlert.showConfirmButton}
        cancelText="Cancelar"
        confirmText={awesomeAlert.confirmText}
        confirmButtonColor={awesomeAlert.confirmButtonColor}
        customView={awesomeAlert.customView}
        onDismiss={awesomeAlert.onDismiss}
        onConfirmPressed={awesomeAlert.onConfirmPressed}
        onCancelPressed={() => {
          resetAwesomeAlert();
        }}
      />
    </PageContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    gap: 16,
  },
  path: {
    fontWeight: "bold",
  },
  departamentoNombre: {
    fontWeight: "bold",
    color: "#333333",
  },
  puesto: {
    backgroundColor: "white",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 16,
    marginBottom: 16,
  },
});
