import React from "react";

const LogoPurple = () => {
  return (
    <svg
      width="415"
      height="140"
      viewBox="0 0 415 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="415" height="140.001" fill="white" />
      <path
        d="M147.233 113.704C142.689 109.512 140.418 103.331 140.419 95.162C140.42 86.9929 142.692 80.8198 147.233 76.6426C151.775 72.4642 158.305 70.375 166.825 70.375C175.345 70.375 181.876 72.4642 186.417 76.6426C190.96 80.8223 193.231 86.9954 193.231 95.162C193.231 103.329 190.96 109.509 186.417 113.704C181.876 117.896 175.345 119.992 166.825 119.994C158.305 119.995 151.775 117.898 147.233 113.704ZM158.283 83.8321C156.477 86.1956 155.574 89.9666 155.574 95.1452C155.574 100.324 156.477 104.108 158.283 106.499C160.087 108.888 162.933 110.082 166.821 110.082C170.71 110.082 173.564 108.888 175.382 106.499C177.202 104.113 178.112 100.329 178.112 95.1452C178.112 89.9616 177.202 86.1906 175.382 83.8321C173.561 81.4686 170.708 80.2868 166.821 80.2868C162.935 80.2868 160.089 81.4686 158.283 83.8321Z"
        fill="#5C5BE5"
      />
      <path
        d="M234.367 82.3309L227.641 82.0678C226.543 82.0695 225.452 82.2381 224.405 82.5679C219.554 84.083 216.373 88.746 216.373 93.8306V116.446C216.386 116.802 216.325 117.156 216.193 117.487C216.061 117.818 215.862 118.118 215.608 118.368C215.357 118.613 215.06 118.806 214.734 118.934C214.407 119.062 214.059 119.123 213.708 119.114H203.925C203.571 119.131 203.217 119.074 202.886 118.946C202.555 118.818 202.254 118.622 202.003 118.371C201.752 118.12 201.557 117.82 201.429 117.489C201.301 117.158 201.244 116.804 201.261 116.449V73.9492C201.249 73.5902 201.31 73.2325 201.441 72.8981C201.573 72.5637 201.771 72.2597 202.024 72.0049C202.272 71.7543 202.568 71.5572 202.895 71.4257C203.222 71.2941 203.573 71.2308 203.925 71.2398H213.708C214.064 71.227 214.419 71.2884 214.75 71.4201C215.081 71.5518 215.38 71.7509 215.63 72.0049C215.878 72.2626 216.072 72.5675 216.2 72.9014C216.328 73.2354 216.388 73.5916 216.376 73.9492C216.376 74.2105 216.445 74.4672 216.578 74.6922C216.711 74.9171 216.902 75.1021 217.132 75.2274C217.361 75.3528 217.62 75.4139 217.881 75.4044C218.142 75.3948 218.396 75.315 218.616 75.1732C220.61 73.868 222.736 72.7766 224.96 71.9172C227.71 70.8859 231.313 70.3691 235.769 70.3666C236.128 70.3551 236.485 70.417 236.82 70.5486C237.154 70.6801 237.458 70.8785 237.713 71.1316C237.963 71.3797 238.159 71.6761 238.29 72.0027C238.422 72.3293 238.485 72.6793 238.476 73.0311V79.6701C238.489 80.0259 238.428 80.3805 238.297 80.7113C238.165 81.0422 237.967 81.3421 237.713 81.592C237.455 81.8401 237.151 82.0341 236.817 82.1623C236.483 82.2905 236.126 82.3503 235.769 82.3384L234.367 82.3309Z"
        fill="#5C5BE5"
      />
      <path
        d="M329.494 113.616C325.008 109.365 322.769 103.243 322.776 95.2497C322.784 87.256 325.04 81.1122 329.546 76.818C334.06 72.5227 340.321 70.375 348.328 70.375C356.335 70.375 362.581 72.4642 367.065 76.6426C371.544 80.8223 373.783 86.8132 373.783 94.6153V97.1063C373.796 97.4654 373.735 97.8234 373.604 98.1579C373.472 98.4925 373.273 98.7963 373.019 99.0504C372.765 99.3045 372.461 99.5035 372.127 99.6348C371.792 99.7662 371.434 99.8271 371.075 99.8137H341.734C341.268 99.813 340.809 99.9217 340.393 100.131C339.977 100.341 339.617 100.645 339.34 101.02C339.064 101.395 338.879 101.829 338.802 102.288C338.724 102.747 338.756 103.218 338.894 103.663C339.42 105.335 340.21 106.658 341.264 107.632C343.025 109.263 345.843 110.078 349.716 110.078C353.59 110.078 356.239 109.207 357.665 107.466C359.091 105.717 360.852 104.828 362.949 104.799H369.458C369.817 104.786 370.175 104.847 370.51 104.979C370.844 105.11 371.148 105.309 371.402 105.563C371.656 105.818 371.855 106.122 371.987 106.457C372.118 106.791 372.179 107.15 372.165 107.509C372.165 115.866 364.204 120.03 348.281 120.001C340.241 119.996 333.978 117.868 329.494 113.616ZM343.145 90.5979H354.383C356.695 90.5979 358.393 88.3252 357.63 86.1421C356.261 82.2411 353.151 80.2905 348.3 80.2905C344.143 80.2905 341.253 81.7173 339.631 84.571C339.284 85.1853 339.104 85.8799 339.109 86.5855C339.113 87.2911 339.303 87.9831 339.658 88.5927C340.014 89.2023 340.522 89.7082 341.134 90.06C341.746 90.4118 342.439 90.5973 343.145 90.5979Z"
        fill="#5C5BE5"
      />
      <path
        d="M126.691 58.9912C124.907 59.4638 123.268 60.3736 121.924 61.6382C120.579 62.9029 119.571 64.4827 118.99 66.2347L111.228 89.5215C110.625 91.3333 108.056 91.3034 107.496 89.4786L100.538 66.9008C99.0886 62.1912 94.853 62.4487 89.9494 62.236C85.0458 62.4413 80.8065 62.1838 79.3586 66.9008L72.4025 89.4786C71.8427 91.3034 69.2678 91.3333 68.6707 89.5215L60.9085 66.2347C60.328 64.4825 59.3199 62.9026 57.9754 61.6379C56.6309 60.3732 54.9923 59.4635 53.2079 58.9912C47.4236 57.4723 39.6988 56.1065 41.4751 60.2581L60.9552 114.767C62.0312 117.738 64.1129 119.223 67.2003 119.223H73.578C76.7799 119.223 78.8616 117.738 79.8232 114.767L88.8877 86.1609C88.964 85.9067 89.1013 85.6751 89.2877 85.4861C89.474 85.2972 89.7038 85.1566 89.9569 85.0768C90.2099 85.1563 90.4396 85.2968 90.6257 85.4858C90.8118 85.6748 90.9487 85.9067 91.0242 86.1609L100.089 114.767C101.05 117.738 103.133 119.223 106.336 119.223H112.712C115.799 119.223 117.881 117.738 118.959 114.767L138.416 60.2581C140.202 56.1065 132.471 57.4723 126.691 58.9912Z"
        fill="#5C5BE5"
      />
      <path
        d="M292.6 114.412L285.155 102.414C282.527 98.2542 279.422 95.6388 275.838 94.5678C277.758 94.1573 279.546 93.2368 281.205 91.8062C282.85 90.3898 284.184 88.6477 285.123 86.6899L290.736 74.8002C291.201 73.805 291.433 72.9896 291.432 72.3539C291.432 71.2182 290.703 70.6516 289.245 70.6541L280.99 70.6671C279.681 70.6698 278.403 71.0678 277.323 71.8091C276.2 72.5542 275.308 73.6004 274.75 74.8282L270.573 83.7436C268.724 87.662 265.282 89.633 260.247 89.6567V59.4287C259.082 58.0945 253.205 58.2419 250.022 59.4622C247.178 60.5519 245.336 63.1941 245.336 66.0937V115.963C245.325 116.302 245.386 116.639 245.514 116.953C245.643 117.267 245.837 117.55 246.083 117.784C246.605 118.263 247.296 118.516 248.005 118.487H257.787C258.492 118.511 259.177 118.26 259.698 117.786C259.939 117.566 260.13 117.296 260.258 116.996V99.617C264.781 99.617 268.235 101.545 270.621 105.401L276.183 114.432C277.848 117.169 280.078 118.534 282.873 118.526L291.127 118.513C292.64 118.513 293.396 117.871 293.394 116.588C293.361 115.981 293.096 115.255 292.6 114.412Z"
        fill="#5C5BE5"
      />
      <path
        d="M314.463 72.9988C313.136 72.0248 307.443 72.1517 304.323 73.0828C301.481 73.9318 299.64 75.9881 299.64 78.2552V117.139C299.636 117.419 299.702 117.696 299.832 117.944C299.963 118.192 300.153 118.404 300.386 118.559C300.884 118.925 301.524 119.108 302.308 119.108H312.091C312.766 119.132 313.432 118.94 313.99 118.559C314.177 118.425 314.339 118.258 314.466 118.067L314.463 72.9988Z"
        fill="#5C5BE5"
      />
      <path
        d="M307.049 69.2232C311.385 69.2232 314.901 65.7078 314.901 61.3714C314.901 57.0349 311.385 53.5195 307.049 53.5195C302.713 53.5195 299.197 57.0349 299.197 61.3714C299.197 65.7078 302.713 69.2232 307.049 69.2232Z"
        fill="#5C5BE5"
      />
      <path
        d="M141.272 51.9034C143.684 49.5419 146.235 47.3268 148.911 45.2701C151.557 43.2343 154.263 41.2695 157.078 39.4708C162.699 35.8147 168.613 32.6317 174.76 29.9545C187.075 24.5825 200.394 21.1511 214.003 20.2946C220.819 19.8023 227.665 19.9272 234.457 20.6678C237.878 20.9178 241.227 21.6008 244.621 22.1027L249.633 23.1887L252.144 23.7429L254.613 24.4295C267.825 28.0065 280.472 33.4699 292.284 40.2227C293.178 40.7339 293.963 41.4162 294.594 42.2307C295.224 43.0452 295.688 43.9759 295.959 44.9698C296.23 45.9637 296.303 47.0012 296.173 48.0231C296.043 49.045 295.713 50.0313 295.202 50.9257C294.691 51.8201 294.008 52.605 293.194 53.2357C292.379 53.8664 291.449 54.3305 290.455 54.6015C289.461 54.8725 288.424 54.9451 287.402 54.8151C286.38 54.6851 285.393 54.3551 284.499 53.844C284.34 53.7544 284.173 53.6481 284.023 53.5492C273.664 46.6023 262.554 40.8441 250.844 36.7558L248.653 35.9684L246.425 35.3153L241.973 34.0055C238.954 33.3524 235.954 32.4941 232.886 32.0574C226.772 30.9361 220.567 30.374 214.35 30.3781C201.888 30.2736 189.366 32.5314 177.452 36.7428C171.468 38.8541 165.67 41.4592 160.118 44.5312C157.319 46.0482 154.619 47.7312 151.966 49.4852C149.341 51.2216 146.818 53.1088 144.411 55.1371L144.327 55.2099C143.884 55.5898 143.312 55.7833 142.73 55.7497C142.147 55.7161 141.601 55.4579 141.205 55.0296C140.81 54.6012 140.595 54.036 140.608 53.4529C140.62 52.8699 140.859 52.3144 141.272 51.9034Z"
        fill="#5C5BE5"
      />
    </svg>
  );
};

export default LogoPurple;