import React from "react";
import { ScrollView, View } from "react-native";

import { PageContainer } from "../../components/PageContainer";
import SolicitudCarreraForm from "../../forms/SolicitudCarreraForm";

export default function PlanDeCarreraScreen(props) {
  return (
    <PageContainer paddingMd>
      <ScrollView>
        <SolicitudCarreraForm />
      </ScrollView> 
    </PageContainer>
  );
}
