import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  ActivityIndicator,
  Switch,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as DocumentPicker from "expo-document-picker";
import tw from "twrnc"

import { editarPlato } from "../services/apiClient";

import AwesomeAlert from "react-native-awesome-alerts";
import ResultadoModal from "../components/ResultadoModal";
import MensajeError from "../components/MensajeError";
import EditarImagen from "../components/EditarImagen";
import { Colores } from "../constants/colores";

export const PlatoEditarForm = (props) => {
  const { plato } = props;
  const [foto, setfoto] = useState();
  const [habilitado, setHabilitado] = useState(0);
  const queryClient = useQueryClient();
  const {width} = useWindowDimensions();

  const alertInitialState = {
    show: false,
    title: "",
    message: "",
    showConfirmButton: false,
  };
  const [mostrarAlert, setmostrarAlert] = useState(alertInitialState);

  let valoresInicialesForm = {
    nombre: plato.nombre,
    descripcion: plato.descripcion,
    habilitado: +plato.habilitado,
  };

  const toggleHabilitadoSwitch = () =>
    setHabilitado((previousState) => !previousState);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue
  } = useForm({
    mode: "onBlur",
    defaultValues: valoresInicialesForm,
  });

  useEffect(() => {
    reset(valoresInicialesForm);
  }, [plato]);

  const resetForm = () => {
    reset({ nombre: "", descripcion: "", habilitado: habilitado });
    setfoto();
    setHabilitado(0);
  };

  const editarPlatoMutate = useMutation(editarPlato, {
    onSuccess: async (response) => {
      resetForm();
      queryClient.invalidateQueries("platos");
      //mostral mostrar modal
      setmostrarAlert({
        show: true,
        showConfirmButton: true,
        customView: (
          <ResultadoModal success={true} message="El plato fue editado" />
        ),
      });
    },
    onError: async (error) => {
      console.log(error.response.data);
      //TODO: mostrar errores en campos
      setmostrarAlert({
        show: true,
        customView: <ResultadoModal success={false} message="Hubo un error" />,
      });
    },
  });

  const formData = new FormData();
  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: ["image/jpeg", "image/png"],
    });
    setfoto(result);
  };

  const onSubmit = (data) => {
    if (foto) {
      formData.append("foto", foto?.file);
    }
    //se cargan el resto de campos del formulario en formData
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }
    editarPlatoMutate.mutate({
      tipoPlato: plato.tipo,
      platoId: plato.id,
      formData,
    });
  };

  useEffect(() => {
    if(plato){
      setValue('nombre',plato?.nombre)
      setValue('habilitado',plato?.habilitado)
      setValue('descripcion',plato?.descripcion)
    }
  }, [plato])

  return (
    <>
      <View style={[tw`px-5 bg-white`,{borderRadius: width >=768 ? 20 : 0}]}>
        <ScrollView>
              <View>
                {width >=768 && (
                  <Text style={tw`text-lg font-semibold mb-5 mt-10 ml-10`}>Editar Plato</Text>
                )}
              </View>

            <View style={width >=768 ? tw`px-20` : tw``}>
              
              <Text style={tw` text-gray-900 mt-3`}>Nombre</Text>
              <Controller
                control={control}
                rules={{
                  required: "Ingrese un nombre",
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={[tw` mt-2 px-3 py-1.5 text-gray-700 border border-gray-300`,{backgroundColor:'#FFF',borderRadius:50}]}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    placeholder="Nombre"
                    value={value}
                  />
                )}
                name="nombre"
              />
              {errors?.nombre && <MensajeError message={errors.nombre?.message} />}
            </View>
            
            <View style={width >=768 ? tw`flex-row mt-10 px-20` : tw`flex-row mt-3`}>
              
              <View style={width >=768 ? tw`flex-1 px-20` : tw`flex-1`}>
                <Text>Agregar imagen</Text>
                <Text style={tw`text-gray-500 mt-1 mb-2`}>Formato valido jpg o png</Text>
                <EditarImagen formData={formData} modelo={plato} />
              </View>

              <View style={width >=768 ? tw`flex-1 px-20 items-center` : tw`flex-1 items-center`}>
                <Text>Estado</Text>
                <Controller
                  control={control}
                  defaultValue={habilitado}
                  name="habilitado"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View style={tw`flex-row flex mt-5 mb-5`}>
                      <Text style={value ? [tw`mt-1 mr-2 `,{color:'#009688'}] : tw`mt-1 mr-2 text-gray-700`}>{value ? 'Activo' : 'Inactivo'}</Text>
                      <Switch
                        style={tw`mt-1`}
                        trackColor={{ false: "#767577", true: "#81b0ff" }}
                        thumbColor={value ? "#f5dd4b" : "#f4f3f4"}
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={(value) => {
                          toggleHabilitadoSwitch;
                          onChange(value ? 1 : 0);
                        }}
                        value={value}
                      />
                    </View>
                  )}
                />          
              </View>
            </View>

          

        {/* <Text style={tw`text-lg mt-5 capitalize`}>{plato.tipo}</Text> */}
        <View style={width >=768 ? tw`mt-10 px-20` : tw`mt-5`}>
            <Text>Descripción</Text>
            <Controller
              control={control}
              rules={{
                required: "Ingrese una descripción",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[tw` mt-2 w-full px-3 py-1.5 text-gray-700 rounded-lg h-40 border border-gray-300`,{backgroundColor:'#FFF', borderRadius:10}]}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  multiline={true}
                  placeholder="Descripción"
                  value={value}
                />
              )}
              name="descripcion"
            />
            {errors?.descripcion && (
              <MensajeError message={errors.descripcion?.message} />
            )}
        </View>

        

        {editarPlatoMutate.isLoading ? (
          <ActivityIndicator size="small" style={tw`mt-4`} color={Colores.primary} />
        ) : (
          <View style={tw`justify-center items-center mb-20 mt-5`}>
            <TouchableOpacity style={[tw`mt-4 px-20 py-2.5 shadow-md`,{backgroundColor:Colores.primary, borderRadius:15}]} onPress={handleSubmit(onSubmit)}>
              <Text style={tw`text-white text-center`}>Guardar</Text>
            </TouchableOpacity>
          </View>
        )}
      </ScrollView>
      </View>

      <AwesomeAlert
        show={mostrarAlert.show}
        showProgress={false}
        title={mostrarAlert.title}
        message={mostrarAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={mostrarAlert.showConfirmButton}
        confirmText="Aceptar"
        customView={mostrarAlert.customView}
        confirmButtonColor="green"
        onDismiss={() => {
          setmostrarAlert(alertInitialState);
          props.navigation.navigate("Administrar platos");
        }}
        onCancelPressed={() => {
          setmostrarAlert(alertInitialState);
        }}
        onConfirmPressed={() => {
          setmostrarAlert(alertInitialState);
          props.navigation.navigate("Administrar platos");
        }}
      />
    </>
  );
};