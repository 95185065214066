import React, { useEffect, useState } from "react";
import {
  FlatList,
  Image,
  Modal,
  Pressable,
  Text,
  TextInput,
  useWindowDimensions,
  View,
} from "react-native";
import { Feather } from "@expo/vector-icons";
import tw from 'twrnc';
import { Colores } from "../../constants/colores";

function SeleccionarPlato({
  data,
  setPlato,
  tipoPlato = "comidas",
  text = "Seleccionar",
  icono
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [filter, setFilter] = useState("");
  const [datos, setdatos] = useState([]);
  const initialData = data?.data[tipoPlato];
  const {width} = useWindowDimensions();

  useEffect(() => {
    setdatos(data?.data[tipoPlato]);
  }, []);

  useEffect(() => {
    //setdatos(data?.data[tipoPlato]);
    if (filter.length) {
      const datosFiltrados = initialData.filter((item) =>
        item.nombre.toLowerCase().includes(filter.toLocaleLowerCase())
      );
      setdatos(datosFiltrados);
    } else {
      setdatos(initialData);
    }
  }, [filter]);

  const Item = ({ plato }) => (
    <Pressable
      onPress={() => {
        setPlato(plato);
        setModalVisible(false);
      }}
    >
      <View style={tw`flex-row mt-2 w-full`}>
        {plato.foto ? (
          <View>
            <Image
              style={tw`rounded-2xl w-[5rem] h-[5rem]`}
              source={{ uri: plato.foto }}
            />
          </View>
        ) : null}
        <View
        style={tw`flex pl-2 flex-1 justify-center`}
        >
          <Text style={tw`font-semibold`}>{plato?.nombre.charAt(0).toUpperCase() + plato?.nombre.slice(1).toLowerCase()}</Text>
        </View>
      </View>
      <View style={[tw`mt-2`,{borderBottomWidth:1, borderBottomColor:Colores.primaryLight}]} />
    </Pressable>
  );

  const renderItem = ({ item }) =>
    item.habilitado === 1 ? <Item plato={item} /> : null;

  return (
    <>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View style={[tw`flex-1 justify-center items-center bg-cyan-100 py-5 px-3`,{backgroundColor:'#7D7D7D50'}]}>
          <View style={ width >= 768 ? tw`w-200 h-full bg-white rounded-2xl p-5`: tw`w-full h-full bg-white rounded-2xl p-5`}>
            <Text style={tw`capitalize mb-2 text-lg font-bold text-gray-400 text-center`}>{tipoPlato}</Text>
            <View style={tw`flex flex-row`}>
              <TextInput
                style={tw`border p-2 border-gray-300 text-gray-700 text-lg rounded-full w-full`}
                onChangeText={setFilter}
                value={filter}
                placeholder="Buscar"
              />
              <Feather
                style={tw`absolute right-3 top-2 text-gray-400`}
                name="search"
                size={24}
              />
            </View>
            {datos?.length ? (

                <FlatList
                  data={datos}
                  renderItem={renderItem}
                  keyExtractor={(item) => item.id}
                />
            ) : (
              <Text>No hay {tipoPlato}</Text>
            )}

            <View style={tw`items-center`}>
              <Pressable
                style={[tw`bg-red-600 px-10 py-2.5 mr-2 mb-2`, {borderRadius:15}]}
                onPress={() => setModalVisible(!modalVisible)}
              >
                <Text style={tw`text-white `}>Cancelar</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
      <Pressable
        style={[tw`py-2 px-3`,{backgroundColor:Colores.primaryLight, borderRadius:30}]}
        onPress={() => setModalVisible(true)}
      >
        <Text style={[tw`text-white text-center`,{color:Colores.primary}]}>
          {icono}
          {text}
        </Text>
      </Pressable>
    </>
  );
}

export default SeleccionarPlato;
