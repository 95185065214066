import { LinearGradient } from "expo-linear-gradient";
import { useAtom } from "jotai";
import React, { useState } from "react";
import {
  View,
  TouchableOpacity,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import {
  Button,
  Text,
  TextInput,
  StyleSheet,
  ActivityIndicator,
} from "react-native";
import RNPasswordStrengthMeter from "react-native-password-strength-meter";
import { useMutation } from "react-query";

import { parsearErrores } from "../utils/generales";
import ResultadoModal from "../components/ResultadoModal";
import { actualizarPassword } from "../services/apiClient";
import { awesomeAlertAtom, userAtom } from "../store/store";
import { useResetAtom } from "jotai/utils";
import AwesomeAlert from "react-native-awesome-alerts";
import MensajeError from "../components/MensajeError";

import tw from "twrnc";
import ColaboradorData from "../components/Colaboradores/ColaboradorData";
import { Colores } from "../constants/colores";
import { useNavigation } from "@react-navigation/native";
import { Octicons } from "@expo/vector-icons";
import { Entypo } from "@expo/vector-icons";

export const CambiarPasswordForm = () => {
  const [colaborador] = useAtom(userAtom);
  const [user, setUser] = useAtom(userAtom);
  const navigator = useNavigation();
  const { width } = useWindowDimensions();

  return (
    <View>
      <ColaboradorData dataColaborador={user} />

      <TouchableOpacity
        style={[
          tw`border  flex-row mt-5 mb-20 ${
            width > 758 ? "mx-10" : "mx-3"
          } px-5 py-7`,
          { borderRadius: 20 },
        ]}
        onPress={() => navigator.navigate("Cambiar Password")}
      >
        <View style={tw`flex-row flex-5`}>
          <Octicons name="key" size={24} color={Colores.primaryLight} />
          <Text
            style={[tw`font-semibold ml-2 text-lg`, { color: Colores.primary }]}
          >
            Cambiar contraseña
          </Text>
        </View>
        <View style={tw`items-end justify-center flex-1`}>
          <Entypo name="chevron-thin-right" size={24} color={Colores.gray} />
        </View>
      </TouchableOpacity>
    </View>
  );
};
