import React from "react";
import { Text, TouchableOpacity, useWindowDimensions, View } from "react-native";
import { calcularEdad, formatoFechaES } from "../../utils/fechas";
import Badge from "../Badge";
import tw from 'twrnc';
import { Colores } from "../../constants/colores";
import { FontAwesome5 } from "@expo/vector-icons";


function ColaboradorDatosPersonales({ nombre, apellido, fecha, dni , direccion, dataColaborador, navigator }) {
  const edad = calcularEdad(fecha);
  const {width} = useWindowDimensions()

  return (
    <View
      style={[tw`bg-white px-5 pb-7 flex  border border-gray-300 shadow-lg`,{borderRadius:20}]}
    >
      {width < 768 && (
        <View style={tw`items-center -m-5 mb-1`}>
          <TouchableOpacity style={[tw`px-20 py-2 items-center justify-center absolute`,{backgroundColor:Colores.primaryStrong, borderRadius:50}]} onPress={()=>{navigator.navigate("Editar Perfil", {colaborador:dataColaborador});}}>
            <Text style={tw`ml-2 text-white`}>Editar perfil</Text>
          </TouchableOpacity>
        </View>
      )}


      <View style={[width > 768 ? tw`mb-3 mt-5`: tw`mb-3 mt-15`,{borderBottomWidth:1, borderBottomColor:Colores.primaryLight}]}>
        <Text style={[tw`text-2xl mb-2`,{color:Colores.primary}]}>Datos Personales</Text>
      </View>

      
      <View style={tw`flex flex-row items-center mt-2`}>
        <Text style={[tw` font-semibold`,{color:Colores.primary}]}>Nombre:</Text>
        <Text style={tw` ml-2`}>{nombre.toUpperCase()}</Text>
      </View>

      <View style={tw`flex flex-row items-center mt-2`}>
        <Text style={[tw` font-semibold`,{color:Colores.primary}]}>
          Apellido:
        </Text>
        <Text style={tw` ml-2`}>{apellido.toUpperCase()}</Text>
      </View>


      <View style={tw`flex flex-row items-center mt-2`}>
        <Text style={[tw` font-semibold`,{color:Colores.primary}]}>DNI:</Text>
        <Text style={tw` ml-2`}>{dni}</Text>
      </View>

      <View style={tw`flex flex-row items-center mt-2`}>
        <Text style={[tw` font-semibold`,{color:Colores.primary}]}>Direccion:</Text>
        <Text style={tw` ml-2`}>{direccion}</Text>
      </View>

      <View style={tw`flex flex-row items-center mt-2`}>
        <Text style={[tw` font-semibold`,{color:Colores.primary}]}>
          Fecha de Nacimiento:
        </Text>
        <Text style={tw` ml-2`}>{formatoFechaES(fecha)}</Text>
      </View>


      <View style={tw`flex flex-row items-center mt-2`}>
        <Text style={[tw` font-semibold`,{color:Colores.primary}]}>Edad: </Text>
        <Text style={tw` ml-1`}>{edad}</Text>
      </View>
    </View>
  );
}

export default ColaboradorDatosPersonales;
