import React from "react";
import {
  View,
  Image,
  StyleSheet,
  Text,
  Pressable,
  ActivityIndicator,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import tw from "twrnc";
import { EvilIcons } from "@expo/vector-icons";
import moment from "moment";
import { Colores } from "../../constants/colores";

const NombrePlato = ({ plato, categoria  = '' }) => {
  return (
    <View style={tw`flex flex-row`}>
      <Text style={{fontColor: Colores.primaryMedium}}>
        { categoria.length ? `${categoria} : ` : null}
        <Text style={tw`text-white`}>
            {
                plato ?
                plato?.nombre.charAt(0).toUpperCase() +
                plato?.nombre.slice(1).toLowerCase()
                :
                `Sin ${categoria}`
            }
        </Text>
      </Text>
    </View>
  );
};

const CardMenuDelDia = ({ menu, handleSubmit, deleteMutate }) => {
  return (
    <View
      key={Math.random(100) * menu.id}
      style={[
        tw`bg-white h-60 w-45 flex-1 flex-between shadow-lg mx-1 my-1`,
        { borderRadius: 20 },
      ]}
    >
      <Image
        source={{ uri: menu?.comida?.foto }}
        style={[
          tw`h-full w-full`,
          StyleSheet.absoluteFill,
          { borderRadius: 20 },
        ]}
      />
      <LinearGradient
        style={[
          tw`w-full h-full absolute top-0`,
          StyleSheet.absoluteFill,
          { borderRadius: 20 },
        ]}
        colors={[
          "rgba(0, 0, 0, 0.8)",
          "rgba(0, 0, 0, 0.1)",
          "rgba(0, 0, 0, 0)",
        ]}
      />

      <View style={tw`flex-1 pl-3 py-2`}>
        <Text style={tw`text-lg capitalize text-white `}>
          {moment(menu.fecha).locale("es").format("dddd")}
        </Text>
        <Text style={tw`capitalize text-white font-light`}>
          {menu?.tipo?.tipo.charAt(0).toUpperCase() +
            menu?.tipo?.tipo.slice(1).toLowerCase()}
        </Text>
      </View>
      <View style={tw`flex-1 justify-end`}>
        <LinearGradient
          style={[
            tw`w-full h-full absolute bottom-0`,
            StyleSheet.absoluteFill,
            { borderRadius: 20 },
          ]}
          colors={[
            "rgba(0, 0, 0, 0)",
            "rgba(0, 0, 0, 0.1)",
            "rgba(0, 0, 0, 0.5)",
            "rgba(0, 0, 0, 0.8)",
          ]}
        />

        <View style={tw`pl-3 py-2`}>
          <NombrePlato plato={menu?.comida} />
          <NombrePlato plato={menu?.guarnicion} categoria="Guarnición" />
          <NombrePlato plato={menu?.postre} categoria="Postre" />
        </View>
      </View>

      <Pressable
        style={tw`absolute top-2 right-3`}
        onPress={() => handleSubmit(menu)}
      >
        {deleteMutate.isLoading ? (
          <ActivityIndicator size="small" color={Colores.primary} />
        ) : (
          <EvilIcons name="close-o" size={24} color="#FFF" />
        )}
      </Pressable>
    </View>
  );
};

export default CardMenuDelDia;
