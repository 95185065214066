import React from "react";
import { ScrollView, StyleSheet, Text, useWindowDimensions, View } from "react-native";
import { useQuery } from "react-query";
import { getAvisosTemporales } from "../../services/apiClient";
import { Loader } from "../Loader";
import Historia from "./Historia";

export default function Historias({navigation}) {
  const {width} = useWindowDimensions();
  const historias = useQuery("historias", () =>
    getAvisosTemporales({ filtros: "include=fotos" })
  );
  if (!historias.data?.data.avisos.length) return <></>;
  return (
    <View style={{ backgroundColor: "white", padding: 5, borderRadius: width >= 768 ? 16 : 0 , borderBottomLeftRadius:16, borderBottomRightRadius:16}}>
      {/* <Text>Historias</Text> */}
      <View style={styles.historiaContedor}>
        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
          {historias.isLoading ? (
            <Loader text="Cargando historias..." />
          ) : historias.data?.data.avisos ? (
            historias.data?.data.avisos.map((historia) => (
              <Historia key={historia.id} historia={historia} props={navigation}/>
            ))
          ) : !historias.isLoading && !historias.isError ? (
            <Text>Sin historias para mostrar</Text>
          ) : (
            historias.isError && <Text>Hubo un error</Text>
          )}
        </ScrollView>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  historiaContedor: {
    display: "flex",
    flexDirection: "row",
    borderBottomLeftRadius:16,
    borderBottomRightRadius:16
  },
});
