import React from "react";
import {
  SafeAreaView,
  ScrollView,
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { FontAwesome5, FontAwesome, Feather } from "@expo/vector-icons";
import tw from 'twrnc';

import CantidadEntidad from "../../components/Empresa/CantidadEntidad";
import { PageContainer } from "../../components/PageContainer";
import { Colores } from "../../constants/colores";

export default function AdministrarDepartamentoScreen(props) {
  const { departamento, empresa } = props.route.params;
  const {width} = useWindowDimensions()

  return (
    <PageContainer paddingMd>
      <ScrollView>
        <View style={[{backgroundColor:'#FFF'}, width > 768 ? {borderRadius: 20} : ""]}>
        <View style={[styles.container]}>
          <Text style={styles.path}>
            <FontAwesome name="building" size={24} color="black" />{" "}
            {empresa.nombre} -{" "}
            <FontAwesome5 name="building" size={20} color="black" />{" "}
            {departamento.nombre}
          </Text>
          <TouchableOpacity
            style={[tw`mt-2 px-6 py-2.5 rounded shadow-md hover:bg-blue-700 hover:shadow-lg`,{backgroundColor:Colores.primary}]}
            onPress={() =>
              props.navigation.navigate("Crear Departamento", {
                navigation: props.navigation,
                departamento
              })
            }
          >
            <Text style={tw`text-center text-white`}>Editar Departamento</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[tw`px-6 py-2.5 rounded shadow-md hover:bg-blue-700 hover:shadow-lg`,{backgroundColor:Colores.primary}]}
            onPress={() =>
              props.navigation.navigate("Crear Puesto", {
                navigation: props.navigation,
                departamentoId: departamento.id
              })
            }
          >
            <Text style={tw`text-center text-white`}>Crear Puesto</Text>
          </TouchableOpacity>
          <View
            style={tw`flex flex-row items-center justify-center gap-[2rem]`}
          >
            <CantidadEntidad
              entidad="puestos"
              cantidad={departamento?.puestos.length}
            />
            <CantidadEntidad
              entidad="colaboradores"
              cantidad={departamento?.colaboradores.length}
            />
          </View>
        </View>

        <View style={tw`p-2`}>
          <View>
            {departamento?.puestos.map((puesto) => (
              <View key={`puesto-${puesto.id}`} style={[styles.puesto, tw`border border-gray-200 rounded-lg`]}>
                <View style={tw`flex-1 items-start justify-center gap-[8px]`}>
                  <Text style={styles.departamentoNombre}>
                    <FontAwesome name="suitcase" size={20} color="#333333" />{" "}
                    {puesto.nombre}
                  </Text>
                  <Text style={{ color: "#94a3b8" }}>
                    {puesto?.descripcion}
                  </Text>
                </View>

                <View style={tw`flex flex-row items-center justify-center gap-[5px]`}>
                  <TouchableOpacity
                    style={[tw`p-2 rounded-lg`,{backgroundColor:Colores.primary}]}
                    onPress={() => {
                      props.navigation.navigate("Administrar Puesto", {
                        departamento: departamento,
                        empresa: empresa,
                        puesto,
                      });
                    }}
                  >
                    <Feather name="settings" size={20} color="white" />
                  </TouchableOpacity>
                  {/* <TouchableOpacity style={[tw`p-2 rounded-lg`,{backgroundColor:Colores.primary}]} onPress={() => {}}>
                    <Feather name="edit" size={20} color="white" />
                  </TouchableOpacity>
                  <TouchableOpacity style={[tw`p-2 rounded-lg`,{backgroundColor:Colores.danger}]} onPress={() => {}}>
                    <FontAwesome5 name="trash-alt" size={20} color="white" />
                  </TouchableOpacity> */}
                </View>
              </View>
            ))}
          </View>
        </View>
        </View>
      </ScrollView>
    </PageContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    gap: 16,
  },
  path: {
    fontWeight: "bold",
  },
  departamentoNombre: {
    fontWeight: "bold",
    color : "#333333"
  },
  puesto: {
    backgroundColor: "white",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 16,
    marginBottom: 16,
  }
});
