import React from "react";
import { Text, View } from "react-native";
import tw from "twrnc";
import { Colores } from "../../constants/colores";

const DetallePlatoDesktop = ({ plato, tipo = "" }) => {
  return (
    <View style={tw`flex flex-row`}>
      {tipo.length ? (
        <Text
          style={[
            tw`ml-1 mt-1 capitalize`,
            { fontColor: Colores.primaryMedium },
          ]}
        >
          {tipo}:
        </Text>
      ) : null}

      {plato?.nombre ? (
        <Text style={tw`ml-1 mt-1  text-white`}>
          {plato?.nombre.charAt(0).toUpperCase() +
            plato?.nombre.slice(1).toLowerCase()}
        </Text>
      ) : (
        <Text style={tw`ml-1 mt-1  text-white`}>
          {tipo.length ? `Sin ${tipo}` : ""}
        </Text>
      )}
    </View>
  );
};

export default DetallePlatoDesktop;
