import React, { useState, useEffect } from "react";
import { View, Text, Dimensions } from "react-native";
import { useQuery } from "react-query";
import { BarChart } from "react-native-chart-kit";

import { getLogsLogin } from "../../services/apiClient";
import { PageContainer } from "../../components/PageContainer";

const screenWidth = Dimensions.get("window").width;

const chartConfig = {
  backgroundGradientFrom: "#FFFFFF",
  backgroundGradientTo: "#FFFFFF",
  color: (opacity = 1) => `rgba(59, 56, 224, ${opacity})`,
};

const LogsLoginScreen = (props) => {
  const { data, isLoading, isError } = useQuery("logsLogin", getLogsLogin);
  const [valores, setValores] = useState([0, 0, 0]);
  const datosGrafico = {
    labels: ["Día", "Semana", "Mes"],
    datasets: [
      {
        data: valores,
      },
    ],
  };

  useEffect(() => {
    if (data?.data.ok) {
      const { dia, semana, mes } = data.data;

      setValores([dia, semana, mes]);
    }
  }, [data]);

  return (
    <PageContainer>
      <View>
        <Text>Logs de login</Text>
        <BarChart
          data={datosGrafico}
          width={screenWidth}
          height={220}
          chartConfig={chartConfig}
        />
      </View>
    </PageContainer>
  );
};

export default LogsLoginScreen;
