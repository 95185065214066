import React from "react";
import { Image, Text, View, TouchableOpacity } from "react-native";
import tw from 'twrnc';
import { AntDesign } from '@expo/vector-icons';
import { Colores } from "../../constants/colores";
import { MaterialCommunityIcons } from '@expo/vector-icons';

function PlatoSeleccionado({ plato, setPlato }) {
  return (
    <View style={tw`flex flex-row`}> 
        {plato.foto ? (
          <View>
            <Image
              style={tw`rounded-xl w-[5rem] h-[5rem]`}
              source={{ uri: plato.foto }}
            />
          </View>
        ): (
            <View style={tw`rounded-2xl border border-gray-200 items-center justify-center w-[5rem] h-[5rem]`}>
              <MaterialCommunityIcons name="image-plus" size={40} color={Colores.primaryLight} />
            </View>
        )}
        <View style={tw`pl-3 justify-center flex-1`}>
          <Text style={tw`text-lg text-gray-500`}>{plato?.nombre.charAt(0).toUpperCase() + plato?.nombre.slice(1).toLowerCase()}</Text>
        </View>

        <TouchableOpacity style={tw`absolute py-1 px-2 right-1 top-1`} onPress={() => {setPlato(null);}}>
          <AntDesign name="closecircleo" size={20} style={tw`text-red-300`} />
        </TouchableOpacity>
    </View>
  );
}

export default PlatoSeleccionado;
