import { View, Text } from "react-native";
import React from "react";
import ColaboradorItem from "./ColaboradorItem";
import tw from "twrnc";

export default function AsignadoPor({ colaborador }) {
  return (
    <View style={tw`flex flex-row items-center gap-2`}>
      <Text style={tw`flex-1`}>Asignado por: </Text>
      <View>
        <ColaboradorItem colaborador={colaborador} />
      </View>
    </View>
  );
}
