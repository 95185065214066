
export const filtrarPermisos = (permisos = [], accion = "administrar", retornarObj = false, permisosPuestoActual = []) => {
  const permisosObjetosFilrados = [];  
  const permisosFiltrados = permisos
    .map((permiso) => {
      const [permisoAccion, permisoObjeto] = parsearSlug(permiso.slug);
      if (permisoAccion === accion) {
        permisosObjetosFilrados.push({
          id: permiso?.id,
          nombre: permiso?.nombre,
          descripcion: permiso?.descripcion,
          entidad: permiso?.slug.split(".")[1],
          departamento: permiso?.departamento,
          isChecked: permisosPuestoActual.includes(permiso.id) ? true : false
        });
        return permisoObjeto.toUpperCase();
      }
      return;
    })
    .filter(Boolean);
  return retornarObj ? permisosObjetosFilrados : permisosFiltrados;
};

const parsearSlug = (slug) => {
  //slug: accion.objecto Ej: editar.colaborador
  return slug.split(".");
};

export const getPermisosActualesPuesto = (permisos) => {
  return permisos.map((permiso) => permiso.id);
}