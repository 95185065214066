import React, { useEffect, useState } from "react";
import { FlatList, ScrollView, Text, TouchableOpacity, View } from "react-native";
import { PageContainer } from "../../components/PageContainer";
import tw from 'twrnc';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Colores } from "../../constants/colores";
import { getRecibos } from "../../services/apiClient";
import { useQuery } from "react-query";
import { CheckBox } from "react-native";

function RecordatoriosScreen(props) {

const {navigation} = props;
const [isSelected, setSelection] = useState(false);
const [contador, setContador] = useState()
const recibos = useQuery(
"recibos",
getRecibos
);

const filtrarRecibos = (cantidad) =>{
    if(cantidad){
      const resultado = recibos.data?.data?.recibos.filter((item) => item.firmado === false)
      return resultado.length;

    }else{
      const resultado = recibos.data?.data?.recibos.filter((item) => item.firmado === false)
      return resultado;
    }
}

console.log(recibos.data?.data?.recibos)

    
  return (
    <PageContainer>
      <ScrollView>
        <View style={tw`mt-5 px-3`}>
            <Text style={tw`text-xl ml-2`}>3 Colaboradores no firmaron
             su recibo</Text>

             <FlatList
                data={filtrarRecibos()}
                keyExtractor={(item) => item.id}
                renderItem={({item})=>(
                    <TouchableOpacity style={[tw`flex flex-row mt-3 border border-gray-300 py-3 shadow-md`,{borderRadius:16}]}>
                       <View style={tw`items-center justify-center flex-3`}>
                           <View style={[tw`w-20 h-20 items-center justify-center`,{borderRadius:16, backgroundColor:`${Colores.danger}30`}]}>
                            <MaterialCommunityIcons name="file-remove" size={50} color={Colores.danger} />
                           </View>
                       </View>
                       <View style={tw`flex-7 justify-around`}>
                           <View style={tw`flex-row`}>
                               <View style={tw`flex-3`}>
                                   <Text>{item.nombre}</Text>
                               </View>
                               <View style={tw`flex-1 items-center justify-center`}>
                                   <CheckBox
                                     value={isSelected}
                                     onValueChange={setSelection}
                                   />
                               </View>
                           </View>

                           <Text>Periodo: Noviembre</Text>
                           <View style={tw`flex-row`}>
                               <View style={tw`flex-1`}>
                                   <Text>Estado:  <Text style={{color:Colores.danger}}>Sin firmar</Text></Text>
                               </View>
                               <View style={tw`flex-1`}>
                                   <Text style={tw`text-gray-500 text-center`}>{item.periodo}</Text>
                               </View>
                           </View>
                       </View>
                    </TouchableOpacity>
                )}
              />

        </View> 
      </ScrollView>
    </PageContainer>
  );
}

export default RecordatoriosScreen;
