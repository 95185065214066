import React from "react";
import { useAtom } from "jotai";
import { Image, Pressable, StyleSheet, View } from "react-native";
import { esVisibleHistoriaModalAtom, historiaAtom } from "../../store/store";
import tw from 'twrnc';
import { Colores } from "../../constants/colores";

function Historia({ historia }) {
  const [, setHistoria] = useAtom(historiaAtom);
  const [, setHistoriaModalVisible] = useAtom(esVisibleHistoriaModalAtom);

  const mostrarHistoriaModal = (historia) => {
    setHistoria(historia);
    setHistoriaModalVisible(true);
  };

  return (
    <Pressable onPress={() => mostrarHistoriaModal(historia)}>
      <View style={[tw`h-20 w-20 flex items-center justify-center p-2 rounded-full ml-2 shadow-sm border`,{borderWidth:3, borderColor:Colores.primary}]}>
        <Image
          style={{ width: 64, height: 64, borderRadius: 32 }}
          source={{ uri: historia.fotos[0]?.foto }}
        />
      </View>
    </Pressable>
  );
}

export default Historia;
