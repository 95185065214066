import {Text, View, FlatList, ScrollView} from 'react-native'
import React from 'react'
import moment from "moment";
import tw from 'twrnc';

const SugerenciaLista = ({datos}) => {

  return (
    <View style={tw``}>
        {datos.length ? (
            <ScrollView style={tw``}>
                <FlatList
                    data={datos}
                    keyExtractor={(item) => item.id}
                    renderItem={({item})=>(
                      <View style={tw`p-2 mt-2 border border-gray-300 rounded-lg`}>
                        <Text style={tw`mb-1 text-gray-600 capitalize`}>{item?.anonima ? "Anonimo" : `${item?.colaborador?.nombre} ${item?.colaborador?.apellido} - ${item?.empresa?.nombre}`}</Text>
                        <Text>{item.sugerencia}</Text>

                        <View style={tw`flex items-end`}>
                        <Text style={tw``}>{moment(item.created_at).format("DD/MM/YYYY")}</Text>
                        </View>
                      </View>
                    )}
                />
            </ScrollView>
        ) : (
            <Text style={tw`text-center`}>No hay datos</Text>
        ) }
    </View>
  )
}

export default SugerenciaLista
