import React, { useEffect, useState } from "react";
import { ScrollView, Text, StyleSheet, TouchableOpacity, View, Modal, useWindowDimensions,CheckBox } from "react-native";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import tw from 'twrnc';
import { FontAwesome5 } from '@expo/vector-icons';

import { exportarEstimativo, getEstimativo, getEstimativoDiario } from "../../services/apiClient";
import { hoy, semanaActual } from "../../utils/fechas";
import SeleccionPorDia from "../../components/Menu/SeleccionPorDia";
import { dia, formatoFechaES } from "../../utils/fechas";
import { groupBy, orderBy } from "../../utils/generales"
import { PageContainer } from "../../components/PageContainer";
import { Colores } from "../../constants/colores";
import { AntDesign } from '@expo/vector-icons';
import { Feather } from '@expo/vector-icons';
import { esWeb } from "../../utils/plataforma";

export default function AlmuerzosScreen(props) {
  const fechas = semanaActual().join(",");
  const {width} = useWindowDimensions();
  const [dias, setdias] = useState([]);
  const [diasAExportar, setdiasAExportar] = useState("");
  const [pedidosPorFecha, setpedidosPorFecha] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const { data, isLoading, isError } = useQuery("estimativo", getEstimativo);
  const { data:estimativo } = useQuery(
    "almuerzos",
    getEstimativoDiario
  );
  const exportarEstimativoMutate = useMutation(exportarEstimativo, {
    onSuccess: (response) => {
      if(esWeb()){
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'estimativo.xlsx');
        document.body.appendChild(link);
        link.click();
        setModalVisible(!modalVisible);
      }
      //TODO: agregar un metodo para android e ios
      
    },
  });

  const handleChekChange = (id) => {
    let temp = dias.map((dia) => {
      if (id === dia.id) {
        return { ...dia, isChecked: !dia.isChecked };
      }
      return dia;
    });

    setdias(temp);
    const fechasSeleccionadas = temp
      .filter((dia) => dia.isChecked)
      .map((dia) => dia.fecha)
      .join(",");
    setdiasAExportar(fechasSeleccionadas);
  };

  useEffect(() => {
    if (data) {
      const estimativo = groupBy(data.data.estimativo, "fecha_menu");
      ordenadoPorFecha(estimativo);
      //se recorre estimativos por fecha
      obtenerPedidosPorFecha(estimativo);
    }
  }, [data]);

  useEffect(() => {
    let ped = pedidosPorFecha.map(({ fecha }, id) => ({
      id,
      fecha,
      isChecked: false,
    }));
    setdias(ped);
  }, [pedidosPorFecha]);

  const ordenadoPorFecha = (estimativoEmpresas) => {
    Object.entries(estimativoEmpresas).forEach(([key, value]) => {
      estimativoEmpresas[key] = orderBy(value, "fecha_menu");
      estimativoEmpresas[key] = groupBy(value, "tipo");
    });
  };

    //TODO: remplazar for por reducers
    const obtenerPedidosPorFecha = (estimativo) => {
      const datosPorFecha = [];
      Object.entries(estimativo).forEach(([fecha, valor]) => {
        let empresas = [];
        const menuPorDia = estimativo[fecha];
        const tipos = [...Object.keys(menuPorDia)];
        //setcabecerasTabla(tipos);
  
        //se extraen las empresas que solicitaron menu ese dia
        const pedidoEmpresas = [];
        Object.entries(menuPorDia).forEach(([tipoMenu, valor]) => {
          valor.forEach(({ empresa, cantidad }) => {
            pedidoEmpresas.push([empresa, tipoMenu, cantidad]);
            empresas = new Set([empresa, ...empresas]);
          });
        });
  
        //settitulosTabla([...empresas]);
        const rows = [];
        empresas.forEach((empresa) => {
          //inicializo la fila con el nombre de la empresa
          const row = [];
          //recorro los tipos de menu
          tipos.forEach((tipo, indexTipo) => {
            //valido si la empresa tiene algun pedido con ese tipo de menu
            const tieneTipoDeMenu = pedidoEmpresas.filter((pedido) => {
              const [empresaPedido, tipoPedido] = pedido;
              if (empresaPedido === empresa && tipoPedido === tipo) {
                return true;
              }
              return false;
            });
  
            if (tieneTipoDeMenu.length) {
              //si tiene se coloca la cantidad solicitada
              const [emp, tipoMenu, cantidad] = tieneTipoDeMenu[0];
              row.push(cantidad);
            } else {
              //si no tiene se coloca 0 cantidad solicitada para ese tipo de menu
              row.push(0);
            }
          });
  
          rows.push(row);
        });
        //setfilasTabla(rows);
        datosPorFecha.push({
          fecha,
          cabecerasTabla: tipos,
          titulosTabla: [...empresas],
          data: rows,
        });
      });
  
      setpedidosPorFecha(datosPorFecha);
    };

  const mostrarDiasActuales = (semana) => {
    return semana.map((dia, idx) => {
      const [fecha] = Object.keys(dia);
      const fechaActual = hoy();
      if (fecha === fechaActual || moment(fecha).isAfter(fechaActual)) {
 
        return (
            <SeleccionPorDia
              key={`f-${fecha}`}
              index={idx}
              hoy={fechaActual}
              fecha={fecha}
              datos={dia[fecha]}
            />
        );
      }
    });
  };



  return (
    <PageContainer paddingMd>

      <ScrollView>

          <View style={[tw`flex-1 h-full bg-white p-5`,{borderRadius:20}]}>
            <Text style={tw`text-lg mb-5`}>Almuerzos</Text>
            <View style={tw`flex-row mb-1`}>

                <View style={tw`flex-1 items-center`}>
                  <TouchableOpacity onPress={()=>props.navigation.navigate('Estimativo Semanal')} style={[tw`border border-gray-200 flex-row py-2`,{borderRadius:10}]}>
                    <View style={[tw`justify-center pl-7 pr-5 py-2`,{borderLeftWidth:2, borderColor:'#FF9900'}]}>
                      <Text style={tw`text-gray-700 text-center`}>Estimativo semanal</Text>
                    </View>
                    <View style={tw`justify-center`}>
                      <AntDesign name="calendar" size={20} color="gray" style={tw`mr-5`} />
                    </View>
                  </TouchableOpacity>
                </View>
                <View style={tw`flex-1 items-center`}>
                  <TouchableOpacity onPress={()=>props.navigation.navigate('Crear menu')} style={[tw`border border-gray-200 flex-row py-2`,{borderRadius:10}]}>
                    <View style={[tw`justify-center pl-7 pr-5 py-2`,{borderLeftWidth:2, borderColor:'#FF9900'}]}>
                      <Text style={tw`text-gray-700 text-center`}>Crear Menu</Text>
                    </View>
                    <View style={tw`justify-center`}>
                      <FontAwesome5 name="concierge-bell" size={20} color="gray" style={tw`mr-5`} />
                    </View>
                  </TouchableOpacity>
                </View>
                <View style={tw`flex-1 items-center`}>
                  <TouchableOpacity onPress={()=>props.navigation.navigate('Administrar platos')} style={[tw`border border-gray-200 flex-row py-2`,{borderRadius:10}]}>
                    <View style={[tw`justify-center pl-7 pr-5 py-2`,{borderLeftWidth:2, borderColor:'#FF9900'}]}>
                      <Text style={tw`text-gray-700 text-center`}>Administrar platos</Text>
                    </View>
                    <View style={tw`justify-center`}>
                      <FontAwesome5 name="list" size={20} color="gray" style={tw`mr-5`} />
                    </View>
                  </TouchableOpacity>
                </View>

            </View>


            <View style={tw`items-center my-5`}>
                <TouchableOpacity style={[tw`px-10 py-2 justify-center flex-row ml-3`,{borderRadius:15,color:Colores.primaryStrong,backgroundColor:Colores.primaryLight}]} onPress={() => setModalVisible(!modalVisible)}>
                    <View style={tw`justify-center ml-1`}>
                      <Text style={{color:Colores.primaryStrong}}>Descargar listado de almuerzos</Text>
                    </View>
                </TouchableOpacity>
            </View>
            {estimativo?.data ? (
              mostrarDiasActuales(estimativo.data.estimativo)
            ) : (
              <Text>No hay datos</Text>
            )}
          </View>

      </ScrollView>
      
      <Modal
            animationType="fade"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
              setModalVisible(!modalVisible);
            }}
          >

            <View style={[ width >=768 ? tw`h-full w-full p-10 items-center justify-center` : tw`h-full w-full p-10 justify-center` ,{backgroundColor:'#7D7D7D50'}]}>
              <View style={width >=768 ? tw`bg-white rounded-lg w-100` : tw`bg-white rounded-lg` }>
                <TouchableOpacity onPress={() => setModalVisible(!modalVisible)} style={tw`absolute right-2 top-2`}>
                  <AntDesign name="closecircle" size={24} style={tw`text-red-500`} />
                </TouchableOpacity>
                <View style={tw`mt-10 mx-10`}>
                {dias?.map((pedido, idx) => (
                  <View
                    style={tw`flex-1 flex-row mt-3`}
                    key={`'pedido'-${idx}`}
                  >
                    <View style={tw`flex-1`}>
                      <CheckBox
                        value={pedido.isChecked}
                        onChange={() => handleChekChange(pedido.id)}
                        style={tw`mt-1`}
                      />
                    </View>

                    <View style={tw`flex-row flex-6`}>
                      <View style={tw`justify-center flex-1`}>
                        <Text style={tw`capitalize ml-1 text-lg`}>
                          {dia(pedido.fecha)}
                        </Text>
                      </View>
                      <View style={tw`items-center flex-1 ml-5`}>
                        <Text style={tw`text-lg text-center`}>
                          {formatoFechaES(pedido.fecha)}
                        </Text>
                      </View>
                    </View>
                  </View>
                ))}
                </View>
                {dias.length && (
                  <View style={tw`items-center justify-center`}>
                  <TouchableOpacity onPress={() => exportarEstimativoMutate.mutate(diasAExportar)} style={[tw`px-5 py-2 mt-10 mb-5 items-center justify-center`,{backgroundColor:Colores.primaryLight, borderRadius:15}]}>
                    <Text style={{color:Colores.primary}}><Feather name="download" size={20} style={tw`mr-2`} />Descargar</Text>
                  </TouchableOpacity>
                  </View>
                )}
              </View>
            </View>
      </Modal>
    </PageContainer>
  );
}

