import { useAtom } from "jotai";
import React from "react";
import { View, SafeAreaView, useWindowDimensions } from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import { PageContainer } from "../../components/PageContainer";
import DepartamentoForm from "../../forms/DepartamentoForm";

import { awesomeAlertAtom } from "../../store/store";

export default function CrearDepartamentoScreen(props) {
  const [awsomeAlert, _] = useAtom(awesomeAlertAtom);
  const { navigation, empresaId, departamento } = props.route.params;
  const {width} = useWindowDimensions()

  return (
    <PageContainer paddingMd >
      <View style={{ flex: 1, padding: 16, backgroundColor:'#FFF', borderRadius: 20 }}>
        <DepartamentoForm navigation={navigation} empresaId={empresaId} departamento={departamento} />
      </View>
      <AwesomeAlert
        show={awsomeAlert.show}
        showProgress={false}
        title={awsomeAlert.title}
        message={awsomeAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={awsomeAlert.showConfirmButton}
        confirmText="Aceptar"
        customView={awsomeAlert.customView}
        confirmButtonColor="green"
        onDismiss={awsomeAlert.onDismiss}
        onCancelPressed={awsomeAlert.onCancelPressed}
        onConfirmPressed={awsomeAlert.onConfirmPressed}
      />
    </PageContainer>
  );
}
