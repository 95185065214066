import React, { useState, useEffect } from "react";
import {
  ActivityIndicator,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { useMutation, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import tw from 'twrnc';

import { editarPuesto, nuevoPuesto } from "../services/apiClient";
import ResultadoModal from "../components/ResultadoModal";
import MensajeError from "../components/MensajeError";
import { awesomeAlertAtom, permisosPuestoAtom } from "../store/store";
import { parsearErrores } from "../utils/generales";
import { Colores } from "../constants/colores";
import ModulosPermisos from "../components/Empresa/Puesto/ModulosPermisos";

function PuestoForm(props) {
  const { navigation, departamentoId, puesto:puestoParam } = props;
  console.log("puesto", puestoParam);
  const [puesto, setpuesto] = useState(puestoParam)
  const [quiereEditar, setquiereEditar] = useState(false);
  const [permisosPuesto, setPermisosPuesto] = useAtom(permisosPuestoAtom);
  const queryClient = useQueryClient();
  
  let initialValues = {
    nombre: "",
    descripcion: "",
  };
  

  useEffect(() => {
    if (puestoParam) {
      setpuesto(puestoParam)
      //setPermisosPuesto(puestoParam?.permisos ?? [])
    }
    console.log("cambiaron las props", puestoParam)
  },[puestoParam])


  useEffect(() => {
    if (puesto) { //aqui.........
      reset({
        nombre: puesto?.nombre ?? "",
        descripcion: puesto?.descripcion ?? "",
      });
    }
    setquiereEditar(Object.keys(puesto ?? {}).length ? true : false);
  }, [puesto, puestoParam]);



  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: initialValues,
  });

  const resetForm = () => {
    setquiereEditar(false);
  };

  const [awsomeAlert, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);

  let mutate = nuevoPuesto;
  let mensaje = "Puesto creado";

  if (quiereEditar) {
    mutate = editarPuesto;
    mensaje = "Puesto editado";
  }

  const puestoMutate = useMutation(mutate, {
    onSuccess: async (response) => {
      console.log(response.data)
      queryClient.invalidateQueries("empresas");
      //resetForm();
      setpuesto(response.data)
      //setPermisosPuesto([]);
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Exito!",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: <ResultadoModal success={true} message={mensaje} />,
        onConfirmPressed: () => {
          resetAwesomeAlert();
          //navigation.navigate("Administrar");
        },
        onDismiss: () => {
          resetAwesomeAlert();
          //navigation.navigate("Administrar");
        },
      });
    },
    onError: async (error) => {
      console.log(error.response.data);
      const mensajesDeError = parsearErrores(error.response.data?.errors);
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Error",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal success={false} message={mensajesDeError} />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else if (data[key]) {
        formData.append(key, data[key]);
      }
    }

    if (quiereEditar) {
      formData.append("_method", "PUT");
      puestoMutate.mutate({ puestoId: puesto.id, formData });
    } else {
      formData.append("departamento_id", departamentoId);
      puestoMutate.mutate(formData);
    }
  };

  return (
    <View styles={tw`flex flex-1`}>
      <View>
        <Text style={tw`mt-2`}>Nombre</Text>
        <Controller
          control={control}
          rules={{
            required: "Ingrese un nombre",
            minLength: {
              value: 3,
              message: "Indique al menos 3 caracteres",
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={tw` mt-3 w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded`}
              onChangeText={onChange}
              onBlur={onBlur}
              multiline={true}
              placeholder="Indique un nombre"
              value={value}
            />
          )}
          name="nombre"
        />
        {errors?.nombre && <MensajeError message={errors.nombre?.message} />}

        <Text style={tw`mt-4`}>Descripción (Opcional)</Text>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={tw` mt-3 w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded`}
              onChangeText={onChange}
              multiline={true}
              placeholder="Indique una descripción"
              value={value}
            />
          )}
          name="descripcion"
        />
        {errors?.descripcion && (
          <MensajeError message={errors.descripcion?.message} />
        )}

        {puestoMutate.isLoading ? (
          <ActivityIndicator size="small" color={Colores.primary} />
          ) : (
            <View style={tw`justify-center items-center mt-5`}>
            <TouchableOpacity style={[tw`mt-5 px-20 py-2.5 shadow-md`,{backgroundColor:Colores.primary, borderRadius:16}]} onPress={handleSubmit(onSubmit)}>
              <Text style={tw`text-center text-white`}>Enviar</Text>
            </TouchableOpacity>
          </View>
        )}

        {puesto ? <ModulosPermisos puesto={puesto} /> : null }
      </View>
    </View>
  );
}

export default PuestoForm;
