import { PageContainer } from "../../components/PageContainer";
import { Text, View } from "react-native";
import tw from "twrnc";
import ItemFormSteps from "../../forms/ItemFormSteps";

export default function AsignarItemsScreen() {
  const formData = new FormData();
  return (
    <PageContainer>
      <View style={tw`flex flex-col items-center gap-8`}>
        <Text style={tw`w-full text-start font-bold text-lg`}>
          Nueva asignación de activo
        </Text>
        
        <ItemFormSteps formData={formData}/>

      </View>
    </PageContainer>
  );
}
