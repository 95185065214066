import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Alert, Modal, StyleSheet, Text, Pressable, View, TouchableOpacity, TextInput, ActivityIndicator } from "react-native";
import Checkbox from 'expo-checkbox';
import AwesomeAlert from "react-native-awesome-alerts";
import { useMutation, useQueryClient } from "react-query";
import tw from 'twrnc';
import { Colores } from "../../constants/colores";
import { firmarRecibo } from "../../services/apiClient";
import MensajeError from "../MensajeError";

const ModalRazonesDesacuerdo = ({modalVisible, setModalVisible,reciboId, setPermitirDescarga}) => {
    const [otra, setOtra] = useState(false)
    const [liquidacionHaberes, setLiquidacionHaberes] = useState(false)
    const [liquidacionRetenciones, setLiquidacionRetenciones] = useState(false)
    const [liquidacionAdicionales, setLiquidacionAdicionales] = useState(false)
    const [mensaje, setMensaje] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const [mostrarAlerta, setMostrarAlerta] = useState(false)
    const queryClient = useQueryClient();
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
      } = useForm({
        mode: "onBlur",
      });

      const firmar = useMutation(firmarRecibo, {
        onSuccess: async (response) => {
          queryClient.invalidateQueries("misRecibos");
          setMensaje({
            titulo:'Listo',
            descripcion:'El recibo se firmo correctamente'
          })
          setPermitirDescarga(true)
          setModalVisible(false)
          setMostrarAlerta(true)
          setIsLoading(false)


        },
        onError: async (error) => {
          setMensaje({
            titulo:'Error',
            descripcion:error.response.data.message
          })
          setModalVisible(false)
          setMostrarAlerta(true)
          setIsLoading(false)

        },
        onSettled: (data) => {
        },
        });

      const onSubmit = (data) => {
        setIsLoading(true)
        if(otra){     
            firmar.mutate({reciboId:reciboId, data:{
                "conforme": 0,
                "razon_id": 1,
                "otra_razon": data.texto
            }});

        }else if(liquidacionHaberes){
            firmar.mutate({reciboId:reciboId, data:{
                "conforme": 0,
                "razon_id": 2,
            }});
          
        }else if(liquidacionRetenciones){
            firmar.mutate({reciboId:reciboId, data:{
                "conforme": 0,
                "razon_id": 3,
            }});     
            
        }else{
            firmar.mutate({reciboId:reciboId, data:{
                "conforme": 0,
                "razon_id": 4,
            }});  

        }
      };
  
  return (
    <View>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
      >
        <View style={[tw`flex-1 items-center px-3 pb-5 pt-10`,{backgroundColor:'#7D7D7D50'}]}>
          <View style={tw`bg-white rounded-xl px-3 py-5 m-auto`}>
            <Text style={tw`font-semibold`}>Seleccione la razón por la cual esta desacuerdo</Text>

            <View style={tw`flex-row mt-5`}>
                <View style={tw`flex-8`}>
                    <Text style={tw`text-gray-600`}>Diferencia en liquidación de haberes</Text>
                </View>
                <View style={tw`flex-1 items-end`}>
                    <Checkbox
                    value={liquidacionHaberes}
                    onValueChange={setLiquidacionHaberes}
                    disabled={otra || liquidacionRetenciones || liquidacionAdicionales}
                    />
                </View>
            </View>

            <View style={tw`flex-row mt-3`}>
                <View style={tw`flex-8`}>
                    <Text style={tw`text-gray-600`}>Diferencia en liquidación de retenciones</Text>
                </View>
                <View style={tw`flex-1 items-end`}>
                    <Checkbox
                    value={liquidacionRetenciones}
                    onValueChange={setLiquidacionRetenciones}
                    disabled={otra || liquidacionHaberes || liquidacionAdicionales}
                    />
                </View>
            </View>

            <View style={tw`flex-row mt-3`}>
                <View style={tw`flex-8`}>
                    <Text style={tw`text-gray-600`}>Diferencia en liquidación de adicionales</Text>
                </View>
                <View style={tw`flex-1 items-end`}>
                    <Checkbox
                    value={liquidacionAdicionales}
                    onValueChange={setLiquidacionAdicionales}
                    disabled={otra || liquidacionRetenciones || liquidacionHaberes}
                    />
                </View>
            </View>

            <View style={tw`flex-row mt-3`}>
                <View style={tw`flex-8`}>
                    <Text style={tw`text-gray-600`}>Otra</Text>
                </View>
                <View style={tw`flex-1 items-end`}>
                    <Checkbox
                    value={otra}
                    onValueChange={setOtra}
                    disabled={liquidacionAdicionales || liquidacionRetenciones || liquidacionHaberes}
                    />
                </View>
            </View>

            {otra && (

                <View style={tw`mt-3`}>
                    <Controller
                      control={control}
                      rules={{
                        required: "Describa la razón",
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                          style={[
                            tw` w-full h-50 px-2 my-1 py-1.5 text-gray-700 border border-gray-300 shadow-sm`,
                            { borderRadius: 10 },
                          ]}
                          onBlur={onBlur}
                          onChangeText={onChange}
                          placeholder="Describa la razón"
                          multiline={true}
                          value={value}
                        />
                      )}
                      name="texto"
                    />
                    {errors?.texto && <MensajeError message={errors.texto?.message} />}
                </View>

            )}

            

            <View style={tw`flex-row justify-around mt-10 mb-3`}>
                <TouchableOpacity style={[tw`px-10 py-2`,{backgroundColor:Colores.primaryMedium, borderRadius:15}]} onPress={()=>setModalVisible(false)}>
                    <Text style={tw`text-white`}>Cancelar</Text>
                </TouchableOpacity>

                <TouchableOpacity style={[tw`px-10 py-2`,{backgroundColor:Colores.primary, borderRadius:15}]} onPress={ isLoading ? null : handleSubmit(onSubmit)}>
                    {isLoading ? (
                      <ActivityIndicator color="#FFF" />
                    ) : (
                      <Text style={tw`text-white`}>Enviar</Text>
                    )}
                </TouchableOpacity>
            </View>

          </View>
        </View>
      </Modal>

      <AwesomeAlert
        show={mostrarAlerta}
        title={mensaje.titulo}
        message={mensaje.descripcion}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={false}
        onDismiss={()=>{
          setMostrarAlerta(false);
        }}
      /> 
    </View>
  );
};

export default ModalRazonesDesacuerdo;