import moment from "moment";

export const calcularEdad = (fecha) => {
  let edad = 0;
  const actualidad = moment();
  edad = actualidad.diff(fecha, "years");
  return edad;
};

export const formatoFechaES = (fecha) => {
  return moment(fecha).format("DD/MM/YYYY");
};

export const dia = (fecha) => {
  return moment(fecha).locale("es").format("dddd");
};

export const semanaActual = () => {
  const comienzoDeSemana = moment().startOf("isoWeek");
  const finalDeSemana = moment().endOf("isoWeek");

  const dias = [];
  let dia = comienzoDeSemana;

  while (dia <= finalDeSemana) {
    const fecha = moment(dia.toDate()).format("Y-MM-DD");
    dias.push(fecha);
    dia = dia.clone().add(1, "d");
  }

  return dias;
};

export const hoy = () => {
  return moment().format("Y-MM-DD");
};
