import React, { useState } from "react";
import { Image, StyleSheet, View, Text, FlatList, Pressable, ScrollView, TextInput, TouchableOpacity } from "react-native";
import { useMutation, useQuery, useQueryClient } from "react-query";
import DataTable from "../../components/DataTable/DataTable";
import { FontAwesome5 } from "@expo/vector-icons";
import { Feather } from "@expo/vector-icons";
import { IconButton } from "../../components/IconButton";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import tw from 'twrnc';
import { FloatingAction } from "react-native-floating-action";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from 'react-native-popup-menu';

import { eliminarBeneficio, getBeneficios } from "../../services/apiClient";
import { formatoFechaES } from "../../utils/fechas";
import Activo from "../../components/Activo";
import ResultadoModal from "../../components/ResultadoModal";
import AwesomeAlert from "react-native-awesome-alerts";
import { awesomeAlertAtom } from "../../store/store";
import { PageContainer } from "../../components/PageContainer";
import { Colores } from "../../constants/colores";
import { AntDesign } from '@expo/vector-icons';

function AdministrarBeneficiosScreen(props) {
  const beneficiosQuery = useQuery("beneficios", getBeneficios);
  const [mostrarAlerta, setmostrarAlerta] = useState(false);
  const [beneficioId, setbeneficioId] = useState();
  const [filteredData, setFilteredData] = useState([])
  const [filtro, setFiltro] = useState()
  const [mostrarAlertaRecibos, setMostrarAlertaRecibos] = useState(false)
  const [awesomeAlert, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);

  let beneficios = [];
  if (beneficiosQuery.data && beneficiosQuery.data.data?.ok) {
    beneficios = beneficiosQuery.data.data.beneficios;
  }

  const queryClient = useQueryClient();
  const eliminarMutate = useMutation(eliminarBeneficio, {
    onSuccess: async (response) => {
      console.log("beneficio eliminado", response.data);
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Exito!",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal success={true} message={response.data.message} />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
      queryClient.invalidateQueries("beneficios");
    },
    onError: async (error) => {
      console.log(
        "hubo un error al intentar eliminar",
        error.response.data.message
      );
      //setmostrarAlerta(false)
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Error",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal
            success={false}
            message={error.response.data.message}
          />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
    },
    onSettled: (data) => {
      if (data.data.ok) {
        setmostrarAlerta(false);
      }
    },
  });

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = beneficios.filter(item => {
        const itemData = item.empresa ? item.empresa.toUpperCase() : ''.toLocaleUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      })
      setFilteredData(newData)
    } else {
      setFilteredData(beneficios)
    }
  }

  const getBeneficio = (beneficioId) => {
    const beneficio = beneficios.filter(
      (beneficio) => beneficio.id === beneficioId
    );
    if (beneficio.length) {
      //devuelve el primer colaborador que se econtro con ese id
      return beneficio[0];
    }
    console.log("No se encontro el beneficio para el id ", beneficioId);
    return;
  };

  const confirmar = (beneficioId) => {
    setbeneficioId(beneficioId);
    setawesomeAlert({
      show: true,
      showProgress: eliminarMutate.isLoading,
      title: "Atención",
      message: "Esta seguro de desea eliminar?",
      showCancelButton: true,
      showConfirmButton: true,
      confirmText: "Eliminar",
      confirmButtonColor: "#DD6B55",
      onConfirmPressed: () => {
        eliminarMutate.mutate(beneficioId);
      },
      onCancelPressed: () => {
        resetAwesomeAlert();
      },
      onDismiss: () => {
        resetAwesomeAlert();
      },
    });
  };


  return (
    <>
      <PageContainer paddingMd>
        <ScrollView>
          <View style={[tw`p-5 bg-white`, { borderRadius: 20 }]}>

            <View style={tw`flex-row`}>
              <View style={tw`flex-1 justify-center`}>
                <Text style={tw`text-lg font-semibold text-gray-500`}>
                  Administrar Beneficios
                </Text>
              </View>

              <View style={tw`flex-1 justify-center items-end`}>
                <TouchableOpacity onPress={() => props.navigation.navigate('Crear Beneficio')} style={[tw`border border-gray-200 flex-row py-2`, { borderRadius: 10 }]}>
                  <View style={[tw`justify-center pl-7 pr-5 py-2`, { borderLeftWidth: 2, borderColor: 'blue' }]}>
                    <Text style={tw`text-gray-700 text-center`}>Nuevo Beneficio</Text>
                  </View>
                  <View style={tw`justify-center`}>
                    <MaterialCommunityIcons name="ticket-percent-outline" size={20} color="gray" style={tw`mr-5`} />
                  </View>
                </TouchableOpacity>
              </View>
            </View>


            <View style={tw`justify-center`}>
              <TextInput
                style={[tw` mt-3 w-full px-3 py-2 text-base font-normal text-gray-700 bg-white border border-solid border-gray-300`, { borderRadius: 23 }]}
                placeholder="Buscar"
                onChangeText={
                  (text) => {
                    searchFilterFunction(text);
                    setFiltro(text);
                  }}
              />
              <AntDesign name="search1" size={20} color={'#7D7D7D'} style={tw`absolute right-4 top-5`} />

            </View>

            <FlatList
              data={filtro ? filteredData : beneficios}
              keyExtractor={(item) => item.id}
              renderItem={({ item }) => (
                <View style={[tw`mt-2 border border-gray-300 flex flex-row shadow-md`, { borderRadius: 16 }]}>
                  <View style={tw`items-center justify-center px-2 py-3`}>
                    <Pressable
                      onPress={() => {
                        let beneficio = getBeneficio(item.id);
                        props.navigation.navigate("Editar Beneficio", { beneficio });
                        beneficio = null;
                      }}
                    >
                      <Image source={{ uri: item.foto }} style={tw`h-25 w-25 rounded-full`} />
                    </Pressable>
                  </View>
                  <View style={tw`p-2 flex-1`}>
                    <Text style={tw`text-lg font-semibold`}>{item.empresa}</Text>
                    <Text style={tw``}>{item.beneficio}</Text>
                    <View style={tw`items-end mt-1`}>
                      <Text style={[tw`py-1 px-2 ml-12`, { color: '#fff', backgroundColor: '#02B879', borderRadius: 45 }]}>{item.descuento}% OFF</Text>
                    </View>
                  </View>
                  <View style={tw`absolute right-5 top-2`}>
                    <Menu renderer={renderers.NotAnimatedContextMenu}>
                      <MenuTrigger>
                        <FontAwesome5 name="ellipsis-h" size={17} color={Colores.primary} />
                      </MenuTrigger>
                      <MenuOptions style={tw`p-1`} optionsContainerStyle={{ borderRadius: 10, marginTop: 20 }}>
                        <MenuOption
                          onSelect={() => {
                            let beneficio = getBeneficio(item.id);
                            props.navigation.navigate("Editar Beneficio", { beneficio });
                            beneficio = null;
                          }}
                          style={tw`flex flex-row px-2 py-2`}
                        >
                          <FontAwesome5 name="edit" size={17} style={{ color: Colores.primary }} />
                          <Text style={[tw`ml-5`, { color: Colores.primary }]}>Editar</Text>
                        </MenuOption>

                        <MenuOption onSelect={() => confirmar(item.id)}
                          style={tw`flex flex-row px-2 py-2`}
                        >
                          <FontAwesome5 name="trash-alt" size={17} style={{ color: Colores.primary }} />
                          <Text style={[tw`ml-5`, { color: Colores.primary }]}>Eliminar</Text>
                        </MenuOption>

                      </MenuOptions>
                    </Menu>
                  </View>
                </View>
              )}
            />

          </View>
        </ScrollView>


        {/* <AwesomeAlert
          show={awesomeAlert.show}
          showProgress={awesomeAlert.showProgress}
          title={awesomeAlert.title}
          message={awesomeAlert.message}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={awesomeAlert.showCancelButton}
          showConfirmButton={awesomeAlert.showConfirmButton}
          cancelText="Cancelar"
          confirmText={awesomeAlert.confirmText}
          confirmButtonColor={awesomeAlert.confirmButtonColor}
          customView={awesomeAlert.customView}
          onCancelPressed={() => {
            resetAwesomeAlert();
          }}
          onConfirmPressed={awesomeAlert.onConfirmPressed}
        /> */}
        {/* <FloatingAction
            color={Colores.primary}
            floatingIcon={<FontAwesome5 name="plus" size={20} color="white"/>}
            onPressMain={()=>props.navigation.navigate('Crear Beneficio')}
            showBackground={false}
      /> */}
      </PageContainer>
      <AwesomeAlert
        show={awesomeAlert.show}
        showProgress={awesomeAlert.showProgress}
        title={awesomeAlert.title}
        message={awesomeAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={awesomeAlert.showCancelButton}
        showConfirmButton={awesomeAlert.showConfirmButton}
        cancelText="Cancelar"
        confirmText={awesomeAlert.confirmText}
        confirmButtonColor={awesomeAlert.confirmButtonColor}
        customView={awesomeAlert.customView}
        onCancelPressed={() => {
          resetAwesomeAlert();
        }}
        onConfirmPressed={awesomeAlert.onConfirmPressed}
        onDismiss={awesomeAlert.onDismiss}
      />
    </>
  );
}

export default AdministrarBeneficiosScreen;
