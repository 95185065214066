import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import * as DocumentPicker from "expo-document-picker";
import { MaterialIcons } from "@expo/vector-icons";
import { useMutation, useQueryClient } from "react-query";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import Badge from "../Badge";
import { actualizarCertificado } from "../../services/apiClient";
import { useAtom } from "jotai";
import { awesomeAlertAtom } from "../../store/store";
import ResultadoModal from "../ResultadoModal";
import { Colores } from "../../constants/colores";
import tw from "twrnc";
import { esWeb } from "../../utils/plataforma";

function SubirCertificado({ licencia, tiempoLimitePresentacion }) {
  const fechaRegistroLicencia = licencia.created_at;
  const [limite, setlimite] = useState();
  const [certificado, setcertificado] = useState();
  const queryClient = useQueryClient();
  const [_, setAwsomeAlert] = useAtom(awesomeAlertAtom);

  const actualizarCertificadoMutate = useMutation(actualizarCertificado, {
    onSuccess: async (response) => {
      queryClient.invalidateQueries("misLicencias");
      setAwsomeAlert({
        show: true,
        showConfirmButton: true,
        customView: (
          <ResultadoModal success={true} message="El certificado fue enviado" />
        ),
      });
      console.log("certificado actualizado");
    },
    onError: async (error) => {
      let mensajeErrores = "";
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        // console.log(`${key}: ${value}`);
        mensajeErrores = mensajeErrores + " \n" + value;
      }
      setAwsomeAlert({
        show: true,
        showConfirmButton: true,
        customView: <ResultadoModal success={false} message={mensajeErrores} />,
      });
    },
  });

  let evento = moment(fechaRegistroLicencia).add(
    tiempoLimitePresentacion,
    "hours"
  );

  //calcular tiempo limite
  const calcularTiempo = useCallback(() => {
    const diferencia =
      new Date(fechaRegistroLicencia).getTimezoneOffset() * 60000;
    let tiempoActual = moment().valueOf();
    let tiempoRestante = evento - tiempoActual + diferencia;
    let duracion = moment.duration(tiempoRestante, "milliseconds");
    let intervalo = 1000;

    const tiempoInterval = setInterval(function () {
      duracion = moment.duration(
        duracion.asMilliseconds() - intervalo,
        "milliseconds"
      );
      setlimite(moment(duracion.asMilliseconds()).format("H[h]:mm[m]:s[s]"));
    }, intervalo);

    return () => clearInterval(tiempoInterval);
  }, [fechaRegistroLicencia, tiempoLimitePresentacion]);

  useEffect(() => {
    const cleanup = calcularTiempo();
    return () => cleanup();
  }, [calcularTiempo]);

  const formData = new FormData();
  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: ["image/jpeg", "image/png"],
    });
    if (result.type === "success") {
      setcertificado(result);
    }
  };

  const submit = () => {
    console.log("enviando certificado", certificado);
    if (certificado) {
      if (esWeb()) {
        formData.append("certificado", certificado?.file);
      } else {
        const archivoCertificado = {
          uri: certificado?.uri,
          name: certificado?.name,
          type: certificado?.mimeType,
        };
        formData.append("certificado", archivoCertificado);
      }
      actualizarCertificadoMutate.mutate({ licenciaId: licencia.id, formData });
    }
  };

  const expiroTiempoLimite =
    moment().diff(evento, "minutes") > 0 ? true : false;

  if (expiroTiempoLimite) {
    return null;
  }

  return (
    <View style={tw`flex flex-col items-center justify-center mt-2`}>
      <Text>Tiempo limite para que envies tu certificado</Text>
      <Badge text={limite} color="red" />
      <Pressable onPress={pickDocument} foreground={true}>
        <View
          style={tw`flex flex-row items-center justify-center py-2 px-4 text-white bg-gray-400 rounded-md`}
        >
          <MaterialCommunityIcons
            name="certificate-outline"
            size={24}
            color="white"
          />
          <Text style={{ color: "white", marginLeft: 16 }}>
            Elegir certificado (png,jpg,jpeg)
          </Text>
        </View>
      </Pressable>
      {certificado ? (
        <Pressable onPress={submit} foreground={true} style={tw`mt-2`}>
          <View
            style={tw`py-2 px-4 flex flex-row gap-2 items-center justify-center bg-green-500 rounded-md`}
          >
            {actualizarCertificadoMutate.isLoading ? (
              <ActivityIndicator size="small" color={Colores.primary} />
            ) : (
              <MaterialIcons name="upload-file" size={24} color="white" />
            )}
            <Text style={{ color: "white", marginLeft: 16 }}>
              Subir {esWeb() ? certificado?.file?.name : certificado.name}
            </Text>
          </View>
        </Pressable>
      ) : null}
    </View>
  );
}


export default SubirCertificado;
