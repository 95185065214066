import React from "react";
import { View, Text } from "react-native";
import tw from "twrnc";
import { Ionicons } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Colores } from "../../constants/colores";

const primary = Colores.primary;

const iconos = {
  1: {
    activo: <Ionicons name="ios-newspaper" size={28} color={primary} />,
    inactivo: <Ionicons name="newspaper-sharp" size={28} color="gray" />,
  },
  2: {
    activo: (
      <MaterialCommunityIcons name="file-upload" size={28} color={primary} />
    ),
    inactivo: (
      <MaterialCommunityIcons
        name="file-upload-outline"
        size={28}
        color="gray"
      />
    ),
  },
  3: {
    activo: (
      <MaterialCommunityIcons name="account-group" size={28} color={primary} />
    ),
    inactivo: (
      <MaterialCommunityIcons
        name="account-group-outline"
        size={28}
        color="gray"
      />
    ),
  },
  4: {
    activo: (
      <MaterialCommunityIcons
        name="book-check"
        size={28}
        color={Colores.primary}
      />
    ),
    inactivo: (
      <MaterialCommunityIcons
        name="book-check-outline"
        size={28}
        color="gray"
      />
    ),
  },
};

const ItemStep = ({ step, currentStep, label }) => {
  const stepActivo = tw`bg-gray-100 border-2 border-dashed border-[${primary}]`;
  const stepPasado = tw`bg-gray-transparent border-none opacity-70`;
  return (
    <View
      style={tw`flex flex-col items-center gap-2 ${
        currentStep < step ? "opacity-50" : "opacity-100"
      }`}
    >
      <View
        style={[
          tw`w-16 h-16 flex flex-col items-center justify-center rounded-full p-1`,
          currentStep == step ? stepActivo : stepPasado,
        ]}
      >
        {
            currentStep >= step ? iconos[step].activo : iconos[step].inactivo 
        }
      </View>
      <Text
        style={[
          tw`font-semibold`,
          { color: step <= currentStep ? primary : "gray" },
        ]}
      >
        {label}
      </Text>
    </View>
  );
};

export default ItemStep;
