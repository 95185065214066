import React from "react";
import tw from 'twrnc';

import { PageContainer } from "../../components/PageContainer";
import { AvisoEditarForm } from "../../forms/AvisoEditarForm";

export default function EditarAvisoScreen(props) {
  const { aviso } = props.route.params;
  return (
    <PageContainer style={tw`items-center justify-center`}>
      <AvisoEditarForm aviso={aviso} {...props} />
    </PageContainer>
  );
}
