import React from "react";
import { useAtom } from "jotai";
import { Button, Image, Modal, View, Text, StyleSheet,TouchableOpacity } from "react-native";
import tw from 'twrnc';


import { esVisibleAvisoModalAtom } from "../../store/store";

function AvisoModal({ visible, aviso }) {
  const [avisoModalVisible, setAvisoModalVisible] = useAtom(
    esVisibleAvisoModalAtom
  );
  return (
    <Modal
      animationType="slide"
      transparent={false}
      visible={visible}
      onRequestClose={() => {
        setAvisoModalVisible(!avisoModalVisible);
      }}
    >
      <TouchableOpacity
        style={tw`w-1 ml-10 mt-10 bg-red-700 rounded-lg py-2 px-5 justify-center items-center`}
        onPress={() => setAvisoModalVisible(false)}
      >
        <Text style={tw`text-white text-lg text-center`}>X</Text>
      </TouchableOpacity>
      <View style={tw`items-center`}>
        {aviso.foto ? (
          <Image
            style={tw`w-90 rounded h-100 shadow-md mt-3`}
            source={{ uri: aviso.foto }}
          />
        ) : null}
        <Text
          style={tw`mt-4 mb-1 p-3 text-center text-2xl font-bold tracking-tight text-gray-900`}
        >
          {aviso.titulo}
        </Text>
        <Text style={tw`p-3 text-lg text-gray-700 dark:text-gray-400`}>{aviso.texto}</Text>
      </View>
    </Modal>
  );
}

export default AvisoModal;
