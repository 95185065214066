import React from "react";
import { FlatList, Image, Text, TouchableOpacity, View } from "react-native";
import tw from 'twrnc';
import { userAtom } from "../../store/store";

import { PageContainer } from "../../components/PageContainer";
import { useAtom } from "jotai";
import { getNotificaciones, marcarNotificacionComoLeida } from "../../services/apiClient";
import { useMutation, useQuery, useQueryClient } from "react-query";
import moment from "moment";

export default function NotificacionesScreen(props) {
    const {navigation} = props;
    const [user, setUser] = useAtom(userAtom);
    const queryClient = useQueryClient();
    const notificaciones = useQuery("notificaciones", getNotificaciones);


  const marcarNotificacion = useMutation(marcarNotificacionComoLeida, {
    onSuccess: async (response) => {
      queryClient.invalidateQueries("notificaciones");
    console.log(response)
    },
    onError: async (error) => {

    },
    onSettled: (data) => {
    },
    });

    const tiempoTranscurrido = fecha => {
      const tiempo =  moment(fecha).fromNow().charAt(0).toUpperCase() + moment(fecha).fromNow().slice(1);
      return tiempo;
   };

    const redirigir = (notificacion) =>{
      if(notificacion.read_at){
          console.log("Redirigir")
      }else{
        marcarNotificacion.mutate(notificacion.id);
      }
    }


  return (
    <PageContainer style={tw`bg-white`}>
        <View style={tw`px-3 flex-1`}>

            <FlatList
              data={notificaciones.data?.data?.notificaciones}
              keyExtractor={item => item.id}
              renderItem={({item, index}) => (
                <TouchableOpacity style={[tw`flex-row px-5 py-4 mt-2`,{backgroundColor: item.read_at ? '#f1f1f1' : '#d9f4ff',borderRadius: 15}]} onPress={()=>redirigir(item)}>
                    <View><Image style={tw`h-12 w-12 rounded-full`} source={{uri:user?.foto}} /></View>

                    <View style={tw`w-10/12 justify-around ml-3`}>
                        <Text style={[tw`font-semibold`,{color: item.read_at ? '#4c4b4b' : '#47416e'}]}>{item.data.titulo}</Text>
                        <Text style={[tw`text-xs`, ,{color: item.read_at ? '#625d5d' : '#645d8b'}]}>{item.data.texto}</Text>
                        <Text style={tw`text-xs text-right text-slate-400 mr-2 mt-2`}>{ tiempoTranscurrido(item.created_at) }</Text>
                    </View>
                </TouchableOpacity>
              )}
            />
            
        </View>
    </PageContainer>
  );
}