import React from "react";
import { View } from "react-native";

import Beneficio from "../../components/beneficios/Beneficio";

function DetalleBeneficioScreen(props) {
  const { beneficio } = props.route.params;
  return (
    <PageContainer>
      <View style={{ flex: 1, padding: "10px" }}>
        <Beneficio datos={beneficio} />
      </View>
    </PageContainer>
  );
}

export default DetalleBeneficioScreen;
