import moment from "moment";
import React from "react";
import { View, Text } from "react-native";
import tw from "twrnc";
import { estadoItem } from "../../utils/generales";

const variantesBadge = {
  pendiente: {
    label: "Pendiente",
    bg: "bg-blue-100",
    borderColor: "border-blue-500",
    textColor: "text-blue-500",
  },
  aceptado: {
    label: "Aceptado",
    bg: "bg-green-100",
    borderColor: "border-green-500",
    textColor: "text-green-500",
  },
  rechazado: {
    label: "Rechazado",
    bg: "bg-red-100",
    borderColor: "border-red-500",
    textColor: "text-red-500",
  },
  devolucion: {
    label: "Devolución",
    bg: "bg-amber-100",
    borderColor: "border-amber-500",
    textColor: "text-amber-500",
  },
  porCaducar: {
    label: "Por caducar",
    bg: "bg-yellow-100",
    borderColor: "border-yellow-500",
    textColor: "text-yellow-500",
  },
  caducado: {
    label: "Caducado",
    bg: "bg-gray-100",
    borderColor: "border-gray-500",
    textColor: "text-gray-500",
  },
};

const EstadoItem = ({item}) => {
  const estado = estadoItem(item)
  const badge = variantesBadge[estado];

  return (
    <View style={tw`rounded-full ${badge.bg} opacity-80 border-2 ${badge.borderColor} px-2`}>
      <Text style={tw`${badge.textColor}`}>{badge.label}</Text>
    </View>
  );
};

export default EstadoItem;
