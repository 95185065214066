import { useState } from 'react';
import { Alert, Modal, Text, Pressable, View, Image, Dimensions, ScrollView, TouchableOpacity } from 'react-native';
import tw from 'twrnc';
import { Ionicons } from '@expo/vector-icons';
import { Colores } from '../../constants/colores';
import { FontAwesome } from '@expo/vector-icons';

const AvisoModalVistaPrevia = ({modalVisible, cambiarEstadoModal, archivos, setArchivos}) => {
  const [imgActive, setImgActive] = useState(0)

  const onchange = (nativeEvent) => {
    if(nativeEvent){
        const slide = Math.ceil(nativeEvent.contentOffset.x / nativeEvent.layoutMeasurement.width)
        if(slide != imgActive){
          setImgActive(slide);
        }
      }
  }
  const deleteImg = (id) =>{
    const extraerImg = archivos.filter((ar)=>ar.id !== id)
    setArchivos(extraerImg)
  }
  return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          cambiarEstadoModal(!modalVisible);
      }}>
        <View style={[tw`w-full h-full`,{backgroundColor:'#000000'}]}>
            <View style={tw`top-5 absolute flex flex-row z-50`}>
                <Pressable
                  onPress={() => cambiarEstadoModal(!modalVisible)}
                  style={tw`ml-5`}
                >
                    <Ionicons name="arrow-back" size={24} color="#FFF" />
                </Pressable>
                <Text style={tw`text-2xl font-semibold text-white text-center ml-15`}>Vista Previa</Text>
            </View>

            <View style={tw`items-center justify-center w-full h-full`}>
                <View style={tw`w-full h-100 items-center justify-center -mt-20`}>
                  <ScrollView
                    scrollEventThrottle={16}
                    onScroll={({nativeEvent}) => onchange(nativeEvent)}
                    showsHorizontalScrollIndicator={false}
                    pagingEnabled
                    horizontal
                    style={tw`w-full h-full`}
                  >
                    {archivos?.length > 0 && (
                      archivos?.map((e, index) =>{
                        return(
                          <View key={e.id} style={tw`h-90 w-full items-end`}>
                            <TouchableOpacity style={tw`mr-10`} onPress={()=>deleteImg(e.id)}>
                              <FontAwesome name="close" size={24} color="#FFF" />
                            </TouchableOpacity>
                            <Image
                              key={e.id}
                              resizeMode='contain'
                              style={[tw`h-100`,{width:Dimensions.get('window').width - 10}]}
                              source={{uri:e.uri}}
                            />
                          </View>
                        )
                      })
                    )}
                  </ScrollView>
                </View>
                <View style={tw`absolute bottom-5 flex-row self-center`}>
                    {archivos?.length > 0 ? (
                      archivos?.map((e,index)=>
                        <Text key={e.id} style={ imgActive == index ? {color:'#FFF', margin:5} : {color:Colores.primary, margin:5} }>
                        ●
                        </Text>
                      )
                    ) : null }
                </View>
            </View>

        </View>
      </Modal>
  );
};

export default AvisoModalVistaPrevia;