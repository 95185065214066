import React, { useEffect, useState } from "react";
import { Picker } from "@react-native-picker/picker";
import { Text, View } from "react-native";
import { useQuery } from "react-query";
import tw from 'twrnc';

import { getSugerenciaTipos } from "../../services/apiClient";

function SugerenciaTipos({ onChange }) {
  const { data, isLoading, isError } = useQuery(
    "sugerenciaTipos",
    getSugerenciaTipos
  );
  const [tipos, settipos] = useState([]);
  const [tipo, settipo] = useState(null);

  useEffect(() => {
    if (data?.data?.sugerenciaTipos.length) {
      const listaTipos = [];
      data.data.sugerenciaTipos.map((sugerenciaTipo) =>
        listaTipos.push(
          <Picker.Item
            style={tw` w-full px-2 py-1.5 border-0 text-gray-700 bg-white`}
            key={`sugerenciaTipo-${sugerenciaTipo.id}`}
            label={sugerenciaTipo.tipo}
            value={sugerenciaTipo.id}
          />
        )
      );
      settipos([...listaTipos]);
    }
  }, [data]);

  return (
    <View>
      <Text style={tw`my-3 text-lg`}>Seleccione un tipo</Text>
      {isLoading ? (
        <Text>Loading...</Text>
      ) : isError ? (
        <Text>Error</Text>
      ) : tipos.length ? (
        <Picker
          style={[tw` px-3 py-3 text-gray-700 border border-gray-300`,{borderRadius:50}]}
          selectedValue={tipo}
          onValueChange={
            (itemValue, itemIndex) => {
              onChange(itemValue);
              settipo(itemValue);
            }
          }
        >
          <Picker.Item label="Seleccione un tipo" value="null" />
          {tipos.map((tipo) => tipo)}
        </Picker>
      ) : (
        <Text>no hay datos</Text>
      )}
    </View>
  );
}

export default SugerenciaTipos;
