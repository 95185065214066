import React from "react";
import { View, Text, useWindowDimensions, Image, ScrollView } from "react-native";
import { useQuery } from "react-query";
import tw from 'twrnc';

import Beneficios from "../../components/beneficios/Beneficios";
import { Loader } from "../../components/Loader";
import { PageContainer } from "../../components/PageContainer";
import { getBeneficiosFiltrados } from "../../services/apiClient";

export default function BeneficiosScreen() {
  const beneficiosQuery = useQuery("beneficios", getBeneficiosFiltrados);
  const { width } = useWindowDimensions();

  return (
    <PageContainer paddingSm>
      <ScrollView>
        <View style={tw`h-full w-full`}>
          {beneficiosQuery.isLoading ? (
            <Loader text="Cargando beneficios" />
          ) : beneficiosQuery.isError ? (
            <Text>Hubo un error</Text>
          ) : beneficiosQuery.data.data?.beneficios?.length ? (
            <Beneficios datos={beneficiosQuery.data.data.beneficios} />
          ) : (
            <View style={tw`items-center mt-15`}>
            <Image
              source={require("../../assets/empty.png")}
              style={[
                width > 768 ? tw`w-full h-100 mt-10` : tw`w-50 h-50`,
                { resizeMode: "contain" },
              ]}
            />
            <Text
              style={[
                width > 768
                  ? tw`text-2xl mt-20 font-semibold`
                  : tw`text-xl mt-5 font-semibold`,
                { color: "#a2a9b8" },
              ]}
            >
              Aún no hay beneficios
            </Text>
          </View>
          )}
        </View>
      </ScrollView>
    </PageContainer>
  );
}
