import { Platform } from "react-native";

export const esWeb = () => {
  if (Platform.OS === "web") {
    return true;
  }
  return false;
};


export const esAndroid = () => {
  if (Platform.OS === "android") {
    return true;
  }
  return false;
};


export const esIOS = () => {
  if (Platform.OS === "ios") {
    return true;
  }
  return false;
};