import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import React, { useState } from "react";
import { Image, Text, TextInput, TouchableOpacity, useWindowDimensions, View } from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import tw from 'twrnc';

import { PageContainer } from "../../components/PageContainer";
import { Colores } from "../../constants/colores";
import MiPerfilEditarForm from "../../forms/MiPerfilEditarForm";
import { awesomeAlertAtom, userAtom } from "../../store/store";
import { BarPasswordStrengthDisplay,
} from "react-native-password-strength-meter";
import { actualizarPassword } from "../../services/apiClient";
import { useMutation } from "react-query";
import { Ionicons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import ResultadoModal from "../../components/ResultadoModal";

function CambiarPasswordScreen(props) {
//   const { colaborador } = props.route.params;

const [password, setPassowrd] = useState("");
const onChange = (password) => setPassowrd(password);
const [user,setUser] = useAtom(userAtom);
const [passwordPrimera, setPasswordPrimera] = useState()
const [passwordSegunda, setPasswordSegunda] = useState()
const [awesomeAlert, setawesomeAlert] = useAtom(awesomeAlertAtom);
const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);
const {width} = useWindowDimensions();
const resetAlert = useResetAtom(awesomeAlertAtom);

const levels = [
  {
    label: "Extremadamente Debil",
    labelColor: "#ff3e00",
    activeBarColor: "#ff3e00",
  },
  {
    label: "Muy Debil",
    labelColor: "#ff5400",
    activeBarColor: "#ff5400",
  },
  {
    label: "Debil",
    labelColor: "#ff6900",
    activeBarColor: "#ff6900",
  },
  {
    label: "Promedio",
    labelColor: "#f3d331",
    activeBarColor: "#f3d331",
  },
  {
    label: "Bueno",
    labelColor: "#f2cf1f",
    activeBarColor: "#f2cf1f",
  },
  {
    label: "Fuerte",
    labelColor: "#14eb6e",
    activeBarColor: "#14eb6e",
  },
  {
    label: "Muy Fuerte",
    labelColor: "#0af56d",
    activeBarColor: "#0af56d",
  },
  {
    label: "Increiblemente Fuerte",
    labelColor: "#00ff6b",
    activeBarColor: "#00ff6b",
  },
];

const resetForm = () => {
  setPasswordPrimera("");
  setPasswordSegunda("");
  resetAlert();
};


  const actualizarPasswordMutate = useMutation(actualizarPassword, {
    onSuccess: async (response) => {
      //mostral mostrar modal
      setawesomeAlert({
        show: true,
        showConfirmButton: false,
        customView: (
          <ResultadoModal
            success={true}
            message="La contraseña fue modificada"
          />
        ),onConfirmPressed: () => {
          resetForm()
        },
        onDismiss: () => {
          resetForm();
        },
      });
      resetForm();
    },
    onError: async (error) => {

      let mensajeErrores = "";
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        mensajeErrores = mensajeErrores + ' \n' + value;
      }
      //TODO: mostrar errores en campos
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Error",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal success={false} message={mensajeErrores} />
        ),onConfirmPressed: () => {
          resetForm();
        },
        onDismiss: () => {
          resetForm();
        },
      });
    },
  });

  const cambiarContra = () => {
    actualizarPasswordMutate.mutate({userId: user.id,
      formData :
      {"password": passwordPrimera,
      "password_confirmation": passwordSegunda
    }});
}   

  return (
    <>
        <PageContainer paddingMd >
            {width < 758 && (
              <View style={[tw`flex-row p-4`,{backgroundColor:Colores.primary, borderBottomLeftRadius:10, borderBottomRightRadius:10}]}>
                <View style={tw`flex-1`}><TouchableOpacity onPress={()=>props.navigation.navigate('Mi Perfil')}><AntDesign name="arrowleft" size={24} color='#FFF' /></TouchableOpacity></View>
                <View style={tw`flex-3`}><Text style={tw`text-center text-lg text-white`}>Contraseña</Text></View>
                <View style={tw`flex-1`}></View>
              </View>
            )}
            <View style={[tw`bg-white p-5`,{borderRadius: width >=768 ? 20 : 0 }]}>

            {width >=768 && (
              <View style={tw`ml-5 my-5 px-10`}>
                <TouchableOpacity onPress={()=>props.navigation.navigate('Mi Perfil')}>
                  <Ionicons name="arrow-back-sharp" size={24} color={Colores.primary} />
                </TouchableOpacity>
                <Text style={tw`text-lg font-semibold mt-5`}>Contraseña</Text>
              </View>
            )}


                <View style={tw`items-center`}>
                    <Image source={require('../../assets/Perfil/key.png')} style={tw`w-50 h-50 mr-1`} />
                    <Text style={[tw`text-lg text-center mt-7`,{color:Colores.primary}]}>Cambiar contraseña</Text>
                    <Text style={tw`text-gray-500 text-center mt-7`}>La contraseña debe tener al menos seis caracteres</Text>
                </View>

                <View style={width >768 ? tw`mx-10`: tw`mx-3`}>
                  <TextInput
                    placeholder="Contraseña"
                    style={[tw` w-full px-3 py-2 text-base font-normal text-gray-700 bg-white mt-7`,{borderBottomWidth:1, borderColor:Colores.gray}]}
                    secureTextEntry={true}
                    onChangeText={(text)=>setPasswordPrimera(text)}
                  />
                  <TextInput
                    placeholder="Repetir Contraseña"
                    style={[tw` w-full px-3 py-2 text-base font-normal text-gray-700 bg-white mt-7`,{borderBottomWidth:1, borderColor:Colores.gray}]}
                    secureTextEntry={true}
                    onChangeText={(text)=>setPasswordSegunda(text)}
                  />
         <         View style={width > 768 ? tw`mt-10 items-center` : tw`mt-5 items-center` }>
                  <BarPasswordStrengthDisplay
                    password={passwordSegunda}
                    minLength={8}
                    levels={levels}
                    width={width > 758 ? width * 0.3 : width * 0.7 }
                    />
                  </View>
          
                  <View style={[tw`justify-center my-10 items-center`]}>
                    <TouchableOpacity style={[tw`px-20 py-3 `,{backgroundColor:Colores.primary, borderRadius:16}]} onPress={()=>cambiarContra()}>
                      <Text style={tw`text-white`}>Cambiar Contraseña</Text>
                    </TouchableOpacity>
                  </View>
                  </View>
                </View>
        </PageContainer>

          <AwesomeAlert
          show={awesomeAlert.show}
          showProgress={awesomeAlert.showProgress}
          title={awesomeAlert.title}
          message={awesomeAlert.message}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={awesomeAlert.showCancelButton}
          showConfirmButton={awesomeAlert.showConfirmButton}
          cancelText="Cancelar"
          confirmText={awesomeAlert.confirmText}
          confirmButtonColor={awesomeAlert.confirmButtonColor}
          customView={awesomeAlert.customView}
          onDismiss={awesomeAlert.onDismiss}
          onConfirmPressed={awesomeAlert.onConfirmPressed}
          onCancelPressed={() => {
            resetAwesomeAlert();
          }}
        />
      </>
  );
}

export default CambiarPasswordScreen;
