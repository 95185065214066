import React, { useEffect } from "react";
import { TouchableOpacity, View, Text } from "react-native";
import tw from "twrnc";
import { Colores } from "../../constants/colores";
import { useAtom } from "jotai";
import { currentStepAtom } from "../../store/store";
import { Loader } from "../Loader";

const ButtonsSteps = ({ totalSteps, validate, submit, isLoading }) => {
  const [currentStep, setCurrentStep] = useAtom(currentStepAtom)
  const handler = (operation) => {
    if (operation === "add") {
      if (currentStep === totalSteps) return;
      setCurrentStep(currentStep + 1);
    }
    if (operation === "sub") {
      if (currentStep === 1) return;
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <View style={tw`w-full flex flex-row items-center justify-end gap-4`}>
      {currentStep == 1 ? null : (
        <TouchableOpacity
          style={[
            tw`min-w-[120px] bg-white rounded-md border-2 border-[${Colores.primary}]`,
          ]}
          onPress={() => handler("sub")}
        >
          <Text
            style={tw`text-white text-md font-bold text-center py-1 text-[${Colores.primary}]`}
          >
            Volver
          </Text>
        </TouchableOpacity>
      )}

      {currentStep == totalSteps ? (
        <TouchableOpacity
          style={[
            tw`min-w-[120px] rounded-md border-2 border-[${Colores.primary}]`,
            {
              backgroundColor: Colores.primary,
            },
          ]}
          onPress={submit}
          disabled={isLoading}
        >
          <Text style={tw`text-white text-md font-bold text-center py-1`}>
            Confirmar
          </Text>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity
          style={[
            tw`min-w-[120px] rounded-md border-2 border-[${Colores.primary}]`,
            {
              backgroundColor: Colores.primary,
            },
          ]}
          onPress={async () => {
            const isValid = await validate()
            if (isValid) {
              handler("add")
            }
          }}
        >
          <Text style={tw`text-white text-md font-bold text-center py-1`}>
            Siguiente
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

export default ButtonsSteps;
