import React from "react";
import { Text, View } from "react-native";
import moment from "moment";
import "moment/locale/es"; // without this line it didn't work
import Badge from "../Badge";
import SubirCertificado from "./SubirCertificado";
import tw from "twrnc";
import { Colores } from "../../constants/colores";

function Licencia({ licenciaDatos }) {
  moment.locale("es");
  const estado = licenciaDatos?.estado.estado.toUpperCase();
  const inicio = moment(licenciaDatos.inicio).format("DD/MM/YYYY");
  const fin = moment(licenciaDatos.fin).format("DD/MM/YYYY");
  let color = "gray";
  const autorizante = licenciaDatos.autorizante?.nombre.toUpperCase();
  const motivo = licenciaDatos?.motivo;
  const tipo = licenciaDatos?.opcion?.tipo;

  switch (licenciaDatos?.estado?.estado) {
    case "AUTORIZADO":
      color = "green";
      break;
    case "NO AUTORIZADO":
      color = "red";
      break;
    default:
      color = "gray";
      break;
  }

  return (
    <View
      style={[
        tw`bg-white p-5 m-2 border border-gray-300 shadow-md`,
        { borderRadius: 16 },
      ]}
    >
      <View style={tw`flex flex-row items-center`}>
        <Text style={tw`font-semibold text-lg my-2`}>
          {licenciaDatos.opcion.opcion}
        </Text>
      </View>

      <View style={tw`flex flex-row items-center`}>
        <Text style={[tw`font-semibold`, { color: Colores.primary }]}>
          Inicio:
        </Text>
        <Text style={tw`ml-2`}>{inicio}</Text>
        <Text style={[tw`font-semibold ml-3`, { color: Colores.primary }]}>
          Fin:
        </Text>
        <Text style={tw`ml-2`}>{fin}</Text>
      </View>

      <View style={tw`flex flex-row items-center mt-2`}>
        <Text style={[tw`font-semibold`, { color: Colores.primary }]}>
          Motivo:
        </Text>
        <Text style={tw`ml-2`}>{motivo ?? "-"}</Text>
      </View>

      <View style={tw`absolute right-1 top-0`}>
        <Badge fontSize="0.7rem" color={color} text={estado} />
      </View>

      <View style={tw`flex flex-row items-center mt-2`}>
        <Text style={[tw`font-semibold`, { color: Colores.primary }]}>
          Autorizante:
        </Text>
        <Text style={tw`ml-2`}>{autorizante ?? "-"}</Text>
      </View>

      {tipo.tipo === "NO PROGRAMADA" || tipo.tipo === "PROGRAMADA" ? (
        <View style={tw`flex flex-row items-center mt-2`}>
          <Text style={tw`font-semibold`}>
            <Text style={[tw`mb-3`, { color: Colores.primary }]}>
              Certificado:
            </Text>{" "}
            {licenciaDatos.certificado ? (
              "presentado"
            ) : (
              <SubirCertificado
                licencia={licenciaDatos}
                tiempoLimitePresentacion={
                  licenciaDatos.opcion.horaLimiteParaJustificar
                }
              />
            )}
          </Text>
        </View>
      ) : null}

      <View style={tw`flex flex-row items-center mt-2`}>
        <Text style={tw``}>
          Solicitado {moment(licenciaDatos.created_at).fromNow()}
        </Text>
      </View>
    </View>
  );
}

export default Licencia;
