import { Picker } from "@react-native-picker/picker";
import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { useQuery } from "react-query";
import { getEmpresasDptosPuestos } from "../services/apiClient";
import { Loader } from "./Loader";
import tw from 'twrnc';

function SelectEmpresa({ seleccion }) {
  const { data, isLoading, isError } = useQuery(
    "empresasDptosPuestos",
    getEmpresasDptosPuestos
  );
  const [itemsEmpresas, setitemsEmpresas] = useState([]);
  const [itemsDptos, setitemsDptos] = useState([]);
  const [itemsPuestos, setitemsPuestos] = useState([]);
  const [empresa, setempresa] = useState();
  const [empresaID, setempresaID] = useState();
  const [dptoID, setdptoID] = useState();
  const [puestoID, setpuestoID] = useState();

  const empresas = [];
  if (data && data?.data.empresas.length) {
    data.data.empresas.map((empresa) => {
      empresas.push(
        <Picker.Item
          key={`empresa-${empresa.id}`}
          label={empresa.nombre}
          value={empresa.id}
        />
      );
    });
  }

  useEffect(() => {
    if (empresas.length) {
      setitemsEmpresas(empresas);
    }
  }, [data]);

  const cargarDptos = (empresaId) => {
    data?.data.empresas.filter((empresa) => {
      if (empresa.id == empresaId) {
        setempresa(empresa);
        const dptos = [];
        empresa.departamentos.map((dpto) => {
          dptos.push(
            <Picker.Item
              key={`dpto-${dpto.id}`}
              label={dpto.nombre}
              value={dpto.id}
            />
          );
        });
        setitemsDptos(dptos);
      }
    });
  };

  const cargarPuestos = (dptoId) => {
    empresa.departamentos.filter((dpto) => {
      if (dpto.id == dptoId) {
        const puestos = [];
        dpto.puestos.map((puesto) => {
          puestos.push(
            <Picker.Item
              key={`puesto-${puesto.id}`}
              label={puesto.nombre}
              value={puesto.id}
            />
          );
        });
        setitemsPuestos(puestos);
      }
    });
  };

  useEffect(() => {
    if (empresas.length) {
      cargarDptos(empresaID);
    }
  }, [empresaID]);

  useEffect(() => {
    if (itemsDptos.length) {
      console.log(`se disparo cargar puestos dptoID ${dptoID}`);
      cargarPuestos(dptoID);
    }
  }, [dptoID, empresa, itemsDptos]);

  return (
    <View>
      {isLoading ? (
        <Loader flex={0} text="Cargando empresas" />
      ) : isError ? (
        <Text>Error: no se pudieron cargar las empresas</Text>
      ) : (
        <Picker
          style={[tw` mt-2 w-full pl-1 py-3 border border-gray-300 text-gray-700 rounded-lg`,{borderRadius:50}]}
          selectedValue={empresaID}
          onValueChange={(itemValue, itemIndex) => {
            setempresaID(itemValue);
            seleccion((prev) => ({ ...prev, empresa_id: itemValue }));
          }}
        >
          <Picker.Item label="Seleccione una empresa" value="null" />
          {itemsEmpresas.map((empresa) => empresa)}
        </Picker>
      )}
      <Text style={[tw`text-lg mt-3 mb-1`,{color:'#6c7581'}]}>Indique el departamento<Text style={{color:'red'}}>*</Text></Text>
     
        <Picker
          style={[tw` mt-2 w-full pl-1 py-3 border border-gray-300 text-gray-700 rounded-lg`,{borderRadius:50}]}
          selectedValue={dptoID}
          onValueChange={(itemValue, itemIndex) => {
            setdptoID(itemValue);
            seleccion((prev) => ({ ...prev, departamento_id: itemValue }));
          }}
        >
          <Picker.Item label="Seleccione un departamento" value="null" />
          {itemsDptos.map((dpto) => dpto)}
        </Picker>
      
      <Text style={[tw`mt-3 mb-1 text-lg`,{color:'#6c7581'}]}>Indique el puesto<Text style={{color:'red'}}>*</Text></Text>
      
        <Picker
          style={[tw` mt-2 w-full pl-1 py-3 border border-gray-300 text-gray-700 rounded-lg`,{borderRadius:50}]}
          selectedValue={puestoID}
          onValueChange={(itemValue, itemIndex) => {
            setpuestoID(itemValue);
            seleccion((prev) => ({ ...prev, puesto_id: itemValue }));
          }}
        >
          <Picker.Item label="Seleccione un puesto" value="null" />
          {itemsPuestos.map((puesto) => puesto)}
        </Picker>
     
    </View>
  );
}

export default SelectEmpresa;
