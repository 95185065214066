import React, { useState } from "react";
import { TouchableOpacity, Animated, useWindowDimensions } from "react-native";
import { FontAwesome } from '@expo/vector-icons';

import { useMutation } from "react-query";
import { useAtom } from "jotai";
import tw from 'twrnc';
import { AntDesign } from '@expo/vector-icons';

import { userAtom } from "../../store/store";
import { avisoMeGusta } from "../../services/apiClient";


// const navigation = useNavigation(); 
export const MeGustaButton = ({ avisoId, megustaCount = 0, megustaList, navigate, colorIcon, descripcion, setModalDetalleVisible, aviso }) => {
  const [cantidadMG, setCantidadMG] = useState(megustaCount);
  const {width} = useWindowDimensions();
  const meGustaMutate = useMutation(avisoMeGusta, {
    onSuccess: ({ data }) => {
      if (data.ok) {
        setLeGusta((prev) => data.meGusta);
        if (data.meGusta) {
          //actualizar cantidad
          setCantidadMG((prev) => prev + 1);
        } else {
          setCantidadMG((prev) => prev - 1);
        }
      }
    },
  });

  const [user] = useAtom(userAtom);
  const yaLeGustaba = megustaList.find((megusta) => megusta.id === user.id);
  const [leGusta, setLeGusta] = useState(yaLeGustaba ? true : false);

  const toggleMeGusta = (estado) => {
    meGustaMutate.mutate({avisoId, estado});
  };

  const animatedValue = new Animated.Value(1);

  function onPressIn() {
      Animated.spring(animatedValue, {
          toValue: 0.9,
          useNativeDriver:true,
          speed:500
      }).start();
  }

  function onPressOut() {
      Animated.spring(animatedValue, {
          toValue: 1,
          useNativeDriver:true,
          speed:500
      }).start();
  }

  const animatedStyle = {
      transform : [{ scale: animatedValue}]
  }

  return (
    <Animated.View style={[tw`flex flex-row`, animatedStyle]}>
      
      <TouchableOpacity onPress={()=>toggleMeGusta(leGusta === true ? null : 1 )} onPressIn={onPressIn} onPressOut={onPressOut}>
          {leGusta ? (
            <AntDesign name="heart" size={30} color='red' style={tw`ml-3`}/>
          ) : (
            <AntDesign name="hearto" size={30} color={colorIcon} style={tw`ml-3`} />
          )}
      </TouchableOpacity>
      {
        aviso.comentariosActivo ?
        <TouchableOpacity onPress={width > 758 ? ()=>setModalDetalleVisible(true) : ()=>navigate('Comentarios',{avisoId, aviso:aviso})}>
          <FontAwesome name="comment-o" size={30} color={colorIcon} style={tw`ml-3`} />
        </TouchableOpacity>
        : null
      }
      

    </Animated.View>
  );
};