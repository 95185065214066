import React from "react";
import { View, Image, Text, useWindowDimensions } from "react-native";
import tw from "twrnc";

const NotFound = ({ text }) => {
  const { width } = useWindowDimensions();
  return (
    <View style={tw`items-center mt-3`}>
      <Image
        source={require("../assets/not-found.png")}
        style={[
          width > 768 ? tw`w-full h-50 mt-10` : tw`w-50 h-50`,
          { resizeMode: "contain" },
        ]}
      />
      <Text
        style={[
          width > 768 ? tw`text-lg mt-20 font-bold` : tw`text-xl font-bold`,
          { color: "#a2a9b8" },
        ]}
      >
        {text}
      </Text>
    </View>
  );
};

export default NotFound;
