import React from "react";
import { Image, Text, View } from "react-native";
import moment from "moment";
import "moment/locale/es"; // without this line it didn't work
import Badge from "../Badge";
import tw from "twrnc";

function LicenciaDetalle({ licenciaDatos, estadoLicencia }) {
  moment.locale("es");
  const estado = licenciaDatos.estado.estado.toUpperCase();
  const inicio = moment(licenciaDatos.inicio).format("DD/MM/YYYY");
  const fin = moment(licenciaDatos.fin).format("DD/MM/YYYY");
  let color = "gray";
  const autorizante = licenciaDatos.autorizante
    ? licenciaDatos.autorizante.apellido.toUpperCase() +
    ", " +
    licenciaDatos.autorizante?.nombre.toUpperCase()
    : "-";
  const solicitante =
    licenciaDatos.solicitante?.apellido.toUpperCase() +
    ", " +
    licenciaDatos.solicitante?.nombre.toUpperCase();
  const solicitanteDNI = licenciaDatos.solicitante?.dni;
  const empresa = licenciaDatos.empresa?.nombre;
  const { motivo, observaciones } = licenciaDatos;
  const tipo = licenciaDatos?.opcion?.tipo;

  switch (estadoLicencia) {
    case 2:
      color = "#dcfce7";
      break;
    case 3:
      color = "#ffbdbdde";
      break;
    default:
      color = "#e5e7eb";
      break;
  }

  return (
    <>
      <View style={[tw`p-3 mt-2 mb-3 rounded-lg`, { backgroundColor: color }]}>
        <View style={tw`flex flex-row items-center`}>
          <Text style={tw` font-bold`}>Solicitante:</Text>
          <Text style={tw` ml-2`}>{solicitante ?? "-"}</Text>
        </View>

        <View style={tw`flex flex-row items-center mt-2`}>
          <Text style={tw` font-bold`}>DNI:</Text>
          <Text style={tw` ml-2`}>{solicitanteDNI ?? "-"}</Text>
        </View>

        <View style={tw`flex flex-row items-center mt-2`}>
          <Text style={tw` font-bold`}>Empresa:</Text>
          <Text style={tw` ml-2`}>{empresa ?? "-"}</Text>
        </View>

        <View style={tw`flex flex-row items-center mt-2`}>
          <Text style={tw` font-bold`}>
            Creada el: 
          </Text>
          <Text>
            {moment(licenciaDatos.created_at).format(" dddd Do [de] MMMM HH:mm")}
          </Text>
        </View>

        <View style={tw`flex flex-row items-center`}>
          <Text style={tw` font-bold`}>Tipo de licencia:</Text>
          <Badge
            fontSize="0.7rem"
            color="#444fff"
            text={licenciaDatos.opcion.opcion.toUpperCase()}
          />
        </View>

        <View style={tw`flex flex-row items-center`}>
          <Text style={tw` font-bold`}>Inicio:</Text>
          <Text style={tw`ml-2 `}>{inicio}</Text>
          <Text style={tw` font-bold ml-5`}>Fin:</Text>
          <Text style={tw`ml-2 `}>{fin}</Text>
        </View>

        <View style={tw`flex flex-row items-center mt-2`}>
          <Text style={tw` font-bold`}>Motivo:</Text>
          <Text style={tw` ml-2`}>{motivo ?? "-"}</Text>
        </View>

        <View style={tw`flex flex-row items-center`}>
          <Text style={tw` font-bold`}>Estado:</Text>
          <Badge color={color} text={estado} />
        </View>

        <View style={tw`flex flex-row items-center`}>
          <Text style={tw` font-bold`}>Autorizante:</Text>
          <Text style={tw` ml-2`}>{autorizante}</Text>
        </View>

        <View style={tw`flex flex-row items-center mt-3`}>
          <Text style={tw` font-bold`}>Observaciones:</Text>
          <Text>{observaciones ?? "-"}</Text>
        </View>

        {tipo.tipo === "NO PROGRAMADA" ||
          (tipo.tipo === "PROGRAMADA" && (
            <View style={tw`flex flex-row items-center`}>
              <Text style={{ fontWeight: "bold", marginRight: 16 }}>
                certificado:{" "}
                {licenciaDatos.certificado ? "presentado" : "no presentado"}
              </Text>
            </View>
          ))}
      </View>

      {licenciaDatos.certificado && (
        <View>
          <Text style={tw`text-lg mb-2`}>Certificado</Text>
          <Image
            source={{ uri: licenciaDatos.certificado }}
            style={[tw`w-full h-100`, { resizeMode: "contain" }]}
          />
        </View>
      )}
    </>
  );
}

export default LicenciaDetalle;
