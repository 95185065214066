import React, { useState } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  FlatList,
  TextInput,
  Pressable,
} from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import AwesomeAlert from "react-native-awesome-alerts";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import tw from "twrnc";

import {
  getColaboradoresEliminados,
  restaurarColaboradorEliminado,
} from "../../services/apiClient";

import ColaboradorAvatar from "../../components/Colaboradores/ColaboradorAvatar";
import { Loader } from "../../components/Loader";
import { awesomeAlertAtom, userAtom } from "../../store/store";
import ResultadoModal from "../../components/ResultadoModal";
import { PageContainer } from "../../components/PageContainer";
import { Colores } from "../../constants/colores";
import { AntDesign } from "@expo/vector-icons";

function ColaboradoresEliminadosScreen(props) {
  const colaboradoresEliminadosQuery = useQuery("colaboradoresEliminados", getColaboradoresEliminados);
  const [mostrarAlerta, setmostrarAlerta] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filtro, setFiltro] = useState();
  const [awesomeAlert, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);


  const [user, _] = useAtom(userAtom);
  const checkPermisos = (listaSlugPermisos) => {
    const tienePermiso = user.permisos.some((permiso) =>
      listaSlugPermisos.includes(permiso.slug)
    );

    return tienePermiso;
  };
  let colaboradores = [];
  if (colaboradoresEliminadosQuery.data && colaboradoresEliminadosQuery.data.data?.ok) {
      colaboradores = colaboradoresEliminadosQuery.data.data.colaboradores;
      colaboradores.map((colaborador) => {
          if (colaborador.empresa_actual.length) {
              colaborador.empresa_actual = colaborador?.empresa_actual[0];
            }
            if (colaborador.departamento_actual.length) {
                colaborador.departamento_actual = colaborador?.departamento_actual[0];
            }
            if (colaborador.puesto_actual.length) {
                colaborador.puesto_actual = colaborador?.puesto_actual[0];
            }
            return colaborador;
        });
  }

  const queryClient = useQueryClient();
  const restaurarColaboradorEliminadoMutate = useMutation(restaurarColaboradorEliminado, {
    onSuccess: async (response) => {
      console.log("colaborador desvinculado", response.data);
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Exito!",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal success={true} message={response.data.message} />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
      queryClient.invalidateQueries("colaboradoresEliminados");
    },
    onError: async (error) => {
      console.log(
        "hubo un error al intentar restaurar",
        error.response.data.message
      );
      //setmostrarAlerta(false)
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Error",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal
            success={false}
            message={error.response.data.message}
          />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
    },
    onSettled: (data) => {
      if (data.data.ok) {
        setmostrarAlerta(false);
      }
    },
  });



  const searchFilterFunction = (text) => {
    if (text) {
      const newData = colaboradores.filter((item) => {
        const itemData = item.nombre
          ? `${item.nombre.toUpperCase()} ${item.apellido.toUpperCase()}`
          : "".toLocaleUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setFilteredData(newData);
    } else {
      setFilteredData(colaboradores);
    }
  };

  if (colaboradoresEliminadosQuery.isLoading)
    return <Loader text="Cargando Colaboradores desvinculados..." />;

  const confirmar = (colaboradorId) => {
    setawesomeAlert({
      show: true,
      showProgress: restaurarColaboradorEliminadoMutate.isLoading,
      title: "Atención",
      message: "Esta seguro que desea restaurar el colaborador?",
      showCancelButton: true,
      showConfirmButton: true,
      confirmText: "Restaurar",
      confirmButtonColor: "#DD6B55",
      onConfirmPressed: () => {
        restaurarColaboradorEliminadoMutate.mutate(colaboradorId);
      },
      onDismiss: () => {
        resetAwesomeAlert();
      },
      onCancelPressed: () => {
        resetAwesomeAlert();
      },
    });
  };

  return (
    <PageContainer paddingMd>
      <View style={[tw`flex-1 p-5 bg-white`, { borderRadius: 20 }]}>
        <View style={tw`z-10`}>
          <AwesomeAlert
            show={awesomeAlert.show}
            showProgress={false}
            title={awesomeAlert.title}
            message={awesomeAlert.message}
            closeOnTouchOutside={true}
            closeOnHardwareBackPress={false}
            showCancelButton={awesomeAlert.showCancelButton}
            showConfirmButton={awesomeAlert.showConfirmButton}
            confirmText={awesomeAlert.confirmText}
            customView={awesomeAlert.customView}
            confirmButtonColor={awesomeAlert.confirmButtonColor}
            onDismiss={awesomeAlert.onDismiss}
            onCancelPressed={awesomeAlert.onCancelPressed}
            onConfirmPressed={awesomeAlert.onConfirmPressed}
          />
        </View>
        <View style={tw`mb-3`}>
          <View style={tw`flex-1 justify-center`}>
            <Text style={tw`text-lg font-semibold text-gray-500`}>
              Colaboradores Desvinculados
            </Text>
          </View>
        </View>

        <View style={tw`justify-center`}>
          <TextInput
            style={[
              tw` w-full px-3 py-2 text-gray-700 bg-white border border-solid border-gray-300`,
              { borderRadius: 23 },
            ]}
            placeholder="Buscar"
            onChangeText={(text) => {
              searchFilterFunction(text);
              setFiltro(text);
            }}
          />
          <AntDesign
            name="search1"
            size={20}
            color={"#7D7D7D"}
            style={tw`absolute right-4 top-1.5`}
          />
        </View>

        <FlatList
          data={filtro ? filteredData : colaboradores}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => (
            <View
              style={[
                tw`p-3 mt-2 border border-gray-300 flex-row shadow-md`,
                item.licencia_activa?.length
                  ? tw`bg-amber-200`
                  : tw`bg-white-400`,
                { borderRadius: 16 },
              ]}
            >
              <View style={tw`items-center justify-center`}>
                <Pressable
                  onPress={() => {
                    props.navigation.navigate("Editar Colaborador", {
                      colaborador: item,
                    });
                  }}
                >
                  <ColaboradorAvatar size={32} foto={item.foto} key={item.id} />
                </Pressable>
              </View>
              <View style={tw`ml-2 flex-1`}>
                  <Text
                    style={tw`text-lg capitalize`}
                  >{`${item.nombre} ${item.apellido}`}</Text>
                  <Text style={tw`mt-1`}>
                    <FontAwesome5
                      name="building"
                      size={10}
                      color={Colores.primary}
                      style={tw`mr-1`}
                    />
                    {item.puesto_actual.nombre
                      ? item.puesto_actual.nombre
                      : "-"}
                  </Text>
                  <Text style={tw`mt-1`}>
                    <FontAwesome5
                      name="envelope"
                      size={10}
                      color={Colores.primary}
                      style={tw`mr-1`}
                    />
                    {item.empresa_actual.nombre
                      ? item.empresa_actual.nombre
                      : "-"}
                  </Text>
              </View>
              <View
                style={[tw`flex flex-col items-start p-1 justify-end gap-2`]}
              >
                {checkPermisos([
                  "administrar.todo",
                  "administrar.colaboradores",
                  "restaurar.colaborador"
                ]) ? (
                  <TouchableOpacity
                    style={[
                      tw`p-2 justify-center items-center text-cyan-600 rounded-sm`,
                      { backgroundColor: Colores.primary },
                    ]}
                    onPress={() => confirmar(item.id)}
                  >
                    <Text style={[tw`text-white`]}>Restaurar Colaborador</Text>
                  </TouchableOpacity>
                ) : null}
              </View>
            </View>
          )}
        />
      </View>
    </PageContainer>
  );
}

export default ColaboradoresEliminadosScreen;
