import React from "react";
import { Ionicons } from "@expo/vector-icons";
import { StyleSheet, Text, View } from "react-native";
import tw from 'twrnc';

export default function  MensajeError({ message }) {
  return (
    <View style={tw`flex flex-row mt-1`}>
      <Ionicons name="alert-circle" size={18} style={tw`ml-1 text-red-500`} />
      <Text style={tw`text-red-500 ml-1`}>{message}</Text>
    </View>
  );
}
