import React, { useEffect, useState } from "react";
import { Image } from "react-native";

import noImage from "../assets/no-image.png";

function Imagen({ uri, style }) {
  const [showDefault, setshowDefault] = useState(true);
  const [error, seterror] = useState(false);
  const [image, setimage] = useState(image);

  useEffect(() => {
    if (showDefault) {
      setimage({ uri: noImage });
    } else {
      if (error) {
        setimage(noImage);
      } else {
        setimage({ uri: uri });
      }
    }
  }, [showDefault, error]);

  if (!uri) {
    return <></>;
  }

  return (
    <Image
      style={style}
      source={image}
      onLoadEnd={() => setshowDefault(false)}
      onError={() => seterror(true)}
    />
  );
}

export default Imagen;
