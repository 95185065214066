import React from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  ActivityIndicator,
  Image,
} from "react-native";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "react-query";
import { useAtom } from "jotai";
import { FontAwesome } from "@expo/vector-icons";
import * as Notifications from "expo-notifications";

import { esAndroid, esWeb } from "../utils/plataforma";
import { enviarExpoPushToken, login } from "../services/apiClient";
import { userAtom } from "../store/store";
import { useState } from "react";
import tw from "twrnc";
import { Colores } from "../constants/colores";

async function registerForPushNotificationsAsync() {
  let token;
  if (!esWeb()) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      alert("Debe proporcionar permiso para activar las notificaciones!");
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;

    if (esAndroid()) {
      Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "#FF231F7C",
      });
    }
    console.log(token);
  }

  return token;
}

function LoginForm() {
  const [_, setUser] = useAtom(userAtom);
  const [error, setError] = useState();
  const [ocultarPassword, setOcultarPassword] = useState(true);
  //react-query
  const sendPushToken = useMutation(enviarExpoPushToken, {
    onSuccess: async (response) => {
      console.log(response);
    },
    onError: async (error) => {
      console.log(error);
    },
  });

  const loginMutate = useMutation(login, {
    onSuccess: async (response) => {
      const token = response.data?.access_token;
      if (token) {
        if (!esWeb()){
          registerForPushNotificationsAsync().then((expoPushToken) => {
            console.log(`push token ${expoPushToken}`);
            sendPushToken.mutate({ expoPushToken });
          });
        }        
        //redirecciona a home automaticamente al cambiar el estado
        setUser(response.data);
      }
      // Invalidate and refetch
    },
    onError: async (error) => {
      if (error?.response?.data) {
        setError(error.response.data.message);
      }else{
        setError(error.message);        
      } 
    },
  });

  //react-hook-form
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    setError();

    const form = {
      email: data.email.toLowerCase().trim(),
      password: data.password,
    };

    loginMutate.mutate(form);
  };

  return (
    <View style={tw`p-5`}>
      <View style={tw`mb-3`}>
        <Text style={tw`mb-2 text-white text-lg`}>Email</Text>
        <Controller
          control={control}
          rules={{
            required: "Ingrese una dirección",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-/s]+\.[A-Z]{2,}$/i,
              message: "Ingrese una dirección válida",
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={[
                tw`w-85 pl-5 py-2 text-lg`,
                { borderRadius: 15, backgroundColor: "#FFF", color: "#1F0069" },
              ]}
              onBlur={onBlur}
              onChangeText={onChange}
              placeholder="Email"
              value={value}
              keyboardType={"email-address"}
              autoCapitalize="none"
            />
          )}
          name="email"
        />
      </View>
      {errors?.email && (
        <Text style={tw`text-red-600`}>{errors.email?.message}</Text>
      )}

      <View style={tw`my-3`}>
        <Text style={tw`mb-2 text-white text-lg`}>Contraseña</Text>
        <View style={tw`justify-center`}>
          <Controller
            control={control}
            rules={{
              maxLength: 100,
              required: "Ingrese la contraseña",
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                style={[
                  tw`w-85 pl-5 py-2 text-lg`,
                  {
                    borderRadius: 15,
                    backgroundColor: "#FFF",
                    color: "#1F0069",
                  },
                ]}
                onBlur={onBlur}
                onChangeText={onChange}
                placeholder="Contraseña"
                secureTextEntry={ocultarPassword}
                value={value}
                autoCapitalize="none"
              />
            )}
            name="password"
          />
          <TouchableOpacity
            onPress={() => setOcultarPassword(!ocultarPassword)}
            style={[tw`absolute right-3`]}
          >
            {ocultarPassword ? (
              <Image
                source={require("../assets/Login/eye.png")}
                style={tw`w-5 h-5 mr-1`}
              />
            ) : (
              <FontAwesome
                name="eye"
                size={24}
                color="black"
                style={{ color: Colores.primary }}
              />
            )}
          </TouchableOpacity>
        </View>
      </View>

      {errors?.password && (
        <Text style={tw`text-red-600`}>{errors.password?.message}</Text>
      )}

      {loginMutate.isLoading ? (
        <ActivityIndicator
          size="small"
          color="#FFF"
          style={tw`mt-5 px-7 py-3`}
        />
      ) : (
        <View style={tw`items-center justify-center`}>
          <TouchableOpacity
            onPress={handleSubmit(onSubmit)}
            style={[
              tw`mt-5 px-15 py-2 text-white text-center text-lg`,
              { backgroundColor: "#5753DD", borderRadius: 15 },
            ]}
          >
            <Text style={tw`text-white text-lg`}>Iniciar Sesión</Text>
          </TouchableOpacity>
        </View>
      )}

      {error && (
        <View
          style={tw`my-3 bg-red-700 rounded-lg py-5 px-6 flex justify-center items-center w-full`}
        >
          <Text style={tw`text-base text-white`}>{error}</Text>
        </View>
      )}
    </View>
  );
}

export default LoginForm;
