import React, { useState } from "react";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import { Controller, useForm } from "react-hook-form";
import {
  ActivityIndicator,
  TextInput,
  Text,
  Button,
  Pressable,
  StyleSheet,
  View,
  TouchableOpacity,
  Image,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import { useMutation } from "react-query";
import * as DocumentPicker from "expo-document-picker";
import { Entypo } from "@expo/vector-icons";
import { AntDesign } from "@expo/vector-icons";
import { Ionicons } from '@expo/vector-icons';
import tw from 'twrnc';

import MensajeError from "../components/MensajeError";
import { awesomeAlertAtom } from "../store/store";
import { nuevaSolicitudPlanDeCarrera } from "../services/apiClient";
import SelectEmpresa from "../components/SelectEmpresa";
import ResultadoModal from "../components/ResultadoModal";
import { Colores } from "../constants/colores";

function SolicitudCarreraForm() {
  const [seleccion, setseleccion] = useState({});
  const [pdf, setPDF] = useState();
  const [awesomeAlert, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);
  const {width} = useWindowDimensions()

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      motivo: '',
      mejora: ''
    }
  });

  const resetForm = () => {
    setPDF();
    setseleccion({});
    reset();
  }

  const nuevaSolicitudMutate = useMutation(nuevaSolicitudPlanDeCarrera, {
    onSuccess: async (response) => {
      if (response.data.ok) {
        setawesomeAlert({
          show: true,
          showProgress: false,
          title: "Exito!",
          title: null,
          message: null,
          showCancelButton: false,
          showConfirmButton: true,
          confirmText: "Aceptar",
          confirmButtonColor: "green",
          customView: (
            <ResultadoModal success={true} message="Tu plan se envio correctamente" />
          ),
          onConfirmPressed: () => {
            resetAwesomeAlert();
            resetForm();
          },
          onDismiss: () => {
            resetAwesomeAlert();
            resetForm();
          },
        });
      }
    },
    onError: async (error) => {
      let mensajeErrores = "";
      if(error.response.status === 403){
          mensajeErrores = error.response?.data?.message;
      }else{
        for (const [key, value] of Object.entries(error.response.data.errors)) {
          mensajeErrores = mensajeErrores + " \n" + value;
        }
      }
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Error",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal
            success={false}
            message={mensajeErrores}
          />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
    },
  });

  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: ["application/pdf"],
    });
    setPDF(result);
  };

  const formData = new FormData();
  const onSubmit = (data) => {
    adjuntarEmpresaDptoPuestoSeleccionado();
    if (pdf) {
      formData.append("pdf", pdf?.file);
    }

    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        if (data[key]) {
          formData.append(key, data[key]);
        }
      }
    }
    nuevaSolicitudMutate.mutate(formData);
  };

  const adjuntarEmpresaDptoPuestoSeleccionado = () => {
    Object.entries(seleccion).forEach(([key, value]) => {
      formData.append(key, value);
    });
  };

  return (
    <>
      <ScrollView>
        {width >= 768 && (
          <View style={[tw`w-full h-20 justify-center mt-10 bg-white`,{borderTopLeftRadius:20, borderTopRightRadius:20}]}>
              <Text style={tw`ml-10 text-lg font-semibold text-gray-600`}>Plan de carrera</Text>
          </View>
        )}
        <View style={tw`h-50 w-full justify-end`}>
            <Image source={require('../assets/plandecarrera_fondo.png')} style={[tw`h-full w-full absolute`, StyleSheet.absoluteFill]} />
            <Text style={tw`text-white text-xl z-50 font-bold px-5 mb-5`}>Animate y desarrollá tu carrera con nosotros, contanos en que área te postularías.</Text>
        </View>
        <View style={tw`p-3 bg-white`}>

          <Text style={[tw`text-lg mb-1`,{color:'#6c7581'}]}>Indique la empresa</Text>
          <SelectEmpresa seleccion={setseleccion} />

          <Text style={tw`mt-3 text-lg`}>¿Cuál es el motivo de tu interés en el puesto o área?</Text>
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "Indique su motivo" },
              minLength: {
                value: 10,
                message: "Debe ingresar al menos 10 caracteres",
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                style={[tw` mt-2 h-50 w-full px-3 py-2 text-base border border-gray-300 text-gray-700`,{borderRadius:16}]}
                onBlur={onBlur}
                onChangeText={onChange}
                placeholder="Motivo"
                value={value}
                multiline={true}
              />
            )}
            name="motivo"
          />
          {errors?.motivo && <MensajeError message={errors.motivo?.message} />}

          <Text style={tw`mt-3 text-lg`}>
            ¿Qué propuesta de mejora aplicarías en el área o puesto de interés?
          </Text>
          <Controller
            control={control}
            rules={{
              required: { value: true, message: "Indique su propuesta" },
              minLength: {
                value: 10,
                message: "Debe ingresar al menos 10 caracteres",
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                style={[tw` mt-2 h-50 w-full px-3 py-2 text-base border border-gray-300 text-gray-700`,{borderRadius:16}]}
                onBlur={onBlur}
                onChangeText={onChange}
                placeholder="Propuesta"
                value={value}
                multiline={true}
              />
            )}
            name="mejora"
          />
          {errors?.mejora && <MensajeError message={errors.mejora?.message} />}

          <Text style={tw`mt-3 text-lg`}>Adjunta tu cv</Text>

          <Pressable onPress={pickDocument} foreground={true} style={tw`items-center justify-center`}>
          {pdf ? (
            <View style={[tw`mt-3 px-6 py-2.5 uppercase rounded shadow-md mb-3 flex flex-row justify-center items-center`,{borderRadius:15, backgroundColor:Colores.primaryMedium}]}>
              <AntDesign name="pdffile1" size={24} color="white" />
              <Text style={tw`ml-2 text-white`}>{pdf && pdf.file.name}</Text>
            </View>
          ) : (
            <View style={[tw`mt-3 px-6 py-2.5 rounded shadow-md mb-3 flex flex-row justify-center items-center`,{borderRadius:15, backgroundColor:Colores.primaryMedium}]}>
              <Ionicons name="document-attach-outline" size={24} color="white" />
              <Text style={tw`text-white text-center ml-2`}>
                Seleccionar archivo (PDF)
              </Text>
            </View>
          )}
          </Pressable>

          {nuevaSolicitudMutate.isLoading ? (
            <ActivityIndicator size="small" color={Colores.primary} />
          ) : (
            <View style={tw`justify-center items-center mt-3 mb-5`}>
              <TouchableOpacity style={[tw`mt-1 px-23 py-2 shadow-md mb-3`,{backgroundColor:Colores.primary, borderRadius:16}]} onPress={handleSubmit(onSubmit)}>
                <Text style={tw`text-white text-center text-lg`}>Enviar</Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </ScrollView>

      <AwesomeAlert
        show={awesomeAlert.show}
        showProgress={awesomeAlert.showProgress}
        title={awesomeAlert.title}
        message={awesomeAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={awesomeAlert.showCancelButton}
        showConfirmButton={awesomeAlert.showConfirmButton}
        cancelText="Cancelar"
        confirmText={awesomeAlert.confirmText}
        confirmButtonColor={awesomeAlert.confirmButtonColor}
        customView={awesomeAlert.customView}
        onDismiss={awesomeAlert.onDismiss}
        onConfirmPressed={awesomeAlert.onConfirmPressed}
      />
    </>
  );
}

export default SolicitudCarreraForm;
