import { View, Text } from "react-native";
import React from "react";
import tw from "twrnc";

export default function DescripcionItem({texto}) {
  return (
    <View style={tw`flex flex-col justify-center gap-3`}>
      <Text style={tw`text-xl font-semibold`}>Descripción</Text>
      <Text style={tw`text-gray-700`}>{texto}</Text>
    </View>
  );
}
