import React from "react";
import { Text, View } from "react-native";

import { PageContainer } from "../../components/PageContainer";
import { PlatoForm } from "../../forms/PlatoForm";

export default function CrearPlatoScreen() {
  return (
    <PageContainer paddingMd>
      <View style={{ flex: 1 }}>
        <PlatoForm />
      </View>
    </PageContainer>
  );
}
