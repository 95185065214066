import React from "react";
import { Dimensions } from "react-native";
import WebView from "react-native-webview";

import { esWeb } from "../utils/plataforma";

export const PdfViewer = ({ pdfUrl }) => {
  if (esWeb()) {
    return (
      <iframe
        src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${pdfUrl}#zoom=page-height&pagemode=none`}
        height={"100%"}
        width={"100%"}
      />
    );
  }
  return (
    <WebView
      source={{
        uri: `https://mozilla.github.io/pdf.js/web/viewer.html?file=${pdfUrl}#zoom=page-height&pagemode=none`,
      }}
      style = {{marginTop: 20, flex: 1, width: Dimensions.get('window').width * 0.9 , height: "100%"}}
    />
  );
};
