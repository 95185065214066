import React from "react";
import { Image, Text, View } from "react-native";
import tw from 'twrnc';

function Beneficio({ datos }) {
  const { empresa , descuento, foto } = datos;

  return (
        <View style={[tw`items-center border border-gray-300 py-3 px-5 m-2 shadow-md bg-white`,{borderRadius:16}]}>
          <Image
            style={[tw`rounded-full my-2 shadow-lg h-30 w-30`]}
            source={{ uri: foto }}
          />
          <Text style={tw`text-lg font-semibold`}>{empresa}</Text>
          <Text style={[tw`font-semibold px-3 text-lg rounded-lg mt-2`,{backgroundColor:'#02B879', color:'#fff', borderRadius:50}]}>{descuento}% OFF</Text>
        </View>
  );
}

export default Beneficio;
