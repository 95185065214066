import {
  Image,
  Modal,
  Text,
  TextInput,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
import React, { useState } from "react";
import tw from "twrnc";
import { FontAwesome } from "@expo/vector-icons";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { awesomeAlertAtom, userAtom } from "../../../store/store";
import { actualizarPassword } from "../../../services/apiClient";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import { Colores } from "../../../constants/colores";
import { BarPasswordStrengthDisplay } from "react-native-password-strength-meter";
import ResultadoModal from "../../ResultadoModal";

const ModalCambiarPassword = ({ modalVisible, setModalVisible }) => {
  const [password, setPassowrd] = useState("");
  const onChange = (password) => setPassowrd(password);
  const [user, setUser] = useAtom(userAtom);
  const [passwordPrimera, setPasswordPrimera] = useState("");
  const [passwordSegunda, setPasswordSegunda] = useState("");
  const [awesomeAlert, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);
  const { width } = useWindowDimensions();
  const resetAlert = useResetAtom(awesomeAlertAtom);
  const [passwordCambiada, setPasswordCambiada] = useState(false);
  const [ocultarPassword, setOcultarPassword] = useState(true);
  const [ocultarRepetirPassword, setOcultarRepetirPassword] = useState(true);
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
  const [error, setError] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  const levels = [
    {
      label: "Extremadamente Debil",
      labelColor: "#ff3e00",
      activeBarColor: "#ff3e00",
    },
    {
      label: "Muy Debil",
      labelColor: "#ff5400",
      activeBarColor: "#ff5400",
    },
    {
      label: "Debil",
      labelColor: "#ff6900",
      activeBarColor: "#ff6900",
    },
    {
      label: "Promedio",
      labelColor: "#f3d331",
      activeBarColor: "#f3d331",
    },
    {
      label: "Bueno",
      labelColor: "#f2cf1f",
      activeBarColor: "#f2cf1f",
    },
    {
      label: "Fuerte",
      labelColor: "#14eb6e",
      activeBarColor: "#14eb6e",
    },
    {
      label: "Muy Fuerte",
      labelColor: "#0af56d",
      activeBarColor: "#0af56d",
    },
    {
      label: "Increiblemente Fuerte",
      labelColor: "#00ff6b",
      activeBarColor: "#00ff6b",
    },
  ];

  const resetForm = () => {
    setPasswordPrimera("");
    setPasswordSegunda("");
    resetAlert();
  };

  const actualizarPasswordMutate = useMutation(actualizarPassword, {
    onSuccess: async (response) => {
      //mostral mostrar modal
      setPasswordCambiada(true);
      setError("");
      resetForm();
    },
    onError: async (error) => {
      console.log(error.response.data.errors);
      let mensajeErrores = "";
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        mensajeErrores = mensajeErrores + " \n" + value;
      }
      //TODO: mostrar errores en campos
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Error",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: <ResultadoModal success={false} message={mensajeErrores} />,
        onConfirmPressed: () => {
          resetForm();
        },
        onDismiss: () => {
          resetForm();
        },
      });
    },
  });

  const cambiarContra = () => {
    const sonValidas = paswordEsValido(passwordPrimera, passwordSegunda);
    if (sonValidas) {
      actualizarPasswordMutate.mutate({
        userId: user.id,
        formData: {
          password: passwordPrimera,
          password_confirmation: passwordSegunda,
        },
      });
    }
  };

  const paswordEsValido = (passwordPrimera, passwordSegunda) => {
    if (
      !passwordPrimera.length ||
      !passwordSegunda.length ||
      passwordPrimera.length < 8 ||
      passwordSegunda.length < 8
    ) {
      setError("La contraseña debe tener al menos 8 caracteres");
      return false;
    }

    if (passwordPrimera !== passwordSegunda) {
      setError("Las contraseñas son distintas");
      return false;
    }

    if (!regex.test(passwordPrimera)) {
      setError("Debe tener minimo una mayuscula, una minuscula y un número");
      return false;
    }
    return true;
  };

  return (
    <Modal animationType="fade" transparent={true} visible={modalVisible}>
      <View
        style={[
          tw`flex-1 items-center justify-center`,
          { backgroundColor: "#7D7D7D50" },
        ]}
        onPress={() => setModalVisible(false)}
      >
        <View style={tw`bg-white px-10 pt-10 pb-5 rounded-xl`}>
          <View
            style={[tw`bg-white p-5`, { borderRadius: width >= 768 ? 20 : 0 }]}
          >
            {width >= 768 && !passwordCambiada ? (
              <View style={tw`ml-5 my-5 px-10`}>
                <Text style={tw`text-lg font-semibold mt-5`}>Contraseña</Text>
              </View>
            ) : null}

            <View style={tw`items-center`}>
              <Image
                source={require("../../../assets/Perfil/key.png")}
                style={tw`w-50 h-50 mr-1`}
              />
              {!passwordCambiada ? (
                <>
                  <Text
                    style={[
                      tw`text-lg text-center mt-7`,
                      { color: Colores.primary },
                    ]}
                  >
                    Cambiar contraseña
                  </Text>
                  <Text style={tw`text-gray-500 text-center mt-5`}>
                    La contraseña debe tener al menos ocho caracteres, una
                    mayúscula y un número
                  </Text>
                </>
              ) : (
                <>
                  <Text
                    style={[
                      tw`text-lg text-center mt-7`,
                      { color: Colores.primary },
                    ]}
                  >
                    Se cambio la contraseña correctamente
                  </Text>
                  <Text style={tw`text-gray-500 text-center mt-5`}>
                    Vamos a cerrar la sesion y tendras que iniciar con tu
                    contraseña nueva
                  </Text>
                </>
              )}
            </View>

            <View style={width > 768 ? tw`mx-10` : tw`mx-3`}>
              {!passwordCambiada ? (
                <>
                  <View style={tw`justify-center`}>
                    <TextInput
                      placeholder="Contraseña"
                      style={[
                        tw` w-full px-3 py-2 text-base font-normal text-gray-700 bg-white mt-7`,
                        { borderBottomWidth: 1, borderColor: Colores.gray },
                      ]}
                      secureTextEntry={ocultarPassword}
                      onChangeText={(text) => setPasswordPrimera(text)}
                    />
                    <TouchableOpacity
                      onPress={() => setOcultarPassword(!ocultarPassword)}
                      style={[tw`absolute right-3 top-9`]}
                    >
                      {ocultarPassword ? (
                        <Image
                          source={require("../../../assets/Login/eye.png")}
                          style={tw`w-5 h-5 mr-1`}
                        />
                      ) : (
                        <FontAwesome
                          name="eye"
                          size={24}
                          color="black"
                          style={{ color: Colores.primary }}
                        />
                      )}
                    </TouchableOpacity>
                  </View>

                  <View style={tw`justify-center`}>
                    <TextInput
                      placeholder="Repetir Contraseña"
                      style={[
                        tw` w-full px-3 py-2 text-base font-normal text-gray-700 bg-white mt-7`,
                        { borderBottomWidth: 1, borderColor: Colores.gray },
                      ]}
                      secureTextEntry={ocultarRepetirPassword}
                      onChangeText={(text) => setPasswordSegunda(text)}
                    />

                    <TouchableOpacity
                      onPress={() =>
                        setOcultarRepetirPassword(!ocultarRepetirPassword)
                      }
                      style={[tw`absolute right-3 top-9`]}
                    >
                      {ocultarRepetirPassword ? (
                        <Image
                          source={require("../../../assets/Login/eye.png")}
                          style={tw`w-5 h-5 mr-1`}
                        />
                      ) : (
                        <FontAwesome
                          name="eye"
                          size={24}
                          color="black"
                          style={{ color: Colores.primary }}
                        />
                      )}
                    </TouchableOpacity>
                  </View>

                  <View
                    style={
                      width > 768
                        ? tw`mt-10 items-center`
                        : tw`mt-5 items-center`
                    }
                  >
                    <BarPasswordStrengthDisplay
                      password={passwordSegunda}
                      minLength={8}
                      levels={levels}
                      width={width > 758 ? width * 0.3 : width * 0.7}
                    />
                    {error.length ? (
                      <View style={tw`bg-red-600 rounded-md p-2 text-center`}>
                        <Text style={tw`font-bold text-white`}>
                          Error: {error}
                        </Text>
                      </View>
                    ) : null}
                  </View>
                </>
              ) : null}

              <View style={[tw`justify-center my-10 items-center`]}>
                <TouchableOpacity
                  style={[
                    tw`px-10 py-3 `,
                    { backgroundColor: Colores.primary, borderRadius: 16 },
                  ]}
                  onPress={
                    !passwordCambiada
                      ? () => cambiarContra()
                      : () => setModalVisible(false)
                  }
                >
                  <Text style={tw`text-white`}>
                    {!passwordCambiada ? "Cambiar Contraseña" : "Cerrar"}{" "}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ModalCambiarPassword;
