import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  TextInput,
  ActivityIndicator,
  TouchableOpacity,
  ScrollView,
  useWindowDimensions,
} from "react-native";

import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import moment from "moment";
import tw from "twrnc";
import MaskedInput from "react-input-mask";
import { TextInputMask } from "react-native-masked-text";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import ResultadoModal from "../components/ResultadoModal";
import MensajeError from "../components/MensajeError";
import {
  editarColaborador,
  nuevoColaborador,
  eliminarColaborador,
} from "../services/apiClient";
import { FontAwesome5 } from "@expo/vector-icons";
import EditarImagen from "../components/EditarImagen";
import { useNavigation } from "@react-navigation/native";
import { Colores } from "../constants/colores";
import { AntDesign } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";
import CustomMaskedInput from "../components/CustomMaskedInput";

function MiPerfilEditarForm({
  colaborador,
  setawesomeAlert,
  resetAwesomeAlert,
}) {
  const navigator = useNavigation();
  const [dataColaborador, setdataColaborador] = useState(colaborador);
  const [isLoading, setIsLoading] = useState(false);

  const colaboradorFechaDeNacimiento = moment(
    dataColaborador?.fechaNacimiento
  )._d;
  const [foto, setfoto] = useState();
  const [fechaNacimiento, setfechaNacimiento] = useState(
    colaboradorFechaDeNacimiento
  );
  const queryClient = useQueryClient();
  const { width } = useWindowDimensions();
  const [images, setImages] = useState([]);
  const [documentoCargado, setDocumentoCargado] = useState(false);
  const [direccion, setDireccion] = useState(colaborador.direccion);

  let valoresInicialesForm = {
    nombre: dataColaborador?.nombre,
    apellido: dataColaborador?.apellido,
    dni: dataColaborador?.dni,
    emailPersonal: dataColaborador?.emailPersonal,
    telPersonal: dataColaborador?.telPersonal,
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: valoresInicialesForm,
  });
  useEffect(() => {
    if (colaborador) {
      setdataColaborador(colaborador);
      setValue("nombre", colaborador?.nombre);
      setValue("apellido", colaborador?.apellido);
      setValue("dni", colaborador?.dni);
      setValue("direccion", colaborador?.direccion);
      setValue("emailCorporativo", colaborador?.emailCorporativo);
      setValue("telCorporativo", colaborador?.telCorporativo);
      setValue("emailPersonal", colaborador?.emailPersonal);
      setValue("telPersonal", colaborador?.telPersonal);
      const fecha = moment(dataColaborador?.fechaNacimiento)._d;
      setfechaNacimiento(fecha);
    }
  }, [colaborador]);

  const editarColaboradorMutate = useMutation(editarColaborador, {
    onSuccess: async (response) => {
      if (response.data.ok) {
        setawesomeAlert({
          show: true,
          showProgress: false,
          title: "Exito!",
          title: null,
          message: null,
          showCancelButton: false,
          showConfirmButton: true,
          confirmText: "Aceptar",
          confirmButtonColor: "green",
          customView: (
            <ResultadoModal success={true} message="Colaborador editado" />
          ),
          onConfirmPressed: () => {
            resetAwesomeAlert();
            navigator.navigate("Mi Perfil");
          },
          onDismiss: () => {
            resetAwesomeAlert();
            navigator.navigate("Mi Perfil");
          },
        });
        queryClient.invalidateQueries("colaboradores");
      }
    },
    onError: async (error) => {
      //TODO: mostrar errores en campos
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Error",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal
            success={false}
            message={error.response.data.message}
          />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
    },
  });

  const formData = new FormData();

  const onSubmit = async (data) => {
    // formData.append("unica", 1);

    if (foto) {
      formData.append("foto", foto?.file);
    }
    if (fechaNacimiento) {
      const fechaFormatoBD = moment(fechaNacimiento).format("yyyy-MM-DD");
      formData.append("fechaNacimiento", fechaFormatoBD);
    }

    //se cargan el resto de campos del formulario en formData
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        if (data[key] && (data[key] !== "")) {
          formData.append(key, data[key]);
        }
      }
    }

    editarColaboradorMutate.mutate({
      colaboradorId: colaborador?.id,
      formData,
    });
  };

  return (
    <>
      <ScrollView>
        <View style={[tw`bg-white`, { borderRadius: width >= 768 ? 20 : 0 }]}>
          {width >= 768 ? (
            <View style={tw`ml-5 my-5 px-10`}>
              <TouchableOpacity onPress={() => navigator.navigate("Mi Perfil")}>
                <Ionicons
                  name="arrow-back-sharp"
                  size={24}
                  color={Colores.primary}
                />
              </TouchableOpacity>
              <Text style={tw`text-lg font-semibold mt-5`}>
                Editar mi perfil
              </Text>
            </View>
          ) : null}
          {width < 758 ? (
            <View
              style={[
                tw`flex-row p-4`,
                {
                  backgroundColor: Colores.primary,
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                },
              ]}
            >
              <View style={tw`flex-1`}>
                <TouchableOpacity
                  onPress={() => navigator.navigate("Mi Perfil")}
                >
                  <AntDesign name="arrowleft" size={24} color="#FFF" />
                </TouchableOpacity>
              </View>
              <View style={tw`flex-3`}>
                <Text style={tw`text-center text-lg text-white`}>
                  Editar mi perfil
                </Text>
              </View>
              <View style={tw`flex-1`}></View>
            </View>
          ) : null}

          <View
            style={
              width > 758
                ? tw`flex-row px-10 mb-10 mt-5`
                : tw`flex-row px-7 mb-10 mt-5`
            }
          >
            <View style={tw`items-center justify-center`}>
              <EditarImagen formData={formData} modelo={colaborador} />

              <TouchableOpacity
                style={[
                  tw`p-2 justify-center items-center absolute bottom-4 right-4 rounded-full`,
                  { backgroundColor: Colores.primary }
                ]}
              >
                <FontAwesome5 name="pen" size={11} color={"#FFF"} />
              </TouchableOpacity>
            </View>

            <View style={tw`p-3 w-9/12`}>
              <Text
                style={tw`text-lg font-semibold`}
              >{`${dataColaborador?.nombre} ${dataColaborador?.apellido}`}</Text>
              <Text style={tw``}>
                <FontAwesome5
                  name="briefcase"
                  size={11}
                  color="black"
                  style={tw`mr-2`}
                />
                {dataColaborador?.puesto}
              </Text>
              <Text>
                <FontAwesome5
                  name="building"
                  size={11}
                  color="black"
                  style={tw`mr-2`}
                />
                {dataColaborador?.empresa}
              </Text>
            </View>
          </View>

          <View
            style={
              { borderBottomWidth: 4, borderBottomColor: Colores.primaryLight }
            }
          />

          <View style={width > 768 ? tw`px-10 z-50` : tw`px-5 z-50`}>
            <Text style={[tw`text-lg mb-2 mt-4`, { color: Colores.primary }]}>
              Datos Personales
            </Text>
            <View
              style={
                {
                  borderBottomWidth: 1,
                  borderBottomColor: Colores.primaryLight,
                }}
            />
            <Text style={[tw`text-sm m-1 mt-4`, { color: Colores.primaryMedium }]}>Nombre</Text>
            <Controller
              control={control}
              rules={{
                required: "Ingrese el nombre",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[
                    tw` w-full px-3 py-2 text-gray-700 border`,
                    { borderRadius: 50, backgroundColor: Colores.disabledInput, color:Colores.disabledText ,borderColor: Colores.disabledBorder },
                  ]}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  placeholder="Nombre"
                  value={value}
                  placeholderTextColor={Colores.primary}
                  disabled
                />
              )}
              name="nombre"
            />
            {errors?.nombre && (
              <MensajeError message={errors.nombre?.message} />
            )}

            <Text style={[tw`text-sm m-1 mt-2`, { color: Colores.primaryMedium }]}>Apellido</Text>
            <Controller
              control={control}
              rules={{
                required: "Ingrese el apellido",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[
                    tw` w-full px-3 py-2 text-gray-700 border`,
                    { borderRadius: 50, backgroundColor: Colores.disabledInput, color:Colores.disabledText ,borderColor: Colores.disabledBorder },
                  ]}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  multiline={true}
                  placeholder="Apellido"
                  value={value}
                  placeholderTextColor={Colores.primary}
                  disabled
                />
              )}
              name="apellido"
            />
            {errors?.apellido && (
              <MensajeError message={errors.apellido?.message} />
            )}
            <Text style={[tw`text-sm m-1 mt-2`, { color: Colores.primaryMedium }]}>DNI</Text>
            <Controller
              control={control}
              rules={{
                required: "Ingrese el dni",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[
                    tw` w-full px-3 py-2 text-gray-700 border`,
                    { borderRadius: 50, backgroundColor: Colores.disabledInput, color:Colores.disabledText ,borderColor: Colores.disabledBorder },
                  ]}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  multiline={true}
                  placeholder="DNI"
                  value={value}
                  placeholderTextColor={Colores.primary}
                  disabled
                />
              )}
              name="dni"
            />
            {errors?.dni && <MensajeError message={errors.dni?.message} />}

            <Text style={[tw`text-sm m-1 mt-2`, { color: Colores.primaryMedium }]}>Dirección</Text>
            <Controller
              control={control}
              rules={{
                required: false,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[
                    tw` w-full px-3 py-2 text-gray-700 border`,
                    { borderRadius: 50, borderColor: Colores.primary },
                  ]}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  multiline={true}
                  placeholder="Direccion"
                  value={value}
                  placeholderTextColor={Colores.primary}
                />
              )}
              name="direccion"
            />
            {errors?.direccion && (
              <MensajeError message={errors.direccion?.message} />
            )}

            <View style={tw`mt-5 z-10 flex-row`}>
              <View style={tw`mr-3`}>
                <Text style={{ color: Colores.primary }}>
                  Fecha de nacimiento
                </Text>
              </View>
              <View style={tw`mr-2`}></View>
              <View>
                <MaterialCommunityIcons
                  name="calendar-edit"
                  size={24}
                  color={Colores.primaryMedium}
                />
              </View>
            </View>
          </View>

          <View
            style={[
              tw`mt-5`,
              { borderBottomWidth: 4, borderBottomColor: Colores.primaryLight },
            ]}
          />

          <View style={width > 768 ? tw`px-10` : tw`px-5`}>
            <Text style={[tw`text-lg mb-2 mt-4`, { color: Colores.primary }]}>
              Contacto
            </Text>
            <View
              style={
                {
                  borderBottomWidth: 1,
                  borderBottomColor: Colores.primaryLight,
                }}
            />

            <Text style={[tw`text-sm m-1 mt-4`, { color: Colores.primaryMedium }]}>Email corporativo</Text>
            <Controller
              control={control}
              rules={
                {
                  // required: "Ingrese un email corporativo",
                }
              }
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[
                    tw` w-full px-3 py-2 text-gray-700 border`,
                    { borderRadius: 50, borderColor: Colores.primary },
                  ]}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  multiline={true}
                  placeholder="Email Corporativo"
                  placeholderTextColor={Colores.primary}
                  value={value}
                />
              )}
              name="emailCorporativo"
            />
            {errors?.emailCorporativo && (
              <MensajeError message={errors.emailCorporativo?.message} />
            )}
            {/* andorid
                     <TextInputMask
                  type={'custom'}
                  options={
                    {
                      mask:"(999) 9 999999"
                    }}
                  
                  value={field.value}
                  onChangeText={field.onChange}
                  style={[tw` mt-4 w-full px-3 py-2 text-gray-700 border`,{borderRadius:50, borderColor:Colores.primary}]}
                >
                  */}

            <Text style={[tw`text-sm m-1 mt-2`, { color: Colores.primaryMedium }]}>Tel. corporativo</Text>
            <Controller
              name="telCorporativo"
              control={control}
              defaultValue=""
              rules={{
                required: false,
              }}
              render={({ field }) => (
                <TextInputMask
                  type={"custom"}
                  options={{
                    mask: "(999) 9 999999",
                  }}
                  value={field.value}
                  onChangeText={field.onChange}
                  style={[
                    tw` w-full px-3 py-2 text-gray-700 border`,
                    { borderRadius: 50, borderColor: Colores.primary }
                  ]}
                />
              )}
            />

            <View style={tw`items-center justify-center`}>
              <View
                style={[
                  tw`mt-4 px-20`,
                  {
                    borderBottomWidth: 1,
                    borderBottomColor: Colores.primaryLight,
                  },
                ]}
              />
            </View>

            <Text style={[tw`text-sm m-1 mt-2`, { color: Colores.primaryMedium }]}>Email personal</Text>
            <Controller
              control={control}
              rules={{
                required: "Ingrese un email personal",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[
                    tw` w-full px-3 py-2 text-gray-700 border`,
                    { borderRadius: 50, borderColor: Colores.primary }
                  ]}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  multiline={true}
                  placeholder="Email personal"
                  value={value}
                  placeholderTextColor={Colores.primary}
                />
              )}
              name="emailPersonal"
            />
            {errors?.emailPersonal && (
              <MensajeError message={errors.emailPersonal?.message} />
            )}

            <Text style={[tw`text-sm m-1 mt-2`, { color: Colores.primaryMedium }]}>Tel. Personal</Text>
            <Controller
              control={control}
              rules={{
                required: false,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[
                    tw` w-full px-3 py-2 text-gray-700 border`,
                    { borderRadius: 50, borderColor: Colores.primary }
                  ]}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  multiline={true}
                  placeholder="Tel personal"
                  value={value}
                  placeholderTextColor={Colores.primary}
                />
              )}
              name="telPersonal"
            />
            {errors?.telPersonal && (
              <MensajeError message={errors.telPersonal?.message} />
            )}
          </View>

          {editarColaboradorMutate.isLoading ? (
            <ActivityIndicator
              size="small"
              color={Colores.primary}
              style={tw`my-5`}
            />
          ) : (
            <View style={tw`items-center`}>
              <TouchableOpacity
                style={[
                  tw`mt-10 px-20 py-2.5 shadow-md`,
                  { backgroundColor: Colores.primary, borderRadius: 16 },
                ]}
                onPress={handleSubmit(onSubmit)}
              >
                <Text style={tw`text-white text-center`}>Guardar</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={tw`mt-5 mb-20 px-20 py-2.5`}
                onPress={() => navigator.navigate("Mi Perfil")}
              >
                <Text style={[tw`text-center`, { color: Colores.primary }]}>
                  Cancelar
                </Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </ScrollView>
    </>
  );
}

export default MiPerfilEditarForm;
