import React from "react";
import { Text } from "react-native";
import tw from 'twrnc';

import { PageContainer } from "../../components/PageContainer";
import { AvisoForm } from "../../forms/AvisoForm";

export default function NuevoAvisoScreen(props) {
  return (
    <PageContainer style={tw`bg-white`}>
      <AvisoForm {...props} />
    </PageContainer>
  );
}
