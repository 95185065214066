import React, { useEffect, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import tw from "twrnc";
import { Colores } from "../../constants/colores";
import { useAtom } from "jotai";
import { userAtom } from "../../store/store";
import { useQuery, useQueryClient } from "react-query";
import { Loader } from "../Loader";
import {
  aceptarEntregaItem,
  rechazarEntregaItem,
} from "../../services/apiClient";

const ConfirmarRecepcionItem = ({ item }) => {
  const queryClient = useQueryClient();
  const [resultadoModal, setResultadoModal] = useState(false);
  const {
    data: respuestaAceptar,
    isLoading,
    refetch: aceptar,
  } = useQuery("aceptarItem", () => aceptarEntregaItem(item?.id), {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: respuestaRechazar,
    isLoading: isLoadingRechazar,
    refetch: rechazar,
  } = useQuery("rechazarItem", () => rechazarEntregaItem(item?.id), {
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(()=>{
    setResultadoModal(false);
  },[item]);

  useEffect(() => {
    if (respuestaAceptar?.data) {
      setResultadoModal(respuestaAceptar.data?.message);
      queryClient.invalidateQueries("MisItems");
    }
  }, [respuestaAceptar]);

  useEffect(() => {
    if (respuestaRechazar?.data) {
      setResultadoModal(respuestaRechazar.data?.message);
      queryClient.invalidateQueries("MisItems");
    }
  }, [respuestaRechazar]);

  const [user] = useAtom(userAtom);
  const esDestinatario = user.id == item?.asignado_id;
  const indicoConformidad = item?.conformeRecepcion !== null;
  const requiereConfirmacion = item?.requiereConformidadRecepcion
    ? true
    : false;

    console.log("item", item)
  if (!requiereConfirmacion || indicoConformidad ) return null;
  if (!esDestinatario) return null;

  if (isLoading || isLoadingRechazar) {
    return <Loader />;
  }

  if (resultadoModal)
    return (
      <View
        style={tw`mt-5 w-1/2 bg-blue-100 p-4 rounded-lg border border-blue-300 self-center`}
      >
        <Text style={tw`font-semibold text-center text-blue-300`}>{resultadoModal}</Text>
      </View>
    );

  return (
    <View style={tw`my-2 px-2 py-4 w-full flex flex-row justify-end gap-4`}>
      <TouchableOpacity
        onPress={() => rechazar()}
        style={tw`w-1/4 bg-white px-5 py-1 rounded-lg shadow-md border border-[${Colores.primary}]`}
      >
        <Text style={tw`text-center text-[${Colores.primary}] font-semibold`}>
          Rechazar
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => aceptar()}
        style={[
          tw`w-1/4 px-5 py-1 rounded-lg shadow-md`,
          { backgroundColor: Colores.primary },
        ]}
      >
        <Text style={tw`text-center text-white font-semibold`}>Aceptar</Text>
      </TouchableOpacity>
    </View>
  );
};

export default ConfirmarRecepcionItem;
