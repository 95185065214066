import {
  View,
  Text,
  TouchableOpacity,
  TextInput,
  FlatList,
} from "react-native";
import React, { useState } from "react";
import tw from "twrnc";
import { AntDesign } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import moment from "moment";
import { Colores } from "../../../constants/colores";
import { getDescargaRecibo } from "../../../services/apiClient";
import { useMutation, useQueryClient } from "react-query";

const TablaRecibos = ({
  recibos,
  setMostrarCargarRecibos,
  mostrarCargarRecibos,
  alertaEliminarRecibo,
}) => {
  const [buscador, setBuscador] = useState();
  const [filtro, setFiltro] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [reciboSeleccionadoId, setReciboSeleccionadoId] = useState(null);

  const queryClient = useQueryClient();

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = recibos.filter((item) => {
        const itemData = item.colaborador
          ? `${item.colaborador.nombre.toUpperCase()} ${item.colaborador.apellido.toUpperCase()}`
          : "".toLocaleUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setFilteredData(newData);
    } else {
      setFilteredData(recibos);
    }
  };

  const confirmar = (reciboId) => {
    alertaEliminarRecibo(reciboId);
  };

  const descargarRecibo = useMutation(getDescargaRecibo, {
    onSuccess: async (response) => {
      // console.log(response.data);
    },
    onError: async (error) => {
      // console.log(error.response.data.message);
    },
    onSettled: (data) => {},
  });

  /* const deleteRecibo = useMutation(eliminarRecibo, {
    onSuccess: async (response) => {
      queryClient.invalidateQueries("recibos");
    },
    onError: async (error) => {
      // console.log(error.response.data.message);
    },
    onSettled: (data) => {},
  }); */

  return (
    <View>
      <View>
        <Text style={tw`text-lg font-semibold`}>Recibos de Sueldo</Text>

        <View style={tw`flex-row mt-5`}>
          <View style={tw`flex-1 items-end`}>
            <TouchableOpacity
              onPress={() => setMostrarCargarRecibos(!mostrarCargarRecibos)}
              style={[
                tw`border border-gray-200 flex-row py-3`,
                { borderRadius: 10 },
              ]}
            >
              <View
                style={[
                  tw`justify-center pl-7 pr-5 py-1`,
                  { borderLeftWidth: 2, borderColor: "blue" },
                ]}
              >
                <Text style={tw`text-gray-700 text-center`}>
                  Carga de recibos
                </Text>
              </View>
              <View style={tw`justify-center`}>
                <MaterialCommunityIcons
                  name="file-plus-outline"
                  size={24}
                  color="gray"
                  style={tw`mr-5`}
                />
              </View>
            </TouchableOpacity>
          </View>

          <View style={tw`flex-1`}></View>
        </View>
      </View>

      <View style={tw`mt-10`}>
        <Text style={tw`text-lg font-semibold`}>Estado de envios</Text>

        <View style={tw`justify-center mt-5`}>
          <TextInput
            style={[
              tw` shadow-md w-full px-3 py-2.5 text-gray-700 border border-solid border-gray-300`,
              { borderRadius: 23 },
            ]}
            placeholder="Buscar colaborador"
            onChangeText={(text) => {
              searchFilterFunction(text);
              setFiltro(text);
            }}
            placeholderTextColor={"#7D7D7D"}
          />
          <AntDesign
            name="search1"
            size={20}
            color={"#7D7D7D"}
            style={tw`absolute right-4`}
          />
        </View>

        <View style={tw`border border-gray-300 mt-3 rounded-xl`}>
          <View
            style={[
              tw`flex-row bg-gray-100 p-5`,
              {
                borderBottomWidth: 1,
                borderColor: "#C7C7C7",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              },
            ]}
          >
            <View style={tw`flex-1`}>
              <Text style={tw`text-center font-semibold`}>Colaborador</Text>
            </View>
            <View style={tw`flex-1`}>
              <Text style={tw`text-center font-semibold`}>Empresa</Text>
            </View>
            <View style={tw`flex-1`}>
              <Text style={tw`text-center font-semibold`}>Mes</Text>
            </View>
            <View style={tw`flex-1`}>
              <Text style={tw`text-center font-semibold`}>Año</Text>
            </View>
            <View style={tw`flex-1`}>
              <Text style={tw`text-center font-semibold`}>Tipo</Text>
            </View>
            <View style={tw`flex-1`}>
              <Text style={tw`text-center font-semibold`}>Firmado</Text>
            </View>
            <View style={tw`flex-1`}>
              <Text style={tw`text-center font-semibold`}>Doc</Text>
            </View>
            <View style={tw`flex-1`}>
              <Text style={tw`text-center font-semibold`}>Eliminar</Text>
            </View>
          </View>

          {/* TODO: ver columan año, tenemos seteado el año en 2022 xD */}
          <FlatList
            data={filtro ? filteredData : recibos}
            keyExtractor={(item) => item?.id}
            renderItem={({ item }) => {
              if (!item?.colaborador) return;

              return (
                <View
                  style={[
                    tw`flex-row p-5`,
                    { borderBottomWidth: 1, borderColor: "#C7C7C7" },
                  ]}
                >
                  <View style={tw`flex-1 flex-row justify-center items-center`}>
                    {/* <Image source={require('../../assets/avatar-prueba.png')} style={tw`w-6 h-6 mr-2`} /> */}
                    <Text
                      style={tw`text-center`}
                    >{`${item.colaborador.nombre} ${item.colaborador.apellido}`}</Text>
                  </View>
                  <View style={tw`flex-1 justify-center`}>
                    <Text style={tw`text-center`}>{item.empresa.nombre}</Text>
                  </View>
                  <View style={tw`flex-1 justify-center`}>
                    <Text style={tw`text-center capitalize`}>
                      {moment(item?.periodo).locale("es").format("MMMM")}
                    </Text>
                  </View>
                  <View style={tw`flex-1 justify-center`}>
                    <Text style={tw`text-center`}>
                      {item.periodo.slice(0, 4)}
                    </Text>
                  </View>
                  <View style={tw`flex-1 justify-center`}>
                    <Text style={tw`text-center`}>
                      {item?.tipo?.tipo ? item.tipo.tipo : "-"}
                    </Text>
                  </View>
                  <View style={tw`flex-1 items-center justify-center`}>
                    <Text
                      style={[
                        item.firmado
                          ? tw`text-center text-green-600 bg-green-200 py-1 px-3`
                          : tw`text-center text-red-600 bg-red-200 py-1 px-3`,
                        { borderRadius: 50 },
                      ]}
                    >
                      {item.firmado ? "Firmado" : "No firmado"}
                    </Text>
                  </View>
                  <View style={tw`flex-1 items-center justify-center`}>
                    <TouchableOpacity
                      onPress={() => descargarRecibo.mutate(item.id)}
                    >
                      <MaterialCommunityIcons
                        name="file-download"
                        size={24}
                        color={Colores.primary}
                      />
                    </TouchableOpacity>
                  </View>
                  <View style={tw`flex-1 items-center justify-center`}>
                    <TouchableOpacity onPress={() => confirmar(item.id)}>
                      <MaterialCommunityIcons
                        name="delete"
                        size={24}
                        color={Colores.primary}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              );
            }}
          />
        </View>
      </View>
    </View>
  );
};

export default TablaRecibos;
