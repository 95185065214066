import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { useQuery } from "react-query";
import { misItems } from "../../../services/apiClient";
import { Loader } from "../../Loader";
import tw from "twrnc";
import ItemsPendientes from "./ItemsPendientes";
import { estadoItem } from "../../../utils/generales";
import ItemsNoPendientes from "./ItemsNoPendietes";

const MisItems = ({ filtro }) => {
  const { data, isFetching, isError, refetch } = useQuery("MisItems", misItems);
  const [pendientes, setPendientes] = useState([]);
  const [noPendientes, setNoPendientes] = useState([]);
  const [noPendientesFiltrados, setNoPendientesFiltrados] = useState([]);

  useEffect(() => {
    const itemsFiltradosPorTipo = noPendientes.filter(
      (item) => filtro.id === item.tipo_id
    );
    setNoPendientesFiltrados(itemsFiltradosPorTipo);
  }, [filtro]);


  useEffect(() => {
    if (data?.data?.items) {
      const itemsPendientes = [];
      const itemsNoPendientes = [];
      data?.data?.items.map((item) => {
        if (estadoItem(item) == "pendiente") {
          itemsPendientes.push(item);
        } else {
          itemsNoPendientes.push(item);
        }
      });
      setPendientes(itemsPendientes);
      setNoPendientes(itemsNoPendientes);
    }
  }, [data]);

  if (isFetching) {
    return <Loader text="Cargado activos" />;
  }

  if (isError) {
    return (
      <TouchableOpacity
        style={[
          tw`m-2 max-w-[180px] p-2 rounded-md`,
          { backgroundColor: Colores.warning },
        ]}
        onPress={() => refetch()}
      >
        <Text>Reintentar cargar mis activos</Text>
      </TouchableOpacity>
    );
  }

  return (
    <View style={tw`flex flex-col items-strech gap-4`}>
      <ItemsPendientes items={pendientes} />
      <ItemsNoPendientes
        filtro={filtro}
        items={
          filtro.id === 0 ? noPendientes : noPendientesFiltrados
        }
      />
    </View>
  );
};

export default MisItems;
