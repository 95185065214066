import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React from "react";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import tw from "twrnc";
import { FontAwesome5 } from "@expo/vector-icons";
import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { getNotificaciones } from "../services/apiClient";
import { useQuery, useQueryClient } from "react-query";
import { notificacionesNoLeidasAtom } from "../store/store";
import { useAtom } from "jotai";
import usePermisos from "../hooks/usePermisos";

const HeaderAvisoRightButton = ({ disabledNotification }) => {
  const { validarPermiso } = usePermisos();
  const queryClient = useQueryClient();
  const notificaciones = useQuery("misRecibos", getNotificaciones);
  const [notificacionesNoLeidas, setNotificacionesNoLeidas] = useAtom(
    notificacionesNoLeidasAtom
  );

  const badgeNotificacionesNoLeidas = () => {
    const resultado = notificaciones.data?.data?.notificaciones.filter(
      (item) => item.read_at === undefined || item.read_at === null
    );
    setNotificacionesNoLeidas(resultado?.length);
  };

  const navigator = useNavigation();

  return (
    <View style={tw`flex-row `}>
      {!disabledNotification && (
        <TouchableOpacity
          onPress={() => navigator.navigate("Notificaciones")}
          style={tw`p-4`}
        >
          <FontAwesome5 name="bell" size={20} color="#FFF" />
          {notificacionesNoLeidas > 0 && (
            <View
              style={tw`w-4 h-4 ml-2 top-3 bg-red-500 rounded-full absolute items-center`}
            >
              <Text style={tw`text-xs top-3 text-white text-center`}>
                {notificacionesNoLeidas}
              </Text>
            </View>
          )}
        </TouchableOpacity>
      )}

      {validarPermiso(["administrar.todo", "crear.avisos"]) ? (
        <Menu style={tw`p-4`}>
          <MenuTrigger>
            <Feather name="edit" style={tw``} size={20} color="#FFF" />
          </MenuTrigger>
          <MenuOptions
            style={tw`p-1`}
            optionsContainerStyle={{ borderRadius: 10, marginTop: 30 }}
          >
            <MenuOption
              onSelect={() => navigator.navigate("Crear Aviso")}
              style={[
                tw`flex flex-row justify-center px-2 py-2`,
                { borderBottomWidth: 1, borderBottomColor: "#808080" },
              ]}
            >
              <Text style={tw`mr-10`}> Publicar </Text>
              <FontAwesome5 name="th" size={20} style={tw`ml-10`} />
            </MenuOption>

            <MenuOption
              onSelect={() => navigator.navigate("Crear Historia")}
              style={tw`flex flex-row justify-center px-2 py-2`}
            >
              <Text style={tw`mr-10`}> Historia </Text>
              <FontAwesome5 name="history" size={20} style={tw`ml-10`} />
            </MenuOption>
          </MenuOptions>
        </Menu>
      ) : null}
    </View>
  );
};

export default HeaderAvisoRightButton;
