import { React, useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  Pressable,
} from "react-native";
import tw from "twrnc";

import LoginForm from "../forms/LoginForm";
import ModalTerminosyCondiciones from "../components/Terminos/ModalTerminosyCondiciones";
import ModalRecuperarPassword from "../components/Login/ModalRecuperarPassword";
import { useAtom } from "jotai";
import { apiAtom } from "../store/store";
import { useMutation } from "react-query";
import { confirmarReseteoPassword } from "../services/apiClient";
import ModalCambioPassword from "../components/Login/ModalCambioPassword";

export default function LoginScreen() {
  const [modalVisible, setModalVisible] = useState(false);
  const [mostrarModalRecuperarPassword, setMostrarModalRecuperarPassword] =
  useState(false);
  const [mostrarModalCambiarPassword, setMostrarModalCambiarPassword] = useState(false);
  const [, setApiURL] = useAtom(apiAtom);

  const resultadoResetPasswordMutate = useMutation(confirmarReseteoPassword);

  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const tokenCambioPassword = url.searchParams.get("token");

  if (tokenCambioPassword) {
    setMostrarModalCambiarPassword(true)
    resultadoResetPasswordMutate.mutate(tokenCambioPassword);
    url.searchParams.delete("token");

    // Url sin el parametro token
    const updatedURL = url.href;

    // Actualizar la URL sin recargar la pagina
    window.history.replaceState({}, document.title, updatedURL);
  }
  console.log("token", tokenCambioPassword);


  return (
    <>
      <Image
        source={require("../assets/workie_fondo.png")}
        style={[tw`h-full w-full absolute`, StyleSheet.absoluteFill]}
      />

      <View style={tw`flex-1 items-center justify-center h-full`}>
        <Image
          source={require("../assets/workie_logo.png")}
          style={[tw`w-full mt-15 h-20`, { resizeMode: "contain" }]}
        />
        <Text style={tw`text-3xl text-white mt-5 mb-10`}>¡Bienvenido!</Text>


        <LoginForm />
        

        <View>
          <TouchableOpacity
            onPress={() => setMostrarModalRecuperarPassword(true)}
          >
            <Text style={tw`text-white text-center m-2 font-light`}>
              ¿Olvidaste tu contraseña?
            </Text>
          </TouchableOpacity>

          <View style={tw`flex-row m-5 items-center`}>
            <Text style={tw`text-base text-white text-center font-semibold`}>
              ¿No tienes una cuenta?
            </Text>

            <Text style={tw`text-sm text-white ml-2 font-light`}>
              Comunicate con RRHH
            </Text>
            <Pressable onPress={() => setModalVisible(true)}>
              <Text style={tw`text-sm mt-1 text-white underline`}>
                Términos y condiciones de uso.
              </Text>
            </Pressable>
            <ModalTerminosyCondiciones modalVisible={modalVisible} setModalVisible={setModalVisible} />
            <ModalRecuperarPassword modalVisible={mostrarModalRecuperarPassword} setModalVisible={setMostrarModalRecuperarPassword} />
          </View>
        </View>
      </View>
      <View style={tw`items-center m-10 bottom-0`}>
        <Text style={tw`text-sm text-white bottom-0`}>
          Al utilizar Workie usted acepta nuestros
        </Text>
        <Pressable onPress={() => setModalVisible(true)}>
          <Text style={tw`text-sm mt-1 text-white underline`}>
            Términos y condiciones de uso.
          </Text>
        </Pressable>
        
        <Pressable
          onPress={() => {
            setApiURL(null);
          }}
        >
          <Text style={tw`text-sm mt-1 text-white underline`}>
            Cambiar de empresa
          </Text>
        </Pressable>
        <ModalTerminosyCondiciones
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
        <ModalRecuperarPassword
          modalVisible={mostrarModalRecuperarPassword}
          setModalVisible={setMostrarModalRecuperarPassword}
        />
        <ModalCambioPassword
          modalVisible={mostrarModalCambiarPassword}
          setModalVisible={setMostrarModalCambiarPassword}
          mutation={resultadoResetPasswordMutate}
        />
      </View>
    </>
  );
}
