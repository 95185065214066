import { Modal, ScrollView, StyleSheet, Text, Touchable, TouchableOpacity, View } from 'react-native'
import React from 'react'
import tw from 'twrnc';
import { AntDesign } from '@expo/vector-icons';

const ModalTerminosyCondiciones = ({modalVisible, setModalVisible}) => {

  return (
    <Modal
          animationType="fade"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            Alert.alert('Modal has been closed.');
            setModalVisible(!modalVisible);
          }}>
              <View style={[tw`h-full w-full p-5`, {backgroundColor:'#24242390'}]}>
                <ScrollView>
                    <View style={tw`bg-white rounded-lg px-5`}>
                            <View style={tw`flex-row`}>
                                <View style={tw`flex-10`}>
                                    <Text style={tw`text-lg font-semibold mt-5`}>Términos y Condiciones de Aplicación Móvil</Text>
                                </View>
                                <View style={tw`flex-2 items-end justify-center`}>
                                    <TouchableOpacity style={tw`rounded-full bg-red-500`} onPress={()=>setModalVisible(false)}>
                                        <AntDesign name="close" size={24} color="white" />
                                    </TouchableOpacity>
                                </View>
                            </View>
                            <Text style={tw`mt-3`}>Los presentes Términos y Condiciones implican un acuerdo legal, entre usted, en adelante “El Usuario” y La Empresa, donde, a través de un Perfil Digital, se le concede el uso personal de la Aplicación. Al acceder al uso de la Aplicación Nubiteam usted reconoce haber leído, entendido y aceptado cumplir con todos los términos, condiciones y avisos contenidos en estos Términos y Condiciones de Uso (en adelante como los “Términos y Condiciones”). Para acceder a la aplicación, el usuario debe aceptar los Términos y Condiciones Particulares y la Política de Protección de Datos Personales aquí establecidos.</Text>
                            <View style={tw`pl-1 mt-3`}>
                                <Text style={tw`font-semibold`}>1.  Descripción de la Aplicación</Text>
                                    <View style={tw`pl-1 pt-2`}>
                                        <Text style={tw`mt-2`}>1.1  El usuario deberá aportar de manera correcta los datos personales que permitan generar su perfil con el fin de que la Empresa tenga acceso a los mismos en caso de necesitar realizar diversas gestiones referidas a la gestión del personal.</Text>
                                        <Text style={tw`mt-2`}>1.2  En caso de que el Usuario considere que posee síntomas compatibles con los del virus COVID-19 podrá informarlos a través de la Aplicación a efectos de realizar el seguimiento sobre su situación de salud y recibir orientación de acuerdo a las políticas internas de la empresa frente a cada situación en particular.</Text>
                                    </View>
                            </View>
                            <View style={tw`pl-1 mt-5`}>
                                <Text style={tw`font-semibold`}>2.  Requisitos de uso de la Aplicación</Text>
                                    <View style={tw`pl-1 pt-2`}>
                                        <Text style={tw`mt-2`}>2.1  Solo podrán hacer uso de esta aplicación quienes sean parte de la Empresa.</Text>
                                        <Text style={tw`mt-2`}>2.2  La utilización de la Aplicación es gratuita, lo que implica que no deberá pagar por ello ningún arancel o retribución.</Text>
                                        <Text style={tw`mt-2`}>2.3  Para poder utilizar la Aplicación se requiere conexión a Internet. La libre descarga y la gratuidad del acceso no comprenden las facilidades de conexión a Internet.</Text>
                                    </View>
                            </View>
                            <View style={tw`pl-1 mt-5`}>
                                <Text style={tw`font-semibold`}>3.  Información contenida en la Aplicación</Text>
                                    <View style={tw`pl-1 pt-2`}>
                                        <Text style={tw`mt-2`}>3.1  El contenido y/o el uso de la Aplicación está orientada a gestionar trámites relacionados con el área de Recursos Humanos.</Text>
                                        <Text style={tw`mt-2`}>3.2  La Aplicación, implica también, un soporte de trabajo integrador de las distintas vías de atención, permitiendo aunar los datos referentes a inquietudes y consultas que los colaboradores puedan tener.</Text>
                                        <Text style={tw`mt-2`}>3.3  Queda terminante prohibido: (i) copiar, modificar, adaptar, traducir, realizar ingeniería inversa, descompilar o desensamblar cualquier parte del contenido y/o de la Aplicación; (ii) hacer uso del contenido en otro sitio web o entorno informático para cualquier propósito sin la autorización previa y por escrito de la Empresa; (iii) reproducir y/o comunicar por cualquier medio el contenido, dado que el acceso se otorga en forma personal y para uso exclusivo del Usuario; (iv) interferir o interrumpir el funcionamiento de la Aplicación; (v) vender, licenciar o explotar el contenido y/o cualquier tipo de acceso y/o uso de la Aplicación; (vi) utilizar la Aplicación con fines ilícitos o inmorales; e (vii) infringir de cualquier modo los presentes Términos y Condiciones.</Text>
                                    </View>
                            </View>
                            <View style={tw`pl-1 mt-5`}>
                                <Text style={tw`font-semibold`}>4.  Protección de Datos Personales</Text>
                                    <View style={tw`pl-1 pt-2`}>
                                        <Text style={tw`mt-2`}>4.1  La Aplicación, cumple con lo establecido en la Ley 25.326 y normativa complementaria, aplicando dicha regulación a todo tratamiento de datos personales y/ o sensibles.</Text>
                                        <Text style={tw`mt-2`}>4.2  El usuario presta su consentimiento expreso para que la Aplicación recabe, procese y almacene información personal del usuario (nombre, DNI, edad, domicilio, geolocalización y otra información relevante), incluida información sensible referida a su salud (seguimiento de síntomas), con el fin de recomendarle pasos a seguir según la situación informada.</Text>
                                        <Text style={tw`mt-2`}>4.3  La Aplicación es administrada por el área de Recursos Humanos de la Empresa. El Usuario podrá ejercer sus derechos de acceso, rectificación y supresión de sus datos personales enviando un correo a rrhh@nubicom.com.ar</Text>
                                        <Text style={tw`mt-2`}>4.4  La Empresa se compromete a adoptar todos los recaudos que sean necesarios para garantizar la seguridad de los datos personales.</Text>
                                    </View>
                            </View>
                            <View style={tw`pl-1 mt-5`}>
                                <Text style={tw`font-semibold`}>5.  Modificaciones de la Aplicación y de los Términos y Condiciones</Text>
                                    <View style={tw`pl-1 pt-2`}>
                                        <Text>La Empresa podrá introducir todos los cambios y modificaciones que estime convenientes en la Aplicación, lo que incluye, agregar, alterar, sustituir o suprimir cualquier contenido de la Aplicación en todo momento. Asimismo, podrá, a su discreción, modificar, añadir o suprimir, partes de los Términos y Condiciones, en cualquier momento. El uso del Aplicativo Nubiteam posterior a la publicación de cambios, significa que acepta y está de acuerdo con los mismos. Acepto los Términos y Condiciones de la Aplicación</Text>
                                    </View>
                            </View>
                            <View style={tw`items-center justify-center mt-7 mb-7`}>
                                <TouchableOpacity style={[tw` px-7 py-1.5`,{backgroundColor:'#5753DD', borderRadius:15}]} onPress={()=>setModalVisible(false)}>
                                    <Text style={tw`text-center text-lg text-white`}>Volver</Text>
                                </TouchableOpacity>
                            </View>
                    </View>
                </ScrollView>
              </View>
        </Modal>
  )
}

export default ModalTerminosyCondiciones

const styles = StyleSheet.create({})