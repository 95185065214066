import React, { useState } from "react";
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  ActivityIndicator,
  Image,
  Pressable,
  Switch,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as DocumentPicker from "expo-document-picker";
import tw from 'twrnc';
import { MaterialCommunityIcons } from "@expo/vector-icons";

import { nuevoBeneficio } from "../services/apiClient";

import AwesomeAlert from "react-native-awesome-alerts";
import ResultadoModal from "../components/ResultadoModal";
import MensajeError from "../components/MensajeError";
import { Colores } from "../constants/colores";

export const BeneficioForm = (props) => {
  
  const [foto, setfoto] = useState();
  const [activo, setactivo] = useState(1);
  const [mostrarDescuento, setMostrarDescuento] = useState();
  const {width} = useWindowDimensions();
  const {navigation} = props;

  const alertInitialState = {
    show: false,
    title: "",
    message: "",
    showConfirmButton: false,
  };
  const [mostrarAlert, setmostrarAlert] = useState(alertInitialState);
  const queryClient = useQueryClient();


  const toggleActivoSwitch = () => setactivo((previousState) => !previousState);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  const resetForm = () => {
    //reset form
    reset({ titulo: "", texto: "" });
    setfoto();
    setactivo(0);
  };

  const nuevoBeneficioMutate = useMutation(nuevoBeneficio, {
    onSuccess: async (response) => {
      resetForm();
      //mostral mostrar modal
      setmostrarAlert({
        show: true,
        showConfirmButton: true,
        customView: (
          <ResultadoModal success={true} message="El beneficio fue creado" />
        ),
      })
      queryClient.invalidateQueries("beneficios");

    },
    onError: async (error) => {
      console.log(error.response.data);
      //TODO: mostrar errores en campos
      setmostrarAlert({
        show: true,
        customView: <ResultadoModal success={false} message="Hubo un error" />,
      });
    },
  });

  const formData = new FormData();
  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: ["image/jpeg", "image/png"],
    });
    setfoto(result);
    console.log(result);
  };

  const onSubmit = (data) => {
    formData.append("unica", 1);
    if (foto) {
      formData.append("foto", foto?.file);
    }


    //se cargan el resto de campos del formulario en formData
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        if (data[key]) {
          formData.append(key, data[key]);
        }
      }
    }
    nuevoBeneficioMutate.mutate(formData);
  };



  return (
    <>
    <ScrollView style={tw`h-full w-full`}>
    <View style={width >= 768 ? tw`px-15 py-10 bg-white rounded-xl` : tw`px-4 bg-white`}>
      {width >= 768 && (
      <View style={[tw`w-full justify-center bg-white`,{borderTopLeftRadius:20, borderTopRightRadius:20}]}>
          <Text style={tw`text-lg font-semibold text-gray-600 mb-5`}>Nuevo Beneficio</Text>
      </View>
      )}
      <View style={tw`flex flex-row w-full`}>
          <View style={tw`mt-3 items-center justify-center`}>
            <Pressable onPress={pickDocument} foreground={true}>
              {foto ? (
                <Image
                  style={[tw`rounded-full h-30 w-30` , { resizeMode:'contain'}]}
                  source={{ uri: foto.uri }}
                />
              ) : (
                <View style={tw`rounded-lg shadow-md items-center justify-center rounded-full h-30 w-30`}>
                    <MaterialCommunityIcons name="image-plus" size={44} style={{color:Colores.primaryLight}} />
                </View>
              )}
              {mostrarDescuento && (
                  <Text style={[tw`py-1 px-2 rounded-xl absolute top-1 right-1`,{color:'#fff',backgroundColor:'#01c2a5'}]}>Descuento {mostrarDescuento}%</Text>
              )}

          </Pressable>
          </View>
          
          <View style={tw`pl-5`}>
            <Text style={tw` mt-5 text-lg text-gray-900`}>Empresa o servicio</Text>
            <Controller
              control={control}
              rules={{
                required: "Indique la empresa/servicio",
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[tw` mt-2 w-full px-3 py-2.5 text-gray-700 border`,{borderRadius:50, borderColor:Colores.primary}]}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  multiline={true}
                  placeholder="ej. Hoyts"
                  value={value}
                />
              )}
              name="empresa"
            />
            {errors?.empresa && <MensajeError message={errors.empresa?.message} />}
            
            <View style={tw`flex flex-row`}>
                <View>
                  <Text style={tw` mt-5 text-lg text-gray-900`}>Descuento</Text>
                  <Controller
                    control={control}
                    rules={{
                      required: "Indique el beneficio",
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        style={[tw` w-20 mt-2 px-3 py-2.5 text-gray-700 border`,{borderRadius:50, borderColor:Colores.primary}]}
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                        placeholder="%"
                        placeholderTextColor={Colores.primary}
                      />
                    )}
                    name="descuento"
                  />
                  {errors?.descuento && (
                    <MensajeError message={errors.descuento?.message} />
                  )}
                </View>
                <View style={tw`items-end justify-center`}>
                  <Controller
                  control={control}
                  defaultValue={activo}
                  name="activo"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <View style={tw`flex-row flex mt-5`}>
                      <Text style={tw`ml-3 text-gray-700 text-lg`}>Activo</Text>
                      <Switch
                        trackColor={{ false: "#767577", true: "#81b0ff" }}
                        thumbColor={value ? "#f5dd4b" : "#f4f3f4"}
                        ios_backgroundColor="#3e3e3e"
                        onValueChange={(value) => {
                          toggleActivoSwitch;
                          onChange(value ? 1 : 0);
                        }}
                        value={value}
                        style={tw`ml-2 mt-1`}
                      />
                    </View>
                  )}
                  />
                </View>
            </View>
          
          </View>

      </View>
        

        <Text style={tw` mt-5 text-lg text-gray-900`}>Descripción de beneficio/descuento   </Text>
        <Controller
          control={control}
          rules={
            {
              //required: 'Indique el descuento'
            }
          }
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              keyboardType="number-pad"
              style={[tw` h-50 mt-2 w-full px-3 py-2 text-gray-700 border border-gray-400`,{borderRadius:10}]}
              onBlur={onBlur}
              onChangeText={onChange}
              multiline={true}
              value={value}
              placeholder="beneficio o descuento"
            />
          )}
          name="beneficio"
        />
        {errors?.beneficio && (
          <MensajeError message={errors.beneficio?.message} />
        )}


        <Text style={tw` mt-5 text-lg text-gray-900`}>Agregar enlace <Text style={tw`text-gray-500`}>(opcional)</Text></Text>
        <Controller
          control={control}
          rules={
            {
              //required: 'Indique una dirección url'
            }
          }
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={[tw` mt-2 w-full px-3 py-2.5 text-gray-700 border border-gray-400`,{borderRadius:50}]}
              onBlur={onBlur}
              onChangeText={onChange}
              multiline={true}
              placeholder="www.ejemplo.com"
              value={value}
            />
          )}
          name="web"
        />
        {errors?.web && <MensajeError message={errors.web?.message} />}


        {nuevoBeneficioMutate.isLoading ? (
          <ActivityIndicator size="small" color={Colores.primary} />
        ) : (
          <View style={tw`items-center justify-center`}>
            <TouchableOpacity onPress={handleSubmit(onSubmit)} style={[tw`mt-7 px-20 py-2.5 rounded shadow-md`,{backgroundColor:Colores.primary, borderRadius:16}]}>
                <Text style={tw`text-white text-center`}>Guardar Beneficio</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>

    </ScrollView>
      <AwesomeAlert
        show={mostrarAlert.show}
        showProgress={false}
        title={mostrarAlert.title}
        message={mostrarAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={mostrarAlert.showConfirmButton}
        confirmText="Aceptar"
        customView={mostrarAlert.customView}
        confirmButtonColor="green"
        onDismiss={() => {
          setmostrarAlert(alertInitialState);
          props.navigation.navigate("Administrar Beneficios");
        }}
        onCancelPressed={() => {
          setmostrarAlert(alertInitialState);
        }}
        onConfirmPressed={() => {
          setmostrarAlert(alertInitialState);
          props.navigation.navigate("Administrar Beneficios");
        }}
      />
    </>

  );
};
