import { DrawerItem } from "@react-navigation/drawer";
import React, { useState } from "react";
import { View, Text, Pressable } from "react-native";
import tw from 'twrnc';
import { Colores } from "../constants/colores";

export default function NavItem({ item, index, ...props }) {
  const [subNav, setsubNav] = useState(false);
  const showSubnav = () => setsubNav(!subNav);

  return (
    <Pressable
      key={`${item.title}-${index}`}
      onPress={
        item.subNav.length > 0
          ? showSubnav
          : () => {
              props.navigation.navigate(item.title);
            }
      }
    >
      <View
        style={[
          (item.subNav?.includes(props.selectedRoute) ||
            item.title === props.selectedRoute) && { backgroundColor: Colores.primaryLight},
          {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 15,
            bottom:0,
            borderRadius:20,
          },
        ]}
      >
        <View
          style={tw`flex flex-row items-center`}
        >
          {item.icon}
          <Text
            style={[tw`text-lg`,{color:item.title === props.selectedRoute ? Colores.primaryStrong : 'gray'}]} 
          >
            {item.title}
          </Text>
        </View>
        {/* <View>
          {item.subNav.length > 0 && subNav
            ? item.iconOpened
            : item.subNav.length > 0
            ? item.iconClosed
            : null}
        </View> */}
      </View>
      {subNav &&
        item.subNav.map((subitem, index) => {
          return (
            <DrawerItem
              key={index}
              label={subitem}
              onPress={() => props.navigation.navigate(subitem)}
              style={
                subitem == props.selectedRoute
                  ? { backgroundColor: "#cfcfcf" }
                  : {}
              }
            />
          );
        })}
    </Pressable>
  );
}
