import { useEffect, useState } from "react";
import { PageContainer } from "../../components/PageContainer";
import { Loader } from "../../components/Loader";
import { Image, ScrollView, Text, View } from "react-native";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import tw from "twrnc";
import { Colores } from "../../constants/colores";

import { archivoItem } from "../../services/apiClient";
import { convertToMegabytes } from "../../utils/generales";
import ConfirmarRecepcionItem from "../../components/Items/ConfirmarRecepcionItem";
import ConfirmarDevolucionItem from "../../components/Items/ConfirmarDevolucionItem";
import TipoItemSeleccionado from "../../components/Items/TipoItemSeleccionado";
import TituloItem from "../../components/Items/TituloItem";
import EstadoItem from "../../components/Items/EstadoItem";
import AsignadoA from "../../components/Items/AsignadoA";
import AsignadoPor from "../../components/Items/AsignadoPor";
import FechaAsignacion from "../../components/Items/FechaAsignacion";
import FechaCaduca from "../../components/Items/FechaCaduca";
import Cantidad from "../../components/Items/Cantidad";
import DescripcionItem from "../../components/Items/DescripcionItem";
import ObservacionesItem from "../../components/Items/ObservacionesItem";
import ArchivoItem from "../../components/Items/ArchivoItem";
import ArchivoRemotoItem from "../../components/Items/ArchivoItemRemoto";

export default function DetalleMiItemScreen(props) {
  const { item } = props.route.params;
  const {
    titulo,
    cantidadEntregada,
    fechaCaduca,
    tipo_id,
    caduca,
    descripcion,
    observaciones,
    requiereConformidadRecepcion,
    conformeDevolucion,
    conformeRecepcion,
    asignado,
    asigno,
    archivos,
  } = item;

  const [imageUrls, setImageUrls] = useState([]);
  const [isLoadingImages, setIsLoadingImages] = useState(false);

  useEffect(() => {
    loadImages();
  }, [item]);

  const loadImages = async () => {
    setIsLoadingImages(true);
    const urls = await Promise.all(
      item.archivos.map(async (archivo) => {
        const imagenes = ["png", "jpg", "jpeg"];
        const { extension } = archivo;
        if (imagenes.includes(extension)) {
          try {
            const response = await archivoItem(archivo.archivo);
            const type = response.headers["content-type"];
            const blobFile = new Blob([response.data], { type });
            const uri = URL.createObjectURL(blobFile);
            return uri;
          } catch (error) {
            console.error(`Error loading image: ${error}`);
            return null;
          }
        }
        return null; // Ignorar los que no son imagenes
      })
    );
    setImageUrls(urls.filter((url) => url !== null));
    setIsLoadingImages(false);
  };
  const [mostrarAlert, setmostrarAlert] = useState(false);
  return (
    <PageContainer paddingMd>
      <ScrollView style={tw`bg-white flex flex-col space-y-4 gap-4 p-4`}>
        <View style={tw`flex flex-row items-start gap-4`}>
          <View
            style={tw`max-w-[350px] flex flex-col gap-4 border border-gray-200 rounded-md p-4`}
          >
            <TipoItemSeleccionado tipoId={tipo_id} />
            <TituloItem titulo={titulo}>
              <EstadoItem
                item={{
                  requiereConformidadRecepcion,
                  conformeDevolucion,
                  conformeRecepcion,
                  caduca,
                  fechaCaduca,
                }}
              />
            </TituloItem>

            <AsignadoA colaborador={asignado} />

            <AsignadoPor colaborador={asigno} />

            <FechaAsignacion />

            <FechaCaduca fecha={fechaCaduca} />

            <Cantidad cantidad={cantidadEntregada} />

            <Text style={tw`text-xl font-semibold`}>Archivos</Text>
            {item?.archivos
              ? item.archivos.map((archivo, index) => (
                  <ArchivoRemotoItem
                    file={archivo}
                    key={archivo.id}
                    index={index}
                    setmostrarAlert={setmostrarAlert}
                    canDelete={false}
                  />
                ))
              : null}
          </View>

          <View
            style={tw`flex-1 flex flex-col gap-4 border border-gray-200 rounded-md p-4`}
          >
            <DescripcionItem texto={descripcion} />

            <ObservacionesItem observaciones={observaciones} />
          </View>
        </View>

        <ConfirmarRecepcionItem item={item} />
        <ConfirmarDevolucionItem item={item} />

        {item?.archivos?.length ? (
          <View
            style={tw`flex flex-col items-start bg-white p-4 rounded-md my-4`}
          >
            <Text style={tw`font-semibold`}>Archivos</Text>
            <View style={tw`grid md:grid-cols-4 gap-2`}>
              {item?.archivos?.map((archivo) => {
                const documentos = ["pdf", "docx"];
                const { extension } = archivo;
                if (documentos.includes(extension)) {
                  const icons = {
                    pdf: (
                      <AntDesign
                        name="pdffile1"
                        size={64}
                        color={Colores.primary}
                      />
                    ),
                    doc: (
                      <Ionicons
                        name="document"
                        size={64}
                        color={Colores.primary}
                      />
                    ),
                  };
                  return (
                    <View
                      key={archivo.archivo}
                      style={tw`flex flex-row items-center p-2`}
                    >
                      {icons[extension]}
                      <View style={tw`flex flex-col justify-center gap-2 p-2`}>
                        <Text style={tw`text-md`}>
                          {archivo.archivo.split("/").pop()}
                        </Text>
                        <Text style={tw`text-sm`}>
                          {convertToMegabytes(archivo.size)}
                        </Text>
                      </View>
                    </View>
                  );
                }
              })}

              {isLoadingImages ? <Loader text="cargando imagenes..." /> : null}
              {imageUrls?.map((imageUrl, index) => (
                <View style={tw`w-full flex flex-row flex-wrap gap-4`}>
                  <Image
                    key={index}
                    style={tw`w-[100px] h-[100px] bg-blue-200 rounded-md`}
                    source={{ uri: imageUrl }}
                  />
                </View>
              ))}
            </View>
          </View>
        ) : null}
      </ScrollView>
    </PageContainer>
  );
}
