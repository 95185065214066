import React from "react";
import { View, Text } from "react-native";
import { FontAwesome5, FontAwesome, Feather } from "@expo/vector-icons";
import tw from "twrnc";

export default function CantidadEntidad({ entidad, cantidad }) {
  const tipoEntidad = {
      "departamentos" : <FontAwesome5 name="building" size={18} color="#94a3b8" />,
      "puestos" : <FontAwesome name="suitcase" size={18} color="#94a3b8" />,
      "colaboradores" : <FontAwesome name="users" size={18} color="#94a3b8" />
  };

  return (
    <View
      style={tw`flex flex-row items-center gap-1`}
    >
      { tipoEntidad[entidad] }
      <Text style={{ color: "#94a3b8", marginVertical: 5, textTransform:"capitalize" }}>{entidad}: {cantidad}</Text>
    </View>
  );
}
