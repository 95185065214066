import React, { useState } from "react";
import { StyleSheet, Text, View, TouchableOpacity, FlatList } from "react-native";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { FontAwesome5 } from "@expo/vector-icons";
import { Feather } from "@expo/vector-icons";
import tw from 'twrnc';

import { IconButton } from "../../components/IconButton";
import { eliminarSugerencia, getSugerencias } from "../../services/apiClient";
import DataTable from "../../components/DataTable/DataTable";
import { formatoFechaES } from "../../utils/fechas";
import AwesomeAlert from "react-native-awesome-alerts";
import { awesomeAlertAtom } from "../../store/store";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import ResultadoModal from "../../components/ResultadoModal";
import Badge from "../../components/Badge";
import { PageContainer } from "../../components/PageContainer";
import { ScrollView } from "react-native-gesture-handler";
import SugerenciaLista from "../../components/sugerencias/SugerenciaLista";
import { Colores } from "../../constants/colores";

function AdministrarSugerenciasScreen() {
  const sugerenciasQuery = useQuery("sugerencias", getSugerencias);
  const [sugerenciaId, setsugerenciaId] = useState();
  const [awesomeAlert, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);
  const [mostrarObservacionesPersonales, setMostrarObservacionesPersonales] = useState(false)
  const [mostrarSugerencias, setMostrarSugerencias] = useState(false)
  const [mostrarProblemasPersonales, setMostrarProblemasPersonales] = useState(false)
  const [mostrarProblemasSector, setMostrarProblemasSector] = useState(false)
  const [mostrarReconocimientos, setMostrarReconocimientos] = useState(false)

  const queryClient = useQueryClient();
  const eliminarMutate = useMutation(eliminarSugerencia, {
    onSuccess: async (response) => {
      console.log("sugerencia eliminado", response.data);
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Exito!",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal success={true} message="Sugerencia eliminada" />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
      queryClient.invalidateQueries("sugerencias");
    },
    onError: async (error) => {
      console.log(
        "hubo un error al intentar eliminar",
        error.response.data.message
      );
      //setmostrarAlerta(false)
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Error",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal
            success={false}
            message={error.response.data.message}
          />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
    },
    onSettled: (data) => {
      if (data.data.ok) {
        setmostrarAlerta(false);
      }
    },
  });

  let sugerencias = [];
  if (sugerenciasQuery.data && sugerenciasQuery.data.data?.ok) {
    sugerencias = sugerenciasQuery.data.data.sugerencias;
  }

  const getSugerencia = (sugerenciaId) => {
    const sugerencia = sugerencias.filter(
      (sugerencia) => sugerencia.id === sugerenciaId
    );
    if (sugerencia.length) {
      console.log(sugerencia);
      //duevele el primer colaborador que se econtro con ese id
      return sugerencia[0];
    }
    console.log("No se encontro el sugerencia para el id ", sugerenciaId);
    return;
  };

  const getTipoDeSugerencia = (sugerenciaId) => {
    const sugerencia = sugerencias.filter(
      (sugerencia) => sugerencia.tipo_id === sugerenciaId
    );
    return sugerencia;
  };

  const confirmar = (sugerenciaId) => {
    setsugerenciaId(sugerenciaId);
    setawesomeAlert({
      show: true,
      showProgress: eliminarMutate.isLoading,
      title: "Atención",
      message: "Esta seguro de desea eleiminar?",
      showCancelButton: true,
      showConfirmButton: true,
      confirmText: "Eliminar",
      confirmButtonColor: "#DD6B55",
      onConfirmPressed: () => {
        console.log("eliminando");
        eliminarMutate.mutate(sugerenciaId);
      },
    });
  };

  return (
    <PageContainer paddingMd>
      <View style={[tw`flex-1 bg-white p-5`,{borderRadius:20}]}>
      <Text style={tw`text-lg font-semibold text-gray-500 mb-3 ml-1`}>Habla con nosotros</Text>
      <View style={tw`h-full flex-1`}>
        
        <View style={tw`border border-gray-300 rounded-lg p-3`}>
            <TouchableOpacity style={tw`flex flex-row`} onPress={()=>setMostrarObservacionesPersonales(!mostrarObservacionesPersonales)}>
              
              <View style={tw`flex-2 flex-row`}>
                <Text style={mostrarObservacionesPersonales ? ([tw`mb-3`,{color:Colores.primary}]) : null }>
                  Sugerencias
                </Text>
              </View>

              <View style={tw`flex-1 items-end`}>
                <FontAwesome5 name={mostrarObservacionesPersonales ? "chevron-up" : "chevron-down"} size={20} color={Colores.primary} style={mostrarObservacionesPersonales ? ([tw`mr-2`,{color:Colores.primary}]) : (tw`mr-2`) }/>
              </View>

            </TouchableOpacity>
            {mostrarObservacionesPersonales && (            
              <SugerenciaLista datos={getTipoDeSugerencia(1)} />
            )}
        </View>


        <View style={tw`border border-gray-300 rounded-lg p-3 mt-2`}>
          <TouchableOpacity style={tw`flex flex-row`} onPress={()=>setMostrarSugerencias(!mostrarSugerencias)}>
              
              <View style={tw`flex-2 flex-row`}>
                <Text style={mostrarSugerencias ? ([tw`mb-3`,{color:Colores.primary}]) : null }>
                  Problemas de Sector
                </Text>
              </View>

              <View style={tw`flex-1 items-end`}>
              <FontAwesome5 name={mostrarSugerencias ? "chevron-up" : "chevron-down"} size={20} color={Colores.primary} style={mostrarSugerencias ? ([tw`mr-2`,{color:Colores.primary}]) : (tw`mr-2`) }/>
              </View>
              
            </TouchableOpacity>

            {mostrarSugerencias && (  
                <SugerenciaLista datos={getTipoDeSugerencia(2)} />
            )}
        </View>

    
        <View style={tw`border border-gray-300 rounded-lg p-3 mt-2`}>
          <TouchableOpacity style={tw`flex flex-row`} onPress={()=>setMostrarProblemasPersonales(!mostrarProblemasPersonales)}>
              
              <View style={tw`flex-2 flex-row`}>
                <Text style={mostrarProblemasPersonales ? ([tw`mb-3`,{color:Colores.primary}]) : null }>
                Problemas Generales
                </Text>
              </View>

              <View style={tw`flex-1 items-end`}>
                <FontAwesome5 name={mostrarProblemasPersonales ? "chevron-up" : "chevron-down"} size={20} color={Colores.primary} style={mostrarProblemasPersonales ? ([tw`mr-2`,{color:Colores.primary}]) : (tw`mr-2`) }/>
              </View>
              
            </TouchableOpacity>

          {mostrarProblemasPersonales && (  
              <SugerenciaLista datos={getTipoDeSugerencia(3)} />
          )}
        </View>


        <View style={tw`border border-gray-300 rounded-lg p-3 mt-2`}>
          <TouchableOpacity style={tw`flex flex-row`} onPress={()=>setMostrarProblemasSector(!mostrarProblemasSector)}>
              
              <View style={tw`flex-2 flex-row`}>
                <Text style={mostrarProblemasSector ? ([tw`mb-3`,{color:Colores.primary}]) : null }>
                Problemas Personales
                </Text>
              </View>


              <View style={tw`flex-1 items-end`}>
              <FontAwesome5 name={mostrarProblemasSector ? "chevron-up" : "chevron-down"} size={20} color={Colores.primary} style={mostrarProblemasSector ? ([tw`mr-2`,{color:Colores.primary}]) : (tw`mr-2`) }/>
              </View>

            </TouchableOpacity>

          {mostrarProblemasSector && (  
              <SugerenciaLista datos={getTipoDeSugerencia(4)} />
          )}
        </View>

        <View style={tw`border border-gray-300 rounded-lg p-3 mt-2`}>
          <TouchableOpacity style={tw`flex flex-row`} onPress={()=>setMostrarReconocimientos(!mostrarReconocimientos)}>
              
              <View style={tw`flex-2 flex-row`}>
                <Text style={mostrarReconocimientos ? ([tw`mb-3`,{color:Colores.primary}]) : null }>
                  Reconocimientos, Felicitaciones y Agredecimientos
                </Text>
              </View>


              <View style={tw`flex-1 items-end`}>
              <FontAwesome5 name={mostrarReconocimientos ? "chevron-up" : "chevron-down"} size={20} color={Colores.primary} style={mostrarReconocimientos ? ([tw`mr-2`,{color:Colores.primary}]) : (tw`mr-2`) }/>
              </View>

            </TouchableOpacity>

          {mostrarReconocimientos && (  
              <SugerenciaLista datos={getTipoDeSugerencia(5)} />
          )}
        </View>

        <AwesomeAlert
          show={awesomeAlert.show}
          showProgress={awesomeAlert.showProgress}
          title={awesomeAlert.title}
          message={awesomeAlert.message}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={awesomeAlert.showCancelButton}
          showConfirmButton={awesomeAlert.showConfirmButton}
          cancelText="Cancelar"
          confirmText={awesomeAlert.confirmText}
          confirmButtonColor={awesomeAlert.confirmButtonColor}
          customView={awesomeAlert.customView}
          onCancelPressed={() => {
            resetAwesomeAlert();
          }}
          onConfirmPressed={awesomeAlert.onConfirmPressed}
        />
      </View>
      </View>
    
    </PageContainer>
  );
}

export default AdministrarSugerenciasScreen;
