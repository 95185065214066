import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import React, { useEffect, useState } from "react";
import { ActivityIndicator, Button, Text, TouchableOpacity, View } from "react-native";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Picker } from "@react-native-picker/picker";
import tw from 'twrnc';

import {
  asignarRelacionDependencia,
  getEmpresasDptosPuestos,
} from "../../services/apiClient";
import { awesomeAlertAtom } from "../../store/store";
import { Loader } from "../Loader";
import ResultadoModal from "../ResultadoModal";
import { Colores } from "../../constants/colores";

function ColaboradorEmpresa({ colaborador, relacion = null, asignarDespues, setcolaboradorCreado, navigation }) {
  const { data, isLoading, isError } = useQuery(
    "empresasDptosPuestos",
    getEmpresasDptosPuestos
  );
  const [, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);
  const queryClient = useQueryClient();

  const [empresa, setempresa] = useState();
  const [empresaID, setempresaID] = useState();
  const [dptoID, setdptoID] = useState();
  const [puestoID, setpuestoID] = useState();
  const [itemsEmpresas, setitemsEmpresas] = useState([]);
  const [itemsDptos, setitemsDptos] = useState([]);
  const [itemsPuestos, setitemsPuestos] = useState([]);

  const empresas = [];
  if (data && data?.data.empresas.length) {
    data.data.empresas.map((empresa) => {
      empresas.push(
        <Picker.Item
          key={`empresa-${empresa.id}`}
          label={empresa.nombre}
          value={empresa.id}
        />
      );
    });
  }

  //TODO: solucionar error al editar colaborador, no cargan bien los datos
  useEffect(() => {
    if (empresas.length) {
      setitemsEmpresas(empresas);
      setempresaID(relacion?.empresaActual);
      setdptoID(relacion?.dptoActual);
      setpuestoID(relacion?.puestoActual);
    }
  }, [data, relacion, colaborador]);

  const cargarDptos = (empresaId) => {
    data?.data.empresas.filter((empresa) => {
      if (empresa.id == empresaId) {
        setempresa(empresa);
        const dptos = [];
        empresa.departamentos.map((dpto) => {
          dptos.push(
            <Picker.Item
              key={`dpto-${dpto.id}`}
              label={dpto.nombre}
              value={dpto.id}
            />
          );
        });
        setitemsDptos(dptos);
      }
    });
  };

  const cargarPuestos = (dptoId) => {
    empresa.departamentos.filter((dpto) => {
      if (dpto.id == dptoId) {
        const puestos = [];
        dpto.puestos.map((puesto) => {
          puestos.push(
            <Picker.Item
              key={`puesto-${puesto.id}`}
              label={puesto.nombre}
              value={puesto.id}
            />
          );
        });
        setitemsPuestos(puestos);
      }
    });
  };

  useEffect(() => {
    if (empresas.length) {
      cargarDptos(empresaID);
    }
  }, [empresaID]);

  useEffect(() => {
    if (itemsDptos.length) {
      cargarPuestos(dptoID);
    }
  }, [dptoID, empresa, itemsDptos]);

  const asignarRelacionDependenciaMutate = useMutation(
    asignarRelacionDependencia,
    {
      onSuccess: async (response) => {
        if (response.data.ok) {
          queryClient.invalidateQueries("colaboradores");
          setawesomeAlert({
            show: true,
            showProgress: false,
            title: "Exito!",
            title: null,
            message: null,
            showCancelButton: false,
            showConfirmButton: true,
            confirmText: "Aceptar",
            confirmButtonColor: "green",
            customView: (
              <ResultadoModal success={true} message="Relaciones asignadas" />
            ),
            onConfirmPressed: () => {
              resetAwesomeAlert();
            },
            onDismiss: () => {
              resetAwesomeAlert();
            },
          });
        }

      },
      onError: async (error) => {
        console.log(error.response.data);
        setawesomeAlert({
          show: true,
          showProgress: false,
          title: "Error",
          title: null,
          message: null,
          showCancelButton: false,
          showConfirmButton: true,
          confirmText: "Aceptar",
          confirmButtonColor: "green",
          customView: (
            <ResultadoModal
              success={false}
              message={error.response.data.message}
            />
          ),
          onConfirmPressed: () => {
            resetAwesomeAlert();
          },
          onDismiss: () => {
            resetAwesomeAlert();
          },
        });
      },
    }
  );

  const enviar = () => {
    const data = {
      empresa_id: +empresaID,
      departamento_id: +dptoID,
      puesto_id: +puestoID,
    };

    asignarRelacionDependenciaMutate.mutate({
      colaboradorId: colaborador.id,
      data,
    });
  };

  return (
    <View>
      <Text style={[tw`text-lg mb-2 mt-4`,{color:Colores.primary}]}>Datos Laborales</Text>
      <View style={[tw``,{borderBottomWidth:1, borderBottomColor:Colores.primaryLight}]} />

      <View>
        {isLoading ? (
          <Loader flex={0} text="Cargando empresas" />
        ) : isError ? (
          <Text>Error: no se pudieron cargar las empresas</Text>
        ) : (
          <Picker
            selectedValue={empresaID}
            onValueChange={(itemValue, itemIndex) => setempresaID(itemValue)}
            style={[tw` mt-4 w-full px-3 py-2 text-gray-700 border`,{borderRadius:50, borderColor:Colores.primary, color:Colores.primary}]}
          >
            <Picker.Item label="Empresa" value="null" />
            {itemsEmpresas.map((empresa) => empresa)}
          </Picker>
        )}
      </View>
      <View style={itemsDptos.length ? null : tw`hidden`}>
        {itemsDptos.length ? (
          <Picker
            selectedValue={dptoID}
            onValueChange={(itemValue, itemIndex) => {
              setdptoID(itemValue);
            }}
            style={[tw` mt-4 w-full px-3 py-2 text-gray-700 border`,{borderRadius:50, borderColor:Colores.primary, color:Colores.primary}]}
          >
            <Picker.Item label="Departamento" value="null" />
            {itemsDptos.map((dpto) => dpto)}
          </Picker>
        ) : (
          <Text>No hay departamentos</Text>
        )}
      </View>
      <View style={itemsPuestos.length ? null : tw`hidden`}>
        {itemsPuestos.length ? (
          <Picker
            selectedValue={puestoID}
            onValueChange={(itemValue, itemIndex) => {
              setpuestoID(itemValue);
            }}
            style={[tw` mt-4 w-full px-3 py-2 text-gray-700 border`,{borderRadius:50, borderColor:Colores.primary, color:Colores.primary}]}
          >
            <Picker.Item label="Puesto" value="null" />
            {itemsPuestos.map((puesto) => puesto)}
          </Picker>
        ) : (
          <Text>No hay puestos</Text>
        )}
      </View>
      <View style={tw`items-center justify-center`}>
        {asignarRelacionDependenciaMutate.isLoading ? (
          <ActivityIndicator size="small" color={Colores.primary} />
        ) : (
    
          <View style={tw`items-center justify-center flex-row`}>
          {asignarDespues && (
            <TouchableOpacity
              style={[tw`my-6 px-20 py-2.5 shadow-md mr-3`,{backgroundColor:'gray', borderRadius:15}]}
              onPress={()=>{setcolaboradorCreado(null), navigation.navigate('Panel de control')}}
              >
              <Text style={tw`text-white text-center`}>Asignar despues</Text>
            </TouchableOpacity>
            )}
            <TouchableOpacity
              style={[tw`my-6 px-20 py-2.5 shadow-md`,{backgroundColor:Colores.primary, borderRadius:15}]}
              onPress={enviar}
              >
              <Text style={tw`text-white text-center`}>Asignar</Text>
            </TouchableOpacity>
          </View>
    
        )}
      </View>
    </View>
  );
}

export default ColaboradorEmpresa;
