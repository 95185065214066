import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";

export default function ResultadoModal({ success, message }) {
  let title = "¡Éxito!";
  let icon = <Ionicons name="checkmark-circle" size={32} color="green" />;

  if (!success) {
    title = "Error";
    icon = <MaterialIcons name="error" size={32} color="red" />;
  }

  return (
    <View style={styles.resultadoContainer}>
      {icon}
      <Text style={styles.resultadoTitulo}> {title} </Text>
      <Text style={styles.resultadoMensaje}>{message}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  resultadoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  resultadoTitulo: {
    fontSize: 18,
    fontWeight: "bold",
  },
  resultadoMensaje: {
    textAlign: "center",
  },
});
