import React, { useState } from "react";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import moment from "moment";
import tw from 'twrnc';
import { AntDesign } from '@expo/vector-icons';

import MenuDelDia from "./MenuDelDia";
import { Colores } from "../../constants/colores";

function MenuPorDia({ menuSemanal, setMostrarModalSeleccionar, setMenu }) {
  //se agrupan los menu por dia
  const porDia = menuSemanal.reduce((grupo, menu) => {
    const dia = moment(menu["fecha"]).locale("es").format("dddd Do [de] MMMM");
    (grupo[dia] = grupo[dia] || []).push(menu);
    return grupo;
  }, {});

  const dias = Object.entries(porDia).map((dia) => {
    const menu = dia[1].map((menu) => (
      <MenuDelDia key={`menuDelDia-${menu?.id}`} menu={menu} setMostrarModalSeleccionar={setMostrarModalSeleccionar} />
    ));

    return (
      <TouchableOpacity
        key={`dia-${dia[0]}`}
        style={tw`bg-white px-3 py-3 mt-1 border border-gray-300 rounded-lg flex-row`}
        onPress={()=>{setMostrarModalSeleccionar(true); setMenu(menu)}}
      >
        <View style={tw`justify-center flex-5`}>
          <Text>{dia[0].toUpperCase()}</Text>
        </View>
        <View style={tw`justify-center flex-1 items-end`}>
        <AntDesign name="arrowright" size={24} color={Colores.primary} />
        </View>

      </TouchableOpacity>

    );
  });

  return <ScrollView>{dias}</ScrollView>;
}

export default MenuPorDia;
