import React from "react";
import { Controller, useForm } from "react-hook-form";
import { TextInput, TouchableOpacity, View, Text } from "react-native";
import tw from "twrnc";
import { Colores } from "../constants/colores";
import MensajeError from "../components/MensajeError";
import { useMutation, useQueryClient } from "react-query";
import { crearCodigoDeInvitacion } from "../services/apiClient";
import ResultadoModal from "../components/ResultadoModal";
import { useAtom } from "jotai";
import { awesomeAlertAtom } from "../store/store";
import { useResetAtom } from "jotai/utils";

const CodigoInvitacionForm = () => {
  const queryClient = useQueryClient();
  const [_, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);

  const crearCodigoInvitacionMutate = useMutation(crearCodigoDeInvitacion, {
    onSuccess: async (response) => {
      resetForm();
      setawesomeAlert({
        show: true,
        showConfirmButton: true,
        customView: (
          <ResultadoModal success={true} message="El codigo fue creado" />
        ),
        onConfirmPressed: () => resetAwesomeAlert(),
      });
      queryClient.invalidateQueries("codigosInvitacion");
    },
    onError: async (error) => {
      console.log(error.response.data);
      let message = "Hubo un error";
      if (error?.response?.data?.message) {
        message = error.response.data.message;
      }
      setawesomeAlert({
        show: true,
        customView: <ResultadoModal success={false} message={message} />,
      });
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  const resetForm = () => {
    reset({ code: "" });
  };

  const onSubmit = (data) => {
    crearCodigoInvitacionMutate.mutate(data);
  };
  return (
    <>
      <View style={tw`flex flex-col gap-2 rounded-md bg-white shadow-md p-5`}>
        <Text style={tw`font-semibold`}>
          REGISTRAR NUEVO CÓDIGO DE INVITACIÓN
        </Text>
        <View style={tw`flex flex-row items-start gap-2`}>
          <View style={tw`flex flex-col`}>
            <Controller
              control={control}
              rules={{
                required: "Indique un código",
                minLength: {
                  value: 3,
                  message: "Al menos 3 caracteres",
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[
                    tw`w-full px-3 py-3 text-gray-700 border`,
                    { borderRadius: 50, borderColor: Colores.primary },
                  ]}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  multiline={true}
                  placeholder="ej. WORKIE2023"
                  value={value}
                />
              )}
              name="code"
            />
            {errors?.code && <MensajeError message={errors.code?.message} />}
          </View>

          <View style={tw`flex flex-row items-center justify-center`}>
            <TouchableOpacity
              onPress={handleSubmit(onSubmit)}
              style={[
                tw`px-8 py-3 rounded-md shadow-md`,
                { backgroundColor: Colores.primary, borderRadius: 16 },
              ]}
            >
              <Text style={tw`text-white text-center`}>Crear</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </>
  );
};

export default CodigoInvitacionForm;
