import { FlatList, Image, Pressable, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Colores } from '../../constants/colores'
import { MaterialCommunityIcons } from '@expo/vector-icons';
import uuid from 'react-native-uuid';
import * as ImagePicker from "expo-image-picker";
import tw from 'twrnc';
import { useState } from 'react';

const AvisoEditarImagen = ({formData,aviso,setImgDelete,images, setImages}) => {

    const RenderItemImg = ({img}) =>{
        const {id,foto,uri} = img.item
        if (typeof id === 'number') {
            return (
                <View>
                    <View style={styles.viewTouch}>
                        <TouchableOpacity style={styles.touchDelete} onPress={()=>deleteImgExist(id)}>
                            <Text style={styles.textImg}>x</Text>
                        </TouchableOpacity>
                    </View>
                    <Image source={foto} style={styles.imgView} />
                </View>
            )
        }
        if(uri){
            return(
                <View>
                    <View style={styles.viewTouch}>
                        <TouchableOpacity style={styles.touchDelete} onPress={()=>deleteImgNoExist(id)}>
                            <Text style={styles.textImg}>x</Text>
                        </TouchableOpacity>
                    </View>
                    <Image source={uri} style={styles.imgView} />
                </View>
            )
        }
        return <Text>no encontro</Text>
    }

    const [isLoading, setIsLoading] = useState(false);

    const pickImages = async () => {
        setIsLoading(true);
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsMultipleSelection: true,
            selectionLimit: 10,
            quality: 0.5
        })
        setIsLoading(false)
        if(result.cancelled){
            return ''
        }
        if (result.selected) {
            const arrImg = [];
            await Promise.all(
                result?.selected.map(async (imgg) => {
                const typeImg = imgg.uri.match(/[^:/]\w+(?=;|,)/)[0];
                if (typeImg === "png" || typeImg === "jpeg") {
                    const hola = await fetch(imgg?.uri);
                    const blob = await hola.blob();
                    const uurl = hola.url;
                    arrImg.push({
                    uri: uurl,
                    file: blob,
                    id: uuid.v4(),
                    });
                }
                })
            )
            .then(() => {
                setImages(old => [...old, ...arrImg]);
            })
            .catch(() => {
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
    };

    const deleteImgExist = (id) =>{
        const imgAll = images.filter(imgg => imgg.id !== id)
        setImages(imgAll)
        setImgDelete(oldData => [...oldData, id])
    }

    const deleteImgNoExist = (id) =>{
        const imgAll = images.filter(imgg => imgg.id !== id)
        setImages(imgAll)
    }

    return (
        <View style={{flexDirection: 'row', flex: 1,marginTop:10}}>
            <Pressable onPress={()=>pickImages()} foreground={true}>
                <View style={[tw`items-center justify-center bg-gray-200 rounded-full h-20 w-20 mr-2.5`]}>
                    <MaterialCommunityIcons name="image-plus" size={44} style={{color:Colores.primaryLight}} />
                </View>
            </Pressable>
            {images?.length > 0 &&(
                <View style={{flex: 1}}>
                <ScrollView horizontal>
                    <View style={{flex: 1}}>
                        <FlatList
                            data={images}
                            renderItem={(item)=> <RenderItemImg img={item} />}
                            horizontal
                        />
                    </View>
                </ScrollView>
                </View>
            )}
        </View>
    )
}

export default AvisoEditarImagen

const styles = StyleSheet.create({
    touchDelete:{
        backgroundColor: 'red',
        width:25,
        height:25,
        borderRadius:20,
        borderColor: 'white',
        borderWidth: 1
    },
    viewTouch:{
        position: 'absolute',
        zIndex:10,
        right:17,
        top:2
    },
    textImg:{
        textAlign: 'center',
        color: 'white',
        justifyContent:'center',
        alignItems: 'center'
    },
    imgView:{
        width:80,
        height: 80,
        borderRadius: 20,
        marginRight: 15
    }
})