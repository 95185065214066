import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { useQuery } from "react-query";
import { Picker } from "@react-native-picker/picker";
import tw from "twrnc";

import { getTiposItem } from "../../services/apiClient";
import { Loader } from "../Loader";
import { Colores } from "../../constants/colores";
import { TouchableOpacity } from "react-native-gesture-handler";

function TiposItemSelect({ tipoItemSeleccionado, seleccionar }) {
  const { data, isFetching, isError, refetch } = useQuery(
    "TiposItem",
    getTiposItem
  );
  const [tipoId, settipoId] = useState(tipoItemSeleccionado);
  const tipos = [];
  if (data && data?.data.tipos.length) {
    data.data.tipos.map((tipo) => {
      tipos.push(
        <Picker.Item
          key={`opcion-${tipo.id}`}
          label={tipo.tipo}
          value={tipo.id}
        />
      );
    });
  }

  useEffect(() => {
    settipoId(tipoItemSeleccionado)
  },[tipoItemSeleccionado,])

  if (isError) {
    return (
      <TouchableOpacity
        style={[tw`m-2 max-w-[180px] p-2 rounded-md`, { backgroundColor: Colores.warning }]}
        onPress={() => refetch()}
      >
        <Text>Reintentar cargar tipos de items</Text>
      </TouchableOpacity>
    );
  }


  return (
    <View>
      {isFetching ? (
        <Loader flex={0} text="Cargando Tipos de items" />
      ) : isError ? (
        <Text>Error al cargar los tipos</Text>
      ) : (
        <Picker
          style={
            tw`max-w-[300px] mt-2 w-full p-3 text-gray-700 border border-gray-200 rounded-md`
          }
          selectedValue={tipoId}
          onValueChange={(itemValue, itemIndex) => {
            settipoId(itemValue);
            seleccionar(data.data.tipos[itemIndex - 1].id); //setea la opcion en el nodo padre
          }}
        >
          <Picker.Item
            key={`opcion-0`}
            label="Seleccione un tipo"
            value={null}
          />
          {tipos.map((tipo) => tipo)}
        </Picker>
      )}
    </View>
  );
}

export default TiposItemSelect;
