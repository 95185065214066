import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  FlatList,
  TextInput,
  Button,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import { FontAwesome5 } from "@expo/vector-icons";
import {
  useQuery,
  useAsync,
  usePagination,
  useQueryClient,
  useInfiniteQuery,
} from "react-query";
import tw from "twrnc";

import { getColaboradoresPublica } from "../../services/apiClient";
import ColaboradorAvatar from "../../components/Colaboradores/ColaboradorAvatar";
import { Loader } from "../../components/Loader";
import { Colores } from "../../constants/colores";
import { AntDesign } from "@expo/vector-icons";
import ColaboradorModalDetalle from "../../components/Colaboradores/ColaboradorModalDetalle";
import { esWeb } from "../../utils/plataforma";

function BuscarColaboradorScreen(props) {
  const [modalDetalleColaborador, setModalDetalleColaborador] = useState(false);
  const [colaboradorSeleccionado, setColaboradorSeleccionado] = useState();
  const [busqueda, setBusqueda] = useState("");
  const [textoBusqueda, setTextoBusqueda] = useState("");
  const queryClient = useQueryClient();
  const [filtrados, setFiltrados] = useState([]);

  const handleChangeText = (text) => {
    setTextoBusqueda(text);
    if (text.length >= 3) {
      setBusqueda(text);
      setPage(1);
    } else {
      if (text.length === 0) {
        setBusqueda();
      }
    }
    /*if (text.length >= 3) {
      //onSearch(text);
      console.log("buscando ", text)
      let pageNumber = 1;
      let moreData = true;
      while (moreData) {
        const data = queryClient.getQueryData(["colaboradoresPublico", pageNumber]);
        if (!data || !'pages' in data) {
          moreData = false;
        } else {
          const { pages } = data;
          const colaboradores = pages[0].colaboradores.data;
          console.log(colaboradores)
          const resultadoBusqueda = colaboradores.filter((colaborador) => colaborador.nombre.toLowerCase().includes(text.toLowerCase()));
          if (resultadoBusqueda.length) {
            const sinRepetidos = resultadoBusqueda.filter(colaborador => !filtrados.some( colaborador2 => colaborador.id === colaborador2.id))
            setFiltrados(prev => [...prev, ...sinRepetidos]) 
          }else{
            //TODO: fetch remoto
            //setFiltrados([])            
          }
          
          pageNumber++;
        }
      }
      const data = queryClient.getQueryData(["colaboradoresPublico", 1]);
    }*/
  };

  const [page, setPage] = useState(1);
  let lastPageNumber = null;

  const { data, error, isError, isLoading, isFetching, hasNextPage } =
    useInfiniteQuery({
      queryKey: ["colaboradoresPublico", page, busqueda],
      queryFn: () => getColaboradoresPublica(page, busqueda),
      getPreviousPageParam: (lastPage) => {
        lastPageNumber = lastPage.colaboradores.last_page;
        return lastPage.colaboradores.current_page ===
          lastPage.colaboradores.last_page
          ? lastPage.colaboradores.current_page - 1
          : undefined; // If there is not a next page, getNextPageParam will return undefined and the hasNextPage boolean will be set to 'false'
      },
      getNextPageParam: (lastPage) => {
        return lastPage.colaboradores.current_page <
          lastPage.colaboradores.last_page
          ? lastPage.colaboradores.current_page + 1
          : undefined; // If there is not a next page, getNextPageParam will return undefined and the hasNextPage boolean will be set to 'false'
      },
      keepPreviousData: true,
    });

  if (isError && !isLoading) return <Text>Hubo un error</Text>;

  const NavigationButtons = () => (
    <View style={tw`flex flex-row items-center justify-evenly m-2`}>
      <Button
        title="Anterior"
        disabled={page === 1 || isLoading}
        onPress={() => setPage((p) => p - 1)}
      />
      {isFetching ? (
        <Loader text="Cargando colaboradores..." />
      ) : (
        <Text>
          {page}/{lastPageNumber}
        </Text>
      )}

      <Button
        title="Siguiente"
        disabled={!hasNextPage || isLoading}
        onPress={() => setPage((p) => p + 1)}
      />
    </View>
  );

  return (
    <View style={tw`p-4 flex-1`}>
      <TextInput
        style={[
          tw` w-full p-3 text-gray-700 bg-white border border-solid border-gray-300`,
          { borderRadius: 23 },
        ]}
        placeholder="Buscar por nombre"
        value={textoBusqueda}
        onChangeText={handleChangeText}
        disabled={isLoading}
      />
      <View style={tw`flex flex-col gap-2 items-start justify-start bg-white rounded-md p-2 mx-auto mt-5`}>
        <Text>Referencia:</Text>
        <View style={tw`flex flex-row items-center justify-center gap-2`}>
          <View style={tw`w-[10px] h-[10px] bg-amber-200`}></View>
          <Text>De Licencia</Text>
        </View>
        <View style={tw`flex flex-row items-center justify-center gap-2`}>
          <View style={tw`w-[10px] h-[10px] bg-gray-200`}></View>
          <Text>Inactivo</Text>
        </View>
      </View>
      <FlatList
        style={tw`flex-1 md:w-2/3 m-auto`}
        data={data?.pages[0].colaboradores?.data}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => (
          <TouchableOpacity
            style={[
              tw`bg-white p-3 mt-2 border border-gray-300 flex-row`,
              esWeb() ? tw`shadow-md` : "",
              item.licencia_activa?.length
                ? tw`bg-amber-200`
                : tw`bg-white-400`,
              item.activo !== 1
                ? tw`bg-gray-200 opacity-50`
                : '',
              { borderRadius: 16 },
            ]}
            onPress={() => {
              setColaboradorSeleccionado(item),
                setModalDetalleColaborador(true);
            }}
          >
            <View style={tw`items-center justify-center`}>
              <ColaboradorAvatar size={32} foto={item.foto} key={item.id} />
            </View>
            <View style={tw`ml-2`}>
              <Text
                style={tw`text-lg capitalize`}
              >{`${item.nombre} ${item.apellido}`}</Text>
              <View style={tw`flex flex-row items-center justify-start mt-1`}>
                <FontAwesome5
                  name="building"
                  size={10}
                  color={Colores.primary}
                  style={tw`mr-1`}
                />
                <Text>
                  {item?.puesto_actual.length
                    ? item.puesto_actual[0].nombre
                    : "-"}
                </Text>
              </View>
              <View style={tw`flex flex-row items-center justify-start mt-1`}>
                <FontAwesome5
                  name="envelope"
                  size={10}
                  color={Colores.primary}
                  style={tw`mr-1`}
                />
                <Text>
                  {item?.empresa_actual.length
                    ? item.empresa_actual[0].nombre
                    : "-"}
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        )}
        //onEndReached={() => hasNextPage && fetchNextPage()}
        onEndReachedThreshold={0.5}
        showsVerticalScrollIndicator={false}
        ListFooterComponent={!isFetching ? <NavigationButtons /> : null}
        ListHeaderComponent={
          isLoading ? (
            <Loader text="Cargando colaboradores..." />
          ) : (
            <NavigationButtons />
          )
        }
      />
      <ColaboradorModalDetalle
        modalVisible={modalDetalleColaborador}
        setModalVisible={setModalDetalleColaborador}
        colaboradorSeleccionado={colaboradorSeleccionado}
      />
    </View>
  );
}

export default BuscarColaboradorScreen;
