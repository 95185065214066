import { Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import tw from 'twrnc';
import { useNavigation } from '@react-navigation/native';

const ItemCard = ({ icon, title, ruta}) => {

  const navigator = useNavigation()

  return (
    <TouchableOpacity style={[tw`p-5 shadow-md items-center flex-row bg-white`,{borderRadius:10}]} onPress={()=>navigator.navigate(ruta)}>
        <View style={tw`justify-center flex-2 items-center`}>
          {icon}
        </View>
        <View style={tw`justify-center flex-7 pb-1`}>
          <Text>{title}</Text>
        </View>
    </TouchableOpacity>
  )
}

export default ItemCard