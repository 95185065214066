import React from "react";
import { View } from "react-native";

import { PageContainer } from "../../components/PageContainer";
import { PlatoEditarForm } from "../../forms/PlatoEditarForm";

function EditarPlatoScreen(props) {
  const { plato } = props.route.params;
  return (
    <PageContainer paddingMd >
      <View style={{ flex: 1 }}>
        <PlatoEditarForm plato={plato} {...props} />
      </View>
    </PageContainer>
  );
}

export default EditarPlatoScreen;
