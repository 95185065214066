import React, { useState } from "react";
import { FlatList, Image, Pressable, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { FontAwesome5 } from "@expo/vector-icons";
import { Feather } from "@expo/vector-icons";
import * as WebBrowser from "expo-web-browser";
import { AntDesign } from "@expo/vector-icons";
import tw from 'twrnc';

import { IconButton } from "../../components/IconButton";
import {
  eliminarSugerencia,
  getSolicitudesPlanDeCarrera,
} from "../../services/apiClient";
import DataTable from "../../components/DataTable/DataTable";
import { formatoFechaES } from "../../utils/fechas";
import AwesomeAlert from "react-native-awesome-alerts";
import { awesomeAlertAtom } from "../../store/store";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import ResultadoModal from "../../components/ResultadoModal";
import ColaboradorAvatar from "../../components/Colaboradores/ColaboradorAvatar";
import { PageContainer } from "../../components/PageContainer";

function AdministrarPlanDeCarreraScreen(props) {
  const sugerenciasQuery = useQuery(
    "solicitudesPlanDeCarreras",
    getSolicitudesPlanDeCarrera
  );
  const [sugerenciaId, setsugerenciaId] = useState();
  const [awesomeAlert, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);

  const queryClient = useQueryClient();

  let solicitudesPlanDeCarrera = [];
  if (sugerenciasQuery.data && sugerenciasQuery.data.data?.ok) {
    solicitudesPlanDeCarrera = sugerenciasQuery.data.data.carreras;
  }

  const getSolicitud = (solicitudId) => {
    const solicitud = solicitudesPlanDeCarrera.filter(
      (solicitud) => solicitud.id === solicitudId
    );
    if (solicitud.length) {
      console.log(solicitud);
      //duevele el primer colaborador que se econtro con ese id
      return solicitud[0];
    }
    console.log("No se encontro la solicitud para el id ", solicitudId);
    return;
  };

  const confirmar = (sugerenciaId) => {
    setsugerenciaId(sugerenciaId);
    setawesomeAlert({
      show: true,
      showProgress: eliminarMutate.isLoading,
      title: "Atención",
      message: "Esta seguro de desea eleiminar?",
      showCancelButton: true,
      showConfirmButton: true,
      confirmText: "Eliminar",
      confirmButtonColor: "#DD6B55",
      onConfirmPressed: () => {
        console.log("eliminando");
        eliminarMutate.mutate(sugerenciaId);
      },
    });
  };

  return (
      <View>
    <PageContainer paddingMd>
  
        <View style={[tw`p-5 bg-white`,{borderRadius:20}]}>
            <Text>Planes de carrera</Text>
            <FlatList
            data={solicitudesPlanDeCarrera}
            keyExtractor={(item) => item.id}
            renderItem={({item})=>(
              <View>
                  <Pressable onPress={() => {
                    const solicitud = getSolicitud(item.id);
                    props.navigation.navigate("Detalle Solicitud Plan De Carrera", {
                      solicitud,
                    });
                  }}>


                <View style={tw`flex flex-row border border-gray-300 rounded-lg my-2 shadow-sm`}>

                    <Image style={tw`h-25 w-25 rounded-l-lg`} source={item.colaborador.foto} />

                    <View style={tw`p-2`}>
                        <Text style={tw`capitalize font-semibold text-lg`}>
                           {`${item.colaborador.nombre} ${item.colaborador.apellido}`} 
                        </Text>
                        <Text style={tw``}>
                          <Text style={tw`font-semibold mr-1`}>
                            Interes:
                          </Text>
                          {item?.empresa?.nombre}
                        </Text>
                        <Text style={tw``}>
                          <Text style={tw`font-semibold mr-1`}>Departamento:</Text>
                          {item?.departamento?.nombre}
                        </Text>
                        <Text style={tw``}>
                          <Text style={tw`font-semibold mr-1`}>Puesto:</Text>
                          {item?.puesto?.nombre}
                        </Text>
                    </View>

                </View>


              </Pressable>

                <TouchableOpacity
                  style={tw`p-1 absolute right-2 top-3`}
                  onPress={() => confirmar(item?.id)}
                >
                    <FontAwesome5 name="trash-alt" size={20} style={tw`text-red-500`} />
                </TouchableOpacity>
              </View>
            )}
            />
        </View>

      </PageContainer>
        <AwesomeAlert
          show={awesomeAlert.show}
          showProgress={awesomeAlert.showProgress}
          title={awesomeAlert.title}
          message={awesomeAlert.message}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={awesomeAlert.showCancelButton}
          showConfirmButton={awesomeAlert.showConfirmButton}
          cancelText="Cancelar"
          confirmText={awesomeAlert.confirmText}
          confirmButtonColor={awesomeAlert.confirmButtonColor}
          customView={awesomeAlert.customView}
          onCancelPressed={() => {
            resetAwesomeAlert();
          }}
          onConfirmPressed={awesomeAlert.onConfirmPressed}
          />
      </View>
  );
}

export default AdministrarPlanDeCarreraScreen;
