import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getTiposItem } from "../../../services/apiClient";
import { Text, View, TouchableOpacity } from "react-native";
import { Loader } from "../../Loader";
import tw from "twrnc";

const BotonesTipoItems = ({setFiltro}) => {
  const { data, isFetching, isError, refetch } = useQuery(
    "TiposItem",
    getTiposItem
  );
  const [tipo, setTipo] = useState({ id: 0, tipo: "Todos" });

  useEffect(() => {
    setFiltro(tipo)
  }, [tipo])

  const tipos = [
    <TouchableOpacity
      style={tw`px-3 py-1 rounded-xl ${
        tipo.tipo.toLocaleLowerCase() === "todos" ? "bg-gray-200" : ""
      }`}
      onPress={() => setTipo({ id: 0, tipo: "Todos" })}
    >
      <Text style={tw`capitalize`}>Todos</Text>
    </TouchableOpacity>,
  ];
  if (data && data?.data.tipos.length) {
    data.data.tipos.map((tipoItem) => {
      tipos.push(
        <TouchableOpacity
          style={tw`px-2 py-1 rounded-xl ${
            tipo.tipo.toLocaleLowerCase() === tipoItem.tipo.toLocaleLowerCase()
              ? "bg-gray-200"
              : ""
          }`}
          onPress={() => setTipo(tipoItem)}
        >
          <Text style={tw`capitalize`}>{tipoItem.tipo.toLowerCase()}</Text>
        </TouchableOpacity>
      );
    });
  }

  if (isError) {
    return (
      <TouchableOpacity
        style={[
          tw`m-2 max-w-[180px] p-2 rounded-md`,
          { backgroundColor: Colores.warning },
        ]}
        onPress={() => refetch()}
      >
        <Text>Reintentar cargar tipos de items</Text>
      </TouchableOpacity>
    );
  }

  return (
    <View style={tw`bg-white p-2`}>
      {isFetching ? (
        <Loader flex={0} text="Cargando filtro de activos" />
      ) : isError ? (
        <Text>Error al cargar los tipos</Text>
      ) : (
        <View style={tw`flex flex-row flex-wrap items-start justify-center gap-4`}>
          {tipos.map((tipo) => tipo)}
        </View>
      )}
    </View>
  );
};

export default BotonesTipoItems;
