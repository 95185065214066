import React from "react";
import { Text, View } from "react-native";
import tw from 'twrnc';
import { FontAwesome5 } from "@expo/vector-icons";
import Badge from "./Badge";

export default function PermisosBadge({ permisos }) {
  if (!permisos.length) {
    return <Text>No tiene</Text>;
  }
 
    return permisos.map((permiso) => {
      if (permiso) {
        if (permiso?.departamento && permiso.entidad?.includes('licencia')) {
          return <View style={tw`flex flex-row items-center justify-center`}>
            <Text style={tw`p-2 bg-green-200 rounded-l-xl capitalize text-xs text-green-700 font-semibold text-center`}>{permiso.entidad}</Text>
            <View style={tw`flex flex-row items-center justify-center p-2 bg-gray-200 rounded-r-xl `}>
              <FontAwesome5 name="building" size={14} color="black" />
              <Text style={tw`capitalize text-xs text-gray-700 font-semibold text-center`}>{permiso.departamento}</Text>
            </View>
          </View>
        }
        return <Badge key={`permiso-${permiso.id}-${permiso.entidad}`} color="green" text={permiso.entidad} />;
      }
    });

}
