import React from "react";
import { View, Text } from "react-native";

import { PageContainer } from "../components/PageContainer";

export default function UserScreen({ navigation }) {
  return (
    <PageContainer>
      <View style={{ flexShrink: 1, padding: "10px" }}>
        <Text>User Screen</Text>
      </View>
    </PageContainer>
  );
}
