import React, { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import DateTimePicker from "@react-native-community/datetimepicker";
import { AntDesign } from "@expo/vector-icons";
import moment from "moment";
import tw from "twrnc";

//import "react-datepicker/dist/react-datepicker.css";

import { Colores } from "../../constants/colores";
import { esWeb } from "../../utils/plataforma";
import WebDatePicker from "../WebDatePicker";

function FechaInicioFin({
  inicio,
  fin,
  soloFechaInicio = false,
  bloquearFechasPrevias = true,
}) {
  const [fechaInicio, setfechaInicio] = useState(new Date());
  const [fechaFin, setfechaFin] = useState(new Date());
  const [showDateTimePickerInicio, setshowDateTimePickerInicio] =
    useState(false);
  const [showDateTimePickerFin, setshowDateTimePickerFin] = useState(false);

  const validarFechaFin = (fechainicio, fechafin) => {
    const fechaFinEsAnteriorAInicio =
      moment(fechafin).isSameOrBefore(fechainicio);
    if (fechaFinEsAnteriorAInicio) {
      setfechaFin(fechainicio);
      fin(fechainicio); //en componente padre
    }
  };

  return (
    <View
      style={[
        tw`border border-gray-300 p-3 content-center`,
        { borderRadius: 20 },
      ]}
    >
      <View style={tw`flex-row items-center z-50`}>
        <Text style={[tw`mr-2`, { color: Colores.primary }]}>Fecha inicio</Text>
        <View style={tw` w-4/5 flex items-left`}>
          {esWeb() ? (
            <WebDatePicker
              key="fecha-inicio"
              selected={fechaInicio}
              minDate={bloquearFechasPrevias ? new Date() : null}
              onChange={(date) => {
                validarFechaFin(date, fechaFin);
                setfechaInicio(date);
                inicio(date.toISOString().split("T")[0]); //en componente padre
                if (soloFechaInicio) {
                  setfechaFin(date);
                  fin(date.toISOString().split("T")[0]); //en componente padre
                }
              }}
            />
          ) : (
            <View style={tw`flex flex-row`}>
              {showDateTimePickerInicio ? (
                <DateTimePicker
                  value={fechaInicio}
                  onChange={(event, date) => {
                    if (event.type == "set") {
                      setfechaInicio(date);
                      setshowDateTimePickerInicio(false);
                      validarFechaFin(date, fechaFin);
                      inicio(date); //en componente padre
                      if (soloFechaInicio) {
                        setfechaFin(date);
                        fin(date);
                      }
                    } else {
                      //cancel button clicked
                      setshowDateTimePickerInicio(false);
                    }
                  }}
                  minimumDate={new Date()}
                />
              ) : null}
              <TouchableOpacity
                onPress={() =>
                  setshowDateTimePickerInicio(!showDateTimePickerInicio)
                }
                style={[
                  tw`flex flex-row items-center justify-center border-2 border-indigo-400 px-4 py-2`,
                  { backgroundColor: "white", borderRadius: 15 },
                ]}
              >
                <AntDesign name="calendar" size={20} color={Colores.primary} />
                <Text style={tw`ml-2 text-center`}>
                  {moment(fechaInicio).format("D/MM/YYYY")}
                </Text>
              </TouchableOpacity>
            </View>
          )}
          <Text style={tw`mt-2 text-left`}>
            Indica tu primer dia de Licencia
          </Text>
        </View>
      </View>
      {!soloFechaInicio ? (
        <View style={tw`flex-row mt-2 items-center z-40`}>
          <Text style={[tw`mb-2 mt-3 mr-6`, { color: Colores.primary }]}>
            Fecha fin
          </Text>
          <View style={tw`mt-2`}>
            {esWeb() ? (
              <WebDatePicker
                key="fecha-fin"
                selected={fechaFin}
                minDate={fechaInicio}
                onChange={(date) => {
                  validarFechaFin(fechaInicio, date);
                  setfechaFin(date);
                  fin(date.toISOString().split("T")[0]); //en componente padre
                }}
              />
            ) : (
              <View style={tw`flex flex-row`}>
                {showDateTimePickerFin ? (
                  <DateTimePicker
                    key="fecha-fin"
                    value={fechaFin}
                    onChange={(event, date) => {
                      if (event.type == "set") {
                        setfechaFin(date);
                        setshowDateTimePickerFin(false);
                        validarFechaFin(fechaInicio, date);
                        fin(date);
                      } else {
                        //cancel button clicked
                        setshowDateTimePickerFin(false);
                      }
                    }}
                    minimumDate={new Date()}
                  />
                ) : null}
                <TouchableOpacity
                  onPress={() =>
                    setshowDateTimePickerFin(!showDateTimePickerFin)
                  }
                  style={[
                    tw`flex flex-row items-center justify-center border-2 border-indigo-400 px-4 py-2`,
                    { backgroundColor: "white", borderRadius: 15 },
                  ]}
                >
                  <AntDesign
                    name="calendar"
                    size={20}
                    color={Colores.primary}
                  />
                  <Text style={tw`ml-2 text-center`}>
                    {moment(fechaFin).format("D/MM/YYYY")}
                  </Text>
                </TouchableOpacity>
              </View>
            )}
            <Text style={tw`mt-2 text-left`}>
              Indica tu último dia de Licencia
            </Text>
          </View>
        </View>
      ) : null}
    </View>
  );
}

export default FechaInicioFin;
