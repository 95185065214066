export const Colores = {
    primary: '#5753dd',
    primaryLight: '#7f7fef4d',
    primaryMedium: '#7F7FEF',
    primaryStrong: '#1F0069',
    secondary: '',
    light: '',
    placeHolder: '#94a3b8',
    disabledInput: '#e2e8f0',
    disabledText: '#64748b',
    disabledBorder: '#b2b1e9',
    gray: '#e5e7eb',
    dark: '#252525',
    success: '#02B879',
    danger: '#ef4444',
    warning: '#FF9900',
    orange: '#E2541C',
    white: '#FFF',
    dangerBg: '#ffbdbdde',
    successBg: '#dcfce7',
}