import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import React from "react";
import { View } from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";

import { PageContainer } from "../../components/PageContainer";
import MiPerfilEditarForm from "../../forms/MiPerfilEditarForm";
import { awesomeAlertAtom } from "../../store/store";

function EditarMiPerfilScreen(props) {
  const { colaborador } = props.route.params;
  const [awesomeAlert, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);

  return (
    <>
      <PageContainer paddingMd >
        <View style={{ flex: 1 }}>
          <MiPerfilEditarForm colaborador={colaborador} setawesomeAlert={setawesomeAlert} resetAwesomeAlert={resetAwesomeAlert} />
        </View>
      </PageContainer>

      <AwesomeAlert
        show={awesomeAlert.show}
        showProgress={awesomeAlert.showProgress}
        title={awesomeAlert.title}
        message={awesomeAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={awesomeAlert.showCancelButton}
        showConfirmButton={awesomeAlert.showConfirmButton}
        cancelText="Cancelar"
        confirmText={awesomeAlert.confirmText}
        confirmButtonColor={awesomeAlert.confirmButtonColor}
        customView={awesomeAlert.customView}
        onDismiss={awesomeAlert.onDismiss}
        onConfirmPressed={awesomeAlert.onConfirmPressed}
        onCancelPressed={() => {
          resetAwesomeAlert();
        }}
      />
    </>
  );
}

export default EditarMiPerfilScreen;
