import { View, Text, useWindowDimensions, TouchableOpacity, Image } from 'react-native'
import React from 'react'
import { useQuery } from 'react-query';
import { getFixture } from '../../services/apiClient';
import tw from 'twrnc';
import { AntDesign } from '@expo/vector-icons';
import { LinearGradient } from "expo-linear-gradient";


const PartidosProde = ({props}) => {
    const fixture = useQuery("fixture",getFixture);
    const { width } = useWindowDimensions()

    const filtrarInfoGrupos = (grupo, round) =>{
      if(grupo && !round){
        const informacion = fixture?.data?.data.fixture.filter((item)=> item.grupo === grupo);
        props.navigation.navigate("Detalle Prode",{informacion})
      } 
      if(!grupo && round){
        const informacion = fixture?.data?.data.fixture.filter((item)=> item.round === round);
        props.navigation.navigate("Detalle Prode",{informacion})
      }
    }

  return (
    <View>

          <View style={tw`items-center justify-center mt-4 mb-1`}>
            <Text style={tw`text-lg font-semibold`}>TUS PRONOSTICOS</Text>
          </View>

          <LinearGradient style={tw`px-3 my-3`} end={{ x: 0.5, y: 0.1 }} colors={['#80082c','#FFF']} >
            <Text style={tw`text-lg text-white`}>GRUPOS </Text>
          </LinearGradient>

          <View style={tw`px-2 mb-5`}>

            {/* <TouchableOpacity style={tw`flex-row border border-gray-200 p-2 rounded-lg shadow-md`} onPress={()=>props.navigation.navigate("Detalle Prode")}> */}
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos("A")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>GRUPO A</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos("B")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>GRUPO B</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos("C")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>GRUPO C</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos("D")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>GRUPO D</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos("E")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>GRUPO E</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos("F")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>GRUPO F</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos("G")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>GRUPO G</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos("H")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>GRUPO H</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>

          </View>

          <LinearGradient style={tw`px-3 mt-3`} end={{ x: 0.5, y: 0.1 }} colors={['#80082c','#FFF']} >
            <Text style={tw`text-lg text-white`}>OCTAVOS DE FINAL</Text>
          </LinearGradient>

          <View style={tw`px-2 mt-3 mb-5`}>
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos(null,"R16")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>OCTAVOS</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>
          </View>

          <LinearGradient style={tw`px-3 mt-3`} end={{ x: 0.5, y: 0.1 }} colors={['#80082c','#FFF']} >
            <Text style={tw`text-lg text-white`}>CUARTOS DE FINAL</Text>
          </LinearGradient>

          <View style={tw`px-2 mt-3 mb-5`}>
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos(null,"QF")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>CUARTOS</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>
          </View>

          <LinearGradient style={tw`px-3 mt-3`} end={{ x: 0.5, y: 0.1 }} colors={['#80082c','#FFF']} >
            <Text style={tw`text-lg text-white`}>SEMIFINAL</Text>
          </LinearGradient>

          <View style={tw`px-2 mt-3 mb-5`}>
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos(null,"SF")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>SEMIFINAL</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>
          </View>

          <LinearGradient style={tw`px-3 mt-3`} end={{ x: 0.5, y: 0.1 }} colors={['#80082c','#FFF']} >
            <Text style={tw`text-lg text-white`}>3ER Y 4TO PUESTO</Text>
          </LinearGradient>

          <View style={tw`px-2 mt-3 mb-5`}>
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos(null,"3PPO")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>3ER Y 4TO PUESTO</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>
          </View>

          <LinearGradient style={tw`px-3 mt-3`} end={{ x: 0.5, y: 0.1 }} colors={['#80082c','#FFF']} >
            <Text style={tw`text-lg text-white`}>FINAL</Text>
          </LinearGradient>

          <View style={tw`px-2 mt-3 mb-5`}>
            <TouchableOpacity style={tw`flex-row border border-gray-200 p-3 mt-3 rounded-lg shadow-md`} onPress={()=>filtrarInfoGrupos(null,"F")}>
              <View style={tw`flex-2 justify-center`}>
                  <Text style={tw`font-semibold`}>FINAL</Text>
              </View>
              <View style={tw`flex-1 items-end justify-center`}>
                <AntDesign name="right" size={24} color="#80082c" />
              </View>
            </TouchableOpacity>
          </View>
    </View>
  )
}

export default PartidosProde