import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getEmpresas } from "../services/apiClient";
import { Loader } from "./Loader";
import RadioButton from "./RadioButton";

const RadioButtonsEmpresas = ({ onSelect }) => {
  const { isLoading, isError, data, error } = useQuery(
    "empresas",
    getEmpresas,
    { cacheTime: 0 }
  );
  const [empresas, setEmpresas] = useState([]);

  useEffect(() => {
    if (data && data?.data?.ok) {        
        const opciones = data.data.empresas.filter(empresa => empresa.id !== 1).map(empresa => ({label: empresa.nombre, value: empresa.id}));
        setEmpresas([{label: "Todas", value: "0"}, ...opciones]);
      }
  }, [data])
  
  if(isLoading){
    return <Loader text="Cargando filtro por empresas" />
  }

  return (
    <RadioButton
      setIcons={false}
      data={empresas}
      defaultSelected={null}
      onSelect={(value) => onSelect(value)}
    />
  );
};

export default RadioButtonsEmpresas;
