import React, { useEffect, useState } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { PageContainer } from "../../components/PageContainer";
import tw from 'twrnc';

import { LinearGradient } from 'expo-linear-gradient';
import ColaboradorData from "../../components/Colaboradores/ColaboradorData";


function DetalleColaboradorScreen(props) {
  const { colaborador } = props.route.params;
  const [dataColaborador, setdataColaborador] = useState(colaborador)
  useEffect(() => {
    console.log('cambio')
    if(colaborador){
      setdataColaborador(colaborador)
    }
  }, [colaborador])


  return (
    <PageContainer>
      <ScrollView>
        <LinearGradient style={[tw`w-full h-70 absolute top-0`,StyleSheet.absoluteFill]} colors={['rgba(92, 91, 229, 0.98)','rgba(92, 91, 229, 0.26)']}/>
        <ColaboradorData dataColaborador={dataColaborador} editar props={props} />
      </ScrollView>
    </PageContainer>
  );
}

export default DetalleColaboradorScreen;
