import React from "react";
import { View, Text, ScrollView, useWindowDimensions } from "react-native";
import { PageContainer } from "../../components/PageContainer";
import SugerenciaForm from "../../forms/SugerenciaForm";
import tw from 'twrnc';

export default function SugerenciasScreen({ navigation }) {
  const {width} = useWindowDimensions()
  return (
    <>
      <ScrollView>
        <PageContainer paddingMd >
            <SugerenciaForm />
        </PageContainer>
      </ScrollView>
    </>
  );
}
