import React from "react";
import { Text, View } from "react-native";
import BotonCertificadoLicencia from "../../components/Licencias/BotonCertificadoLicencia";
import tw from 'twrnc';

import LicenciaEstado from "../../components/Licencias/LicenciaEstado";
import { PageContainer } from "../../components/PageContainer";
import { formatoFechaES } from "../../utils/fechas";

function DetalleLicenciaScreen(props) {
  const { licencia } = props.route.params;
  const { solicitante, empresa, autorizante, estado, opcion } = licencia;
  return (
    <PageContainer>
      <View style={tw`flex py-3 px-4 border border-gray-300 rounded-lg m-2 shadow-lg`}>
        <Text style={tw`text-lg font-semibold`}>Solicitante</Text>

        <View style={tw`flex flex-row mt-2`}>
          <Text style={tw` font-semibold`}>
            Nombre y Apellido: 
          </Text>
          <Text style={tw` ml-1`}>
            {solicitante.nombre}, {solicitante.apellido}
          </Text>
        </View>

        <View style={tw`flex flex-row mt-2`}>
          <Text style={tw` font-semibold`}>
            DNI: 
          </Text>
          <Text style={tw` ml-1`}>
          {solicitante.dni}
          </Text>
        </View>

        <View style={tw`flex flex-row mt-2`}>
          <Text style={tw` font-semibold`}>
            Empresa: 
          </Text>
          <Text style={tw` ml-1`}>
            {empresa.nombre}
          </Text>
        </View>

        <View style={tw`flex flex-row mt-2`}>
          <Text style={tw` font-semibold`}>
          Tipo de licencia:
          </Text>
          <Text style={tw` ml-1`}>
          {opcion.opcion}
          </Text>
        </View>

        <View style={tw`absolute right-1 top-0`}>
          {/* <Text>Estado</Text> */}
          <LicenciaEstado estado={estado.estado} />
        </View>

        <View style={tw`flex flex-row mt-2`}>
              {autorizante && (
                <>
                  <View style={tw`flex flex-row`}>
                    <Text style={tw` font-semibold`}>Autorizante:</Text>
                    <Text style={tw` ml-1`}>{`${autorizante.nombre}, ${autorizante.apellido}`}</Text>
                  </View>
             
                  <View style={tw`flex flex-row ml-3`}>
                    <Text style={tw` font-semibold`}>DNI:</Text>
                    <Text style={tw` ml-1`}>{autorizante.dni}</Text>
                  </View>
                </>
              )}
        </View>

        <View style={tw`flex flex-row mt-2`}>
          <View style={tw`flex flex-row`}>
              <Text style={tw` font-semibold`}>
                Fecha Inicio:
              </Text>
              <Text style={tw` ml-1`}>
                {formatoFechaES(licencia?.inicio)}
              </Text>
          </View>
          <View style={tw`flex flex-row ml-3`}>
              <Text style={tw` font-semibold`}>
                Fecha Fin:
              </Text>
              <Text style={tw` ml-1`}>
                {formatoFechaES(licencia?.fin)}
              </Text>
          </View>
        </View>

        <View style={tw`flex flex-row mt-2`}>
          <Text style={tw` font-semibold`}>
            Horario:
          </Text>
          <Text style={tw` ml-1`}>
            {licencia.horario ?? "-"}
          </Text>
        </View>


        <View style={tw`flex flex-row mt-2`}>
          <Text style={tw` font-semibold`}>
            Dias:
          </Text>
          <Text style={tw` ml-1`}>
            {licencia.dias ?? "-"}
          </Text>
        </View>

        <View style={tw`flex flex-row mt-2`}>
          <Text style={tw` font-semibold`}>
            Motivo: 
          </Text>
          <Text style={tw` ml-1`}>
            {licencia.motivo ?? "-"}
          </Text>
        </View>

        <View style={tw`flex flex-row mt-2`}>
          <Text style={tw` font-semibold`}>
            Extension:
          </Text>
          <Text style={tw` ml-1`}>
            {licencia.extension ? formatoFechaES(licencia.extension) : "-"}
          </Text>
        </View>

        <View style={tw`flex flex-row mt-2`}>
          <Text style={tw` font-semibold `}>
            Observaciones:
          </Text>
          <Text style={tw` ml-1`}>
            {licencia.observaciones ?? "-"}
          </Text>
        </View>

        <Text style={tw` mt-2 font-semibold`}>Certificado:</Text>
        {licencia.certificado ? (
          <BotonCertificadoLicencia url={licencia.certificado} />
        ) : (
          <Text style={tw` my-2`}>No presentado</Text>
        )}
      </View>
    </PageContainer>
  );
}

export default DetalleLicenciaScreen;
