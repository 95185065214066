import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { useMutation, useQueryClient } from "react-query";
import tw from "twrnc";
import { eliminarCodigoInvitacion } from "../../services/apiClient";
import { useAtom } from "jotai";
import { awesomeAlertAtom } from "../../store/store";
import { useResetAtom } from "jotai/utils";
import ResultadoModal from "../ResultadoModal";
import { Loader } from "../Loader";

const Codigo = ({ codigoData, onDelete }) => {
  const fecha = new Date(codigoData.createdAt);
  return (
    <View style={tw`p-2 bg-white rounded-md shadow-md`}>
      <View style={tw`flex flex-row items-center`}>
        <View style={tw`flex flex-row items-center flex-1`}>
          <Text>Código: </Text>
          <Text style={tw`font-semibold`}>{codigoData.code} </Text>
        </View>
        <TouchableOpacity
          style={tw`p-2`}
          onPress={() => onDelete.mutate(codigoData.code)}
        >
          {onDelete.isLoading ? (
            <Loader text="eliminado" />
          ) : (
            <Text style={tw`text-red-400`}>Eliminar</Text>
          )}
        </TouchableOpacity>
        <Text style={tw`text-xs bg-indigo-300 p-1 rounded-md text-white`}>
          Creado: {fecha.toLocaleDateString()}
        </Text>
      </View>
    </View>
  );
};

const CodigosExistentes = ({ codigos = [] }) => {
  const queryClient = useQueryClient();
  const [_, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);

  const eliminarMutate = useMutation(eliminarCodigoInvitacion, {
    onSuccess: async (response) => {
      console.log("sugerencia eliminado", response.data);
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Exito!",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal success={true} message="Código eliminado" />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
      queryClient.invalidateQueries("codigosInvitacion");
    },
    onError: async (error) => {
      console.log(
        "hubo un error al intentar eliminar",
        error.response.data.message
      );
      //setmostrarAlerta(false)
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Error",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal
            success={false}
            message={error?.response?.data?.message}
          />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
    },
  });

  return (
    <View style={tw`flex flex-col justify-center gap-2`}>
      <Text style={tw`font-semibold p-2 bg-white rounded-md shadow-md`}>
        CÓDIGOS DE INVITACIÓN
      </Text>
      {codigos.length ? (
        codigos.map((codigo) => (
          <Codigo
            key={codigo.id}
            codigoData={codigo}
            onDelete={eliminarMutate}
          />
        ))
      ) : (
        <Text>No hay codigos registrados</Text>
      )}
    </View>
  );
};

export default CodigosExistentes;
