import React from "react";
import { StyleSheet, View } from "react-native";
import {
  Table,
  TableWrapper,
  Row,
  Rows,
  Col,
} from "react-native-table-component-2";
import tw from 'twrnc';
import { Colores } from "../../constants/colores";

function TablaEstimativo({ data, tableHead, tableTitle }) {
  if (!data.length) {
    return null;
  }
  const totalxColumnas = totalPorColumna(data);
  const cantidadDeColumnas = data[0].length;
  const flexArr = Array.from({ length: cantidadDeColumnas }, (_, i) => 1);
  const widthArr = Array.from({ length: cantidadDeColumnas }, (_, i) => 75);

  return (
    <View style={tw`border border-gray-300 rounded-lg mt-3`}>
      <Table style={{ minWidth: 300 }} borderStyle={tw``}>
        <Row data={["Empresa", ...tableHead]} flexArr={flexArr} style={[tw`h-20`]} textStyle={[tw`text-center font-bold`]}/>
        <TableWrapper style={styles.wrapper}>
          <Col data={[...tableTitle, "Totales"]} style={[tw`border border-gray-300 rounded-lg shadow-lg`]} textStyle={[tw`text-center`]} />
          <Rows data={[...data, totalxColumnas]} style={[tw`border-gray-300`,styles.row]} flexArr={flexArr} textStyle={[tw`text-center`]} />
        </TableWrapper>
      </Table>
    </View>
  );
}

const styles = StyleSheet.create({
  container: { paddingTop: 30, backgroundColor: "#fff" },
  head: { height: 40, backgroundColor: "#f1f8ff" },
  wrapper: { flexDirection: "row" },
  row: { height: 80, borderTopWidth:1 },
});

const totalPorColumna = (data) => {
  if (!data.length) return;
  const cantidadDeColumnas = data[0].length;
  const filaTotales = [];
  for (let i = 0; i < cantidadDeColumnas; i++) {
    const totalColumna = 0;
    data.forEach((row) => {
      totalColumna += row[i];
    });
    filaTotales.push(totalColumna);
  }
  return filaTotales;
};

export default TablaEstimativo;
