import React, { useEffect, useState } from "react";
import { PageContainer } from "../../components/PageContainer";
import { Text, View } from "react-native";
import { useQuery } from "react-query";
import { getCodigosDeInvitacion } from "../../services/apiClient";
import { Loader } from "../../components/Loader";
import CodigosExistentes from "../../components/CodigosInvitacion/CodigosExistentes";
import CodigoInvitacionForm from "../../forms/CodigoInvitacionForm";
import tw from "twrnc";
import AwesomeAlert from "react-native-awesome-alerts";
import { useAtom } from "jotai";
import { awesomeAlertAtom } from "../../store/store";
import { useResetAtom } from "jotai/utils";

const CodigosInvitacionScreen = () => {
  const { data, isLoading, isError } = useQuery(
    "codigosInvitacion",
    getCodigosDeInvitacion
  );
  const [codigos, setCodigos] = useState([]);
console.log(codigos)
  useEffect(() => {
    if (data?.data?.invitationCodes) {
      setCodigos(data.data.invitationCodes);
    }
  }, [data]);

  const [awesomeAlert, _] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);

  return (
    <PageContainer paddingMd>
      <View style={tw`flex flex-col justify-center gap-8`}>
        {isLoading ? (
          <Loader text="Cargando codigos" />
        ) : (
          <CodigosExistentes codigos={codigos} />
        )}
        <CodigoInvitacionForm />
      </View>
      <AwesomeAlert
        show={awesomeAlert.show}
        showProgress={awesomeAlert.showProgress}
        title={awesomeAlert.title}
        message={awesomeAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={awesomeAlert.showCancelButton}
        showConfirmButton={awesomeAlert.showConfirmButton}
        cancelText="Cancelar"
        confirmText={awesomeAlert.confirmText}
        confirmButtonColor={awesomeAlert.confirmButtonColor}
        customView={awesomeAlert.customView}
        onCancelPressed={() => {
          resetAwesomeAlert();
        }}
        onConfirmPressed={awesomeAlert.onConfirmPressed}
      />
    </PageContainer>
  );
};

export default CodigosInvitacionScreen;