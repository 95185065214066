import React from "react";
import { Image, Text, View } from "react-native";
import tw from "twrnc"

import { PageContainer } from "../../components/PageContainer";

function DetallePlatoScreen(props) {
  const { plato } = props.route.params;
  
  return (
    <PageContainer>
      <View style={tw`p-2`}>
        <View style={tw`border border-gray-300 rounded-lg`}>
          {plato.foto ? (
            <Image
              style={[tw` h-[10rem]`, { width: "100%",borderTopRightRadius:10,borderTopLeftRadius:10}]}
              source={{ uri: plato.foto }}
            />
          ) : null}
          <Text style={tw`mb-1 ml-2 mt-2 text-xl font-semibold tracking-tight text-gray-900`}>{plato.nombre}</Text>
          <Text style={tw`mb-2 ml-2 text-lg font-normal text-gray-700 dark:text-gray-400`}>Descripción: {plato.descripcion}</Text>
          <View style={tw`ml-1 flex flex-row`}>
          <Text style={tw`py-2 px-4 bg-blue-300 w-20 m-1 rounded-lg shadow-md capitalize  text-center`}>{plato.tipo}</Text>
          <Text style={tw`py-2 px-4 bg-blue-300 w-30 m-1 rounded-lg shadow-md  text-center`}>{plato.habilitado ? "Habilitada" : "Desabilitada"}</Text>
          </View>
        </View>
      </View>
    </PageContainer>
  );
}

export default DetallePlatoScreen;
