import {
  Modal,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
import React from "react";
import tw from "twrnc";
import { AntDesign } from "@expo/vector-icons";
import { Colores } from "../../constants/colores";
import { Loader } from "../Loader";

const ModalCambioPassword = ({ modalVisible, setModalVisible, mutation }) => {
  const { width } = useWindowDimensions();

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        Alert.alert("Modal has been closed.");
        setModalVisible(!modalVisible);
      }}
    >
      <View
        style={[tw`bg-white flex-1 grid items-center `, width > 758 && { alignItems: "center" }]}
      >
        <View style={tw`flex-1 items-center justify-center mx-4`}>
          <View style={tw`items-center`}>
            {mutation.isLoading ? (
              <View style={tw`flex flex row center`}>
                <Loader text="Verificando token para reseteo de contraseña, aguarda por favor" />
              </View>
            ) : null}
          </View>

          {mutation.isSuccess ? (
            <View style={tw`flex flex-col items-center justify-center gap-4 border border-green-400 rounded-md p-4`}>
              <View style={tw`flex-1`}>
                <AntDesign
                  name="checkcircleo"
                  size={32}
                  color={Colores.success}
                  style={tw`mr-2`}
                />
              </View>
              <View style={tw`justify-center flex-6`}>
                <Text style={tw`text-lg`}>{mutation.data?.data?.message}</Text>
              </View>
            </View>
          ) : null}

          {mutation.error ? (
            <View style={tw`flex flex-col md:flex-row items-center gap-4 mt-5 border border-red-400 rounded-md p-4`}>
              <View style={tw`flex flex-col items-center justify-center flex-1`}>
                <AntDesign
                  name="closecircleo"
                  size={32}
                  color={Colores.danger}
                  style={tw`mr-2`}
                />
                <Text style={tw`text-lg`}>{mutation.error.response.data?.errors.token.join(",")}</Text>
              </View>
              <View style={tw`flex flex-col justify-center items-start`}>
                <Text style={tw`text-md`}>- Verifica tu casilla de correo e intenta con el ultimo email que recibiste.</Text>
                <Text style={tw`text-md`}>- De lo contrario inicia el proceso nuevamente o solicita a los administradores que restablezcan tu contraseña</Text>
              </View>
            </View>
          ) : null}

          <View style={tw`mt-20 items-center`}>
            <TouchableOpacity
              onPress={() => {
                setModalVisible(false);
              }}
            >
              <Text style={[tw`underline text-lg`, { color: Colores.primary }]}>
                Volver al inicio de sesión
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ModalCambioPassword;
