import { View, Text } from "react-native";
import React from "react";
import tw from "twrnc";

export default function FechaAsignacion({ fecha }) {
  let date = new Date();
  if (fecha) {
    date = new Date(fecha);
  }
  return (
    <View style={tw`flex flex-row items-center`}>
      <Text style={tw`flex-1`}>Fecha asignación: </Text>
      <Text style={tw`text-gray-500`}>
        {date.toLocaleDateString("es-AR", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </Text>
    </View>
  );
}
