import React, { useState } from "react";
import { Pressable, StyleSheet, View, Modal, Text, Image, TouchableOpacity, useWindowDimensions, ScrollView } from "react-native";
import Beneficio from "./Beneficio";
import tw from 'twrnc';
import { A } from '@expo/html-elements';
import { Colores } from "../../constants/colores";

function Beneficios({ datos }) {
  const {width} = useWindowDimensions();
  const [modalVisible, setModalVisible] = useState(false);
  const [mostrarBeneficio, setMostrarBeneficio] = useState({})

  const AbrirModal = (beneficio) =>{
    setModalVisible(true)
    setMostrarBeneficio(beneficio)
  }
  
  const ModalBeneficio = (beneficio) => {
    return (
        <Modal
          animationType="fade"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            Alert.alert('Modal has been closed.');
            setModalVisible(!modalVisible);
          }}>
              <View style={[width >= 768 ? tw`h-full w-full items-center p-5 ` : tw`h-full w-full p-5`, {backgroundColor:'#24242390'}]}>
              <ScrollView>
              <View style={width >= 768 ? tw`p-5 bg-white rounded-lg w-100` : tw`p-5 bg-white rounded-lg`}>

              <View style={tw`items-center`}>
              <Image source={{uri:mostrarBeneficio.foto}} style={[tw`h-50 w-50 rounded-lg`,{resizeMode:'contain'}]} />
              </View>

              <View style={tw`flex flex-row mt-3`}>
                <View style={tw`flex-1`}>
                  <Text style={tw`text-lg font-semibold mt-3`}>{mostrarBeneficio?.empresa}</Text>
                </View>
              </View>

              <Text style={tw`my-3 text-lg`}>{mostrarBeneficio?.beneficio}</Text>
              <A style={tw`my-3 text-lg`} href={mostrarBeneficio?.web}>{mostrarBeneficio?.web}</A>
              <View style={tw`flex-1 items-end justify-center`}>
                <Text style={[tw`py-1 px-2 text-lg rounded-xl`,{color:'#fff',backgroundColor:'#02B879', borderRadius:50}]}>{mostrarBeneficio.descuento}% OFF</Text>
              </View>
              <TouchableOpacity
                style={tw`rounded-full py-1 px-2 bg-red-500 rounded-full absolute top-2 right-2`}
                onPress={() => setModalVisible(!modalVisible)}>
                  <Text style={tw`text-white`}>X</Text>
              </TouchableOpacity>


              </View>
              </ScrollView>
              </View>

        </Modal>
    )};

  return (
    <>
      <ModalBeneficio />
      {width >= 768 ? (
        <View style={tw`flex-row mb-10`}>
            <View style={[tw`flex-1 justify-center`,{backgroundColor:Colores.primaryMedium, borderTopLeftRadius:10, borderBottomLeftRadius:10}]}>
              <Text style={tw`font-bold text-2xl text-center text-white`}>¡Mira estos descuentos!</Text>
            </View>
            <View style={tw`flex-1`}>
              <Image source={require('../../assets/descuentos.png')} style={[tw`h-25 w-full`,{borderBottomRightRadius:10, borderTopRightRadius:10}]} />
            </View>
            <View>

            </View>
        </View>
      ):(
        <Text style={tw`mt-3 text-center text-2xl font-semibold`}>¡Mirá estos descuentos!</Text>
      )}
      <View style={tw`p-2 flex-wrap flex-row justify-evenly`}>
        {datos.map((beneficio) => {
          return (
            <Pressable onPress={()=>AbrirModal(beneficio)}>
            <Beneficio key={`beneficio-${beneficio.id}`} datos={beneficio} />
            </Pressable>
          );
        })}
      </View>
    </>
  );
}

export default Beneficios;
