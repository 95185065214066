import { StyleSheet, View } from 'react-native';
import tw from 'twrnc';
import { MeGustaButton } from '../Avisos/MeGustaButton';
import SwiperFlatList from './SwiperFlatList';


export default function CarouselComponents({archivos,avisoId,megustaCount,megustaList,navigate, btnDisabled,redirigir}) {


    return (
        <View style={tw`flex-1 w-full mt-3`} onPress={redirigir}>
            <SwiperFlatList archivos={archivos} />
            <View style={tw`flex-row`}>
                <View style={tw`flex-2 justify-center items-end pr-5`}>
                    {!btnDisabled && (
                        <MeGustaButton
                            avisoId={avisoId}
                            megustaCount={megustaCount}
                            megustaList={megustaList}
                            navigate={navigate}
                            colorIcon='gray'
                        />
                    )}
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    carousel: {
        aspectRatio: 1.5,
        flexGrow: 0,
        marginBottom: 15,
        flex: 1,
    },
    item: {
        backgroundColor: 'white',
        flex: 1,
        borderRadius: 5,
        borderColor: 'white',
        elevation: 3,
    },
    imageBackground: {
        flex: 1,
    
    },
});