import React from "react";
import { View, Text } from "react-native";
import ItemCard from "../../Items/ItemCard";
import NotFound from "../../NotFound";
import tw from "twrnc";

const ItemsNoPendientes = ({ items, filtro }) => {

  return (
    <View style={tw`flex flex-col flex-wrap gap-4`}>
      <Text style={tw`text-lg font-semibold capitalize`}>{filtro?.tipo}</Text>
      <View style={tw`flex flex-row flex-wrap items-strech gap-4`}>
        {items.map((item) => (
          <ItemCard key={item.id} item={item} />
        ))}
      </View>
      {!items.length ? (
        <NotFound text="No hay activos" />
      ) : null}
    </View>
  );
};

export default ItemsNoPendientes;
