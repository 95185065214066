import React, { useState, useEffect } from "react";
import { View } from "react-native";
import { PageContainer } from "../../components/PageContainer";
import tw from "twrnc";

import { Picker } from "@react-native-picker/picker";

import { getEmpresas } from "../../services/apiClient";
import { useQuery } from "react-query";
import FirmaReciboForm from "../../forms/FirmaReciboForm";

function ConfigurarFirmaScreen(props) {
  const empresas = useQuery("empresas", getEmpresas);
  const [empresa, setEmpresa] = useState(1);
  
  return (
    <PageContainer>
      <View style={tw`px-3 h-full w-full`}>
        <Picker
          selectedValue={empresa}
          onValueChange={(itemValue, itemIndex) => setEmpresa(itemValue)}
        >
          {empresas.data?.data?.empresas.map((item) => (
            <Picker.Item
              label={item.nombre}
              value={item.id}
              key={item.nombre}
            />
          ))}
        </Picker>      
       <FirmaReciboForm empresaId={empresa}/>
      </View>
    </PageContainer>
  );
}


export default ConfigurarFirmaScreen;
