import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import React from "react";
import {
  ActivityIndicator,
  Image,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useMutation, useQueryClient } from "react-query";
import tw from 'twrnc';
import { MaterialIcons } from "@expo/vector-icons";
import { FontAwesome } from '@expo/vector-icons';


import { elegirMenu } from "../../services/apiClient";
import { awesomeAlertAtom } from "../../store/store";
import Imagen from "../Imagen";
import ResultadoModal from "../ResultadoModal";
import { Colores } from "../../constants/colores";
import { LinearGradient } from "expo-linear-gradient";
import DetallePlatoDesktop from "./DetallePlatoDesktop";

function MenuDelDiaDesktop({ menu, setMostrarModalSeleccionar }) {
  const [, setawsomeAlert] = useAtom(awesomeAlertAtom);
  const reset = useResetAtom(awesomeAlertAtom);
  const queryClient = useQueryClient();
  const elegirMenuMutate = useMutation(elegirMenu, {
    onSuccess: async (response) => {
      if (!response.data.ok) {
        setawsomeAlert({
          show: true,
          showProgress: false,
          onDismiss: reset,
          customView: (
            <ResultadoModal success={false} message={response.data.message} />
          ),
        });
        console.error(response.data.message);
      } else {
        console.log("menu seleccionado", response.data);
        queryClient.invalidateQueries("menuSemanalSeleccionado");
      }
    },
    onError: async (error) => {
      console.log("error en consulta", error);
    },
  });

  const submit = (menuId) => {
    console.log("Se selecciono el menu", menuId);
    elegirMenuMutate.mutate(menuId);
    setMostrarModalSeleccionar(false);
    
  };

  return (
    <TouchableOpacity onPress={() => submit(menu.id)} key={menu.id} style={[tw`border rounded-lg border-gray-300 mt-5 mb-7 mx-2 shadow-lg h-60`,{borderRadius:10}]}>
        <Image source={{uri:menu?.comida?.foto}} style={[tw`h-full w-full`,StyleSheet.absoluteFill,{borderRadius:10}]} />
        {/* <LinearGradient style={[tw`w-full h-full absolute top-0`,StyleSheet.absoluteFill, {borderRadius:10}]} colors={['rgba(0, 0, 0, 0.8)','rgba(0, 0, 0, 0.1)','rgba(0, 0, 0, 0)']} /> */}

        <LinearGradient style={[tw`flex-1 p-3`,{borderTopLeftRadius:10, borderTopRightRadius:10}]} colors={['rgba(0, 0, 0, 0.8)','rgba(0, 0, 0, 0.5)','rgba(0, 0, 0, 0.1)','rgba(0, 0, 0, 0)']}>
            <View style={tw``}>
              <Text style={tw`text-white text-lg font-light `}>{menu?.tipo?.tipo.charAt(0).toUpperCase() + menu?.tipo?.tipo.slice(1).toLowerCase()}</Text>
            </View>
        </LinearGradient>

        <LinearGradient style={[tw`flex-1 justify-end p-2`,{borderBottomLeftRadius:10, borderBottomRightRadius:10}]} colors={['rgba(0, 0, 0, 0)','rgba(0, 0, 0, 0.1)','rgba(0, 0, 0, 0.5)','rgba(0, 0, 0, 0.8)']}>
            <DetallePlatoDesktop plato={menu.comida} tipo="" />
            <DetallePlatoDesktop plato={menu.guarnicion} tipo="Guarnición" />
            <DetallePlatoDesktop plato={menu.postre} tipo="Postre" />
        </LinearGradient>
        {/* <LinearGradient style={[tw`w-full h-full absolute bottom-0`,StyleSheet.absoluteFill, {borderRadius:10}]} colors={['rgba(0, 0, 0, 0)','rgba(0, 0, 0, 0.1)','rgba(0, 0, 0, 0.5)','rgba(0, 0, 0, 0.8)']} /> */}

      
    </TouchableOpacity>

  );
}

export default MenuDelDiaDesktop;
