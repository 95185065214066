import React, { useState } from "react";
import { Alert, Modal, Text, View, FlatList, TouchableOpacity, Image, useWindowDimensions, ScrollView } from "react-native";
import tw from 'twrnc';
import { AntDesign } from '@expo/vector-icons';
import ColaboradorModalDetalle from "../Colaboradores/ColaboradorModalDetalle";

const ModalMegustas = ({modalVisible, setModalVisible, meGustas}) => {
  const {width} = useWindowDimensions()
  const [colaboradorSeleccionado, setColaboradorSeleccionado] = useState()
  const [modalDetalleColaborador, setModalDetalleColaborador] = useState(false)

  return (
    <View>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View style={[tw`items-center justify-center flex-1`,{backgroundColor:"#00000080"}]}>
            <View style={ width > 768 ? tw`bg-white w-100 h-100 rounded-xl` : tw`bg-white w-full h-full` }>

                <View style={[tw`justify-center p-3`,{borderBottomWidth:1, borderColor:"#dbdbdb"}]}>
                    <Text style={[tw`text-center text-lg`,{color:"#404040"}]}>Me gusta</Text>
                    <TouchableOpacity onPress={()=>setModalVisible(false)} style={tw`absolute right-3`}>
                        <AntDesign name="close" size={24} color="#404040" />
                    </TouchableOpacity>
                </View>

                <ScrollView>
                  <View>
                      <FlatList
                        data={meGustas}
                        keyExtractor={(item) => item?.id}
                        renderItem={({item})=>(
                            <TouchableOpacity style={tw`flex-row my-1`} onPress={()=>{setColaboradorSeleccionado(item),setModalDetalleColaborador(true)}}>
                                <View style={tw`justify-center flex-1 items-center`}>
                                    <Image source={{uri:item.foto}} style={tw`w-10 h-10 rounded-full`} />
                                </View>
                                <View style={tw`justify-center flex-5`}>
                                    <Text>{`${item.nombre} ${item.apellido}`}</Text>
                                </View>
                            </TouchableOpacity>
                        )
                        }
                      />
                  </View>
                </ScrollView>

            </View>
        </View>
      </Modal>
      <ColaboradorModalDetalle inicio modalVisible={modalDetalleColaborador} setModalVisible={setModalDetalleColaborador} colaboradorSeleccionado={colaboradorSeleccionado} />
    </View>
  );
};

export default ModalMegustas;