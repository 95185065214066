import React from "react";
import { Image, StyleSheet, View } from "react-native";

function ColaboradorAvatar(props) {
  const { nombre, foto, size } = props;
  const styles = StyleSheet.create({
    avatar: {
      borderRadius: size/2,
      height: size,
      width: size,
    },
  });
  

  return (
    <View>
      <Image style={styles.avatar} source={foto?.includes('http') ? {uri: foto} : require('../../assets/default.jpg') } />
    </View>
  );
}

export default ColaboradorAvatar;
