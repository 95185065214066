import React, { useState } from "react";
import {
  Text,
  View,
  TextInput,
  StyleSheet,
  ActivityIndicator,
  Image,
  Pressable,
  Switch,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";

import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as DocumentPicker from "expo-document-picker";
import DateTimePicker from "@react-native-community/datetimepicker";
import moment from "moment";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";
import MaskedInput from "react-input-mask";

import AwesomeAlert from "react-native-awesome-alerts";
import ResultadoModal from "../components/ResultadoModal";
import MensajeError from "../components/MensajeError";
import { nuevoColaborador } from "../services/apiClient";
import ColaboradorEmpresa from "../components/Colaboradores/ColaboradorEmpresa";
import { awesomeAlertAtom } from "../store/store";
import tw from "twrnc";
import { Colores } from "../constants/colores";
import CustomMaskedInput from "../components/CustomMaskedInput";
import { esWeb } from "../utils/plataforma";
import WebDatePicker from "../components/WebDatePicker";

function ColaboradorForm(props) {
  const [foto, setfoto] = useState();
  const { width } = useWindowDimensions();
  const [activo, setactivo] = useState(1);
  const [colaboradorCreado, setcolaboradorCreado] = useState(null);
  const [fechaNacimiento, setfechaNacimiento] = useState(
    moment().subtract(18, "years")._d
  );
  const [altaAfip, setAltaFip] = useState(
    moment()._d
  );
  const [showDateTimePicker, setshowDateTimePicker] = useState(false);
  const [awesomeAlert, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);
  const queryClient = useQueryClient();
  const { navigation } = props;

  const toggleActivoSwitch = () => setactivo((previousState) => !previousState);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    mode: "onBlur",
  });

  const nuevoColaboradorMutate = useMutation(nuevoColaborador, {
    onSuccess: async (response) => {
      if (response.data.ok) {
        setawesomeAlert({
          show: true,
          showProgress: false,
          title: "Exito!",
          title: null,
          message: null,
          showCancelButton: false,
          showConfirmButton: true,
          confirmText: "Aceptar",
          confirmButtonColor: "green",
          customView: (
            <ResultadoModal success={true} message="Colaborador creado" />
          ),
          onConfirmPressed: () => {
            resetAwesomeAlert();
          },
          onDismiss: () => {
            resetAwesomeAlert();
          },
        });
        queryClient.invalidateQueries("colaboradores");
        setcolaboradorCreado(response.data.colaborador);
        resetForm();
        // navigator.navigate("Panel de control");
      }

      console.log(response.data);
    },
    onError: async (error) => {
      let mensajeErrores = "";
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        // console.log(`${key}: ${value}`);
        mensajeErrores = mensajeErrores + " \n" + value;
      }

      //TODO: mostrar errores en campos
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Error",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: <ResultadoModal success={false} message={mensajeErrores} />,
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
    },
  });

  const resetForm = () => {
    //reset form
    reset({
      nombre: "",
      apellido: "",
      dni: "",
      emailCorporativo: "",
      telCorporativo: "",
      emailPersonal: "",
      telPersonal: "",
      direccion: "",
    });
    setfoto();
  };

  const formData = new FormData();
  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: ["image/jpeg", "image/png"],
    });
    setfoto(result);
    // console.log(result);
  };

  const onSubmit = (data) => {
    formData.append("unica", 1);
    if (foto) {
      formData.append("foto", foto?.file);
    }
    if (fechaNacimiento) {
      const fechaFormatoBD = moment(fechaNacimiento).format("yyyy-MM-DD");
      formData.append("fechaNacimiento", fechaFormatoBD);
    }

    if (altaAfip) {
      const fechaFormatoBD = moment(altaAfip).format("yyyy-MM-DD");
      formData.append("altaAfip", fechaFormatoBD);
    }

    //se cargan el resto de campos del formulario en formData
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        if (data[key] && data[key] !== "") {
          formData.append(key, data[key]);
        }
      }
    }
    if (!data.emailCorporativo) {
      formData.delete("emailCorporativo");
    }
    nuevoColaboradorMutate.mutate(formData);
  };

  return (
    <>
      <View style={[tw`bg-white`, { borderRadius: width >= 768 ? 20 : 0 }]}>
        {!colaboradorCreado ? (
          <>
            {width >= 768 && (
              <Text style={tw`text-lg font-semibold my-5 ml-10`}>
                Nuevo Colaborador
              </Text>
            )}

            <View
              style={
                width >= 768
                  ? tw`flex-row my-3 px-20 items-center`
                  : tw`flex-row my-3 px-4`
              }
            >
              <Pressable
                onPress={pickDocument}
                foreground={true}
                style={tw`items-center justify-center flex-2`}
              >
                {foto ? (
                  <Image
                    style={[
                      tw`rounded-full h-25 w-25`,
                      { resizeMode: "contain" },
                    ]}
                    source={{ uri: foto.uri }}
                  />
                ) : (
                  <View
                    style={[
                      tw`items-center justify-center rounded-full border border-gray-300 h-25 w-25 shadow-md`,
                    ]}
                  >
                    <MaterialCommunityIcons
                      name="image-plus"
                      size={44}
                      color={Colores.primaryLight}
                    />
                  </View>
                )}
              </Pressable>
              <View style={tw`items-start justify-center flex-4`}>
                <Text style={[tw`text-lg ml-3`, { color: Colores.primary }]}>
                  Agregar imagen
                </Text>
              </View>
            </View>

            <View
              style={{
                borderBottomWidth: 3,
                borderBottomColor: Colores.primaryLight,
              }}
            />

            <View style={width >= 768 ? tw`px-20 z-50` : tw`px-5 z-50`}>
              <Text style={[tw`text-lg mb-2 mt-4`, { color: Colores.primary }]}>
                Datos Personales
              </Text>
              <View
                style={[
                  tw``,
                  {
                    borderBottomWidth: 1,
                    borderBottomColor: Colores.primaryLight,
                  },
                ]}
              />

              <Controller
                control={control}
                rules={{
                  required: "Ingrese el nombre",
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={[
                      tw` mt-4 w-full px-3 py-2 text-gray-700 border`,
                      { borderRadius: 50, borderColor: Colores.primary },
                    ]}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    placeholder="Nombre/s"
                    placeholderTextColor={Colores.primary}
                    value={value}
                  />
                )}
                name="nombre"
              />
              {errors?.nombre && (
                <MensajeError message={errors.nombre?.message} />
              )}

              <Controller
                control={control}
                rules={{
                  required: "Ingrese el apellido",
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={[
                      tw` mt-5 w-full px-3 py-2 text-gray-700 border`,
                      { borderRadius: 50, borderColor: Colores.primary },
                    ]}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    multiline={true}
                    placeholder="Apellido/s"
                    placeholderTextColor={Colores.primary}
                    value={value}
                  />
                )}
                name="apellido"
              />
              {errors?.apellido && (
                <MensajeError message={errors.apellido?.message} />
              )}

              <Controller
                control={control}
                rules={{
                  required: "Ingrese el dni",
                  maxLength: {
                    value: 8,
                    message: "No puede ingresar más de 8 digitos",
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={[
                      tw` mt-5 w-full px-3 py-2 text-gray-700 border`,
                      { borderRadius: 50, borderColor: Colores.primary },
                    ]}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    multiline={true}
                    placeholder="DNI"
                    placeholderTextColor={Colores.primary}
                    value={value}
                  />
                )}
                name="dni"
              />
              {errors?.dni && <MensajeError message={errors.dni?.message} />}

              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={[
                      tw` mt-5 w-full px-3 py-2 text-gray-700 border`,
                      { borderRadius: 50, borderColor: Colores.primary },
                    ]}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    multiline={true}
                    placeholder="Direccion"
                    placeholderTextColor={Colores.primary}
                    value={value}
                  />
                )}
                name="direccion"
              />
              {errors?.direccion && (
                <MensajeError message={errors.direccion?.message} />
              )}

              <View style={tw`mt-5 z-10 flex-row`}>
                <View style={tw`mr-3`}>
                  <Text style={{ color: Colores.primary }}>
                    Fecha de nacimiento
                  </Text>
                </View>
                <View style={tw`mr-2`}>
                  {esWeb() ? (
                    <WebDatePicker
                      key="fecha-nacimiento"
                      selected={fechaNacimiento}
                      minDate={moment().subtract(90, "years")._d}
                      maxDate={moment().subtract(18, "years")._d}
                      onChange={(date) => {
                        setfechaNacimiento(date);
                      }}
                    />
                  ) : (
                    <View style={tw`flex flex-row items-center justify-start`}>
                      {showDateTimePicker ? (
                        <DateTimePicker
                          value={fechaNacimiento}
                          onChange={(event, date) => {
                            if (event.type == "set") {
                              setfechaNacimiento(date);
                              setshowDateTimePicker(false);
                            } else {
                              //cancel button clicked
                              setshowDateTimePicker(false);
                            }
                          }}
                          minimunDate={moment().subtract(90, "years")._d}
                          maximunDate={moment().subtract(18, "years")._d}
                        />
                      ) : null}
                      <TouchableOpacity
                        onPress={() =>
                          setshowDateTimePicker(!showDateTimePicker)
                        }
                        style={[
                          tw`flex flex-row items-center justify-center border-2 border-indigo-400 px-4 py-2`,
                          { backgroundColor: "white", borderRadius: 15 },
                        ]}
                      >
                        <AntDesign
                          name="calendar"
                          size={20}
                          color={Colores.primary}
                        />
                        <Text style={tw`ml-2 text-center`}>
                          {moment(fechaNacimiento).format("MM/YYYY")}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
                <View>
                  <MaterialCommunityIcons
                    name="calendar-edit"
                    size={24}
                    color={Colores.primaryMedium}
                  />
                </View>
              </View>

              <View style={tw`mt-5 z-10 flex-row`}>
                <View style={tw`mr-3`}>
                  <Text style={{ color: Colores.primary }}>
                    Fecha de alta en AFIP
                  </Text>
                </View>
                <View style={tw`mr-2`}>
                  {esWeb() ? (
                    <WebDatePicker
                      key="fecha-altaAfip"
                      selected={altaAfip}
                      minDate={moment().subtract(60, "years")._d}
                      maxDate={moment()._d}
                      onChange={(date) => {
                        setAltaFip(date);
                      }}
                    />
                  ) : (
                    <View style={tw`flex flex-row items-center justify-start`}>
                      {showDateTimePicker ? (
                        <DateTimePicker
                          value={altaAfip}
                          onChange={(event, date) => {
                            if (event.type == "set") {
                              setAltaFip(date);
                              setshowDateTimePicker(false);
                            } else {
                              //cancel button clicked
                              setshowDateTimePicker(false);
                            }
                          }}
                          minimunDate={moment().subtract(60, "years")._d}
                          maximunDate={moment()._d}
                        />
                      ) : null}
                      <TouchableOpacity
                        onPress={() =>
                          setshowDateTimePicker(!showDateTimePicker)
                        }
                        style={[
                          tw`flex flex-row items-center justify-center border-2 border-indigo-400 px-4 py-2`,
                          { backgroundColor: "white", borderRadius: 15 },
                        ]}
                      >
                        <AntDesign
                          name="calendar"
                          size={20}
                          color={Colores.primary}
                        />
                        <Text style={tw`ml-2 text-center`}>
                          {moment(altaAfip).format("MM/YYYY")}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
                <View>
                  <MaterialCommunityIcons
                    name="calendar-edit"
                    size={24}
                    color={Colores.primaryMedium}
                  />
                </View>
              </View>
            </View>

            

            <View
              style={[
                tw`mt-5`,
                {
                  borderBottomWidth: 1,
                  borderBottomColor: Colores.primaryLight,
                },
              ]}
            />

            <View style={width >= 768 ? tw`px-20` : tw`px-5`}>
              <Text style={[tw`text-lg mb-2 mt-4`, { color: Colores.primary }]}>
                Contacto
              </Text>
              <View
                style={[
                  tw``,
                  {
                    borderBottomWidth: 1,
                    borderBottomColor: Colores.primaryLight,
                  },
                ]}
              />

              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={[
                      tw` mt-4 w-full px-3 py-2 text-gray-700 border`,
                      { borderRadius: 50, borderColor: Colores.primary },
                    ]}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    multiline={true}
                    placeholder="Email Corporativo"
                    placeholderTextColor={Colores.primary}
                    value={value}
                  />
                )}
                name="emailCorporativo"
              />
              {errors?.emailCorporativo && (
                <MensajeError message={errors.emailCorporativo?.message} />
              )}

              {watch("emailCorporativo")?.includes("@") ? (
                <View
                  style={tw`mt-2 p-2 bg-yellow-100 rounded-md flex flex-row items-center justify-center gap-2`}
                >
                  <AntDesign
                    name="warning"
                    size={24}
                    style={tw`text-yellow-700`}
                  />
                  <Text>
                    Si ingresa un mail corporativo, el colaborador debera
                    acceder al sistema usando este.
                  </Text>
                </View>
              ) : null}

              <Controller
                name="telCorporativo"
                control={control}
                defaultValue=""
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <CustomMaskedInput
                    placeholder="Telefono Corporativo"
                    placeholderTextColor={Colores.primary}
                    value={field.value}
                    onChangeText={field.onChange}
                    mask="(999) 9 999999"
                    style={[
                      tw` mt-4 w-full px-3 py-2 text-gray-700 border`,
                      { borderRadius: 50, borderColor: Colores.primary },
                    ]}
                  >
                    {(inputProps) => (
                      <TextInput
                        {...inputProps}
                        type="text"
                        style={[
                          tw` mt-4 w-full px-3 py-2 text-gray-700 border`,
                          { borderRadius: 50, borderColor: Colores.primary },
                        ]}
                        placeholder="Telefono Corporativo"
                        placeholderTextColor={Colores.primary}
                      />
                    )}
                  </CustomMaskedInput>
                )}
              />

              <View style={tw`items-center justify-center`}>
                <View
                  style={[
                    tw`mt-4 px-20`,
                    {
                      borderBottomWidth: 1,
                      borderBottomColor: Colores.primaryLight,
                    },
                  ]}
                />
              </View>

              <Controller
                control={control}
                rules={{
                  required: "Ingrese un email personal",
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={[
                      tw` mt-4 w-full px-3 py-2 text-gray-700 border`,
                      { borderRadius: 50, borderColor: Colores.primary },
                    ]}
                    onBlur={onBlur}
                    onChangeText={onChange}
                    multiline={true}
                    placeholder="Email personal"
                    placeholderTextColor={Colores.primary}
                    value={value}
                  />
                )}
                name="emailPersonal"
              />
              {errors?.emailPersonal && (
                <MensajeError message={errors.emailPersonal?.message} />
              )}

              <Controller
                name="telPersonal"
                control={control}
                defaultValue=""
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <MaskedInput
                    mask="(999) 9 999999"
                    maskChar=""
                    value={field.value}
                    onChange={field.onChange}
                  >
                    {(inputProps) => (
                      <TextInput
                        {...inputProps}
                        type="text"
                        style={[
                          tw` mt-4 w-full px-3 py-2 text-gray-700 border`,
                          { borderRadius: 50, borderColor: Colores.primary },
                        ]}
                        placeholder="Telefono Personal"
                        placeholderTextColor={Colores.primary}
                      />
                    )}
                  </MaskedInput>
                )}
              />
              {errors?.telPersonal && (
                <MensajeError message={errors.telPersonal?.message} />
              )}
            </View>

            <View style={tw`hidden`}>
              <Controller
                control={control}
                defaultValue={activo}
                name="activo"
                render={({ field: { onChange, onBlur, value } }) => (
                  <View style={tw`flex flex-row p-1 mt-6 mb-5`}>
                    <Switch
                      trackColor={{ false: "#767577", true: "#81b0ff" }}
                      thumbColor={value ? "#f5dd4b" : "#f4f3f4"}
                      ios_backgroundColor="#3e3e3e"
                      onValueChange={(value) => {
                        toggleActivoSwitch;
                        onChange(value ? 1 : 0);
                      }}
                      value={value}
                    />
                    <Text style={tw`ml-2 `}>Activo</Text>
                  </View>
                )}
              />
            </View>

            {nuevoColaboradorMutate.isLoading ? (
              <ActivityIndicator
                size="small"
                style={tw`my-10`}
                color={Colores.primary}
              />
            ) : (
              <View style={tw`items-center justify-center`}>
                <TouchableOpacity
                  style={[
                    tw`my-10 px-20 py-2.5 shadow-md`,
                    { backgroundColor: Colores.primary, borderRadius: 15 },
                  ]}
                  onPress={handleSubmit(onSubmit)}
                >
                  <Text style={tw`text-white text-center`}>Registrar</Text>
                </TouchableOpacity>
              </View>
            )}
            <View
              style={{
                borderBottomWidth: 3,
                borderBottomColor: Colores.primaryLight,
              }}
            />
          </>
        ) : (
          <View style={tw`px-5`}>
            <Text style={[tw`mt-5 text-lg`, { color: Colores.primary }]}>
              Nuevo colaborador{" "}
              <Text
                style={tw`capitalize`}
              >{`${colaboradorCreado.nombre} ${colaboradorCreado.apellido}`}</Text>
            </Text>
            <ColaboradorEmpresa
              colaborador={colaboradorCreado}
              setcolaboradorCreado={setcolaboradorCreado}
              asignarDespues
              navigation={navigation}
            />
          </View>
        )}
      </View>

      <AwesomeAlert
        show={awesomeAlert.show}
        showProgress={awesomeAlert.showProgress}
        title={awesomeAlert.title}
        message={awesomeAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={awesomeAlert.showCancelButton}
        showConfirmButton={awesomeAlert.showConfirmButton}
        cancelText="Cancelar"
        confirmText={awesomeAlert.confirmText}
        confirmButtonColor={awesomeAlert.confirmButtonColor}
        customView={awesomeAlert.customView}
        onDismiss={awesomeAlert.onDismiss}
        onConfirmPressed={awesomeAlert.onConfirmPressed}
      />
    </>
  );
}

export default ColaboradorForm;
