import React, { useState } from "react";
import {
  View,
  Text,
  FlatList,
  StyleSheet,
  Button,
  ScrollView,
  TouchableOpacity,
  Modal,
  useWindowDimensions,
} from "react-native";
import { useQuery } from "react-query";
import { FontAwesome } from "@expo/vector-icons";
import tw from "twrnc";

import { getEmpresasDptosPuestos } from "../../services/apiClient";
import CantidadEntidad from "../../components/Empresa/CantidadEntidad";
import AwesomeAlert from "react-native-awesome-alerts";
import { awesomeAlertAtom, userAtom } from "../../store/store";
import { FontAwesome5 } from "@expo/vector-icons";
import { useAtom } from "jotai";
import { PageContainer } from "../../components/PageContainer";
import { Colores } from "../../constants/colores";
import { Loader } from "../../components/Loader";

const Item = ({ empresa, permisos, props }) => {
  const { nombre, departamentos_count, colaboradores_count } = empresa;

  const puedeEliminar = permisos.some(
    (permiso) =>
      permiso.slug === "administrar.todo" ||
      permiso.slug === "administrar.empresas" ||
      permiso.slug === "eliminar.empresa"
  );

  return (
    <View
      style={tw`mt-2 p-3 border border-gray-300 rounded-lg shadow-lg flex-row`}
    >
      <View style={tw`flex-3`}>
        <View>
          <Text style={tw`flex  bg-white justify-center mt-2`}>
            <FontAwesome
              name="building"
              size={24}
              color="#333333"
              style={tw`mr-2`}
            />
            {nombre}
          </Text>
        </View>

        <View style={tw`mt-3 flex flex-row`}>
          <View>
            <CantidadEntidad
              entidad="departamentos"
              cantidad={departamentos_count}
            />
          </View>
          <View style={tw`ml-5`}>
            <CantidadEntidad
              entidad="colaboradores"
              cantidad={colaboradores_count}
            />
          </View>
        </View>
      </View>

      <View style={tw`flex-1`}>
        <View style={tw`items-end`}>
          <TouchableOpacity
            style={[
              tw`p-2 rounded shadow-md`,
              { backgroundColor: Colores.primary },
            ]}
            onPress={() =>
              props.navigation.navigate("Administrar Empresa", { empresa })
            }
          >
            <Text style={tw`text-white text-center`}>
              <FontAwesome5 name="cogs" size={20} />
            </Text>
          </TouchableOpacity>
        </View>
        <View style={tw`items-end`}>
          {puedeEliminar && (
            <TouchableOpacity
              onPress={() => console.log(`eliminar ${empresa.id}`)}
              style={[
                tw`py-2 px-3 mt-2 rounded shadow-md`,
                { backgroundColor: Colores.danger },
              ]}
            >
              <FontAwesome5 name="trash" size={20} color="#FFF" />
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  );
};

export default function AdministrarScreen(props) {
  const { data, isLoading, isError } = useQuery(
    "empresas",
    getEmpresasDptosPuestos
  );
  const [{ permisos }] = useAtom(userAtom);

  const [modalVisible, setModalVisible] = useState(false);
  const { width } = useWindowDimensions();
  const [awsomeAlert, _] = useAtom(awesomeAlertAtom);
  const renderItem = ({ item }) => (
    <Item empresa={item} props={props} permisos={permisos} />
  );

  const renderSpinner = () => {
    return (
      <View style={{ textAlign: "center", padding: 16 }}>
        <Loader text="Cargando" />
      </View>
    );
  };

  return (
    <PageContainer paddingMd>
      <View style={[tw`p-5 bg-white`, width > 768 && { borderRadius: 20 }]}>
        <TouchableOpacity
          onPress={() =>
            props.navigation.navigate("Crear Empresa", {
              navigation: props.navigation,
            })
          }
          style={[
            tw`px-6 py-2.5 shadow-md`,
            { backgroundColor: Colores.primary, borderRadius: 15 },
          ]}
        >
          <Text style={tw`text-white text-center`}>Agregar Nueva Empresa</Text>
        </TouchableOpacity>

        <FlatList
          data={data?.data?.empresas}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          ListFooterComponent={isLoading ? renderSpinner : null}
        />
      </View>

      <AwesomeAlert
        show={awsomeAlert.show}
        showProgress={false}
        title={awsomeAlert.title}
        message={awsomeAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={awsomeAlert.showConfirmButton}
        confirmText="Aceptar"
        customView={awsomeAlert.customView}
        confirmButtonColor="green"
        onDismiss={awsomeAlert.onDismiss}
        onCancelPressed={awsomeAlert.onCancelPressed}
        onConfirmPressed={awsomeAlert.onConfirmPressed}
      />
    </PageContainer>
  );
}
