import React from "react";

import { PageContainer } from "../../components/PageContainer";
import { HistoriaForm } from "../../forms/HistoriaForm";

export default function NuevaHistoriaScreen(props) {
  return (
    <PageContainer>
      <HistoriaForm {...props} />
    </PageContainer>
  );
}
