import React, { useEffect, useState } from "react";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import * as DocumentPicker from "expo-document-picker";
import tw from 'twrnc';
import { Colores } from "../constants/colores";
import { FontAwesome } from '@expo/vector-icons';


export default function EditarImagen(props) {
  const { modelo, formData } = props;
  const [foto, setfoto] = useState();
  let urlImagen = null;

  useEffect(() => {
    setfoto();
  }, [modelo]);

  if (modelo.foto && !foto) {
    //foto actual en la BD
    urlImagen = modelo.foto;
  } else if (foto) {
    //si es una imagen seleccionada de la galeria
    urlImagen = foto.uri;
  }

  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: ["image/jpeg", "image/png"],
    });
    if (result) {
      formData.append("foto", result?.file);
      setfoto(result);
    }
  };

  return (
    <>
      <Pressable onPress={pickDocument} foreground={true}>
      {(modelo.foto || foto) ? (
        <>
        {urlImagen === "http://45.224.140.5/nubiteamV2/storage/menu/cubiertos.png" ? (
          <View style={[tw`items-center justify-center bg-gray-200 rounded-full h-30 w-30`]}>
              <FontAwesome name="cutlery" size={40} color={Colores.primaryLight} />
          </View>
        ) : (
          <Image
            style={[tw`h-30 w-30 rounded-full`,{resizeMode:'contain',backgroundColor:Colores.primaryLight }]}
            source={{ uri: urlImagen }}
          />

        )}
        </>
      ):(
          <View style={[tw`items-center justify-center bg-gray-200 rounded-full h-30 w-30`]}>
              <MaterialCommunityIcons name="image-plus" size={44} style={{color:Colores.primaryLight}} />
          </View>
      )}
      </Pressable>
    </>
  );
}
