import React from "react";
import { Text, View } from "react-native";

import { PageContainer } from "../../components/PageContainer";
import { formatoFechaES } from "../../utils/fechas";

export default function DetalleSugerenciaScreen(props) {
  const { sugerencia } = props.route.params;
  console.log(sugerencia);
  return (
    <PageContainer>
      <View style={{ flex: 1, padding: "10px" }}>
        <Text>detalle de la sugerencia</Text>
        <Text>Empresa: {sugerencia.empresa.nombre}</Text>
        <Text>Tipo: {sugerencia.tipo.tipo}</Text>
        <Text>{sugerencia.sugerencia}</Text>
        <Text>Fue realizada el {formatoFechaES(sugerencia.created_at)}</Text>
      </View>
    </PageContainer>
  );
}
