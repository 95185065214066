import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { View, Text, useWindowDimensions } from 'react-native'
import React, { useState } from 'react'
import { Feather } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';
import { MaterialIcons } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import HomeScreen from '../pages/HomeScreen';
import { BeneficiosScreen, MenuScreen, MiPerfilScreen, SugerenciasScreen } from '../pages';
import { Colores } from '../constants/colores';
import HeaderRightButton from './HeaderAvisoRightButton';
import LogoTitle from './LogoTitle';

const Tab = createBottomTabNavigator();


export default function BottomTab({navigation}) {
  const {width} = useWindowDimensions()


  return (
    <Tab.Navigator initialRouteName='Home'
      screenOptions={{
        headerTitleAlign:'center',
        tabBarActiveTintColor:Colores.primary,
        headerLeft: () => ( width < 758 && ( <FontAwesome5 name="bars" color='#FFF' size={20} style={{ marginLeft: 15 }} onPress={()=>navigation.openDrawer()} />)  
        ),
        headerShadowVisible:false,
        headerShown: width >= 768 ? false : true,
        tabBarStyle: {display: width >= 768 ? 'none' : null}
      }}
    >
        <Tab.Screen
          name="Home"
          component={HomeScreen}
          options={{
            tabBarIcon:({color})=>(<Feather name="home" size={24} color={color} />),
            headerTitle: () => <LogoTitle />,
            headerStyle:{
              backgroundColor:Colores.primary,
              borderBottomLeftRadius:16,
              borderBottomRightRadius:16,
            },
            headerShadowVisible:false,
            headerTitleAlign:width >= 768 ? 'left' : 'center',
            headerRight: () => <HeaderRightButton />
          }} /> 
        <Tab.Screen
          name="Sugerencias"
          component={SugerenciasScreen}
          options={{
            headerBackgroundContainerStyle:{
              backgroundColor:Colores.primary,
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
            },
            headerShadowVisible:false,
            headerTransparent:true,
            headerTintColor:'#FFF',
            headerTitleAlign:'center',
            tabBarIcon:({color})=>(<MaterialIcons name="move-to-inbox" size={24} color={color} />),
          }} /> 
        <Tab.Screen
          name="Beneficios"
          component={BeneficiosScreen}
          options={{
            headerStyle:{
              backgroundColor:Colores.primary,
              borderBottomLeftRadius:16,
              borderBottomRightRadius:16,
            },
            headerTintColor:'#FFF',
            tabBarIcon:({color})=>(<MaterialCommunityIcons name="ticket-percent-outline" size={24} color={color} />),
          }} /> 
        <Tab.Screen
          name="Menu"
          component={MenuScreen}
          options={{
            headerStyle:{
              backgroundColor:Colores.primary,
              borderBottomLeftRadius:16,
              borderBottomRightRadius:16,
            },
            headerTintColor:'#FFF',
            tabBarIcon:({color})=>(<FontAwesome5 name="utensils" size={22} color={color} />),
          }} /> 
        <Tab.Screen
          name="Perfil"
          component={MiPerfilScreen}
          options={{
            headerTransparent:true,
            headerTintColor:'#FFF',
            tabBarIcon:({color})=>(<Feather name="user" size={24} color={color} />),
            headerShown:false,
          }} /> 
    </Tab.Navigator>
  )
}
