import React from "react";
import * as WebBrowser from "expo-web-browser";
import { Image, Pressable, Text, useWindowDimensions, View } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import tw from 'twrnc';

import ColaboradorDatosPersonales from "../../components/Colaboradores/ColaboradorDatosPersonales";
import { PageContainer } from "../../components/PageContainer";
import { Colores } from "../../constants/colores";

function DetalleSolicitudPlanDeCarreraSecreen(props) {
  const { solicitud } = props.route.params;
  const {width} = useWindowDimensions();

  return (
    <PageContainer paddingMd >
      <View style={[tw`top-15 bg-white`,{borderRadius: width >=768 ? 20 : 0}]}>
        <View>
          {width >=768 && (
            <Text style={tw`text-lg font-semibold my-5 ml-10`}>Solicitud de plan de carrera</Text>
          )}
        </View>
        <View style={width >=768 ? tw`flex flex-row pb-2 pt-5 px-10` :  tw`flex flex-row pb-2 pt-5 px-4`}>
          <Image style={tw`h-20 w-20 rounded-full`} source={solicitud.colaborador?.foto} />
          <View style={tw`justify-center pl-5`}>
              <Text style={tw`text-lg font-semibold capitalize`}>{`${solicitud.colaborador?.nombre} ${solicitud.colaborador?.apellido}`}</Text>
              <Text style={tw``}>{solicitud.colaborador?.empresa_actual[0]?.nombre}</Text>
          </View>
        </View>
        
        <View style={width >=768 ? tw`px-10 mt-2` : tw`px-3 mt-2`}>
            <View style={[tw`mb-3`,{borderBottomWidth:1, borderBottomColor:Colores.primary}]}>
              <Text style={[tw`text-xl mb-2`,{color:Colores.primary}]}>Actualmente</Text>
            </View>
            <Text style={tw`text-lg`}>
              {`En ${solicitud.colaborador?.empresa_actual[0]?.nombre} en el departamento de ${solicitud.colaborador?.departamento_actual[0]?.nombre} ocupa el puesto ${solicitud.colaborador?.puesto_actual[0]?.nombre ?? "-"}.`}
            </Text>

            <View style={[tw`mb-3`,{borderBottomWidth:1, borderBottomColor:Colores.primary}]}>
              <Text style={[tw`text-xl mt-4 mb-1`,{color:Colores.primary}]}>Interes</Text>
            </View>
            <Text style={tw`text-lg`}>
              {`Se encuentra interesado en ${solicitud?.empresa?.nombre} en el departamento ${solicitud?.departamento?.nombre} para el puesto ${solicitud?.puesto?.nombre}`}
            </Text>

            <View style={[tw`mb-3`,{borderBottomWidth:1, borderBottomColor:Colores.primary}]}>
              <Text style={[tw`text-xl mt-4 mb-1`,{color:Colores.primary}]}>Motivo</Text>
            </View>
            <Text style={tw`text-lg`}>{solicitud.motivo}</Text>

            <View style={[tw`mb-3`,{borderBottomWidth:1, borderBottomColor:Colores.primary}]}>
              <Text style={[tw`text-xl mt-4 mb-1`,{color:Colores.primary}]}>Propuesta o Mejora</Text>
            </View>
            <Text style={tw`text-lg`}>{solicitud.mejora}</Text>

            <View style={[tw`mb-3`,{borderBottomWidth:1, borderBottomColor:Colores.primary}]}>
              <Text style={[tw`text-xl mt-4 mb-1`,{color:Colores.primary}]}>Curriculum Vitae</Text>
            </View>


              <Pressable
                style={tw`justify-start items-start`}
                onPress={async () => {
                  await WebBrowser.openBrowserAsync(solicitud.pdf);
                  //TODO: si falla en android checkear si no es web usar https://docs.expo.dev/versions/latest/sdk/filesystem/
                }}
                foreground={false}
              >
                <View style={[tw`flex flex-row px-3 py-2 rounded-lg mb-20`,{backgroundColor:Colores.primaryMedium}]}>

                  <AntDesign name="pdffile1" size={20} color="white" />

                  <Text style={tw`text-white mt-1 ml-2 capitalize`}>
                    CV - {solicitud.colaborador.nombre},{" "}
                    {solicitud.colaborador.apellido}
                  </Text>

                </View>
              </Pressable>

        </View>

      </View>
    </PageContainer>
  );
}

export default DetalleSolicitudPlanDeCarreraSecreen;
