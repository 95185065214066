import React, { useState } from "react";
import { View, Text, ScrollView, useWindowDimensions } from "react-native";
import tw from "twrnc";

import { CambiarPasswordForm } from "../../forms/CambiarPasswordForm";
import AwesomeAlert from "react-native-awesome-alerts";
import { awesomeAlertAtom } from "../../store/store";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import { PageContainer } from "../../components/PageContainer";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Colores } from "../../constants/colores";
import BotonesTipoItems from "../../components/MiPerfil/items/BotonesTipoItems";
import MisItems from "../../components/MiPerfil/items/MisItems";

const MiPerfilScreen = (props) => {
  const [awesomeAlert, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);
  const resetAlert = useResetAtom(awesomeAlertAtom);
  const { width } = useWindowDimensions();

  let borderRadius = 5;
  if (width > 758) {
    borderRadius = 10;
  }

  const [activeTab, setActiveTab] = useState(1);
  const [filtro, setFiltro] = useState(null);

  return (
    <>
      <ScrollView>
        <PageContainer paddingMd>
          <View
            style={[
              tw`mt-2 bg-white h-full shrink border border-gray-200 shadow-md rounded-xl`,
            ]}
          >
            {width > 758 && (
              <View style={[tw`p-5`, { borderTopRadius: borderRadius }]}>
                <Text style={tw`font-semibold text-lg`}>Mi Perfil</Text>
              </View>
            )}
            <View
              style={tw`flex flex-row items-center gap-4 border-solid border-b border-b-gray-300`}
            >
              <TouchableOpacity
                style={tw`px-4 py-2 ${
                  activeTab === 1
                    ? `border-b-2 border-b-[${Colores.primary}]`
                    : ""
                }`}
                onPress={() => setActiveTab(1)}
              >
                <Text>Mis Datos</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={tw`px-4 py-2 ${
                  activeTab === 2
                    ? `border-b-2 border-b-[${Colores.primary}]`
                    : ""
                }`}
                onPress={() => setActiveTab(2)}
              >
                <Text>Activos</Text>
              </TouchableOpacity>
            </View>
            <View style={tw`${activeTab === 1 ? "visible" : "hidden"}`}>
              <CambiarPasswordForm />
            </View>
            <View
              style={tw`flex flex-col bg-gray-100 gap-4 ${
                activeTab === 2 ? "visible" : "hidden"
              }`}
            >
              <BotonesTipoItems setFiltro={setFiltro} />
              <View style={tw`flex flex-col justify-center p-4`}>
                <MisItems filtro={filtro} />
              </View>
            </View>
          </View>
        </PageContainer>
      </ScrollView>
      <AwesomeAlert
        show={awesomeAlert.show}
        showProgress={awesomeAlert.showProgress}
        title={awesomeAlert.title}
        message={awesomeAlert.message}
        closeOnTouchOutside={true}
        closeOnHardwareBackPress={false}
        showCancelButton={awesomeAlert.showCancelButton}
        showConfirmButton={awesomeAlert.showConfirmButton}
        cancelText="Cancelar"
        confirmText={awesomeAlert.confirmText}
        confirmButtonColor={awesomeAlert.confirmButtonColor}
        customView={awesomeAlert.customView}
        onCancelPressed={() => {
          resetAwesomeAlert();
        }}
        onConfirmPressed={awesomeAlert.onConfirmPressed}
        onDismiss={awesomeAlert.onDismiss}
      />
    </>
  );
};

export default MiPerfilScreen;
