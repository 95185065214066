import { ActivityIndicator, Image, Modal, ScrollView, StyleSheet, Text, TextInput, Touchable, TouchableOpacity, useWindowDimensions, View } from 'react-native'
import React, { useState } from 'react'
import tw from 'twrnc';
import { AntDesign } from '@expo/vector-icons';
import { Colores } from '../../constants/colores';
import { useForm, Controller } from "react-hook-form";
import MensajeError from '../MensajeError';
import { cambiarPasswordOlvidada } from '../../services/apiClient';
import { useMutation } from 'react-query';
import ModalCorreoEnviado from './ModalCorreoEnviado';

const ModalRecuperarPassword = ({modalVisible, setModalVisible}) => {
    const [mostrarModalCorreoEnviado, setMostrarModalCorreoEnviado] = useState(false)
    const [email, setEmail] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const {width} = useWindowDimensions()
const {
  control,
  handleSubmit,
  formState: { errors },
  reset,
} = useForm({
  mode: "onBlur",
});

const cambiarPassword = useMutation(cambiarPasswordOlvidada, {
    onSuccess: async (response) => {
        setIsLoading(false)
        setMostrarModalCorreoEnviado(true)
    },
  });

const onSubmit = (data) => {

    setIsLoading(true)

    const form = {email:data.email.toLowerCase()};
    setEmail(data.email.toLowerCase())
    
    cambiarPassword.mutate(form);
};

  return (
    <Modal
          animationType="fade"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(!modalVisible);
          }}>
              <View style={[tw`bg-white flex-1`, width > 758 &&  {alignItems:'center'}]}>
                <View style={tw`flex-4 items-center justify-center`}>
                <Image source={require('../../assets/Login/candado.png')} style={[ width > 758 ? tw`w-50 h-50` : tw`w-full h-50`,{resizeMode:'contain'}]} />
                </View>
                <View style={tw`flex-7`}>
                    <Text style={tw`text-center font-semibold text-lg`}>¿No recuerdas tú contraseña?</Text>
                    <Text style={tw`text-center text-gray-400 mt-5 p-3`}>Ingresa tu correo electrónico y te enviaremos un enlace para que recuperes el acceso a tu cuenta.</Text>
                    
                    <View style={tw`px-5`}>
                        <Controller
                          control={control}
                          rules={{
                            required: false,
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-/s]+\.[A-Z]{2,}$/i,
                                message: "Ingrese un email válido",
                            },
                          }}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <TextInput
                              style={[tw` mt-4 w-full px-3 py-2 text-gray-700 border`,{borderRadius:50, borderColor:'gray'}]}
                              onBlur={onBlur}
                              onChangeText={onChange}
                              multiline={true}
                              placeholder="Correo electrónico"
                              placeholderTextColor='gray'
                              value={value}
                            />
                          )}
                          name="email"
                        />
                        {errors?.email && (
                          <MensajeError message={errors.email?.message} />
                        )}
                    </View>

                    <View style={tw`items-center justify-center mt-7`}>
                        <TouchableOpacity style={[tw`px-15 py-3 shadow-md`,{backgroundColor:Colores.primary, borderRadius:15}]} onPress={handleSubmit(onSubmit)}>
                            {isLoading ? (
                                <ActivityIndicator color='#FFF' />
                            ):(
                                <Text style={tw`text-center text-white`}>Enviar enlace</Text>
                            )}
                        </TouchableOpacity>
                    </View>

                    <View style={tw`flex-row justify-center mt-10`}>
                        <Text style={tw`mr-1`}>¿No tienes una cuenta?</Text>
                        <Text style={{color:Colores.primary}}>Comunicate con RRHH</Text>
                    </View>
                </View>
                <View style={[tw`flex-1 justify-center`,{backgroundColor: width > 758 ? '#FFF' : Colores.primaryLight}]}>
                    <TouchableOpacity onPress={()=>setModalVisible(false)}>
                        <Text style={[tw`text-center py-3 text-lg font-semibold`,{color:Colores.primary}]}>Volver al inicio de sesión</Text>
                    </TouchableOpacity>
                </View>
              </View>
              <ModalCorreoEnviado modalVisible={mostrarModalCorreoEnviado} setModalVisible={setMostrarModalCorreoEnviado} ocultarModalRecuperarPassword={setModalVisible} email={email} setEmail={setEmail} />
        </Modal>
  )
}

export default ModalRecuperarPassword

