import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { Modal, Text, Pressable, View, Image, TextInput, TouchableOpacity, Switch, ActivityIndicator,} from "react-native";
import { useMutation, useQueryClient } from "react-query";
import tw from 'twrnc';
import { nuevoAviso } from "../../services/apiClient";
import { empresasSeleccionadasAtom, idsEmpresasAtom, userAtom } from "../../store/store";
import ResultadoModal from "../../components/ResultadoModal";
import { Colores } from "../../constants/colores";
import { useForm, Controller } from "react-hook-form";
import MensajeError from "../MensajeError";
import { MultiSelectEmpresas } from "../MultiSelect";
import { AntDesign } from '@expo/vector-icons';
import * as DocumentPicker from "expo-document-picker";
import { validateURL } from "../../utils/generales";
import * as FileSystem from 'expo-file-system';


const CrearHistoriaModalDesktop = ({modalVisibleHistoriaDesktop, setModalVisibleHistoriaDesktop, navigation}) => {
    const [foto, setfoto] = useState();
    const [, setEmpresasSeleccionadas] = useAtom(empresasSeleccionadasAtom);
    const [modalVisible, setModalVisible] = useState(false);
    const [idsEmpresas, setidsEmpresas] = useAtom(idsEmpresasAtom);
    const [esTemporal, setesTemporal] = useState(1);
    const [imagen, setImagen] = useState(false)
    const [agregarTexto, setAgregarTexto] = useState(true)
    const toggleSwitch = () => setIsEnabled(previousState => !previousState);
    const queryClient = useQueryClient();
    const [user, setUser] = useAtom(userAtom);
  
    const alertInitialState = {
      show: false,
      title: "",
      message: "",
      showConfirmButton: false,
    };
    const [mostrarAlert, setmostrarAlert] = useState(alertInitialState);
  
    const toggleEsTemporalSwitch = () =>
      setesTemporal();
    const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm({
      mode: "onBlur",
    });
  
    const resetForm = () => {
      //reset form
      reset({ titulo: "", texto: "", url: "" });
      setfoto();
      setesTemporal();
      setidsEmpresas();
      //resetear multiselect
      setEmpresasSeleccionadas([]);
      setAgregarTexto(true);
      setModalVisible(false);
      setModalVisibleHistoriaDesktop(false);
    };
  
    const nuevoAvisoMutate = useMutation(nuevoAviso, {
      onSuccess: async (response) => {
        queryClient.invalidateQueries("historias");
        queryClient.invalidateQueries("avisosCompletos");
        resetForm();
        //mostral mostrar modal
        setmostrarAlert({
          show: true,
          showConfirmButton: true,
          customView: (
            <ResultadoModal success={true} message="La historia fue creada" />
          ),
        });
      },
      onError: async (error) => {
        console.log(error.response.data);
        //TODO: mostrar errores en campos
        setmostrarAlert({
          show: true,
          customView: <ResultadoModal success={false} message="Hubo un error" />,
        });
      },
    });

    const getDocumentSize = async (uri) => {
      if (typeof uri === 'string' && uri.startsWith('data:')) {
        // Web environment
        return { size: uri.length }; // This is just a rough estimation
      } else {
        // Native environment
        try {
          const fileInfo = await FileSystem.getInfoAsync(uri);
          return fileInfo;
        } catch (error) {
          console.log('Error getting document file info:', error);
          throw error;
        }
      }
    };
  
    const formData = new FormData();
    const pickDocument = async () => {
      try {
        let result = await DocumentPicker.getDocumentAsync({
          type: ["image/jpeg", "image/png"],
        });
    
        if (result.type === 'success') {
          const fileInfo = await getDocumentSize(result.uri);
    
          const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
          if (fileInfo.size > maxSizeInBytes) {
            alert("El tamaño maximo permitido por imagen es de 2 MB.");
            return;
          }
    
          setfoto(result);
        }
      } catch (error) {
        console.error('Error picking document:', error);
      }
    };
  
    const onSubmit = (data) => {
      
      formData.append("unica", 1);
      if (foto) {
        formData.append("foto[]", foto?.file);
      }
      if (idsEmpresas) {
        formData.append("empresas", idsEmpresas);
      }
      //se cargan el resto de campos del formulario en formData
      for (const key in data) {
        if (key === "field" && data[key][1]) {
          formData.append(key, data[key][1]);
        } else if(data[key]) {
          formData.append(key, data[key]);
        }
      }
      nuevoAvisoMutate.mutate(formData);
    };
 
  return (
    <View>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisibleHistoriaDesktop}
      >
        <View style={[tw`flex-1 justify-center z-40`,{backgroundColor:'#000000bf'}]}>
          <View style={[tw`flex-row`,{borderBottomLeftRadius:20, borderBottomRightRadius:20}]}>

          <View style={tw`flex-3`}></View>
         <View style={tw`flex-4`}>
            <View style={[tw`items-center py-3`,{borderTopLeftRadius:20, borderTopRightRadius:20, backgroundColor:'#FFF'}]}><Text>Crea una nueva historia</Text></View>

            <View style={tw`flex-row`}>
            <Pressable onPress={pickDocument} foreground={true} style={[tw`flex-2 bg-white`,{borderBottomLeftRadius:20}]}>
                <View style={tw`flex-1`}>
                    {foto ? (
                      <Image
                        style={[tw`flex-1`,{resizeMode:'contain', borderBottomLeftRadius:20, backgroundColor:Colores.primaryLight}]}
                        source={{ uri: foto.uri }}
                      />
                    ):(  
                      <View style={[tw`flex-1 items-center justify-center`,{backgroundColor:Colores.primaryLight, borderBottomLeftRadius:20}]}>
                        <Text>
                          Agregar imagen
                        </Text>
                      </View>
                    )}
                </View>
            </Pressable>



            <View style={[tw`flex-2 h-180 w-50 border-gray-300 bg-white`,{borderBottomRightRadius:20, borderTopWidth:1}]}>          
                <View style={tw`flex-row p-3`}>
                  <View style={tw`justify-center`}>
                    <Image source={{uri:user?.foto}} style={tw`h-10 w-10 rounded-full`} />
                  </View>
                  <View style={tw`justify-center`}>
                    <Text style={tw`ml-3 font-semibold capitalize`}>{`${user.nombre} ${user.apellido} - ${user.puesto}`}</Text>
                  </View>
                  </View>

                  <View style={tw`px-3`}>

                      <View style={tw`items-center`}>
                        <View style={tw`flex-row my-3`}>
                          <Text style={tw`font-semibold mr-2`}>
                            Agregar titulo/descripción
                          </Text>
                          <Switch
                            trackColor={{ false: "#767577", true: "#81b0ff" }}
                            thumbColor={agregarTexto ? "#f5dd4b" : "#f4f3f4"}
                            ios_backgroundColor="#3e3e3e"
                            onValueChange={setAgregarTexto}
                            value={agregarTexto}
                          />
                        </View>
                      </View>

                      {agregarTexto && (

                        <View>
                            <Controller
                              control={control}
                              rules={{
                                required: "Ingrese un titulo",
                              }}
                              render={({ field: { onChange, onBlur, value } }) => (
                                <TextInput
                                  style={tw` w-full px-3 mt-2 py-1.5 text-gray-500 rounded-full border border-gray-200`}
                                  onBlur={onBlur}
                                  onChangeText={onChange}
                                  placeholder="Escribir un titulo para la foto o video"
                                  value={value}
                                />
                              )}
                              name="titulo"
                            />
                            {errors?.titulo && <MensajeError message={errors.titulo?.message} />}
                              
                            <Controller
                              control={control}
                              rules={{
                                required: "Ingrese el texto",
                              }}
                              render={({ field: { onChange, onBlur, value } }) => (
                                <TextInput
                                  style={tw` w-full h-30 px-3 mt-2 py-1.5 text-gray-500 rounded-lg border border-gray-200`}
                                  onBlur={onBlur}
                                  onChangeText={onChange}
                                  multiline={true}
                                  value={value}
                                  placeholder="Descripción"
                                />
                              )}
                              name="texto"
                            />
                            {errors?.texto && <MensajeError message={errors.texto?.message} />}
                        </View>

                      )}
                      <View>
                        <Controller
                          control={control}
                          rules={{ validate: validateURL }}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <TextInput
                              style={[
                                tw` w-full px-3 py-2.5 text-base font-normal text-gray-700 shadow-sm border border-gray-300`,
                                { borderRadius: 50 },
                              ]}
                              onBlur={onBlur}
                              onChangeText={onChange}
                              placeholder="Video url"
                              value={value}
                            />
                          )}
                          name="url"
                        />
                        {errors?.url && <MensajeError message={errors.url?.message} />}
                      </View>
                      <View style={tw`hidden`}>
                        <Controller
                          control={control}
                          defaultValue={esTemporal}
                          name="esTemporal"
                          render={({ field: { onChange, onBlur, value } }) => (
                            <View style={tw`flex-row flex my-5`}>
                              <Switch
                                trackColor={{ false: "#767577", true: "#81b0ff" }}
                                thumbColor={value ? "#f5dd4b" : "#f4f3f4"}
                                ios_backgroundColor="#3e3e3e"
                                onValueChange={(value) => {
                                  toggleEsTemporalSwitch;
                                  onChange(value ? 1 : 0);
                                }}
                                value={value}
                                style={tw`mt-1`}
                              />
                              <Text style={tw`ml-3 text-gray-700 text-lg`}>Subir como historia</Text>
                            </View>
                          )}
                        />
                      </View>
                            
                            
                      <View style={tw`mt-3`}>
                        <MultiSelectEmpresas />
                      </View>
                      
                      {nuevoAvisoMutate.isLoading ? (
                      <ActivityIndicator size="small" color={Colores.primary} />
                        ) : (
                        <View style={tw`items-center justify-center mt-5 mb-10`}>
                          <TouchableOpacity style={[tw`ml-1 px-20 py-2.5 shadow-md`,{backgroundColor:Colores.primary, borderRadius:15}]} onPress={handleSubmit(onSubmit)}>
                            <Text style={tw`text-white text-center`}>
                              Publicar
                            </Text>
                          </TouchableOpacity>
                        </View>
                      )}

                    </View>          
            </View>

            </View>

            </View>


            <View style={tw`flex-3`}></View>

          </View>
            <TouchableOpacity style={tw`absolute top-5 right-5`} onPress={()=>setModalVisibleHistoriaDesktop(false)}><AntDesign name="close" size={24} color="#FFF" /></TouchableOpacity>
        </View>
      </Modal>
    </View>
  );
};


export default CrearHistoriaModalDesktop;