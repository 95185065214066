import React from "react";
import { View } from "react-native";
import tw from "twrnc";
import ItemStep from "./ItemStep";
import LineaStep from "./LineaStep";

const AsignarItemSteps = ({ currentStep = 1 }) => {
  return (
    <View style={tw`flex flex-row items-center gap-4`}>
      <ItemStep step={1} currentStep={currentStep} label="Detalles" />

      <LineaStep current={currentStep > 1} />

      <ItemStep step={2} currentStep={currentStep} label="Archivos" />

      <LineaStep current={currentStep > 2  } />

      <ItemStep step={3} currentStep={currentStep} label="Colaboradores" />

      <LineaStep current={currentStep > 3 && currentStep <= 4} />

      <ItemStep step={4} currentStep={currentStep} label="Resumen" />

    </View>
  );
};

export default AsignarItemSteps;
