import React, { useState } from "react";
import { View, Text, ScrollView, useWindowDimensions } from "react-native";
import { useQuery } from "react-query";
import { Loader } from "../../components/Loader";

import { menuSemanal } from "../../services/apiClient";
import MenuPorDia from "../../components/Menu/MenuPorDia";
import MenuSemanalSeleccionado from "./MenuSemanalSeleccionado";
import { useAtom } from "jotai";
import { awesomeAlertAtom } from "../../store/store";
import AwesomeAlert from "react-native-awesome-alerts";
import tw from 'twrnc';
import ModalSeleccionarComida from "../../components/Menu/ModalSeleccionarComida";
import MenuPorDiaDesktop from "../../components/Menu/MenuPorDiaDesktop";
import MenuSemanalSeleccionadoDesktop from "./MenuSemanalSeleccionadoDesktop";

export default function MenuScreen() {
  const [menu, setMenu] = useState()
  const {width} = useWindowDimensions()
  const [mostrarModalSeleccionar, setMostrarModalSeleccionar] = useState(false)
  const [awesomeAlert] = useAtom(awesomeAlertAtom);
  const { isLoading, isError, data, error } = useQuery(
    "menuSemanal",
    menuSemanal
  );

  return (
    <View style={[ width > 758 ? tw`flex-1 h-full` : tw`flex-1 h-full bg-white p-2`]}>
    <ScrollView>
      <View style={ width > 758 ? tw`flex-row h-full` : tw`h-full flex-1`}>
      {width > 758 ? (
      <View style={tw`flex-1`}>
      </View>
      ) : null}
      <View style={[ width > 758 ? tw`flex-3 h-full px-3 pb-5 mt-10 bg-white`: tw`flex-1 h-full`, width > 758 ? {borderRadius: 20} : ""]}>
      
        <View style={width > 758 ? tw`mt-10` : ""}>
          {width < 758 ? (
          <ScrollView>
    
            <Text
              style={tw`mb-2 mt-7 text-lg font-semibold text-center`}
            >
              Menúes disponibles
            </Text>
            {data && data?.data.menuSemanal.length ? (
            <MenuPorDia menuSemanal={data.data.menuSemanal} setMenu={setMenu} setMostrarModalSeleccionar={setMostrarModalSeleccionar}/>
            ) : (
            <Text>No se registran fechas de menú</Text>
            )}

            <ModalSeleccionarComida modalVisible={mostrarModalSeleccionar} setModalVisible={setMostrarModalSeleccionar} menu={menu} />

          </ScrollView>
          ) : (
          <ScrollView>
              <View style={tw`bg-white flex-1 px-5 pb-5`}>
                  <Text
                    style={tw`mb-5 text-lg font-semibold`}
                  >
                    Menú de esta semana
                  </Text>
                  {data && data?.data.menuSemanal.length ? (
                      <MenuPorDiaDesktop menuSemanal={data.data.menuSemanal} setMenu={setMenu} setMostrarModalSeleccionar={setMostrarModalSeleccionar}/>
                  ) : (
                  <Text>No se registran fechas de menú</Text>
                  )}
                  
              </View>
          </ScrollView>
          )}
        </View>

        <View>
            {isLoading ? (
              <Loader text="Cargando Menu de la semana" />
            ) : isError ? (
              <Text>Error: {error.message}</Text>
            ) : (
              data ? (
                <>
                {width < 758 ? (<MenuSemanalSeleccionado />) : ( <MenuSemanalSeleccionadoDesktop />)}
                </>
              ) : null
            )}
        </View>



      </View>

      {width > 758 ? (
        <View style={tw`flex-1`}>
        </View>
      ) : null}

      </View>
      </ScrollView>
         <AwesomeAlert
         show={awesomeAlert.show}
         showProgress={false}
         title={awesomeAlert.title}
         message={awesomeAlert.message}
         closeOnTouchOutside={true}
         closeOnHardwareBackPress={false}
         showCancelButton={awesomeAlert.showCancelButton}
         showConfirmButton={awesomeAlert.showConfirmButton}
         confirmText={awesomeAlert.confirmText}
         customView={awesomeAlert.customView}
         confirmButtonColor={awesomeAlert.confirmButtonColor}
         onDismiss={awesomeAlert.onDismiss}
         onCancelPressed={awesomeAlert.onCancelPressed}
         onConfirmPressed={awesomeAlert.onConfirmPressed}
       />
    </View>

  );
}
