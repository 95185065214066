import React from "react";
import {
  ScrollView,
  Text,
  View,
} from "react-native";
import { useMutation, useQuery, useQueryClient } from "react-query";
import moment from "moment";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import tw from 'twrnc';


import {
  eliminarMenuSeleccionado,
  verMenuSemanalSeleccionado,
} from "../../services/apiClient";
import { Loader } from "../../components/Loader";
import { awesomeAlertAtom } from "../../store/store";
import ResultadoModal from "../../components/ResultadoModal";
import CardMenuDelDia from "../../components/Menu/CardMenuDelDia";

export default function MenuSemanalSeleccionadoDesktop() {
  const [, setawsomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);
  const queryClient = useQueryClient();
  const menuSemanalSeleccionado = useQuery(
    "menuSemanalSeleccionado",
    verMenuSemanalSeleccionado
  );

  const eliminarMenuSeleccionadoMutate = useMutation(eliminarMenuSeleccionado, {
    onSuccess: async (response) => {
      if (!response.data.ok) {
        setawsomeAlert({
          show: true,
          showProgress: false,
          customView: (
            <ResultadoModal success={false} message={response.data.message} />
          ),
        });
        console.error(response.data.message);
      } else {
        console.log("menuSemanalSeleccionado", response.data);
        queryClient.invalidateQueries("menuSemanalSeleccionado");
      }
    },
    onError: async (error) => {
      console.log("error en consulta", error);
    },
  });

  const submit = (menu) => {
    const dia = moment(menu.fecha).locale("es").format("dddd");
    setawsomeAlert({
      show: true,
      showProgress: eliminarMenuSeleccionadoMutate.isLoading,
      title: "Atención",
      message: `Esta seguro que desea eliminar el menu del ${dia}?`,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: "#DD6B55",
      cancelText: "Cancelar",
      confirmText: "Eliminar selección",
      onCancelPressed: () => {
        resetAwesomeAlert();
      },
      onConfirmPressed: () => {
        eliminarMenuSeleccionadoMutate.mutate(menu.id);
        resetAwesomeAlert();
      },
    });
  };

  const dibujarSeleccion = () => {
    if (menuSemanalSeleccionado.isLoading) {
      return <Loader text="Cargando menu seleccionado para la semana" />;
    }
    if (
      menuSemanalSeleccionado.data?.data?.menuSeleccionadoSemanaActual.length
    ) {
      return (
        <View style={tw`flex-row flex-1`}>
          {menuSemanalSeleccionado.data?.data?.menuSeleccionadoSemanaActual.map(
            (menu) => {
              return (
                <CardMenuDelDia key={`cardMenuDelDia-${menu.id}`} menu={menu} handleSubmit={submit} deleteMutate={eliminarMenuSeleccionadoMutate} />
              );
            }
          )}
        </View>
      );
    } else {
      return (
        <Text style={{ margin: 16, textAlign: "center" }}>
          Aun no has seleccionado tu menu para esta semana
        </Text>
      );
    }
  };

 
  return (
    <View style={tw`bg-white`}>
      <Text style={tw`my-5 text-lg font-semibold text-center`}>
        Mis almuerzos seleccionados
      </Text>
      <View style={tw`bg-gray-100 rounded-md border border-gray-300 flex-1`}>
      <ScrollView horizontal={true}>
      {dibujarSeleccion()}
      </ScrollView>
      </View>
    </View>
  );
}
