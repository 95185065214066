import { createElement } from "react";
/*
minDate, maxDate debe ser un objeto Date()
*/

const WebDatePicker = ({ key, selected = null, onChange, minDate = null, maxDate = null, type = 'date' }) => {
    const date = selected ? new Date(selected) : new Date(Date.now());
    let isoString = date.toISOString().split("T")[0];

    if(selected instanceof Date){
      isoString = selected.toISOString().split("T")[0]
    }

    if(type === 'month'){
      const [ year, month ] = isoString.split('-');
      isoString = `${year}-${month}`;
    }

    if (minDate) {
        minDate = minDate.toISOString().split('T')[0];
    }

    if (maxDate) {
        maxDate = maxDate.toISOString().split('T')[0];
    }


    return createElement('input', {
        key,
        type: type,
        value: isoString,
        min: minDate,
        max: maxDate,
        onChange: (event) => {
          let dateString = event.target.value;
          let [year, month, day] = dateString.split("-").map(val => parseInt(val, 10));
          let date = new Date(year, month - 1, day);
          if(type === 'month'){
            date = new Date(year, month - 1 );
          }
          onChange(date)
          },
        onKeyDown: (e) => e.preventDefault(),
        style: {height: 30, padding: 5, border: "2px solid #677788", borderRadius: 5,  width: type === 'month' ? 160 : 130}
      })
}

export default WebDatePicker