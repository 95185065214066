import moment from "moment";

//ordernar lista de objetos por una propiedad
export const groupBy = (arr, key) => {
  const grouped = arr.reduce((storage, item) => {
    const group = item[key];
    storage[group] = storage[group] || [];
    storage[group].push(item);
    return storage;
  }, {});
  return grouped;
};

export const orderBy = (arr, key, sort = "ASC") => {
  const order = arr.sort((a, b) => {
    if (sort === "DESC") {
      if (a[key] < b[key]) {
        return 1;
      }
      if (a[key] > b[key]) {
        return -1;
      }
      return 0;
    }

    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });

  return order;
};

export const parsearErrores = (errores) => {
  let listaErrores = "";
  Object.keys(errores).forEach((campo) => {
    errores[campo].map((error) => {
      listaErrores = `${listaErrores} \n ${error}`;
    });
  });
  return listaErrores;
};

export const convertToMegabytes = (input) => {
  // Convert the input to a number (assuming it's a valid number)
  const numberInBytes = parseFloat(input);

  if (isNaN(numberInBytes)) {
    return "Invalid input. Please provide a numeric value.";
  }

  const megabytes = numberInBytes / (1024 * 1024);
  return `${megabytes.toFixed(2)} MB`;
};

export const validateURL = (url) => {
  if (!url) {
    return true;
  }

  const urlRegex = /^(https?|http):\/\/[^\s/$.?#].[^\s]*$/;
  if (!urlRegex.test(url)) {
    return "URL no válida, debe contener https:// o http://";
  }
  return true;
};

export const estadoItem = ({
  requiereConformidadRecepcion,
  conformeDevolucion,
  conformeRecepcion,
  caduca,
  fechaCaduca,
}) => {
  let estado = "pendiente";
  if (!requiereConformidadRecepcion) {
    estado = "aceptado";
  }
  estado = conformeDevolucion ? "devolucion" : estado;
  const diasPrevioAdvertencia = 7;

  if (caduca === 1 && fechaCaduca) {
    const hoy = moment();
    const diasParaCaducar = moment(fechaCaduca).diff(hoy, "days");
    if (diasParaCaducar < 0) {
      estado = "caducado";
    }

    if (diasParaCaducar >= 0 && diasParaCaducar <= diasPrevioAdvertencia) {
      estado = "porCaducar";
    }
  }

  if (conformeRecepcion !== null && estado !== "caducado") {
    if (conformeRecepcion == 1) {
      estado = "aceptado";
    }
    if (conformeRecepcion == 0) {
      estado = "rechazado";
    }
  }

  return estado;
};
