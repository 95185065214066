import React from "react";
import { Picker } from "@react-native-picker/picker";
import { useQuery } from "react-query";

import { getTiposRecibo } from "../../services/apiClient";
import { Loader } from "../Loader";

export const SelectTiposRecibo = ({ setTipoRecibo, tipoRecibo }) => {

  const { data, isLoading, isError } = useQuery(
    "tiposRecibo",
    getTiposRecibo
  );

  if (isLoading) {
    <Loader text="cargando tipos de recibos" />
  }

  if (isError) {
    <Text> Error al intentar cargar los tipos de recibo</Text>
  }

  return (
    <Picker
      placeholder="Seleccionar el tipo de recibo"
      style={{ paddingVertical: 10, paddingHorizontal: 5, borderRadius: 50 }}
      selectedValue={tipoRecibo}
      onValueChange={(itemValue, itemIndex) => setTipoRecibo(itemValue)}
    >
      <Picker.Item label={"Seleccionar el tipo de recibo"} value={null} />
      {data?.data?.tipos?.map((item) => (
        <Picker.Item label={item.tipo} value={item.id} key={item.id} />
      ))}
    </Picker>
  );
};
