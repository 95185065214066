import React, { useState, useEffect } from "react";
import {
  ActivityIndicator,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { useMutation, useQueryClient } from "react-query";
import { Controller, useForm } from "react-hook-form";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import tw from 'twrnc';

import { editarDepartamento, nuevoDepartamento } from "../services/apiClient";
import ResultadoModal from "../components/ResultadoModal";
import MensajeError from "../components/MensajeError";
import { awesomeAlertAtom } from "../store/store";
import { parsearErrores } from "../utils/generales";
import { Colores } from "../constants/colores";

function DepartamentoForm(props) {
  const { navigation, empresaId, departamento } = props;
  const queryClient = useQueryClient();
  const [quiereEditar, setquiereEditar] = useState(false);
  let initialValues = {
    nombre: "",
    descripcion: "",
  };

  useEffect(() => {
    if (departamento) {
      reset({
        nombre: departamento?.nombre ?? "",
        descripcion: departamento?.descripcion ?? "",
      });
    }
    setquiereEditar(Object.keys(departamento ?? {}).length ? true : false);
  }, [props]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: initialValues,
  });

  const resetForm = () => {
    setquiereEditar(false);
  };

  const [awsomeAlert, setawesomeAlert] = useAtom(awesomeAlertAtom);
  const resetAwesomeAlert = useResetAtom(awesomeAlertAtom);


  let mutate = nuevoDepartamento;
  let mensaje = "Departamento creado";

  if (quiereEditar) {
    mutate = editarDepartamento;
    mensaje = "Departamento editado";
  }

  const departamentoMutate = useMutation(mutate, {
    onSuccess: async (response) => {
      queryClient.invalidateQueries("empresas");
      resetForm();
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Exito!",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: <ResultadoModal success={true} message={mensaje} />,
        onConfirmPressed: () => {
          resetAwesomeAlert();
          navigation.navigate("Administrar");
        },
        onDismiss: () => {
          resetAwesomeAlert();
          navigation.navigate("Administrar");
        },
      });
    },
    onError: async (error) => {
      console.log(error.response.data);
      const mensajesDeError = parsearErrores(error.response.data?.errors);
      setawesomeAlert({
        show: true,
        showProgress: false,
        title: "Error",
        title: null,
        message: null,
        showCancelButton: false,
        showConfirmButton: true,
        confirmText: "Aceptar",
        confirmButtonColor: "green",
        customView: (
          <ResultadoModal success={false} message={mensajesDeError} />
        ),
        onConfirmPressed: () => {
          resetAwesomeAlert();
        },
        onDismiss: () => {
          resetAwesomeAlert();
        },
      });
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else if (data[key]) {
        formData.append(key, data[key]);
      }
    }
    if (quiereEditar) {
      formData.append("_method", "PUT");
      departamentoMutate.mutate({ departamentoId: departamento.id, formData });
    } else {
      formData.append("empresa_id", empresaId);
      departamentoMutate.mutate(formData);
    }
  };

  return (
    <View styles={tw`flex  flex-1`}>
      <View>
        <Text style={tw`mt-2`}>Nombre</Text>
        <Controller
          control={control}
          rules={{
            required: "Ingrese un nombre",
            minLength: {
              value: 3,
              message: "Indique al menos 3 caracteres",
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={tw` mt-4 w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded`}
              onChangeText={onChange}
              onBlur={onBlur}
              multiline={true}
              placeholder="Indique un nombre"
              value={value}
            />
          )}
          name="nombre"
        />
        {errors?.nombre && <MensajeError message={errors.nombre?.message} />}

        <Text style={tw`mt-4`}>Descripción (Opcional)</Text>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              style={tw` mt-4 w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded`}
              onChangeText={onChange}
              multiline={true}
              placeholder="Indique una descripción"
              value={value}
            />
          )}
          name="descripcion"
        />
        {errors?.descripcion && (
          <MensajeError message={errors.descripcion?.message} />
        )}

        

        {departamentoMutate.isLoading ? (
          <ActivityIndicator size="small" color={Colores.primary} />
        ) : (
          <View style={tw`justify-center items-center mt-5`}>
            <TouchableOpacity style={[tw`mt-4 px-20 py-2.5 uppercase shadow-md hover:bg-blue-700 hover:shadow-lg`,{backgroundColor:Colores.primary, borderRadius:16}]} onPress={handleSubmit(onSubmit)}>
              <Text style={tw`text-center text-white`}>
                Enviar
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </View>
  );
}

export default DepartamentoForm;
