import { View, Text } from 'react-native'
import React from 'react'
import ColaboradorItem from './ColaboradorItem'
import tw from "twrnc"

export default function AsignadoA({colaborador}) {
  return (
    <View style={tw`flex flex-col justify-center gap-3`}>
    <Text style={tw`font-bold mt-4`}>Asignado a:</Text>
    <View
      style={tw`p-2 my-2 flex flex-row items-center gap-2 w-full max-w-[250px] border border-gray-200 rounded-lg`}
    >
      <ColaboradorItem colaborador={colaborador} />
    </View>
  </View>
  )
}