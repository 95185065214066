import React from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  useWindowDimensions,
  StyleSheet,
} from "react-native";
import tw from "twrnc";
import { FontAwesome5 } from "@expo/vector-icons";
import { Colores } from "../../constants/colores";
import ColaboradorDatosPersonales from "./ColaboradorDatosPersonales";
import ColaboradorDatosDeContacto from "./ColaboradorDatosDeContacto";
import ColaboradorDatosEmpresariales from "./ColaboradorDatosEmpresariales";
import DatosPermisos from "../DatosPermisos";
import { LinearGradient } from "expo-linear-gradient";
import { useNavigation } from "@react-navigation/native";
import { Feather } from "@expo/vector-icons";

const ColaboradorData = ({ dataColaborador, editar, props }) => {
  const { width } = useWindowDimensions();
  const navigator = useNavigation();

  if ('puesto_actual' in dataColaborador && dataColaborador?.puesto_actual[0]) {
    dataColaborador.puesto = dataColaborador?.puesto_actual[0]?.nombre
  }

  if ('empresa_actual' in dataColaborador && dataColaborador?.empresa_actual[0]) {
    dataColaborador.empresa = dataColaborador?.empresa_actual[0]?.nombre
  }

  if ('departamento_actual' in dataColaborador && dataColaborador?.departamento_actual[0]) {
    dataColaborador.departamento = dataColaborador?.departamento_actual[0]?.nombre
  }

  return (
    <View>
      <LinearGradient
        style={[
          width > 758
            ? tw`w-full h-50 absolute top-0`
            : tw`w-full h-70 absolute top-0`,
          StyleSheet.absoluteFill,
        ]}
        end={{ x: 0.5, y: 0.1 }}
        colors={["rgba(92, 91, 229, 0.26)", "rgba(92, 91, 229, 0.98)"]}
      />

      {/*width < 758 && (
        <View style={tw`flex-row mt-5`}>
          <TouchableOpacity
            style={tw`flex-1 items-center justify-center`}
            onPress={() => navigator.openDrawer()}
          >
            <Feather name="menu" size={24} color="#FFF" />
          </TouchableOpacity>
          <View style={tw`flex-3`}>
            <Text style={tw`text-center text-white text-lg`}>Mi Perfil</Text>
          </View>
          <View style={tw`flex-1`}></View>
        </View>
      )*/}

      <View
        style={[
          tw`justify-center px-3 mt-10`,
          width > 758 && { alignItems: "center" },
        ]}
      >
        <View style={tw`flex-row`}>
          <View style={tw`px-5`}>
            <Image
              style={tw`h-30 w-30 rounded-full border-2 border-white`}
              source={{
                uri: dataColaborador?.foto
                  ? dataColaborador.foto
                  : require("../../assets/default.jpg"),
              }}
            />
          </View>

          <View style={tw`p-3 w-7/12`}>
            <Text style={tw`text-lg text-white font-semibold mt-1 capitalize`}>
              {`${dataColaborador?.nombre} ${dataColaborador?.apellido}`}
            </Text>
            <View style={tw`flex flex-row items-baseline justify-start`}>
              <FontAwesome5
                name="briefcase"
                size={12}
                color="#FFF"
                style={tw`mr-2`}
              />
              <Text style={tw`text-white mt-1 capitalize`}>
                {
                  dataColaborador?.puesto}
              </Text>
            </View>
            <View style={tw`flex flex-row items-baseline justify-start`}>
              <FontAwesome5
                name="building"
                size={12}
                color="#FFF"
                style={tw`mr-2`}
              />

              <Text style={tw`text-white mt-1 mb-3 capitalize`}>
                {
                  dataColaborador?.empresa}
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View style={width > 758 ? tw`px-10 mt-7` : tw`px-3`}>
        <View style={tw`mt-5`}>
          {width > 768 && (
            <View style={tw`items-end`}>
              <TouchableOpacity
                style={[
                  tw`px-5 py-3 flex-row justify-center`,
                  { backgroundColor: "#F3F3F3", borderRadius: 50 },
                ]}
                onPress={() => {
                  navigator.navigate("Editar Perfil", {
                    colaborador: dataColaborador,
                  });
                }}
              >
                <FontAwesome5 name="pen" size={17} color="black" />
                <Text style={tw`ml-2`}>Editar perfil</Text>
              </TouchableOpacity>
            </View>
          )}

          <View style={tw`mt-4`}>
            <ColaboradorDatosPersonales
              dataColaborador={dataColaborador}
              nombre={dataColaborador?.nombre}
              apellido={dataColaborador?.apellido}
              fecha={dataColaborador?.fechaNacimiento}
              dni={dataColaborador?.dni}
              direccion={dataColaborador?.direccion}
              navigator={navigator}
            />
          </View>
          {dataColaborador?.user_id && (
            <TouchableOpacity
              style={tw`items-center absolute w-full`}
              onPress={() => {
                props.navigation.navigate("Editar Colaborador", {
                  colaborador: dataColaborador,
                });
              }}
            >
              <Text
                style={[
                  tw`text-white text-center px-20 py-2`,
                  { backgroundColor: Colores.primary, borderRadius: 20 },
                ]}
              >
                Editar perfil
              </Text>
            </TouchableOpacity>
          )}
        </View>

        <View style={width > 758 ? tw`mt-7` : tw`mt-3`}>
          <ColaboradorDatosDeContacto
            emailCorporativo={dataColaborador?.user?.email}
            emailPersonal={dataColaborador?.emailPersonal}
            telCorporativo={dataColaborador?.telCorporativo}
            telPersonal={dataColaborador?.telPersonal}
          />
        </View>

        <View style={width > 758 ? tw`mt-7` : tw`mt-3`}>
          <ColaboradorDatosEmpresariales
            empresa={
              dataColaborador?.empresa
            }
            departamento={
              dataColaborador?.departamento
            }
            puesto={
              dataColaborador?.puesto
            }
          />
        </View>

        <View
          style={[
            width > 758
              ? tw`bg-white px-5 pb-7 pt-5 mt-7 mb-3 flex  border border-gray-300 shadow-lg`
              : tw`bg-white px-5 pb-7 pt-5 mt-3 mb-3 flex  border border-gray-300 shadow-lg`,
            { borderRadius: 20 },
          ]}
        >
          <View
            style={[
              tw`mb-3`,
              { borderBottomWidth: 1, borderBottomColor: Colores.primaryLight },
            ]}
          >
            <Text style={[tw`text-2xl mb-2`, { color: Colores.primary }]}>
              Permisos
            </Text>
          </View>
          <DatosPermisos permisos={dataColaborador?.permisos} />
        </View>
      </View>
    </View>
  );
};

export default ColaboradorData;
