import { StyleSheet, View, Image, Dimensions } from 'react-native';
import Carousel, { Pagination, PaginationLight } from 'react-native-x-carousel';

const { width, height } = Dimensions.get('window');
const CarouselSwiper = ({archivos, ancho}) => {
    
    const renderItem = data => (
        <View
            key={data.id}
            style={styles.cardContainer}
        >
            <Image
                style={[styles.card,{height:height * 0.5 ,  width: ancho ? width * ancho : width , resizeMode:'contain'}]}
                source={{ uri: data.foto }}
            />
        </View>
    );

    return (
        <View style={styles.container}>
            <Carousel
            pagination={archivos.length > 1 ? Pagination : null }
            renderItem={renderItem}
            data={archivos}
            />
        </View>
    );
};

    const styles = StyleSheet.create({
        container: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
        cardContainer: {
            alignItems: 'center',
            justifyContent: 'center',
        },
        cardWrapper: {
            borderRadius: 8,
            overflow: 'hidden',
        },
        card: {
        height: height * 0.4,
        borderRadius:10
        },
        cornerLabel: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            borderTopLeftRadius: 8,
        },
        cornerLabelText: {
            fontSize: 12,
            color: '#fff',
            fontWeight: '600',
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 2,
            paddingBottom: 2,
        },
    });

export default CarouselSwiper;