import React from "react";
import { Text, View } from "react-native";
import Imagen from "../Imagen";
import tw from "twrnc";

const DetallePlato = ({ plato, tipo }) => {
  return (
    <View style={tw`flex flex-row border-b-2 border-gray-200`}>
      {plato?.foto ? (
        <View>
          <Imagen uri={plato.foto} style={tw`w-16 h-16`} />
        </View>
      ) : null}
      <View>
        {plato?.nombre ? (
          <>
            <Text style={tw`capitalize ml-1 mt-1 `}>
              {plato.nombre.charAt(0).toUpperCase() +
                plato.nombre.slice(1).toLowerCase()}
            </Text>
            <Text style={tw`ml-1 mt-1 text-gray-500`}>{tipo}</Text>
          </>
        ) : (
          <Text style={tw`ml-1 mt-1 `}>Sin {tipo}</Text>
        )}
      </View>
    </View>
  );
};

export default DetallePlato;
