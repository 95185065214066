import React from "react";
import { Text, View } from "react-native";
import tw from 'twrnc';

function Badge({ color = "transparent", fontSize = "0.6rem", text = "" }) {
  return (
    <View style={[tw`bg-${color}-200 m-2 py-2 px-3`,{borderRadius:45}]}>
      <Text style={tw`capitalize text-${color}-700 text-xs font-semibold text-center`}>
        {text}
      </Text>
    </View>
  );
}

export default Badge;
