import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { FontAwesome5 } from "@expo/vector-icons";
import tw from 'twrnc';

const NombreTitle = ({title, icon, color}) => {
  return (
    <View style={tw`flex flex-row`}>
        {icon && (
          <FontAwesome5 name={icon} size={24} color={color} style={tw`mr-1 mt-1`}/>
        )}
        <Text style={[tw`text-lg ml-1`,{color:color}]}>{title}</Text>
    </View>
  )
}

export default NombreTitle
