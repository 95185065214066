import React from "react";
import { ScrollView, Text, View } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import {
  Collapse,
  CollapseHeader,
  CollapseBody,
} from "accordion-collapse-react-native";
import tw from 'twrnc';

import TablaEstimativo from "./TablaEstimativo";
import { dia, formatoFechaES } from "../../utils/fechas";
import { Colores } from "../../constants/colores";

export const ListaEstimativoSemanal = ({ pedidosPorFecha }) => {
  if (!pedidosPorFecha.length) return <Text>No hay datos</Text>;
  return (
    <View style={tw`w-full flex-1`}>
      {pedidosPorFecha.map((pedido, idx) => {
        const { cabecerasTabla, titulosTabla, data, fecha } = pedido;
        return (
          <View key={`dia-${idx}`} style={[tw`p-4 mt-2 border border-gray-300 shadow-md`,{borderRadius:10}]}>
            <Collapse style={tw`w-full`}>

              <CollapseHeader style={tw`flex flex-row w-full`}>
                <View style={tw`flex-3`}>
                  <Text style={tw`font-semibold capitalize`}>
                    {` ${dia(fecha)} ${formatoFechaES(fecha)}`}
                  </Text>
                </View>
                <View style={tw`flex-1 items-end`}>
                <MaterialIcons name="keyboard-arrow-down" size={24} color={Colores.primary} />
                </View>
              </CollapseHeader>

              <CollapseBody>
                <ScrollView horizontal={true}>
                  <TablaEstimativo
                    data={data}
                    tableHead={cabecerasTabla}
                    tableTitle={titulosTabla}
                  />
                </ScrollView>
              </CollapseBody>

            </Collapse>
          </View>
        );
      })}
    </View>
  );
};
