import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  FlatList,
  ScrollView,
} from "react-native";
import { useQuery, useQueryClient } from "react-query";
import { buscarColaboradores } from "../services/apiClient";
import { Loader } from "./Loader";
import tw from "twrnc";
import ColaboradorAvatar from "./Colaboradores/ColaboradorAvatar";
import useDebounce from "../hooks/useDeboucned";
import { Colores } from "../constants/colores";
import { AntDesign } from "@expo/vector-icons";

const ColaboradorSeleccionado = ({ colaborador, onClose }) => (
  <View
    style={tw`max-w-[300px] flex flex-row items-center justify-start p-2 bg-white rounded-xl border border-gray-200`}
  >
    <View style={tw`flex-1 flex flex-row items-center`}>
      <ColaboradorAvatar
        size={42}
        foto={colaborador.foto}
        key={colaborador.id}
      />
      <View style={tw`flex-1 ml-4 flex flex-col justify-start items-start`}>
        <Text style={tw`font-semibold capitalize`}>
          {`${colaborador?.nombre} ${colaborador?.apellido}`}
        </Text>
      </View>
    </View>
    <TouchableOpacity
      style={tw`ml-2 bg-gray-200 rounded-full`}
      onPress={onClose}
    >
      <AntDesign name="closecircleo" size={24} style={tw`text-gray-700`} />
    </TouchableOpacity>
  </View>
);

const ColaboradorSelect = ({ onChange, colaborador }) => {

  const [colaboradorSeleccionado, setColaboradorSeleccionado] = useState(colaborador);
  const [filter, setFilter] = useState();
  const debouncedFilter = useDebounce(filter, 500);

  const { data, error, isFetching, isLoading } = useQuery(
    ["colaboradores", debouncedFilter],
    () => buscarColaboradores(debouncedFilter),
    {
      enabled: !!filter,
    }
  );

  const handleFilterChange = (text) => {
    setFilter(text);
  };

  const handleItemPress = (colaborador) => {
    setColaboradorSeleccionado(colaborador);
    if (onChange) {
      onChange(colaborador);
    }
  };

  const handleClose = () => {
    onChange(null);
    setColaboradorSeleccionado(null);
  };

  if (error) {
    return <Text>Error</Text>;
  }

  useEffect(() => {
    setColaboradorSeleccionado(colaborador);
  }, [colaborador]);

  useEffect(() => {
    if (filter?.length === 0) {
      console.log("cambio filtro")
      onChange(null);
    }
  }, [filter]);

  return (
    <View style={tw`flex flex-col gap-4`}>
      <View style={tw`relative max-w-[300px] flex flex-row`}>
        <TextInput
          placeholder="Buscar colaborador"
          value={filter}
          onChangeText={handleFilterChange}
          style={[
            tw`w-full px-3 py-2 text-base font-normal text-gray-700 bg-white border border-gray-200`,
            { borderRadius: 50 },
          ]}
        />
        <AntDesign
          name="search1"
          style={tw`absolute right-2 px-3 py-2 text-gray-700`}
          size={20}
        />
      </View>

      <View style={tw`flex flex-wrap`}>
        {colaboradorSeleccionado ? (
          <ColaboradorSeleccionado
            colaborador={colaboradorSeleccionado}
            onClose={handleClose}
          />
        ) : null}
      </View>

      {isFetching ? (
        <Loader text="buscando" />
      ) : !colaboradorSeleccionado ? (
        <ScrollView style={tw`max-w-[500px] max-h-[500px] mt-2 rounded-xl`}>
          <FlatList
            data={data?.data?.colaboradores || []}
            keyExtractor={(item) => item.id.toString()}
            renderItem={({ item }) => (
              <TouchableOpacity onPress={() => handleItemPress(item)}>
                <View
                  style={tw`flex flex-row items-center p-2 bg-white border-b-2 border-b-slate-200`}
                >
                  <ColaboradorAvatar size={32} foto={item.foto} key={item.id} />
                  <Text
                    style={{
                      padding: 10,
                      borderColor: "#ccc",
                      backgroundColor:
                        colaboradorSeleccionado === item.id
                          ? "#e0e0e0"
                          : "white",
                    }}
                  >
                    {`${item.nombre} ${item.apellido}`}
                  </Text>
                </View>
              </TouchableOpacity>
            )}
          />
        </ScrollView>
      ) : null}
    </View>
  );
};

export default ColaboradorSelect;
