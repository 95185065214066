import React from "react";
import { Text, View, CheckBox } from "react-native";
import tw from "twrnc";

const CustomCheckbox = ({ label, value, setValue, numeric }) => {
  const handleChange = () => {
    if (numeric) {
      setValue(value ? 0 : 1);
    } else {
      // true/false
      setValue(!value);
    }
  };
  return (
    <View style={tw`flex-row mt-3 justify-around`}>
      <CheckBox value={value} onValueChange={handleChange} />
      <Text style={tw`ml-2 text-gray-600`}>{label}</Text>
    </View>
  );
};

export default CustomCheckbox;
