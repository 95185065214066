import React, { useEffect, useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import tw from 'twrnc';

function RadioButton({ data, onSelect, defaultSelected, setIcons = true }) {
  const [userOption, setUserOption] = useState();
  const [colorBtn, setColorBtn] = useState()
  const [icono, setIcono] = useState()
  const selectHandler = (value) => {
    onSelect(value);
    setUserOption(value);
  };

  useEffect(() => {
    setUserOption(defaultSelected ?? data[0]);
  }, [defaultSelected]);
  
  useEffect(() => {
    if (setIcons) {
      switch (userOption) {
        case 1:
            setColorBtn('gray')
            setIcono('clockcircleo')
          break;
        case 2:
            setColorBtn('green')
            setIcono('checkcircleo')
          break;
        case 3:
            setColorBtn('red')
            setIcono("close")
          break;
        default:
          setColorBtn('gray')
          setIcono("close")
          break;
      }
    }else{
      setColorBtn('gray')
      setIcono("close")
    }
  }, [userOption])
  

  return (
    <View style={tw`flex flex-row flex-wrap items-center justify-center mt-3`}>
      {data.map((item) => {
        return (
          <View key={`rb-${item.value}`} style={tw`m-1`}>
            <Pressable
              style={[
                item.value === userOption ? styles.selected : styles.unselected,
                tw`border border-gray-300 p-2 rounded-lg flex flex-row items-center justify-center mx-1`,
              ]}
              onPress={() => selectHandler(item.value)}
            >
              {item.value === userOption && (
                <AntDesign name={icono} size={20} color={colorBtn} style={tw`mr-2`} />
              )}
              <Text>{item.label}</Text>
            </Pressable>
          </View>
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
});

export default RadioButton;
