import React from "react";
import { Text, View } from "react-native";

import { PageContainer } from "../../components/PageContainer";
import ColaboradorForm from "../../forms/ColaboradorForm";

function CrearColaboradorScreen(props) {
  return (
    <PageContainer paddingMd >
      <View style={{ flex: 1 }}>
        <ColaboradorForm {...props} />
      </View>
    </PageContainer>
  );
}

export default CrearColaboradorScreen;
