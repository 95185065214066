import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  ScrollView,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
import { PageContainer } from "../../components/PageContainer";
import tw from "twrnc";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Colores } from "../../constants/colores";
import { getMisRecibos } from "../../services/apiClient";
import { useQuery } from "react-query";
import { useAtom } from "jotai";
import { userAtom } from "../../store/store";
import moment from "moment";
import { esWeb } from "../../utils/plataforma";

function MiReciboScreen(props) {
  const { navigation } = props;
  const [user, setUser] = useAtom(userAtom);
  const { width } = useWindowDimensions();
  const [recibosFirmados, setRecibosFirmados] = useState([]);
  const [recibosNoFirmados, setRecibosNoFirmados] = useState([]);

  const misRecibos = useQuery("misRecibos", getMisRecibos);

  const filtrarRecibos = (estado) => {
    if (estado) {
      const resultado = misRecibos?.data?.data?.recibos.filter(
        (item) => item.firmado === true
      );
      return resultado;
    } else {
      const resultado = misRecibos?.data?.data?.recibos.filter(
        (item) => item.firmado === false
      );
      return resultado;
    }
  };

  const esweb = esWeb();

  return (
    <PageContainer paddingMd>
      {!misRecibos?.data?.data?.recibos ? (
        <ActivityIndicator size={"large"} color={Colores.primary} />
      ) : (
        <View
          style={[
            width >= 768 ? tw`px-10 bg-white pb-20` : tw`px-3 bg-white`,
            { borderRadius: 20, paddingTop: 10 },
          ]}
        >
          <Text style={tw`text-xl mt-3`}>Pendientes de firmar</Text>
          {filtrarRecibos(false).length >= 1 ? (
            <View
              style={
                width >= 768
                  ? tw``
                  : [tw`p-1`, { borderRadius: 8 }]
              }
            >
              <FlatList
                data={filtrarRecibos(false)}
                keyExtractor={(item) => item?.id}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    style={[
                      tw`w-full flex flex-row border border-gray-300 mt-3 py-3`,
                      esweb ? tw`shadow-md` : "",
                      { borderRadius: 16 },
                    ]}
                    onPress={() =>
                      navigation.navigate("Detalle Recibo", { recibo: item })
                    }
                  >
                    <View style={
                      width >= 768
                        ? tw`p-3 items-center flex-2`
                        : [tw`p-1 items-center flex-3`]
                    }>
                    <View
                        style={[
                          tw`w-20 h-20 items-center justify-center`,
                          {
                            borderRadius: 16,
                            backgroundColor: `${Colores.danger}30`,
                          },
                        ]}
                      >
                        <MaterialCommunityIcons
                          name="file-remove"
                          size={50}
                          color={Colores.danger}
                        />
                      </View>
                    </View>
                    <View style={tw`flex-7 justify-around`}>
                      <Text style={tw`capitalize font-bold`}>{`${item?.tipo?.tipo
                        } ${moment(item?.periodo)
                          .locale("es")
                          .format("MMMM")}`}</Text>
                      <Text
                        style={tw`capitalize`}
                      >{`${user.nombre} ${user.apellido}`}</Text>
                      <View style={tw`flex-row`}>
                        <View style={tw`flex-1`}>
                          <Text>
                            Estado:{" "}
                            <Text style={{ color: Colores.danger }}>
                              Sin firmar
                            </Text>
                          </Text>
                        </View>
                        <View style={tw`flex-1`}>
                          <Text style={tw`text-gray-500 text-center`}>
                            {moment(item?.periodo).format("DD-MM-YYYY")}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </TouchableOpacity>
                )}
              />
            </View>
          ) : (
            <View
              style={[
                tw`flex p-5 items-center bg-slate-100 text-slate-300 justify-center`,
                { borderRadius: 16 },
              ]}
            >
              <Text>No tiene recibos pendientes</Text>
            </View>
          )}

<Text style={tw`text-xl mb-2 mt-5`}>Firmados</Text>
          {filtrarRecibos(true).length >= 1 ? (
            <View
              style={
                width >= 768
                  ? tw``
                  : [tw`p-1`, { borderRadius: 8 }]
              }
            >
              <FlatList
                data={filtrarRecibos(true)}
                keyExtractor={(item) => item?.id}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    style={[
                      tw`w-full flex flex-row border border-gray-300 mt-3 py-3`,
                      esweb ? tw`shadow-md` : "",
                      { borderRadius: 16 },
                    ]}
                    onPress={() =>
                      navigation.navigate("Detalle Recibo", { recibo: item })
                    }
                  >
                    <View style={
                      width >= 768
                        ? tw`p-3 items-center flex-2`
                        : [tw`p-1 items-center flex-3`]
                    }>
                      <View
                        style={[
                          tw`w-20 h-20 items-center justify-center`,
                          {
                            borderRadius: 16,
                            backgroundColor: `${Colores.success}30`,
                          },
                        ]}
                      >
                        <MaterialCommunityIcons
                          name="file-check"
                          size={50}
                          color={Colores.success}
                        />
                      </View>
                    </View>
                    <View style={tw`flex-7 justify-around`}>
                      <Text style={tw`capitalize font-bold`}>{`${item?.tipo?.tipo
                        } ${moment(item?.periodo)
                          .locale("es")
                          .format("MMMM")}`}</Text>
                      <Text
                        style={tw`capitalize`}
                      >{`${user.nombre} ${user.apellido}`}</Text>
                      <View style={tw`flex-row`}>
                        <View style={tw`flex-1`}>
                          <Text>
                            Estado:{" "}
                            <Text style={{ color: Colores.success }}>
                              Firmado
                            </Text>
                          </Text>
                        </View>
                        <View style={tw`flex-1`}>
                          <Text style={tw`text-gray-500 text-center`}>
                            {moment(item?.periodo).format("DD-MM-YYYY")}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </TouchableOpacity>
                )}
              />
            </View>
          ) : (
            <View
              style={[
                tw`flex p-5 items-center bg-slate-100 text-slate-300 justify-center`,
                { borderRadius: 16 },
              ]}
            >
              <Text>No tiene recibos firmados</Text>
            </View>
          )}
        </View>
      )}
    </PageContainer>
  );
}

export default MiReciboScreen;
