import { useAtom } from "jotai"
import { userAtom } from "../store/store"


const usePermisos = () => {
    const [user,_] = useAtom(userAtom);

    const validarPermiso = (slugsPermisos) => {
        if (user?.permisos && user.permisos.length) {
            const resultado = slugsPermisos.map( slug => {
                return user.permisos.some( permiso => permiso.slug === slug.toLowerCase());                
            });

            return resultado.some( res => res === true);
        }

        return false;
    }

    return { validarPermiso };
}

export default usePermisos;