import { View, Text, Image, ScrollView, TouchableOpacity } from "react-native";
import tw from "twrnc";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import { FontAwesome5 } from "@expo/vector-icons";

import { PageContainer } from "../../components/PageContainer";
import { Colores } from "../../constants/colores";
import { GoBackButton } from "../../components/GoBackButton";
import { MeGustaButton } from "../../components/Avisos/MeGustaButton";
import CarouselComponents from "../../components/carousel/CarouselComponents";
import CarouselSwiper from "../../components/carousel/CarouselSwiper";
import ColaboradorModalDetalle from "../../components/Colaboradores/ColaboradorModalDetalle";
import { useState } from "react";
import ModalMegustas from "../../components/Avisos/ModalMegustas";

export default function DetalleAvisoScreen(props) {
  const { aviso } = props.route.params;
  const [colaboradorSeleccionado, setColaboradorSeleccionado] = useState();
  const [modalDetalleColaborador, setModalDetalleColaborador] = useState(false);
  const [mostrarModalMeGustas, setMostrarModalMeGustas] = useState(false);

  return (
    <PageContainer>
      <View
        style={[tw`flex-1 w-full h-full`, { backgroundColor: Colores.dark }]}
      >
        <View style={tw`flex-2 justify-between`}>
          <View style={tw`flex-2 flex-row`}>
            <View style={tw`flex-1 items-start pt-4`}>
              <GoBackButton page="Avisos" color="#FFF" />
            </View>

            <View style={tw`flex-1 items-end pr-7 pt-5`}>
              {/* <Menu>
                  <MenuTrigger>
                    <FontAwesome5 name="ellipsis-h" size={20} color="#FFF" />
                  </MenuTrigger>
                  <MenuOptions style={tw`p-1`} optionsContainerStyle={{borderRadius:10, marginTop:20}}>

                    <MenuOption
                    onSelect={() => navigation.navigate("Editar Aviso", { aviso })}
                    style={tw`flex flex-row px-2 py-2`}
                    >
                      <FontAwesome5 name="pen" size={17} style={{color:Colores.primary}}/>
                      <Text style={[tw`ml-5`,{color:Colores.primary}]}>Editar</Text>
                    </MenuOption>


                    <MenuOption onSelect={() => eliminar(aviso.id)}
                    style={tw`flex flex-row px-2 py-2`}
                    >
                      <FontAwesome5 name="trash" size={17} style={{color:Colores.primary}}/>
                      <Text style={[tw`ml-5`,{color:Colores.primary}]}>Eliminar</Text>
                    </MenuOption>

                  </MenuOptions>
                </Menu> */}
            </View>
          </View>
          <View style={tw`flex-1 flex-row pl-2 mb-2`}>
            <View style={tw`justify-center flex-1`}>
              <TouchableOpacity
                onPress={() => {
                  setColaboradorSeleccionado(aviso.autor),
                    setModalDetalleColaborador(true);
                }}
              >
                <Image
                  source={{ uri: aviso?.autor.foto }}
                  style={tw`h-10 w-10 rounded-full`}
                />
              </TouchableOpacity>
            </View>
            <View style={tw`justify-center flex-6`}>
              <TouchableOpacity
                onPress={() => {
                  setColaboradorSeleccionado(aviso.autor),
                    setModalDetalleColaborador(true);
                }}
              >
                <Text
                  style={tw`font-semibold text-white capitalize`}
                >{`${aviso?.autor.nombre} ${aviso?.autor.apellido}`}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setColaboradorSeleccionado(aviso.autor),
                    setModalDetalleColaborador(true);
                }}
              >
                <Text
                  style={tw`text-white`}
                >{`${aviso?.autor.puesto_actual[0].nombre}`}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>

        {aviso.fotos.length > 0 ? (
          <View style={tw`h-1/2`}>
            <CarouselSwiper
              paginationLight
              archivos={aviso?.fotos}
              avisoId={aviso.id}
              megustaCount={aviso.megusta_count}
              megustaList={aviso.megusta}
              navigate={props.navigation.navigate}
              btnDisabled
            />
          </View>
        ) : null}
        <View style={tw`flex-4 px-3 pt-2`}>
          <View style={tw`flex-row`}>
            <View style={tw`flex-1 justify-center`}>
              {aviso.megusta_count > 0 ? (
                <TouchableOpacity onPress={() => setMostrarModalMeGustas(true)}>
                  <Text
                    style={tw`mt-2 text-white font-semibold`}
                  >{`${aviso.megusta_count} Me gusta`}</Text>
                </TouchableOpacity>
              ) : null}
            </View>
            <View style={tw`flex-1 items-end`}>
              <MeGustaButton
                aviso={aviso}
                avisoId={aviso.id}
                megustaCount={aviso.megusta_count}
                megustaList={aviso.megusta}
                navigate={props.navigation.navigate}
                colorIcon="white"
              />
            </View>
          </View>
          <ScrollView>
            <Text style={tw`text-lg font-semibold mt-2 text-white`}>
              {aviso?.titulo ?? ""}
            </Text>
            <Text style={tw` mt-3 mb-3 text-white`}>{aviso?.texto ?? ""}</Text>
          </ScrollView>
        </View>

        <ModalMegustas
          modalVisible={mostrarModalMeGustas}
          setModalVisible={setMostrarModalMeGustas}
          meGustas={aviso.megusta}
        />

        <ColaboradorModalDetalle
          inicio
          modalVisible={modalDetalleColaborador}
          setModalVisible={setModalDetalleColaborador}
          colaboradorSeleccionado={colaboradorSeleccionado}
        />
      </View>
    </PageContainer>
  );
}
